import * as React from 'react'

import { computed } from 'mobx'
import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import BaseActionButton from '../../../../BaseActionButton/BaseActionButton'
import QrCodesStore from '../../../QRCodes.store'
import { QRCodesViewFormStore } from '../QRCodesViewForm.store'

interface IProps {
  store: QRCodesViewFormStore
  qrCodesStore: QrCodesStore
}

enum Steps {
  START_QUEUE = 'START_QUEUE',
  START_SESSION = 'START_SESSION',
  END_SESSION = 'END_SESSION',
}

@observer
export default class QueueFooter extends React.Component<IProps> {
  public render(): JSX.Element {
    const {
      qrCodesStore: {
        toggleEndRideConfirm,
        toggleStartSessionShown,
        startQueue,
      },
      store: { userBusEnterMap },
    } = this.props

    const areUsersOnBus = Object.values(userBusEnterMap).length > 0

    return (
      <>
        {this.currentStep === Steps.START_QUEUE && (
          <BaseActionButton
            className="primary-theme medium-bold"
            onClick={startQueue}
            title={Localization.translator.startQueue}
            isEnabled
            isGrow
          />
        )}
        {this.currentStep === Steps.START_SESSION && (
          <BaseActionButton
            className="primary-theme medium-bold"
            onClick={toggleStartSessionShown}
            title={Localization.translator.startSession}
            isEnabled={areUsersOnBus}
            isGrow
          />
        )}
        {this.currentStep === Steps.END_SESSION && (
          <BaseActionButton
            className="primary-theme-inverted medium-bold"
            onClick={toggleEndRideConfirm}
            title={Localization.translator.endSessionForAll}
            isEnabled
            isGrow
          />
        )}
      </>
    )
  }

  @computed
  private get currentStep(): Steps {
    const {
      qrCodesStore: { isActive, isQueueActive },
    } = this.props

    if (!isActive) return Steps.START_QUEUE
    if (isQueueActive) return Steps.START_SESSION
    return Steps.END_SESSION
  }
}
