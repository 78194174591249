import * as React from 'react'

import { observer } from 'mobx-react'
import { Swipeable } from 'react-swipeable'

import DeliveryWorkflowCard from '../../../../ExpandableWorkflowCard/components/DeliveryWorkflowCard'
import DeliveriesMapViewStore from '../DeliveriesMapView.store'

const MAX_PERCENT = 100
const CARDS_OFFSET = 12

interface IProps {
  store: DeliveriesMapViewStore
}

@observer
export default class DeliveriesMapSwipeableCards extends React.Component<IProps> {
  public render() {
    const {
      isGallerySwipingDisabled,
      displayedSitemapDeliveries,
      displayedDeliveryIdx,
      showNextDelivery,
      showPreviousDelivery,
      openDeliveryDetails,
    } = this.props.store

    if (!displayedSitemapDeliveries.length || isGallerySwipingDisabled) {
      return null
    }

    const percentTranslate = MAX_PERCENT * displayedDeliveryIdx
    const pixelTranslate =
      CARDS_OFFSET * 2 + displayedDeliveryIdx * CARDS_OFFSET * 3

    return (
      <div className="deliveries-carousel full-width absolute">
        <Swipeable
          onSwipedRight={showPreviousDelivery}
          onSwipedLeft={showNextDelivery}
          trackMouse={true}
          className="full-width full-height overflow-hidden"
        >
          <div
            className="delivery-card-container nowrap py10"
            style={{
              transform: `translateX(calc(-${percentTranslate}% + ${pixelTranslate}px))`,
            }}
          >
            {displayedSitemapDeliveries.map(d => (
              <div
                key={d.id}
                className="delivery-workflow-card beautiful-shadow brada8 inline-block mr12 vertical-align-middle no-select bg-white"
              >
                <DeliveryWorkflowCard
                  delivery={d}
                  onClick={openDeliveryDetails}
                />
              </div>
            ))}
          </div>
        </Swipeable>
      </div>
    )
  }
}
