import KnownTranslatorKeys from '../../shared/localization/knownTranslatorKeys'
import { enumToList } from '../../shared/utils/converters'

enum ScanStationGroupingOption {
  NONE = 'None',
  SCANNER = 'Scanner',
  COMPANY = 'Company',
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  IS_ACTIVE = 'IsActive',
}

export const scanStationGroupingOptionList = enumToList(
  ScanStationGroupingOption,
)

export const getScanStationGroupingOptionTranslatorKey = (
  id: string,
): KnownTranslatorKeys => {
  switch (id) {
    case ScanStationGroupingOption.NONE:
      return KnownTranslatorKeys.none
    case ScanStationGroupingOption.SCANNER:
      return KnownTranslatorKeys.scannerName
    case ScanStationGroupingOption.COMPANY:
      return KnownTranslatorKeys.company
    case ScanStationGroupingOption.DAY:
      return KnownTranslatorKeys.day
    case ScanStationGroupingOption.WEEK:
      return KnownTranslatorKeys.week
    case ScanStationGroupingOption.MONTH:
      return KnownTranslatorKeys.month
    case ScanStationGroupingOption.IS_ACTIVE:
      return KnownTranslatorKeys.isActive
    default:
      throw new Error(id + ' is unhandled')
  }
}

export default ScanStationGroupingOption
