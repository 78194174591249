import {
  IGeoJson2DGeographicCoordinates,
  IGlobeViewCircle,
  IGlobeViewItemShapeInterface,
  IGlobeViewPin,
  IGlobeViewPolyline,
  IGlobeViewRectangle,
  IGlobeViewSpecificItemData,
  IGlobeViewTextBox,
  IPosition,
  ISitemapCircle,
  ISitemapItemShapeCoordinates,
  ISitemapItemShapeInterface,
  ISitemapPin,
  ISitemapPolyline,
  ISitemapRectangle,
  ISitemapSpecificItemData,
  ISitemapTextBox,
  LocationType,
  SitemapItemShapeType,
} from '~/client/graph'
import MapViewItemType from '~/client/src/shared/enums/MapViewItemType'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import Area from '~/client/src/shared/models/LocationObjects/Area'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import Gate from '~/client/src/shared/models/LocationObjects/Gate'
import InteriorDoor from '~/client/src/shared/models/LocationObjects/InteriorDoor'
import InteriorPath from '~/client/src/shared/models/LocationObjects/InteriorPath'
import Level from '~/client/src/shared/models/LocationObjects/Level'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import LocationIntegration from '~/client/src/shared/models/LocationObjects/LocationIntegration'
import LogisticsObject from '~/client/src/shared/models/LocationObjects/LogisticsObject'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import Route from '~/client/src/shared/models/LocationObjects/Route'
import Staging from '~/client/src/shared/models/LocationObjects/Staging'
import VerticalObject from '~/client/src/shared/models/LocationObjects/VerticalObject'
import Zone from '~/client/src/shared/models/LocationObjects/Zone'
import Sitemap from '~/client/src/shared/models/Sitemap'
import SitemapItem from '~/client/src/shared/models/SitemapItem'
import { DEFAULT_SITEMAP_ITEM_COLOR } from '~/client/src/shared/utils/SitemapItemsColors'

import EnglishTree from '../../../localization/english/tree'
import GlobeView from '../../../models/GlobeView'
import GlobeViewCircleProperties from './GlobeViewCircleProperties'
import GlobeViewIconProperties from './GlobeViewIconProperties'
import GlobeViewItemProperties, {
  GlobeViewShapeProperties,
} from './GlobeViewItemProperties'
import GlobeViewLabelProperties from './GlobeViewLabelProperties'
import GlobeViewPolyLineProperties from './GlobeViewPolyLineProperties'
import GlobeViewRectangleProperties from './GlobeViewRectangleProperties'
import MapViewItemBase from './MapViewItemBase'
import PolyLineShapeCoordinates from './PolyLineShapeCoordinates'
import SitemapCircleProperties from './SitemapCircleProperties'
import SitemapIconProperties from './SitemapIconProperties'
import SitemapItemProperties, {
  SitemapShapeProperties,
} from './SitemapItemProperties'
import SitemapLabelProperties from './SitemapLabelProperties'
import SitemapPolyLineProperties from './SitemapPolyLineProperties'
import SitemapRectangleProperties from './SitemapRectangleProperties'

const NEW_SITEMAP_ITEM_NAME_BY_TYPE = {
  [MapViewItemType.TextBox]: 'New Text Box',
  [MapViewItemType.Line]: 'New Line',
}

const ICON_BY_SITEMAP_ITEM_TYPE = {
  [MapViewItemType.Line]: MapViewLocationIcon.Line,
  [MapViewItemType.TextBox]: MapViewLocationIcon.TextBox,
}

const ICON_BY_ATTRIBUTE_TYPE = {
  [LocationType.Building]: MapViewLocationIcon.Building,
  [LocationType.Zone]: MapViewLocationIcon.Zone,
  [LocationType.Route]: MapViewLocationIcon.Route,
  [LocationType.Gate]: MapViewLocationIcon.Gate,
  [LocationType.OffloadingEquipment]: MapViewLocationIcon.Equipment,
  [LocationType.LogisticsObject]: MapViewLocationIcon.Logistics,
  [LocationType.VerticalObject]: MapViewLocationIcon.Stairs,
  [LocationType.Level]: MapViewLocationIcon.Level,
  [LocationType.Area]: MapViewLocationIcon.Area,
  [LocationType.Integration]: MapViewLocationIcon.Monitoring,
  [LocationType.Staging]: MapViewLocationIcon.Staging,
  [LocationType.InteriorDoor]: MapViewLocationIcon.InteriorDoor,
  [LocationType.InteriorPath]: MapViewLocationIcon.InteriorPath,
}

const DATA_OBJECTS_CONSTRUCTOR_BY_TYPE = {
  [LocationType.Building]: Building,
  [LocationType.Zone]: Zone,
  [LocationType.Route]: Route,
  [LocationType.Gate]: Gate,
  [LocationType.OffloadingEquipment]: OffloadingEquipment,
  [LocationType.LogisticsObject]: LogisticsObject,
  [LocationType.VerticalObject]: VerticalObject,
  [LocationType.Level]: Level,
  [LocationType.Area]: Area,
  [LocationType.Integration]: LocationIntegration,
  [LocationType.Staging]: Staging,
  [LocationType.InteriorDoor]: InteriorDoor,
  [LocationType.InteriorPath]: InteriorPath,
}

const LOGISTICS_TYPE_NAME_BY_ICON_NAME = {
  [MapViewLocationIcon.Bathroom]: EnglishTree.restrooms,
  [MapViewLocationIcon.Break]: EnglishTree.breakText,
  [MapViewLocationIcon.Canteen]: EnglishTree.canteen,
  [MapViewLocationIcon.Dumpster]: EnglishTree.dumpster,
  [MapViewLocationIcon.Entrance]: EnglishTree.access,
  [MapViewLocationIcon.HandWash]: EnglishTree.washArea,
  [MapViewLocationIcon.Medical]: EnglishTree.medicalArea,
  [MapViewLocationIcon.MeetingPoint]: EnglishTree.musterPoint,
  [MapViewLocationIcon.Parking]: EnglishTree.parking,
  [MapViewLocationIcon.Smoking]: EnglishTree.smokingArea,
  [MapViewLocationIcon.Temperature]: EnglishTree.covidCheckpoint,
  [MapViewLocationIcon.Tent]: EnglishTree.breakArea,
  [MapViewLocationIcon.Toilet]: EnglishTree.toilet,
  [MapViewLocationIcon.Walkway]: EnglishTree.walkway,
  [MapViewLocationIcon.ElectricalRoom]: EnglishTree.electricalRoom,
  [MapViewLocationIcon.Trailer]: EnglishTree.trailer,
}

const VERTICAL_OBJECT_TYPE_NAME_BY_ICON_NAME = {
  [MapViewLocationIcon.Stairs]: EnglishTree.stairway,
  [MapViewLocationIcon.Elevator]: EnglishTree.elevator,
  [MapViewLocationIcon.Shaft]: EnglishTree.shaft,
}

const OFFLOADING_EQUIPMENT_TYPE_NAME_BY_ICON_NAME = {
  [MapViewLocationIcon.Crane]: EnglishTree.crane,
  [MapViewLocationIcon.Hoist]: EnglishTree.hoist,
  [MapViewLocationIcon.AerialLift]: EnglishTree.aerialLift,
  [MapViewLocationIcon.Gradall]: EnglishTree.gradall,
  [MapViewLocationIcon.ForkLift]: EnglishTree.forkLift,
}

const EMPTY_SITEMAP_ITEM_NAME = ' - '

export default class MapViewItemFactory {
  public static fromSitemapItem(
    dataObject: LocationAttributeBase,
    item: SitemapItem,
    sitemap?: Sitemap,
  ) {
    const displayData: ISitemapSpecificItemData =
      sitemap?.getItemDisplayData?.(item.id) || ({} as ISitemapSpecificItemData)
    const isDisplayed = sitemap?.isItemDisplayed?.(item.id)

    const defaultColor = (dataObject || item).color

    const shape = MapViewItemFactory.getSitemapShapeProperties(
      displayData.shape,
      defaultColor,
    )
    const icon = MapViewItemFactory.getSitemapIconProperties(
      displayData.icon,
      shape,
    )
    const label = MapViewItemFactory.getSitemapLabelProperties(
      displayData.label,
      icon,
    )
    return new MapViewItemBase(
      dataObject,
      item,
      isDisplayed,
      new SitemapItemProperties(icon, label, shape),
    )
  }

  public static fromGlobeItem(
    dataObject: LocationAttributeBase,
    sitemapItem: SitemapItem,
    globe?: GlobeView,
  ) {
    const displayData: IGlobeViewSpecificItemData =
      globe?.displayDataMap[sitemapItem.id]?.item ||
      ({} as IGlobeViewSpecificItemData)
    const defaultColor = (dataObject || sitemapItem).color
    const shape = MapViewItemFactory.getGlobeViewShapeProperties(
      displayData.shape,
      defaultColor,
      sitemapItem?.shapeCoordinates,
    )

    const isDisplayed =
      globe?.isItemDisplayed?.(sitemapItem.id) && !!sitemapItem.coordinates

    const icon = MapViewItemFactory.getGlobeViewIconProperties(displayData.icon)
    const label = MapViewItemFactory.getGlobeViewLabelProperties(
      displayData.label,
    )
    const newItem = new MapViewItemBase(
      dataObject,
      sitemapItem,
      isDisplayed,
      null,
      new GlobeViewItemProperties(icon, label, shape),
    )
    if (
      sitemapItem?.shapeCoordinates?.type &&
      !newItem.globeViewItemProperties.shapeProperties
    ) {
      newItem.updateGlobeViewShape(sitemapItem.shapeCoordinates.type)
    }
    return newItem
  }

  public static getSitemapIconProperties(
    icon: ISitemapPin,
    shape?: SitemapShapeProperties,
  ): SitemapIconProperties {
    return (
      icon &&
      new SitemapIconProperties(
        !icon.isHidden,
        icon.position || (shape && shape.calculateIconPosition()),
      )
    )
  }

  public static getSitemapLabelProperties(
    label: ISitemapTextBox,
    icon?: SitemapIconProperties,
  ): SitemapLabelProperties {
    return (
      label &&
      new SitemapLabelProperties(
        label.fontSize,
        label.isTextBoxDisplayed,
        !label.isHidden,
        label.position || (icon && icon.calculateLabelPosition()),
        label.color,
      )
    )
  }

  public static getSitemapShapeProperties(
    shape: ISitemapItemShapeInterface,
    defaultColor: string,
  ): SitemapShapeProperties {
    if (!shape) {
      return null
    }
    switch (shape.type) {
      case SitemapItemShapeType.Polyline:
        const polyline = MapViewItemFactory.getSitemapPolyLineProperties(
          shape as ISitemapPolyline,
          defaultColor,
          (shape as ISitemapPolyline).isClosed,
        )
        return polyline
      case SitemapItemShapeType.Rectangle:
        return MapViewItemFactory.getSitemapRectangleProperties(
          shape as ISitemapRectangle,
          defaultColor,
        )
      case SitemapItemShapeType.Circle:
        return MapViewItemFactory.getSitemapCircleProperties(
          shape as ISitemapCircle,
          defaultColor,
        )
    }
  }

  public static getSitemapPolyLineProperties(
    polyline: ISitemapPolyline,
    defaultColor: string,
    isClosed?: boolean,
  ): SitemapPolyLineProperties {
    return new SitemapPolyLineProperties(
      polyline.lineWidth,
      polyline.lineColor || defaultColor,
      polyline.fillColor || defaultColor,
      polyline.fillOpacity,
      isClosed,
      polyline.arrowPosition,
      polyline.points,
      polyline.isDisplayed,
    )
  }

  public static getSitemapRectangleProperties(
    rectangle: ISitemapRectangle,
    defaultColor: string,
  ): SitemapRectangleProperties {
    return new SitemapRectangleProperties(
      rectangle.lineWidth,
      rectangle.lineColor || defaultColor,
      rectangle.fillColor || defaultColor,
      rectangle.fillOpacity,
      rectangle.position,
      rectangle.width,
      rectangle.height,
      rectangle.rotation,
      rectangle.isDisplayed,
    )
  }

  public static getSitemapCircleProperties(
    circle: ISitemapCircle,
    defaultColor: string,
  ): SitemapCircleProperties {
    return new SitemapCircleProperties(
      circle.lineWidth,
      circle.lineColor || defaultColor,
      circle.fillColor || defaultColor,
      circle.fillOpacity,
      circle.position,
      circle.radius,
      circle.isDivided,
      circle.divisionStartAngle,
      circle.divisionEndAngle,
      circle.isDisplayed,
    )
  }

  public static getGlobeViewIconProperties(
    icon: IGlobeViewPin,
  ): GlobeViewIconProperties {
    return icon && new GlobeViewIconProperties(!icon.isHidden)
  }

  public static getGlobeViewLabelProperties(
    label: IGlobeViewTextBox,
  ): GlobeViewLabelProperties {
    return (
      label &&
      new GlobeViewLabelProperties(
        label.fontSize,
        label.isTextBoxDisplayed,
        !label.isHidden,
        label.color,
      )
    )
  }

  public static getGlobeViewShapeProperties(
    shape: IGlobeViewItemShapeInterface,
    defaultColor: string,
    shapeCoordinates?: ISitemapItemShapeCoordinates,
  ): GlobeViewShapeProperties {
    if (!shape) {
      return null
    }

    switch (shapeCoordinates?.type || shape.type) {
      case SitemapItemShapeType.Polyline:
        const polyline = MapViewItemFactory.getGlobeViewPolyLineProperties(
          shape as IGlobeViewPolyline,
          defaultColor,
          shapeCoordinates?.isClosed,
        )
        return polyline
      case SitemapItemShapeType.Rectangle:
        return MapViewItemFactory.getGlobeViewRectangleProperties(
          shape as IGlobeViewRectangle,
          defaultColor,
        )
      case SitemapItemShapeType.Circle:
        return MapViewItemFactory.getGlobeViewCircleProperties(
          shape as IGlobeViewCircle,
          defaultColor,
        )
    }
  }

  public static getGlobeViewPolyLineProperties(
    polyline: IGlobeViewPolyline,
    defaultColor: string,
    isClosed?: boolean,
  ): GlobeViewPolyLineProperties {
    return new GlobeViewPolyLineProperties(
      polyline.lineWidth,
      polyline.lineColor || defaultColor,
      polyline.fillColor || defaultColor,
      polyline.fillOpacity,
      isClosed,
      polyline.arrowPosition,
      polyline.isDisplayed,
    )
  }

  public static getGlobeViewRectangleProperties(
    rectangle: IGlobeViewRectangle,
    defaultColor: string,
  ): GlobeViewRectangleProperties {
    return new GlobeViewRectangleProperties(
      rectangle.lineWidth,
      rectangle.lineColor || defaultColor,
      rectangle.fillColor || defaultColor,
      rectangle.fillOpacity,
      rectangle.isDisplayed,
    )
  }

  public static getGlobeViewCircleProperties(
    circle: IGlobeViewCircle,
    defaultColor: string,
  ): GlobeViewCircleProperties {
    return new GlobeViewCircleProperties(
      circle.lineWidth,
      circle.lineColor || defaultColor,
      circle.fillColor || defaultColor,
      circle.fillOpacity,
      circle.isDisplayed,
    )
  }

  public static createDataLessItem(
    type: MapViewItemType,
    projectId: string,
    existingNames: string[],
  ) {
    const sitemapItem = MapViewItemFactory.createSitemapItem(projectId, type)
    sitemapItem.name = MapViewItemFactory.getValidatedName(
      sitemapItem.name,
      existingNames,
    )
    return new MapViewItemBase(
      null,
      sitemapItem,
      true,
      new SitemapItemProperties(
        null,
        MapViewItemFactory.getSitemapItemLabel(sitemapItem),
        MapViewItemFactory.getSitemapItemShape(sitemapItem),
      ),
      new GlobeViewItemProperties(
        null,
        MapViewItemFactory.getGlobeViewItemLabel(sitemapItem),
        MapViewItemFactory.getGlobeViewItemShape(sitemapItem),
      ),
    )
  }

  public static getSitemapItemLabel(item: SitemapItem) {
    if (item.type === MapViewItemType.TextBox) {
      return new SitemapLabelProperties()
    }
  }

  public static getSitemapItemShape(item: SitemapItem) {
    if (item.type === MapViewItemType.Line) {
      return new SitemapPolyLineProperties(null, item.color, item.color)
    }
  }

  public static getGlobeViewItemLabel(item: SitemapItem) {
    if (item.type === MapViewItemType.TextBox) {
      return new GlobeViewLabelProperties()
    }
  }

  public static getGlobeViewItemShape(item: SitemapItem) {
    if (item.type === MapViewItemType.Line) {
      return new GlobeViewPolyLineProperties(null, item.color, item.color)
    }
  }

  public static createDataItem(
    type: LocationType,
    iconName: MapViewLocationIcon,
    position: IPosition,
    projectId: string,
    existingNames: string[],
    color?: string,
    coordinates?: IGeoJson2DGeographicCoordinates,
  ) {
    const typeName = MapViewItemFactory.getTypeName(type, iconName)
    const dataObject = MapViewItemFactory.createDataAttribute(
      type,
      projectId,
      iconName,
      typeName,
      color,
    )
    dataObject.name = MapViewItemFactory.getValidatedName(
      dataObject.name,
      existingNames,
    )

    const sitemapItem = MapViewItemFactory.createSitemapItem(
      projectId,
      null,
      color,
      coordinates,
    )

    const sitemapIcon = new SitemapIconProperties(true, position)
    const sitemapLabel = new SitemapLabelProperties(
      null,
      false,
      type !== LocationType.LogisticsObject,
      sitemapIcon.calculateLabelPosition(),
    )

    const globeViewIcon = new GlobeViewIconProperties(true)
    const globeViewLabel = new GlobeViewLabelProperties(
      null,
      false,
      type !== LocationType.LogisticsObject,
    )

    return new MapViewItemBase(
      dataObject,
      sitemapItem,
      true,
      new SitemapItemProperties(sitemapIcon, sitemapLabel, null),
      new GlobeViewItemProperties(globeViewIcon, globeViewLabel, null),
    )
  }

  private static getTypeName(
    type: LocationType,
    iconName: MapViewLocationIcon,
  ) {
    switch (type) {
      case LocationType.LogisticsObject:
        return LOGISTICS_TYPE_NAME_BY_ICON_NAME[iconName]
      case LocationType.VerticalObject:
        return VERTICAL_OBJECT_TYPE_NAME_BY_ICON_NAME[iconName]
      case LocationType.OffloadingEquipment:
        return OFFLOADING_EQUIPMENT_TYPE_NAME_BY_ICON_NAME[iconName]
      default:
        return null
    }
  }

  public static createDataAttribute(
    type: LocationType,
    projectId: string,
    iconName: MapViewLocationIcon,
    typeName?: string,
    color?: string,
  ) {
    const Constructor = DATA_OBJECTS_CONSTRUCTOR_BY_TYPE[type]
    return (
      Constructor &&
      new Constructor(
        null,
        typeName || type,
        color || DEFAULT_SITEMAP_ITEM_COLOR,
        iconName || ICON_BY_ATTRIBUTE_TYPE[type],
        projectId,
      )
    )
  }

  public static getValidatedName(name: string, existingNames: string[]) {
    let validatedName = name
    let counter = 1
    while (existingNames.includes(validatedName)) {
      counter++
      validatedName = `${name} ${counter}`
    }
    return validatedName
  }

  private static createSitemapItem(
    projectId: string,
    type?: MapViewItemType,
    color?: string,
    coordinates?: IGeoJson2DGeographicCoordinates,
  ) {
    return new SitemapItem(
      null,
      NEW_SITEMAP_ITEM_NAME_BY_TYPE[type] || EMPTY_SITEMAP_ITEM_NAME,
      color || DEFAULT_SITEMAP_ITEM_COLOR,
      type,
      ICON_BY_SITEMAP_ITEM_TYPE[type],
      projectId,
      null,
      null,
      null,
      null,
      null,
      coordinates,
      type === MapViewItemType.Line
        ? new PolyLineShapeCoordinates([], false)
        : null,
    )
  }
}
