import * as React from 'react'

import { classList } from 'react-classlist-helper'

import './Checkbox.scss'

interface IProps {
  isChecked?: boolean
  isMinus?: boolean
  isDisabled?: boolean
  className?: string
  label?: string
  labelClassName?: string
  isCentered?: boolean
  shouldDisplayAsRadio?: boolean
  darkMode?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export default class Checkbox extends React.Component<IProps> {
  public static defaultProps = {
    isChecked: false,
    isCentered: false,
  }

  public render() {
    const {
      onClick,
      isChecked,
      className,
      isDisabled,
      isMinus,
      label,
      isCentered,
      labelClassName,
      shouldDisplayAsRadio,
      darkMode,
    } = this.props

    return (
      <div
        onClick={onClick}
        className={classList({
          'inactive-element': isDisabled,
          'no-grow pr12': !isCentered,
          'row filter-option-checkbox-container': true,
          'x-center full-width': isCentered,
          'p-radio': shouldDisplayAsRadio,
          [`p-checkbox ${className || ''}`]: true,
          'p-checkbox-checked': isChecked,
        })}
      >
        <div
          className={classList({
            'row x-center p-checkbox-box p-component no-grow': true,
            'bg-white': darkMode,
          })}
        >
          <span
            className={classList({
              'p-checkbox-icon p-c pi': true,
              'pi-check': isChecked,
              'pi-minus': isMinus,
            })}
          />
        </div>
        {label && (
          <span
            className={classList({
              ml12: true,
              [labelClassName]: !!labelClassName,
            })}
          >
            {label}
          </span>
        )}
      </div>
    )
  }
}
