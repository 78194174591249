import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconName, IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import CompactActionsMenuModal from '~/client/src/shared/components/CompactActionsMenuModal/CompactActionsMenuModal'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import SitePermit from '~/client/src/shared/models/Permit'
import SitePermitFollowingsStore from '~/client/src/shared/stores/domain/SitePermitFollowings.store'

import SitePermitCreationFormStore from '../SitePermitCreationForm.store'
import PermitPdfReportButton from './PermitPdfReportButton'

// localization: translated

interface IProps {
  isShown: boolean
  onHide(): void
  onUpdateStatusClick(): void
  onReportSubmitClick(
    workflow: SitePermit,
    additionalRecipientIds?: string[],
    callbackFn?: () => void,
  ): void
  onDuplicateClick(workflow: SitePermit): void

  store: SitePermitCreationFormStore

  sitePermitFollowingsStore?: SitePermitFollowingsStore
}

interface IActionItem {
  text?: string
  additionalText?: string
  iconName?: IconName
  isShown?: boolean
  isDisabled?: boolean
  hasChevronRightIcon?: boolean
  textColorClassName?: string
  isReportBtn?: boolean
  onClick?(event?: React.SyntheticEvent): void
}

const ICON_SIZE = 18

@inject('sitePermitFollowingsStore')
@observer
export default class FormActionsMenu extends React.Component<IProps> {
  public render() {
    const { isShown, onHide } = this.props

    return (
      <CompactActionsMenuModal
        isShown={isShown}
        onHide={onHide}
        ignoredOutsideElementId="form-menu-button"
      >
        {this.actionItems.map((aItem, idx) =>
          aItem.isReportBtn
            ? this.renderReportPdfButton(idx)
            : this.renderActionItem(idx, aItem),
        )}
      </CompactActionsMenuModal>
    )
  }

  private renderActionItem(
    key: React.Key,
    {
      isShown,
      isDisabled,
      text,
      additionalText,
      iconName,
      hasChevronRightIcon,
      textColorClassName,
      onClick,
    }: IActionItem,
  ): JSX.Element {
    if (!isShown) return null

    return (
      <div
        key={key}
        className={classList({
          'common-row row': true,
          opacity5: isDisabled,
        })}
        onClick={onClick}
      >
        <Icon
          icon={iconName}
          size={ICON_SIZE}
          className={classList({
            'text mr10': true,
            'dark-gray': !textColorClassName,
            [textColorClassName]: !!textColorClassName,
          })}
        />
        <div className="col py2">
          <span
            className={classList({
              'text extra-large line-24': true,
              [textColorClassName]: !!textColorClassName,
            })}
          >
            {text}
          </span>
          {additionalText && (
            <span className="text large grey line-extra-large">
              {additionalText}
            </span>
          )}
        </div>
        {hasChevronRightIcon && (
          <Icon
            icon={IconNames.CHEVRON_RIGHT}
            size={ICON_SIZE}
            className="text dark-gray ml10"
          />
        )}
      </div>
    )
  }

  private renderReportPdfButton(key: React.Key): JSX.Element {
    return (
      <PermitPdfReportButton
        key={key}
        className="common-row"
        store={this.props.store}
        onReportSubmitClick={this.props.onReportSubmitClick}
        iconSize={ICON_SIZE}
      />
    )
  }

  private get actionItems(): IActionItem[] {
    return [
      {
        isShown: true,
        text: Localization.translator.duplicateCurrentWorkflow,
        additionalText:
          this.isDuplicateDisabled &&
          Localization.translator.olderWorkflowCantBeDuplicate,
        iconName: IconNames.DUPLICATE,
        isDisabled: this.isDuplicateDisabled,
        onClick: this.duplicateWorkflow,
      },
      {
        isShown: !this.isDeleted,
        text: this.isAlreadyFollowed
          ? Localization.translator.unsubscribe
          : Localization.translator.subscribe_verb,
        additionalText: this.isAlreadyFollowed
          ? Localization.translator.followFormMenuDescr.toStop
          : Localization.translator.followFormMenuDescr.toReceive,
        iconName: this.isAlreadyFollowed
          ? IconNames.STAR
          : IconNames.STAR_EMPTY,
        onClick: this.toggleFollowing,
      },
      {
        isShown: this.isChangeStatusShown,
        text: Localization.translator.updateStatus,
        iconName: IconNames.REFRESH,
        onClick: this.props.onUpdateStatusClick,
        hasChevronRightIcon: true,
      },
      {
        isReportBtn: true,
      },
      {
        isShown: !this.isDeleted,
        isDisabled: this.isDeleteDisabled,
        text: Localization.translator.deleteForm,
        additionalText: this.isDeleteDisabled && this.deleteButtonText,
        iconName: IconNames.TRASH,
        onClick: this.toggleDeleteConfirm,
        textColorClassName: 'red',
      },
    ]
  }

  private get isDeleted(): boolean {
    return this.props.store.existingPermit?.isDeleted
  }

  private get isDeleteDisabled(): boolean {
    const { existingPermit, isFormEditAllowedForUser } = this.props.store
    return existingPermit?.isDone || !isFormEditAllowedForUser
  }

  private get deleteButtonText(): string {
    const { existingPermit, isFormEditAllowedForUser } = this.props.store
    if (existingPermit?.isDone) {
      return Localization.translator.formDeleteMenuDescr.isDone
    }
    if (!isFormEditAllowedForUser) {
      return Localization.translator.formDeleteMenuDescr.onlyFor
    }
  }

  private get isChangeStatusShown(): boolean {
    return !this.isDeleted && this.props.store.isAdminOrFormsMaster
  }

  private get isAlreadyFollowed(): boolean {
    const { store, sitePermitFollowingsStore } = this.props
    return sitePermitFollowingsStore.isEntityFollowed(store.existingPermit.id)
  }

  private get isDuplicateDisabled(): boolean {
    return !this.props.store.isActualTypeVersion
  }

  private toggleFollowing = () => {
    const { store, sitePermitFollowingsStore } = this.props
    return sitePermitFollowingsStore.toggleEntityFollowing(
      store.existingPermit.id,
    )
  }

  private toggleDeleteConfirm = (event: React.SyntheticEvent) => {
    if (this.isDeleteDisabled) {
      event?.stopPropagation()
      return
    }

    this.props.store.toggleDeleteConfirmModal()
  }

  private duplicateWorkflow = (event: React.SyntheticEvent) => {
    if (this.isDuplicateDisabled) {
      event?.stopPropagation()
      return
    }

    this.props.onDuplicateClick(this.props.store.existingPermit)
  }
}
