import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import StatusUpdateInputBase from '~/client/src/shared/components/ActivityItemContentWithStatusUpdate/componets/StatusUpdateInputBase'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'

import ProjectDateStore from '../../../stores/ui/ProjectDate.store'
import BulkStatusUpdateStore, { ALL_COMPANIES } from '../BulkStatusUpdate.store'

// translated

interface IProps {
  commonStatusUpdate: StatusUpdate
  date: Date
  company: string
  activity: Activity
  eventsStore?: EventsStore
  projectDateStore?: ProjectDateStore
  store: BulkStatusUpdateStore
}

const STEP = 5

@inject('eventsStore', 'projectDateStore')
@observer
export default class BulkPercentCompleteUpdate extends React.Component<IProps> {
  private get openStatusMap(): Map<string, boolean> {
    return this.props.store.percentCompleteInputOpenStatus
  }

  private get loadingStatusMap(): Map<string, boolean> {
    return this.props.store.percentCompleteInputLoadingStatus
  }

  private get valueStatusMap(): Map<string, number> {
    return this.props.store.percentCompleteInputValueStatus
  }

  private get activityKey(): string {
    const { activity, company, date } = this.props
    return `${activity.code}-${company}-${date.getTime()}`
  }

  @computed
  private get didValueUpdate(): boolean {
    const { commonStatusUpdate, company, date, store, projectDateStore } =
      this.props
    const { dateFor, didUpdateByCompany } = commonStatusUpdate

    if (store.isConfirmModalShown) {
      return false
    }

    if (!projectDateStore.isSameDay(new Date(+dateFor), date)) {
      return false
    }

    if (company === ALL_COMPANIES) {
      return store.allCompanies.some(companyName =>
        didUpdateByCompany(
          companyName,
          projectDateStore.isSameDay,
          true,
          false,
        ),
      )
    }

    return didUpdateByCompany(company, projectDateStore.isSameDay, true, false)
  }

  private get leftCaption(): JSX.Element {
    const { percentCompleteInputMessage, areDaysWithoutPercentCompleteUpdate } =
      this.props.store

    if (percentCompleteInputMessage) {
      return (
        <div className="row y-center">
          <Icons.CheckFillBlue className="no-grow" />
          <div className="text large primary-blue pl5">
            {percentCompleteInputMessage}
          </div>
        </div>
      )
    }

    if (this.didValueUpdate && areDaysWithoutPercentCompleteUpdate) {
      return (
        <div
          className="text red large"
          onClick={this.bulkUpdatePercentComplete}
        >
          {Localization.translator.estimateMissing}
        </div>
      )
    }

    return (
      <div className="text red extra-large">
        {Localization.translator.PComplete}
      </div>
    )
  }

  private get icon(): JSX.Element {
    if (this.didValueUpdate) {
      return <Icons.StatusBadge className="col" />
    }
    return <Icons.StatusBadgeGray className="col" />
  }

  public render() {
    const {
      activity: { getTodayPercentPosition },
      store,
    } = this.props
    const {
      currentPercentCompleteValue,
      updatePercentCompleteForSelection,
      clearActualButtonsState,
    } = store
    const normalizedPercentComplete =
      Math.round(getTodayPercentPosition(this.props.projectDateStore) / STEP) *
      STEP

    return (
      <StatusUpdateInputBase
        value={currentPercentCompleteValue}
        didUpdate={this.didValueUpdate}
        leftCaption={this.leftCaption}
        icon={this.icon}
        targetValue={normalizedPercentComplete}
        step={STEP}
        onChange={updatePercentCompleteForSelection}
        openStatusMap={this.openStatusMap}
        loadingStatusMap={this.loadingStatusMap}
        valueStatusMap={this.valueStatusMap}
        activityKey={this.activityKey}
        onInputClosed={clearActualButtonsState}
        onInputValueChanged={this.onInputValueChanged}
      />
    )
  }

  private onInputValueChanged = (value: number) => {
    const { isActualFinishSettingSelected, clearActualButtonsState } =
      this.props.store
    if (isActualFinishSettingSelected && value !== StatusUpdate.MAX_PERCENT) {
      clearActualButtonsState()
    }
  }

  private bulkUpdatePercentComplete = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    this.props.store.bulkUpdatePercentComplete()
  }
}
