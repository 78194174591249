import React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import QrCodesStore from '../../../QRCodes.store'
import { QRCodesViewFormStore, TabIds } from '../QRCodesViewForm.store'
import QueueFooter from './QueueFooter'
import RegularFooter from './RegularFooter'

interface IProps {
  store: QRCodesViewFormStore
  qrCodesStore: QrCodesStore
}

@observer
export default class ScannerFooter extends React.Component<IProps> {
  public render(): JSX.Element {
    {
      const {
        qrCodesStore: { isActive, isFullScreenMode },
        store: { userBusEnterMap, selectedTabId },
      } = this.props

      const passengersCount: number = Object.keys(userBusEnterMap)?.length
      const isScanView = selectedTabId === TabIds.Scan

      return (
        <div className="relative bt-light-input-border qr-codes-scanner-active">
          <div className="col pb20">
            <div className="row">
              {isActive && !!passengersCount && isScanView && (
                <div className="row text large px16 py8 bb-light-input-border">
                  <Icons.StatusUpdateStateGray
                    className="row pr6 no-grow"
                    size={16}
                  />
                  {Localization.translator.activeUsers}
                  <span className="text bold large ml5">{passengersCount}</span>
                </div>
              )}
            </div>
            <div
              className={classList({
                'text center full-width large pa8': true,
                'orange bold': isActive,
                light: !isActive,
              })}
            >
              {isActive
                ? Localization.translator.scannerActive
                : Localization.translator.scannerInactive}
            </div>
            {!isFullScreenMode && (
              <div className="row x-evenly px16">
                {this.renderActiveFooter()}
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  private renderActiveFooter(): JSX.Element {
    const { isQueueScanner } = this.props.qrCodesStore

    if (isQueueScanner) {
      return (
        <QueueFooter
          store={this.props.store}
          qrCodesStore={this.props.qrCodesStore}
        />
      )
    }

    return (
      <RegularFooter
        store={this.props.store}
        qrCodesStore={this.props.qrCodesStore}
      />
    )
  }
}
