import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { Content, Header, View } from '~/client/src/shared/components/Layout'
import ContactHelp from '~/client/src/shared/components/Login/ContactHelp/ContactHelp'
import ConfirmResetPasswordForm from '~/client/src/shared/components/PasswordResetView/components/ConfirmResetPasswordForm/ConfirmResetPassword'
import RequestResetPasswordForm from '~/client/src/shared/components/PasswordResetView/components/RequestResetPasswordForm/RequestResetPasswordForm'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import EventsStore from '../../stores/EventStore/Events.store'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import PasswordResetViewStore from './PasswordResetView.store'

enum ResetPasswordViewModes {
  REQUEST_RESET,
  CONFIRM_RESET,
}

interface IProps {
  resetCode?: string
  email?: string
  eventsStore?: EventsStore
}

@inject('eventsStore')
@observer
export default class PasswordResetView extends React.Component<IProps> {
  private readonly store: PasswordResetViewStore
  private readonly mode: ResetPasswordViewModes =
    ResetPasswordViewModes.REQUEST_RESET

  public constructor(props: IProps) {
    super(props)

    const { resetCode, eventsStore, email } = props

    if (resetCode) {
      this.mode = ResetPasswordViewModes.CONFIRM_RESET
    }

    this.store = new PasswordResetViewStore(eventsStore)
    this.store.init(resetCode, email)
  }

  public componentWillUnmount() {
    this.store.reset()
  }

  public render() {
    const { error, success } = this.store

    return (
      <View>
        <Header>
          {success && <div className="text green bold pa10">{success}</div>}
          {error && <div className="text red bold pa10">{error}</div>}
        </Header>
        <Content className="pa10">
          {this.content}
          <ContactHelp
            mailto="info@struxhub.com"
            hint={Localization.translator.contactHelpAtMail(
              'info@struxhub.com',
            )}
            subject={Localization.translator.contactHelp}
          />
          {Localization.shouldShowLanguageSelector && (
            <LanguageSelector className="extra-large mt10 primary-blue" />
          )}
        </Content>
      </View>
    )
  }

  private get content() {
    const {
      requestPasswordReset,
      confirmPasswordReset,
      inputValueChange,
      inputValueReset,
      getValueByInputName,
    } = this.store

    if (this.mode === ResetPasswordViewModes.CONFIRM_RESET) {
      return (
        <ConfirmResetPasswordForm
          onConfirmPasswordReset={confirmPasswordReset}
          onChange={inputValueChange}
          inputValueReset={inputValueReset}
          getValue={getValueByInputName}
        />
      )
    }

    return (
      <RequestResetPasswordForm
        onRequestPasswordReset={requestPasswordReset}
        onChange={inputValueChange}
        inputValueReset={inputValueReset}
        getValue={getValueByInputName}
      />
    )
  }
}
