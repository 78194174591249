import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import ModeSelector from '~/client/src/mobile/components/ModeSelector/ModeSelector'
import { ActivitiesTreeNodeTypes } from '~/client/src/shared/enums/ActivitiesTreeNodeTypes'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'

interface ICollapseControl {
  areAllCollapsed: boolean
  expandType: ActivitiesTreeNodeTypes
  activityFiltersStore?: ActivityFiltersStore
  onExpandTypeChanged: (
    expandType: ActivitiesTreeNodeTypes,
    collapseAll?: boolean,
  ) => void
  onModeToggled: () => void
}

@inject('activityFiltersStore')
@observer
export default class CollapseControl extends React.Component<ICollapseControl> {
  public render() {
    const { areAllCollapsed, onModeToggled } = this.props

    return (
      <div className="row text primary-blue uppercase ba-light-grey py15 pl20 relative">
        <div onClick={this.expandNodes}>
          Expand
          {!areAllCollapsed && (
            <span className="pl20" onClick={this.collapseNodes}>
              Close
            </span>
          )}
        </div>
        <ModeSelector onModeToggled={onModeToggled} />
      </div>
    )
  }

  @action.bound
  private expandNodes(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    switch (this.props.expandType) {
      case null:
        this.props.onExpandTypeChanged(ActivitiesTreeNodeTypes.LEVEL_CONTAINER)
        break

      case ActivitiesTreeNodeTypes.LEVEL_CONTAINER:
        if (!this.props.activityFiltersStore.areCompaniesEnabled) {
          this.props.onExpandTypeChanged(null, false)
          break
        }
        this.props.onExpandTypeChanged(ActivitiesTreeNodeTypes.ZONE_CONTAINER)
        break

      case ActivitiesTreeNodeTypes.ZONE_CONTAINER:
        this.props.onExpandTypeChanged(
          ActivitiesTreeNodeTypes.COMPANY_CONTAINER,
        )
        break

      default:
        return
    }
  }

  @action.bound
  private collapseNodes(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    switch (this.props.expandType) {
      case ActivitiesTreeNodeTypes.COMPANY_CONTAINER:
        this.props.onExpandTypeChanged(ActivitiesTreeNodeTypes.ZONE_CONTAINER)
        break
      case ActivitiesTreeNodeTypes.ZONE_CONTAINER:
        this.props.onExpandTypeChanged(ActivitiesTreeNodeTypes.LEVEL_CONTAINER)
        break
      case ActivitiesTreeNodeTypes.LEVEL_CONTAINER:
        this.props.onExpandTypeChanged(null, true)
        break
      default:
        this.props.onExpandTypeChanged(ActivitiesTreeNodeTypes.LEVEL_CONTAINER)
        break
    }
  }
}
