enum DeliveryDetailsActions {
  SHOW_FORM = 'show-form',
  CHANGE_INSIDE_BOOKING_DEADLINE = 'change-inside-booking-deadline',
  SIMPLIFIED_FORM_FILLING = 'simplified-form-filling',
  DELETE_DELIVERY = 'delete-delivery',
  SCHEDULE_DELIVERY = 'schedule-delivery',
  SHOW_DATE_CONFIRM_DIALOG = 'show-date-confirm-dialog',
  SHOW_NON_WORK_TIMES_DIALOG = 'show-non-work-times-dialog',
  MARK_REQUEST_AS_UNSCHEDULED = 'mark_request_as_unscheduled',
  DUPLICATE_DELIVERY = 'duplicate-delivery',
  CREATE_FROM_TO_DELIVERY = 'create-from-to-delivery',
  SELECT_RESTRICTED_ATTRS = 'select-restricted-attrs',
  CANCEL_DELIVERY = 'cancel-delivery',
}

export default DeliveryDetailsActions
