import * as React from 'react'

import { Radio, RadioGroup } from '@blueprintjs/core'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import {
  CancelationReason,
  cancelationReasonsList,
} from '~/client/src/shared/enums/CancelationReason'
import InitialState from '~/client/src/shared/stores/InitialState'
import getCancelationReasonTitle from '~/client/src/shared/utils/getCancelationReasonTitle'

import StruxhubTextArea from '../../../StruxhubInputs/StruxhubTextArea'

// localization: translated

interface IProps {
  onChange: (reason?: string) => void

  state?: InitialState
}

@inject('state')
@observer
export default class ReasonOptions extends React.Component<IProps> {
  @observable private selectedOption: CancelationReason
  @observable private otherReason: string

  public render() {
    return (
      <>
        <RadioGroup
          className="my12"
          onChange={this.onChangeReasonOption}
          selectedValue={this.selectedOption}
        >
          {this.availableReasons.map((option, index) => (
            <Radio value={option} key={index}>
              {getCancelationReasonTitle(option)}
            </Radio>
          ))}
        </RadioGroup>
        {this.isOtherReason && (
          <StruxhubTextArea
            isMinimalisticMode={true}
            value={this.otherReason}
            onChange={this.onInputOtherReason}
          />
        )}
      </>
    )
  }

  @action.bound
  private onInputOtherReason(event: React.FormEvent<HTMLTextAreaElement>) {
    this.otherReason = event.currentTarget.value
    this.props.onChange(this.otherReason)
  }

  @action.bound
  private onChangeReasonOption(event: React.FormEvent<HTMLInputElement>) {
    this.selectedOption = event.currentTarget.value as CancelationReason
    this.props.onChange(getCancelationReasonTitle(this.selectedOption))

    if (this.isOtherReason) {
      this.otherReason = null
      this.props.onChange(null)
    }
  }

  private get isOtherReason(): boolean {
    return this.selectedOption === CancelationReason.Other
  }

  private get availableReasons(): CancelationReason[] {
    const { hiddenCancelationReasons } =
      this.props.state.delivery.configurations

    return cancelationReasonsList.filter(
      r => !hiddenCancelationReasons.includes(r),
    )
  }
}
