export enum LocalStorageKey {
  ShouldSkipEmailLinking = 'should-skip-email-linking',
  DefaultLoginMethod = 'default-login-method',
  ConcreteDirectData = 'concrete-direct-data',
  ScanStationReportFilter = 'scan-station-filter',
  FormReportReportFilter = 'form-report-filter',
  DeliveryReportReportFilter = 'delivery-report-filter',
  LastUsedScanner = 'last-used-scanner',
  ActiveScanner = 'active-scanner',
  ScannedUsers = 'scanned-users',
  GetTheAppBannerDismissed = 'get-the-app-banner-dismissed',
  OneTimePopup = 'one-time-popup',
}
