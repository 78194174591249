import * as Sentry from '@sentry/browser'

type RecentMessages = {
  [message: string]: {
    count: number
    timeout?: NodeJS.Timeout
    spikeDetected: boolean
  }
}

const isSentrySpikeDetected = createSentrySpikeTracker()

export function beforeSend(event: Sentry.SentryEvent, hint?: any) {
  const spiked = isSentrySpikeDetected(hint?.originalException ?? event)
  return spiked ? null : event
}

function createSentrySpikeTracker(
  maxEvents: number = 5,
  timeoutMs: number = 1000,
): (string) => boolean {
  const recentMessages: RecentMessages = {}

  return function isSentrySpikeDetected(event) {
    const message = event.message || event

    if (recentMessages[message]?.spikeDetected) {
      // Abort if previously detected
      return true
    }

    // Clear previous timeout to restart the timer at the end, if not detected
    if (recentMessages[message]?.timeout) {
      clearTimeout(recentMessages[message].timeout)
    }

    const count = (recentMessages[message]?.count || 0) + 1
    const spikeDetected = count > maxEvents

    try {
      if (spikeDetected) {
        if (!recentMessages[message].spikeDetected) {
          const errorMsg = `Sentry spike detected, preventing future logs for "${message}"`
          console.error(errorMsg)
          Sentry.captureException(new Error(errorMsg))
        }
        return true
      }
    } finally {
      // Set after the check to prevent infinite loop
      recentMessages[message] = { count, spikeDetected }
    }

    recentMessages[message].timeout = setTimeout(() => {
      delete recentMessages[message]
    }, timeoutMs)

    return false
  }
}
