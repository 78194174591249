import React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'

import { ViewMode } from './DraggableBar'

import './DraggableBar.scss'

interface IProps {
  viewMode: ViewMode
  selectViewMode: (mode: ViewMode) => void
  setContentRef: (ref) => void

  isDraggingDisabled?: boolean
  isFixedSize?: boolean
  height?: number
  className?: string
}

const UNSET_HEIGHT_ATTRIBUTE = 'unset'
const OPENED_VIEW_MODE_THRESHOLD = 60
const CLOSED_VIEW_MODE_BOTTOM_OFFSET = 15
const BASE_CONTAINER_HEIGHT = 220
const BASE_CHILD_HEIGHT = 19

@observer
export default class ActionDraggableBar extends React.Component<IProps> {
  @observable private isDraggingMode: boolean = false
  @observable private height: string = null
  @observable private draggingPosition: number = null
  @observable private childHeight: number = null
  @observable private containerHeight: number = BASE_CONTAINER_HEIGHT
  private draggableContainerRef: HTMLDivElement = null

  public render() {
    const { children, isFixedSize } = this.props

    return (
      <div
        ref={this.setDraggableContainerRef}
        style={{
          height: this.draggableContainerHeight,
          bottom: 0,
          maxHeight: '95%',
        }}
        className={classList({
          'draggable-bar bg-white full-width': true,
          absolute: !isFixedSize,
          'fixed-size-menu': isFixedSize,
        })}
      >
        {!isFixedSize && this.renderBodyHeader()}
        <div
          className={classList({
            'col items-list': true,
            scrollable: !isFixedSize,
            'bt-light-grey': !!isFixedSize,
          })}
        >
          {children}
        </div>
      </div>
    )
  }

  private get draggableContainerHeight(): string {
    switch (true) {
      case this.isDraggingMode:
        return `${this.draggingPosition}px`
      case !!this.props.height:
        return `${this.props.height}px`
      case !!this.height:
        return this.height
      case !!this.containerHeight:
        return `${this.containerHeight + (this.childHeight || 0)}px`
      default:
        return UNSET_HEIGHT_ATTRIBUTE
    }
  }

  public recomputeSize(size: number) {
    if (!this.childHeight) {
      this.childHeight = BASE_CHILD_HEIGHT
    } else {
      this.childHeight = size
      if (this.props.viewMode !== ViewMode.Closed) {
        this.resizeByY(this.containerHeight + this.childHeight)
      }
    }
  }

  private onDrag = (e: DraggableEvent, data: DraggableData) => {
    const newPosition = this.draggableContainerRef.clientHeight - data.y
    this.resizeByY(newPosition)
  }

  private resizeByY(y: number) {
    const { selectViewMode } = this.props
    switch (true) {
      case y < OPENED_VIEW_MODE_THRESHOLD:
        selectViewMode(ViewMode.Opened)
        this.height = `${BASE_CONTAINER_HEIGHT}px`
        return
      case this.draggableContainerRef?.offsetParent &&
        y >
          this.draggableContainerRef.offsetParent.clientHeight -
            CLOSED_VIEW_MODE_BOTTOM_OFFSET:
        selectViewMode(ViewMode.Closed)
        this.height = '100%'
        return
      default:
        selectViewMode(ViewMode.Mixed)
        this.height = null
        this.draggingPosition = y
        break
    }
  }

  private renderBodyHeader(): JSX.Element {
    const { className, isDraggingDisabled, isFixedSize } = this.props
    const defaultClassName = `drag-handle ${
      !this.isDraggingMode ? 'not-dragging' : 'dragging'
    }`
    const headerClassName = `col x-center list-header ${className || ''}`

    return (
      <>
        <div className={headerClassName}>
          <div className="pa10 row full-width x-center relative">
            {!isFixedSize && !isDraggingDisabled && (
              <>
                <Draggable
                  axis="y"
                  defaultClassName={defaultClassName}
                  onStart={this.onDragStart}
                  onStop={this.onDragEnd}
                  onDrag={this.onDrag}
                  handle=".draggable-section"
                >
                  <div className="draggable-section transparent-header full-height" />
                </Draggable>
                <div className="bg-palette-brand-light scrollable-button no-grow brada5 draggable-section" />
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  @action.bound
  private onDragStart() {
    this.childHeight = null
    this.height = null
    this.isDraggingMode = true
  }

  @action.bound
  private onDragEnd() {
    this.draggingPosition = null
    this.isDraggingMode = false
  }

  private setDraggableContainerRef = (ref: HTMLDivElement) => {
    this.draggableContainerRef = ref
    this.props.setContentRef(this)
  }
}
