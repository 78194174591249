import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { CellMeasurerCache } from 'react-virtualized'

import {
  IFormMaterial,
  IMeasure,
  IPermitTypeField,
  LocationType,
  PermitFieldType,
} from '~/client/graph'
import BaseCompactPopup from '~/client/src/shared/components/BaseCompactPopup/BaseCompactPopup'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import InitialState from '~/client/src/shared/stores/InitialState'
import { getLocationTypeDisplayName } from '~/client/src/shared/types/IHierarchyParent'
import { NOOP } from '~/client/src/shared/utils/noop'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import SitePermitCreationFormStore, {
  FormDropdownType,
  IFormDropdownOption,
} from '../../../../SitePermitCreationForm.store'
import FormCompanyPicker from './components/FormCompanyPicker'
import FormDropdownOptionsList from './components/FormDropdownOptionsList'
import FormLocationPicker from './components/FormLocationPicker'
import FormUserPicker from './components/FormUserPicker'

import './FormDropdownOptionsModal.scss'

// localization: translated

interface IProps {
  store: SitePermitCreationFormStore

  state?: InitialState
}

const DEFAULT_ROW_HEIGHT = 48
const DROPDOWN_TYPES = [
  PermitFieldType.Material,
  PermitFieldType.Select,
  PermitFieldType.Measure,
]
const isCorrectLocationType = (locationType: LocationType): boolean =>
  locationType && locationType !== LocationType.OffloadingEquipment

@inject('state')
@observer
export default class FormDropdownOptionsModal extends React.Component<IProps> {
  private readonly cellMeasurerCache: CellMeasurerCache = null

  public constructor(props: IProps) {
    super(props)

    this.cellMeasurerCache = new CellMeasurerCache({
      defaultHeight: DEFAULT_ROW_HEIGHT,
      fixedWidth: true,
    })
  }

  public render() {
    const { state, store } = this.props
    const onOutsideClickHandler = this.isShown ? this.onModalClose : NOOP

    return (
      <MenuCloser
        className={classList({
          'form-dropdown-options-modal': true,
          'is-shown': this.isShown,
        })}
        closeMenu={onOutsideClickHandler}
      >
        <BaseCompactPopup
          childrenClassName="full-height overflow-hidden"
          titleClassName="text center large bold two-line-text-ellipsis"
          title={this.title}
          isShown={this.isShown}
          onHide={this.onModalClose}
          shouldIncludeSearch={this.isSearchBarShown}
          searchPlaceholder={this.searchPlaceholder}
          searchValue={store.modalSearchValue}
          onSearchValueChange={this.onSearchValueChange}
          onSearchValueClear={this.clearSearchValue}
        >
          <div className="col full-height">
            {state.isLoading ? <Loader /> : this.renderOptions()}
          </div>
        </BaseCompactPopup>
      </MenuCloser>
    )
  }

  private renderOptions(): JSX.Element {
    if (!this.isShown) return null

    const { store } = this.props

    if (this.isLocationPickerShown) {
      return <FormLocationPicker store={store} />
    }
    if (this.isUserPickerShown) {
      return <FormUserPicker store={store} />
    }
    if (this.isCompanyPickerShown) {
      return <FormCompanyPicker store={store} />
    }

    return (
      <FormDropdownOptionsList
        cellMeasurerCache={this.cellMeasurerCache}
        dropdownOptions={this.dropdownOptions}
        scrollToIndex={this.selectedOptionIndex}
        isMultiple={this.isMultiple}
        onOptionClick={this.handleOptionChange}
      />
    )
  }

  private isOptionSelected = (option: IFormDropdownOption): boolean => {
    const { selectedFieldValues, selectedFieldDropdownType, template } =
      this.props.store
    const selectedFieldValue = selectedFieldValues[this.selectedFieldIndex]

    switch (selectedFieldDropdownType) {
      case FormDropdownType.MATERIAL:
        return (
          (selectedFieldValue as IFormMaterial)?.materialId === option.value
        )
      case FormDropdownType.PROCUREMENT_ID:
        return (
          (selectedFieldValue as IFormMaterial)?.procurementId === option.value
        )
      case FormDropdownType.DEFAULT:
        return this.isMeasure
          ? (selectedFieldValues as IMeasure[]).some(
              v => v?.units === option.value,
            )
          : selectedFieldValues.includes(option.value)
      case FormDropdownType.FORM_TYPE:
        return template?.id === option.value

      default:
        return false
    }
  }

  private onModalClose = () => {
    const { resetSelectedField, hideFormTypeSelector } = this.props.store
    resetSelectedField()
    hideFormTypeSelector()
    this.clearSearchValue()

    this.clearCellMeasurerCache()
  }

  private onSearchValueChange = (newValue: string) => {
    this.props.store.updateSearchValue(newValue)
    this.clearCellMeasurerCache()
  }

  private clearSearchValue = () => {
    this.props.store.updateSearchValue(EMPTY_STRING)
    this.clearCellMeasurerCache()
  }

  private handleOptionChange = (optionValue: string) => {
    const {
      changeSelectedFieldValue,
      isTypeSelectorShown,
      hideFormTypeSelector,
      onPermitTypeChanged,
    } = this.props.store

    if (isTypeSelectorShown) {
      onPermitTypeChanged(optionValue)
      hideFormTypeSelector()
    } else {
      changeSelectedFieldValue(optionValue, this.isMultiple && !!optionValue)
    }

    this.clearSearchValue()
    this.clearCellMeasurerCache()
  }

  private clearCellMeasurerCache = () => {
    this.cellMeasurerCache.clearAll()
  }

  private get selectedFieldName(): string {
    const { selectedFieldDropdownType, selectedSubField } = this.props.store
    switch (selectedFieldDropdownType) {
      case FormDropdownType.PROCUREMENT_ID:
        return Localization.translator.procurementID
      case FormDropdownType.FORM_TYPE:
        return Localization.translator.formType
      default:
        return selectedSubField?.caption || this.selectedField?.caption
    }
  }

  private get title(): string {
    return `${Localization.translator.select} ${this.formattedFieldCaption}`
  }

  private get searchPlaceholder(): string {
    if (this.props.state.isLoading) {
      return `${Localization.translator.loading}...`
    }

    return `${Localization.translator.search} ${this.formattedFieldCaption}`
  }

  private get formattedFieldCaption(): string {
    const { selectedLocationType } = this.props.store
    if (
      this.isLocationPickerShown &&
      isCorrectLocationType(selectedLocationType)
    ) {
      return `${this.selectedFieldName} (${getLocationTypeDisplayName(
        selectedLocationType,
      )})`
    }
    return this.selectedFieldName
  }

  private get dropdownOptions(): IFormDropdownOption[] {
    return this.props.store.dropdownModalOptions.map(opt => ({
      ...opt,
      isSelected: this.isOptionSelected(opt),
    }))
  }

  private get selectedOptionIndex(): number {
    return this.dropdownOptions.findIndex(opt => opt.isSelected)
  }

  private get selectedField(): IPermitTypeField {
    return this.props.store.selectedField
  }

  private get selectedFieldIndex(): number {
    return this.props.store.selectedFieldIndex
  }

  private get isMeasure(): boolean {
    return this.selectedField?.type === PermitFieldType.Measure
  }

  private get isMultiple(): boolean {
    return (
      this.selectedField?.type !== PermitFieldType.Material &&
      this.selectedField?.isMultiple
    )
  }

  private get isTypeSelectorShown(): boolean {
    return this.props.store.isTypeSelectorShown
  }

  private get isSearchBarShown(): boolean {
    return (
      !this.isLocationPickerShown &&
      !this.isCompanyPickerShown &&
      !this.isUserPickerShown
    )
  }

  private get isLocationPickerShown(): boolean {
    return this.props.store.isLocationPickerDisplayed
  }

  private get isCompanyPickerShown(): boolean {
    return this.props.store.isCompanyPickerDisplayed
  }

  private get isUserPickerShown(): boolean {
    return this.props.store.isUserPickerDisplayed
  }

  private get isShown(): boolean {
    return (
      this.isTypeSelectorShown ||
      this.isLocationPickerShown ||
      this.isCompanyPickerShown ||
      this.isUserPickerShown ||
      DROPDOWN_TYPES.includes(this.selectedField?.type)
    )
  }
}
