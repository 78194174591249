import * as React from 'react'

import CleanDialog from '~/client/src/mobile/components/CleanDialog/CleanDialog'
import { LogoWithTitle } from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

interface IProps {
  open: boolean
  onDismiss: (isUnderstood?: boolean) => void
  actionButton: React.ReactNode
}

/**
 * The content of the one-time popup that appears when the user logs in.
 * @param onDismiss - Callback to dismiss the popup.
 * @param actionButton - The badge button to download the app. Varies depending on the platform.
 */
export default function PopupContent({
  open,
  onDismiss,
  actionButton,
}: IProps) {
  const { oneTimePopup } = Localization.translator
  return (
    <CleanDialog
      open={open}
      clickOutsideToDismiss
      onClose={onDismiss}
      title={oneTimePopup.title}
      icon={<LogoWithTitle />}
      actions={[
        {
          title: oneTimePopup.dismiss,
          onClick: () => onDismiss(true),
        },
        actionButton,
      ]}
    >
      <p className="text large">{oneTimePopup.body}</p>
    </CleanDialog>
  )
}
