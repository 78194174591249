import React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  ActivityStatus,
  DeliveryStatus,
  SitePermitStatus,
} from '~/client/graph'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'

import ContentObjectModel from '../../models/ContentObjectModel'
import Delivery from '../../models/Delivery'
import Message from '../../models/Message'
import SitePermit from '../../models/Permit'
import StatusUpdate from '../../models/StatusUpdate'
import BaseAssignmentsStore from '../../stores/BaseAssignments.store'
import BaseFollowingsStore from '../../stores/BaseFollowings.store'
import BaseActionBarStore from '../../stores/ui/BaseActionBar.store'
import ActionDraggableBar from '../DraggableBar/ActionDraggableBar'
import { ViewMode } from '../DraggableBar/DraggableBar'
import MenuCloser from '../MenuCloser'
import MessagesActionBar from './MessagesActionbar'

import './ActionBar.scss'

export type statusType = SitePermitStatus | DeliveryStatus | ActivityStatus
const FIXED_BAR_HEIGHT = 140
const FIXED_BAR_HEIGHT_WITH_CHECKBOX = 160

interface IProps {
  store: BaseActionBarStore
  shouldShowStatusSelector: boolean

  applyStatusAndStep(status: statusType, stepId: string): void
  applyMessage?(): void
  renderButtons(
    renderStatusBtn: (
      btnText: string,
      isValid: boolean,
      status: statusType,
      isDeny?: boolean,
      customBtnHandler?: () => void,
    ) => JSX.Element,
  ): JSX.Element
  renderStatusSelectMenu(
    setStatusAndStep: (status: statusType, stepId: string) => void,
  ): JSX.Element
  toggleStatusSelectMenu(): void

  renderAdditionalCheckbox?(): JSX.Element
  assignmentsStore: BaseAssignmentsStore
  followingsStore: BaseFollowingsStore
  isReadOnly: boolean
  entity:
    | ContentObjectModel<any>
    | Message
    | Delivery
    | StatusUpdate
    | SitePermit
  customRenderer?: (status: statusType, stepId: string) => JSX.Element
  informationalTextElement?: JSX.Element
  isMobileMode?: boolean
}

@observer
export default class ActionBar extends React.Component<IProps> {
  @observable public viewMode: ViewMode = ViewMode.Mixed
  @observable private isFocused: boolean = false
  @observable private containerRef: HTMLDivElement
  private formRef: HTMLFormElement = null
  private draggableRef: ActionDraggableBar = null

  public render() {
    const { renderAdditionalCheckbox } = this.props
    const height = renderAdditionalCheckbox
      ? FIXED_BAR_HEIGHT_WITH_CHECKBOX
      : FIXED_BAR_HEIGHT
    return (
      <MenuCloser
        isOpen={this.isFocused}
        closeMenu={this.setIsFocused.bind(null, false)}
      >
        <ActionDraggableBar
          height={this.isFocused ? null : height}
          viewMode={this.viewMode}
          selectViewMode={this.selectViewMode}
          isFixedSize={!this.isFocused}
          className="relative bt-light-grey"
          setContentRef={this.setDraggableRef}
        >
          {this.renderContent()}
        </ActionDraggableBar>
      </MenuCloser>
    )
  }

  private setDraggableRef = ref => {
    this.draggableRef = ref
  }

  @action.bound
  private toggleStatusSelect() {
    this.isFocused = false
    this.formRef?.blur()
    this.props.toggleStatusSelectMenu()
  }

  private renderContent() {
    const {
      entity,
      renderStatusSelectMenu,
      renderAdditionalCheckbox,
      store,
      renderButtons,
      customRenderer,
      isReadOnly,
      assignmentsStore,
      followingsStore,
      informationalTextElement,
      isMobileMode,
      shouldShowStatusSelector,
      applyMessage,
    } = this.props

    const { isFileUploading, isImageUploading } = store

    return (
      <div
        className={classList({
          'col form-action-bar pa10 full-height': true,
          'inactive-element': isFileUploading || isImageUploading,
          'bt-light-grey': !this.isFocused,
        })}
      >
        {!isReadOnly && (
          <div className="col mr5 no-grow">
            {informationalTextElement}
            <div className="row">
              {renderButtons(this.renderStatusBtn)}
              {shouldShowStatusSelector && (
                <div
                  className="no-grow mr10 pointer"
                  onClick={this.toggleStatusSelect}
                >
                  <Icon icon={IconNames.CHEVRON_UP} className="no-grow" />
                </div>
              )}
            </div>
          </div>
        )}
        {renderStatusSelectMenu(this.setStatusAndStep)}
        <div className="action-bar-form-holder" ref={this.setContainerRef}>
          <MessagesActionBar
            actionBarStore={store}
            entity={entity}
            customStatusRenderer={customRenderer}
            assignmentsStore={assignmentsStore}
            followingsStore={followingsStore}
            containerRef={this.containerRef}
            isFocused={this.isFocused}
            formsRef={this.formRef}
            setIsFocused={this.setIsFocused}
            setFormsRef={this.setFormsRef}
            isMobileMode={isMobileMode}
            draggableRef={this.draggableRef}
            applyMessage={applyMessage}
            renderAdditionalCheckbox={renderAdditionalCheckbox}
          />
        </div>
      </div>
    )
  }

  @action.bound
  public setFormsRef(value) {
    this.formRef = value
  }

  @action.bound
  public setIsFocused(value: boolean) {
    this.isFocused = value
  }

  @action.bound
  private setContainerRef(ref: HTMLDivElement) {
    this.containerRef = ref
  }

  private setStatusAndStep = (status: statusType, stepId: string): void => {
    this.props.applyStatusAndStep(status, stepId)
  }

  private renderStatusBtn = (
    btnText: string,
    isValid: boolean,
    status: statusType,
    isDeny?: boolean,
    customBtnHandler?: () => void,
  ): JSX.Element => {
    return (
      <BaseActionButton
        isActive={false}
        isEnabled={isValid}
        className={classList({
          'text large ellipsis m5 brada24 scale-blue-theme': true,
          'inverse-scale-blue-theme': isDeny,
          'inactive-element': !isValid,
        })}
        isGrow={true}
        title={btnText}
        onClick={this.onStatusClick.bind(this, status, customBtnHandler)}
      />
    )
  }

  private onStatusClick(status: statusType, customBtnHandler?: () => void) {
    if (!customBtnHandler) {
      this.setStatusAndStep(status, null)
      return
    }

    customBtnHandler()
  }

  @action.bound
  private selectViewMode(mode: ViewMode) {
    if (this.viewMode !== mode) {
      this.viewMode = mode
    }
    if (this.viewMode === ViewMode.Closed) {
      this.formRef?.blur()
    }
  }
}
