import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import Draggable, {
  ControlPosition,
  DraggableData,
  DraggableEvent,
} from 'react-draggable'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { customDebounce } from '~/client/src/shared/utils/util'

import CalendarEventLabelType from '../../../enums/CalendarEventLabelType'
import CalendarEvent, {
  CalendarEventEntityType,
} from '../../../models/CalendarEvent'
import InitialState from '../../../stores/InitialState'
import CursorFollowTooltip from '../../CursorFollowTooltip/CursorFollowTooltip'
import DeliveryWorkflowCard from '../../ExpandableWorkflowCard/components/DeliveryWorkflowCard'
import PermitCard from '../../SitemapCards/PermitCard'
import DeliveryCalendarCard from './DeliveryCalendarCard/DeliveryCalendarCard'
import LongPressWrapper from './LongPressWrapper'
import PermitCalendarCard from './PermitCalendarCard/PermitCalendarCard'

import './CalendarEvent.scss'

// translated

interface IProps {
  event: CalendarEvent
  style: any
  className?: string
  onEventClicked?: (e: CalendarEvent) => void
  onEventMoved?: (e: CalendarEvent, x: number, y: number) => void
  onEventTimeChange?: (
    e: CalendarEvent,
    startDate: number,
    endDate: number,
  ) => void
  containerWidth?: number

  onLongSelect?: (event: CalendarEvent) => void
  isSelected?: boolean

  state?: InitialState
}

const CIRCLE_RADIUS = 5
const MIN_HORIZONTAL_WIDTH = 42
const DEBOUNCE_TIME = 300

@inject('state')
@observer
export default class CalendarEventComponent extends React.Component<IProps> {
  @observable private eventWidth: number = 0
  @observable private parentWidth: number = 0
  @observable private eventHeight: number = 0
  @observable private eventPosition: ControlPosition = {
    x: 0,
    y: 0,
  }
  @observable private topPosition: number = 0
  @observable private bottomPosition: number = 0
  @observable private isResizing: boolean = false
  @observable private isMoving: boolean = false

  private eventContainer: HTMLDivElement
  private readonly onUpdateContainerSizes: () => void = null

  public constructor(props: IProps) {
    super(props)

    this.onUpdateContainerSizes = customDebounce(
      this.updateContainerSizes,
      DEBOUNCE_TIME,
    )
    this.setPositions()
  }

  public componentDidMount() {
    this.updateContainerSizes()
    window.addEventListener('resize', this.onUpdateContainerSizes)
  }

  public componentDidUpdate(props: IProps) {
    this.updateContainerSizes()
    if (this.props.isSelected !== props.isSelected) {
      this.setPositions()
      this.eventPosition = {
        x: 0,
        y: 0,
      }
      this.isMoving = false
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.onUpdateContainerSizes)
  }

  public render() {
    const { event, style, className, isSelected, state } = this.props
    const isLoading = state.loading.get(e.UPDATE_DELIVERY)
    const {
      labelType,
      className: eventClassName,
      data: entity,
      entityType,
    } = event
    const shouldRotateCard =
      !!this.eventWidth && MIN_HORIZONTAL_WIDTH >= Math.floor(this.eventWidth)

    let label: string | JSX.Element
    let tooltipData: string | JSX.Element

    const isPreviewType = labelType === CalendarEventLabelType.Preview
    const isNewType = labelType === CalendarEventLabelType.New

    if (isNewType) {
      switch (entityType) {
        case CalendarEventEntityType.Delivery:
          label = Localization.translator.newDelivery
          break
        case CalendarEventEntityType.Form:
          label = Localization.translator.newForm
          break
      }
    } else if (entity || isPreviewType) {
      switch (entityType) {
        case CalendarEventEntityType.Delivery:
          label = (
            <DeliveryCalendarCard
              isRotated={shouldRotateCard}
              containerHeight={this.eventHeight}
              containerWidth={this.eventWidth}
              delivery={entity}
              backgroundColor={style.backgroundColor}
              border={style.border}
              color={style.color}
              className={className}
              shouldUseMockValues={isPreviewType}
            />
          )
          break
        case CalendarEventEntityType.Form:
          label = (
            <PermitCalendarCard
              isRotated={shouldRotateCard}
              containerHeight={this.eventHeight}
              containerWidth={this.eventWidth}
              permit={entity}
              backgroundColor={style.backgroundColor}
              border={style.border}
              color={style.color}
              className={className}
              shouldUseMockValues={isPreviewType}
            />
          )
          break
      }
    }

    switch (entityType) {
      case CalendarEventEntityType.Delivery:
        tooltipData = (
          <DeliveryWorkflowCard
            className="calendar-day-view-event new highlighted-event unclickable-element"
            delivery={entity}
            eventStyles={event.styles}
            isCalendarTooltip
          />
        )
        break
      case CalendarEventEntityType.Form:
        tooltipData = (
          <PermitCard
            className="brada8 inline-block mr12 vertical-align-middle no-select bg-white unclickable-element"
            permit={entity}
            key={entity?.id}
          />
        )
        break
    }

    const content =
      !!event.data && !isPreviewType && !this.isMoving ? (
        <CursorFollowTooltip
          key={event.dataId}
          className="bp3-tooltip-calendar-event"
          portalClassName="bp3-tooltip-calendar-event unclickable-element"
          content={tooltipData}
        >
          {label}
        </CursorFollowTooltip>
      ) : (
        label
      )

    if (!isSelected) {
      return (
        <LongPressWrapper
          onClick={this.onClickHandler}
          onLongSelect={this.onLongPress?.bind(null, event)}
        >
          <div
            className={classList({
              'calendar-day-view-event': true,
              'disabled-border': !isNewType,
              [className]: !!className && isNewType,
              [eventClassName]: !!eventClassName,
            })}
            style={style}
            ref={this.setRef}
          >
            {content}
          </div>
        </LongPressWrapper>
      )
    }

    const eventStyle = {
      ...style,
      height: this.eventHeight || style.height,
      left: 0,
    }

    return (
      <>
        {!isLoading && !this.isMoving && (
          <Draggable
            axis="y"
            defaultClassName="unset-draggable"
            defaultClassNameDragging="unclickable-element unset-draggable"
            onDrag={this.onResizeTop}
            onStop={this.onResizeEnd}
            position={{
              x: this.eventPosition.x,
              y: this.topPosition,
            }}
            handle=".dragging-trigger-top"
          >
            <div id="Draggable-top" className="top-dot dragging-trigger-top" />
          </Draggable>
        )}
        <Draggable
          axis="both"
          defaultClassName="absolute full-width"
          defaultClassNameDragging="absolute full-width bg-white unclickable-element"
          onStop={this.onDragStop}
          onDrag={this.setPosition}
          position={this.eventPosition}
          handle=".dragging-trigger"
        >
          <div
            className={classList({
              'calendar-day-view-event dragging-trigger resizable-box': true,
              'disabled-border': !isNewType,
              [className]: !!className && isNewType,
              [eventClassName]: !!eventClassName,
            })}
            onClick={this.onClickHandler}
            style={eventStyle}
            ref={this.setRef}
          >
            {content}
          </div>
        </Draggable>
        {!isLoading && !this.isMoving && (
          <Draggable
            axis="y"
            defaultClassNameDragging="unclickable-element"
            onDrag={this.onResizeBottom}
            onStop={this.onResizeEnd}
            position={{
              x: this.eventPosition.x,
              y: this.bottomPosition,
            }}
            handle=".dragging-trigger-top"
          >
            <div id="Draggable-top" className="bot-dot dragging-trigger-top" />
          </Draggable>
        )}
      </>
    )
  }

  private onResizeBottom = (_: DraggableEvent, data: DraggableData) => {
    this.isResizing = true
    if (
      this.eventContainer.parentElement.clientHeight - this.bottomPosition <
      -CIRCLE_RADIUS
    ) {
      return
    }

    const delta = this.bottomPosition - data.y
    this.eventHeight -= delta
    this.bottomPosition = data.y
  }

  private onResizeTop = (_: DraggableEvent, data: DraggableData) => {
    this.isResizing = true
    if (this.topPosition < -CIRCLE_RADIUS) {
      return
    }

    const delta = this.topPosition - data.y
    this.eventPosition.y -= delta
    this.eventHeight += delta
    this.topPosition = data.y
  }

  private onResizeEnd = () => {
    const { onEventTimeChange, event, style } = this.props
    this.isResizing = false
    const endDate = this.eventHeight - style.height + this.eventPosition.y

    onEventTimeChange(event, this.eventPosition.y, endDate)
  }

  private onDragStop = () => {
    const xMultiplier = Math.round(this.eventPosition.x / this.parentWidth)

    if (xMultiplier === 0 && this.eventPosition.y === 0) {
      this.onClickHandler()
      this.eventPosition.x = 0
      this.eventPosition.y = 0
    } else {
      this.props.onEventMoved(
        this.props.event,
        xMultiplier,
        this.eventPosition.y,
      )
    }
  }

  private setPosition = (_: DraggableEvent, data: DraggableData) => {
    this.isMoving = true
    this.eventPosition.x = data.x
    this.eventPosition.y = data.y
  }

  private setRef = (ref: HTMLDivElement) => {
    this.eventContainer = ref

    if (ref) {
      this.eventWidth = this.eventContainer.clientWidth
      this.parentWidth = this.eventContainer.parentElement.clientWidth
    }
  }

  private onClickHandler = () => {
    const { onEventClicked, event } = this.props

    if (onEventClicked) {
      onEventClicked(event)
    }
  }

  @action.bound
  private updateContainerSizes() {
    if (this.eventContainer && !this.isResizing) {
      const boundingRect = this.eventContainer.getBoundingClientRect()
      this.eventWidth = Math.floor(boundingRect.width)
      this.eventHeight = this.props.isSelected
        ? this.props.style.height
        : Math.floor(boundingRect.height)
    }
  }

  private onLongPress = (event: CalendarEvent) => {
    //TODO: Long Press behavior is broken. Temporary disabled
    return
    this.onClickHandler()

    if (event?.data?.isFromConcreteDirect) {
      return
    }

    this.setPositions()
    this.props.onLongSelect?.(event)
  }

  private setPositions = () => {
    this.topPosition = this.props.style.top - CIRCLE_RADIUS
    this.bottomPosition =
      this.props.style.top + this.props.style.height + CIRCLE_RADIUS
  }
}
