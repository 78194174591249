import { computed } from 'mobx'

import { IActivityCodeType } from '~/client/graph'
import ActivityCodeType from '~/client/src/shared/models/ActivityCodeType'
import ActivityCodeRelationshipsStore from '~/client/src/shared/stores/domain/ActivityCodeRelationships.store'
import ActivityCodesStore from '~/client/src/shared/stores/domain/ActivityCodes.store'

import IActivityCodeNode from '../../interfaces/IActivityCodeNode'
import EventsStore from '../EventStore/Events.store'
import BaseStoreWithById from '../baseStores/BaseWithById.store'

const RESPONSIBILITY_CODE_TYPE_NAME = 'Responsibility (P2)'

export default class ActivityCodeTypesStore extends BaseStoreWithById<
  ActivityCodeType,
  IActivityCodeType
> {
  public constructor(
    private eventsStore: EventsStore,
    private activityCodesStore: ActivityCodesStore,
    private activityCodeRelationshipsStore: ActivityCodeRelationshipsStore,
  ) {
    super(ActivityCodeType)
  }

  public get byId() {
    return this.eventsStore.appState.activityCodeTypes
  }

  @computed
  public get tree(): { [key: string]: IActivityCodeNode[] } {
    const tree = {}

    const maps = this.getActivityCodeTypeMaps()
    this.list.forEach(codeType => {
      tree[codeType.id] = this.getActivityCodeTree(codeType, maps)
    })

    return tree
  }

  @computed
  public get responsibilityCodeTypeId(): string {
    const responsibilityCodeTypeType = this.list.find(type =>
      type.name.includes(RESPONSIBILITY_CODE_TYPE_NAME),
    )
    return responsibilityCodeTypeType && responsibilityCodeTypeType.id
  }

  public getNamesFromIds(ids: string[]): string[] {
    return this.list
      .filter(codeType => ids.includes(codeType.id))
      .map(codeType => codeType.name)
  }

  @computed
  public get summary(): { [key: string]: number } {
    const tree = {}

    const maps = this.getActivityCodeTypeMaps()
    this.list.forEach(codeType => {
      tree[codeType.name] = maps.codes[codeType.id]?.length || 0
    })

    return tree
  }

  private getActivityCodeTree(
    activityCodeType: ActivityCodeType,
    maps: { relationships; codes },
  ): IActivityCodeNode[] {
    const relationships = maps.relationships[activityCodeType.id] || []
    const codes = maps.codes[activityCodeType.id] || []

    const codesMap = new Map()
    const tree: IActivityCodeNode[] = []

    const byCodeId = relationships.reduce((map, relationship) => {
      const codeId = relationship.activityTypeId
      if (!map[codeId]) {
        map[codeId] = []
      }

      map[codeId].push(relationship.activityId)
      return map
    }, {})

    codes.forEach(code => {
      const activitiesIds = byCodeId[code.id] || []

      codesMap.set(code.id, {
        code,
        activitiesIds: Array.from(new Set(activitiesIds)),
      })
    })

    codes.forEach(code => {
      const node = codesMap.get(code.id)

      const parentNode = code.parentId && codesMap.get(code.parentId)
      if (parentNode) {
        if (!parentNode.children) {
          parentNode.children = []
        }
        parentNode.children.push(node)
      } else {
        tree.push(node)
      }
    })

    return tree
  }

  private getActivityCodeTypeMaps() {
    const maps = {
      relationships: {},
      codes: {},
    }
    this.activityCodeRelationshipsStore.availableRelationships.forEach(
      relationship => {
        const typeId = relationship.activityCodeTypeId
        if (!maps.relationships[typeId]) {
          maps.relationships[typeId] = []
        }

        maps.relationships[typeId].push(relationship)
      },
    )

    this.activityCodesStore.list.forEach(code => {
      const typeId = code.activityCodeTypeId
      if (!maps.codes[typeId]) {
        maps.codes[typeId] = []
      }

      maps.codes[typeId].push(code)
    })

    return maps
  }
}
