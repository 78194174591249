import { action, computed, observable } from 'mobx'

import User from '~/client/src/shared/models/User'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'

import QrCodesStore from '../../QRCodes.store'

export class QRCodesEditFormStore {
  @observable public isUsersListExpanded: boolean = false
  @observable public isLocationPickerShown: boolean = false
  @observable public isUserDirectoryShown: boolean = false

  public constructor(
    private readonly store: QrCodesStore,
    private readonly projectMembersStore: ProjectMembersStore,
  ) {}

  @computed
  public get selectedUsers(): User[] {
    return [...this.store.newScanner.allowedUsers]
      .map(id => this.projectMembersStore.getById(id))
      .filter(u => !!u)
  }

  @action.bound
  public toggleUsersList(): void {
    this.isUsersListExpanded = !this.isUsersListExpanded
  }

  @action.bound
  public toggleLocationPickerState(): void {
    this.isLocationPickerShown = !this.isLocationPickerShown
  }

  @action.bound
  public toggleUserDirectory(): void {
    this.isUserDirectoryShown = !this.isUserDirectoryShown
  }

  @action.bound
  public showUsersList(): void {
    this.isUsersListExpanded = true
  }

  @action.bound
  public onApplyAllowedUsers(appliedUsersIds: string[] = []) {
    this.store.newScanner.allowedUsers = appliedUsersIds
    this.toggleUserDirectory()
  }

  public exitScanner = (): void => {
    const { onCreateCallback } = this.store
    onCreateCallback()
  }

  // public onScannerUpdated = (eventContext: EventContext): void => {
  //   const { scanner, selectScanner } = this.store
  //   const [eventType, sc] = eventContext.event

  //   if (eventType === SCANNER_UPDATED && sc?.scanner?.id === scanner.id) {
  //     const updatedScanner = Scanner.fromDto(sc.scanner?.item)

  //     selectScanner(updatedScanner)
  //   }
  // }
}
