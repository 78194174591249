import { action, computed, observable } from 'mobx'

import {
  ActivityStatus,
  IActivityCodeDataInput,
  IActivityDataInput,
  IActivityInput,
} from '~/client/graph'
import {
  ActivityWithDataDocument,
  CheckIsActivityCodeUniqueDocument,
} from '~/client/graph/operations/generated/Schedule.generated'
import ActivityDataFieldId from '~/client/src/shared/enums/ActivityDataFieldId'
import InitialState from '~/client/src/shared/stores/InitialState'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ConstraintsStore from '~/client/src/shared/stores/domain/Constraints.store'
import DeliveryRestrictionsStore, {
  RestrictionAttributeKey,
} from '~/client/src/shared/stores/domain/DeliveryRestrictions.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { EMPTY_OBJECT_ID } from '~/client/src/shared/utils/GraphExecutor'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import ActivitiesStore from '../../stores/domain/Activities.store'
import GraphExecutorStore from '../../stores/domain/GraphExecutor.store'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import { ActivityDetailsTab } from './ActivityDetailsInterfaces'
import ActivityFormStore, {
  ActivityFieldType,
} from './components/ActivityForm.store'

enum ActivityDetailsMode {
  CREATE,
  CHANGE,
  VIEW,
  NONE,
}

const DEFAULT_TAB = ActivityDetailsTab.Form
const DEFAULT_MODE = ActivityDetailsMode.NONE

export default class ActivityDetailsStore {
  @observable public isSaving: boolean = false
  @observable public activeDetailsTab: ActivityDetailsTab = DEFAULT_TAB
  @observable public mode: ActivityDetailsMode = DEFAULT_MODE
  @observable public shouldScrollToBottom: boolean = false
  @observable public shouldOpenLogTab: boolean = false
  @observable public isConfirmationDialogOpened: boolean = false

  public dialogQuestion: string
  public doneDialogHandler: () => void
  public cancelDialogHandler: () => void
  private deliveryRestrictionsStore: DeliveryRestrictionsStore = null
  public activityFormStore: ActivityFormStore = null

  public constructor(
    private readonly state: InitialState,
    private readonly activitiesStore: ActivitiesStore,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    tagsStore: TagsStore,
    constraintsStore: ConstraintsStore,
  ) {
    this.deliveryRestrictionsStore = new DeliveryRestrictionsStore(
      state,
      locationAttributesStore,
      tagsStore,
      graphExecutorStore,
      constraintsStore,
    )

    this.activityFormStore = new ActivityFormStore(
      this.projectDateStore,
      this.companiesStore,
      this.projectMembersStore,
      tagsStore,
    )

    this.prepareUI()
  }

  @action.bound
  public prepareUI() {
    const { selectedActivity, draftNewActivityData } = this.activitiesStore
    const activityDraftData = selectedActivity
      ? selectedActivity.toInput(true)
      : draftNewActivityData

    this.activityFormStore.initDraftData(activityDraftData)
    this.resetLocationValuesIfNeed()

    if (selectedActivity) {
      this.setViewActivityMode()
    }

    if (this.shouldOpenLogTab) {
      this.switchActiveTab(ActivityDetailsTab.Log)
    }
  }

  @action.bound
  public changeSelectedFieldValue(newValue: string) {
    const isCompanyField =
      this.activityFormStore.selectedField.type ===
      ActivityFieldType.CompanySelect

    const isUserField =
      this.activityFormStore.selectedField.type === ActivityFieldType.UserSelect

    this.activityFormStore.changeSelectedFieldValue(newValue)

    if (isCompanyField || isUserField) {
      this.activityFormStore.unsetSelectedField()
    }

    if (this.activityFormStore.selectedField?.locationType) {
      this.resetLocationValuesIfNeed()
    }
  }

  @action.bound
  public async checkActivityCodeUniqueness(code): Promise<boolean> {
    this.isSaving = true

    try {
      // eslint-disable-next-line no-var
      var {
        data: { isActivityCodeClaimed: isUnique },
      } = await this.graphExecutorStore.query(
        CheckIsActivityCodeUniqueDocument,
        {
          scheduleId: this.state.activeScheduleId,
          code,
        },
      )
    } finally {
      this.isSaving = false
    }

    return isUnique
  }

  @action.bound
  public async saveActivity(isEditing: boolean = false) {
    const {
      fields,
      newActivityData,
      changedFieldsMessage,
      resetIsChangedState,
    } = this.activityFormStore
    this.isSaving = true

    const data: IActivityDataInput = {
      activity: {
        scheduleId: this.state.activeScheduleId || EMPTY_OBJECT_ID,
        status: ActivityStatus.NotStarted,
        locations: [],
        id: isEditing ? newActivityData.id : null,
      } as IActivityInput,
      activityCodeData: [] as IActivityCodeDataInput[],
      projectId: this.state.activeProject.id,
      message: isEditing ? { text: changedFieldsMessage } : null,
    }

    fields.forEach(f => {
      const value = newActivityData[f.fieldId]

      if (f.type === ActivityFieldType.Text) {
        data.activity[f.fieldId] = newActivityData[f.fieldId] = value?.trim()
        return
      }

      if (f.locationType && value) {
        data.activity.locations.push({
          id: value,
          type: f.locationType,
        })
        return
      }

      if (f.isActivity) {
        data.activity[f.fieldId] = value
      }
    })

    await this.graphExecutorStore.mutate(ActivityWithDataDocument, { data })

    resetIsChangedState()
    this.isSaving = false
  }

  @action.bound
  public setLogVisibility(isVisible: boolean) {
    this.shouldOpenLogTab = isVisible
  }

  @action.bound
  public setCreateActivityMode() {
    this.activitiesStore.selection = EMPTY_STRING
    this.mode = ActivityDetailsMode.CREATE
    this.setLogVisibility(false)
    this.switchActiveTab(ActivityDetailsTab.Form)
  }

  @action.bound
  public setViewActivityMode() {
    this.mode = ActivityDetailsMode.VIEW
    this.switchActiveTab(ActivityDetailsTab.Form)
  }

  @action.bound
  public setEditActivityMode() {
    this.mode = ActivityDetailsMode.CHANGE
    this.switchActiveTab(ActivityDetailsTab.Form)
  }

  @action.bound
  public switchActiveTab(newActiveTab: ActivityDetailsTab) {
    this.activeDetailsTab = newActiveTab
  }

  @action.bound
  public setConfirmDialogVisibility(isOpen: boolean) {
    this.isConfirmationDialogOpened = isOpen
  }

  public setConfirmDialogQuestion(question: string) {
    this.dialogQuestion = question
  }

  public setConfirmationDialogHandler(handler: () => void) {
    this.doneDialogHandler = handler
  }

  public setCancelDialogHandler(handler: () => void) {
    this.cancelDialogHandler = handler
  }

  public get isCreateModeActive() {
    return this.mode === ActivityDetailsMode.CREATE
  }

  public get isViewModeActive() {
    return this.mode === ActivityDetailsMode.VIEW
  }

  public get isEditModeActive() {
    return this.mode === ActivityDetailsMode.CHANGE
  }

  private resetLocationValuesIfNeed = () => {
    this.resetLocationValueIfNeed(ActivityDataFieldId.ZONE_ID)
    this.resetLocationValueIfNeed(ActivityDataFieldId.LEVEL_ID)
    this.resetLocationValueIfNeed(ActivityDataFieldId.AREA_ID)
  }

  private resetLocationValueIfNeed = (locationFieldId: ActivityDataFieldId) => {
    const { changeFieldValue, newActivityData } = this.activityFormStore
    const locationValue = newActivityData[locationFieldId] as string
    const locationIds = this.getAllowedLocationIdsById(locationFieldId)

    if (!locationIds?.includes(locationValue)) {
      changeFieldValue(locationFieldId, null)
    }
  }

  private getAllowedLocationIdsById = (
    locationFieldId: ActivityDataFieldId,
  ): string[] => {
    switch (locationFieldId) {
      case ActivityDataFieldId.ZONE_ID:
        return this.allowedZoneIds
      case ActivityDataFieldId.LEVEL_ID:
        return this.allowedLevelIds
      case ActivityDataFieldId.AREA_ID:
        return this.allowedAreaIds
    }
  }

  @computed
  public get allowedLocationIds(): string[] {
    const locationIds = this.getAllowedLocationIdsById(
      this.activityFormStore.selectedField?.fieldId as ActivityDataFieldId,
    )

    if (!locationIds) {
      return []
    }

    return locationIds.length ? locationIds : [EMPTY_STRING]
  }

  @computed
  private get allowedZoneIds(): string[] {
    return this.deliveryRestrictionsStore
      .filterZonesByBuilding(
        this.activityFormStore.newActivityData.buildingId,
        this.locationAttributesStore.zonesStore.list,
      )
      .map(({ id }) => id)
  }

  @computed
  private get allowedLevelIds(): string[] {
    const { newActivityData } = this.activityFormStore
    return this.deliveryRestrictionsStore
      .filterAttributesByZoneAndBuilding(
        this.locationAttributesStore.levelsStore.list,
        RestrictionAttributeKey.RestrictedLevels,
        newActivityData.buildingId,
        newActivityData.zoneId,
      )
      .map(({ id }) => id)
  }

  @computed
  private get allowedAreaIds(): string[] {
    const { filterAttributesByZoneAndBuilding, filterAreasBySelectedLevel } =
      this.deliveryRestrictionsStore
    const { newActivityData } = this.activityFormStore
    const areas = filterAttributesByZoneAndBuilding(
      this.locationAttributesStore.areasStore.list,
      RestrictionAttributeKey.RestrictedAreas,
      newActivityData.buildingId,
      newActivityData.zoneId,
    )

    return filterAreasBySelectedLevel(newActivityData.levelId, areas).map(
      ({ id }) => id,
    )
  }
}
