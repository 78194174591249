import { action, observable } from 'mobx'

import { ConfigurationByNameDocument } from '~/client/graph/operations/generated/Configurations.generated'

import { IntegrationAppName } from '../../../enums/IntegrationAppName'
import { LocalStorageKey } from '../../../enums/LocalStorageKey'
import LocalStorageHelper from '../../../utils/LocalStorageHelper'
import { customDebounce } from '../../../utils/util'
import EventsStore from '../../EventStore/Events.store'
import InitialState from '../../InitialState'
import GraphExecutorStore from '../GraphExecutor.store'

interface IConcreteDirectPreferences {
  [projectCode: string]: { areCDOrdersHidden: boolean }
}

interface ConcreteDirectConfiguration {
  isActive?: boolean
  cDBaseUrl?: string
  cDLogin?: string
  cDPassword?: string
  siteId?: string
  syncStartDate?: Date
}

const defaultCdConfiguration: ConcreteDirectConfiguration = {
  isActive: false,
  cDBaseUrl: '',
  cDLogin: '',
  cDPassword: '',
  siteId: '',
  syncStartDate: null,
}

const DEBOUNCE_DELAY = 400

export default class ConcreteDirectIntegrationStore {
  @observable public areCDOrdersHidden = false
  @observable public concreteDirectConfiguration: ConcreteDirectConfiguration =
    defaultCdConfiguration

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {
    this.updateShowingOfOrders = customDebounce(
      this.updateShowingOfOrders,
      DEBOUNCE_DELAY,
    )
  }

  @action.bound
  public init() {
    if (!this.isIntegrationEnabled) {
      return
    }
    this.initItemFields()
    this.updatePreferences()
  }

  @action.bound
  public updateShowingOfOrders() {
    if (!this.isIntegrationEnabled) {
      return
    }

    this.areCDOrdersHidden = !this.areCDOrdersHidden

    this.updatePreferences(true)
  }

  @action.bound
  private updatePreferences(shouldSaveToStorage?: boolean) {
    const {
      activeProject: { code },
      user: { id: userId },
    } = this.state

    const cdPrefs: IConcreteDirectPreferences = {
      [code]: { areCDOrdersHidden: false },
    }

    try {
      Object.assign(
        cdPrefs,
        LocalStorageHelper.getUserPreferenceByKey(
          userId,
          LocalStorageKey.ConcreteDirectData,
        ),
      )
    } finally {
      if (shouldSaveToStorage) {
        cdPrefs[code].areCDOrdersHidden = this.areCDOrdersHidden

        LocalStorageHelper.setUserPreferences(userId, {
          [LocalStorageKey.ConcreteDirectData]: cdPrefs,
        })
      } else {
        this.areCDOrdersHidden = cdPrefs[code].areCDOrdersHidden
      }
    }
  }

  @action.bound
  public async initItemFields() {
    const { data } = await this.graphExecutorStore.query(
      ConfigurationByNameDocument,
      { name: `project.${this.projectId}.integrations.concreteDirect` },
    )
    if (data?.configuration?.value) {
      const config = JSON.parse(data.configuration.value)
      this.concreteDirectConfiguration = {
        isActive: config?.IsActive,
        cDBaseUrl: config?.CDBaseURL,
        cDLogin: config?.CDLogin,
        cDPassword: config?.CDPassword,
        siteId: config?.SiteId,
        syncStartDate: config?.SyncStartDate
          ? new Date(config?.SyncStartDate)
          : null,
      }
    }
  }

  public get isIntegrationEnabled(): boolean {
    return this.state.isIntegrationEnabled(IntegrationAppName.CONCRETE_DIRECT)
  }

  public get isIntegrationActive(): boolean {
    return this.concreteDirectConfiguration?.isActive
  }

  private get projectId(): string {
    return this.state.activeProject.id
  }

  private get state(): InitialState {
    return this.eventsStore.appState
  }
}
