import { action, computed, observable } from 'mobx'

import { FilterType } from '~/client/graph'
import ActivityListViewMode from '~/client/src/mobile/enums/ActivityListViewMode'
import { ActivitiesTreeNodeTypes } from '~/client/src/shared/enums/ActivitiesTreeNodeTypes'
import Activity from '~/client/src/shared/models/Activity'
import ActivitiesTreeNode from '~/client/src/shared/models/ActvitiesTreeNode'
import { IActivitiesTreeNode } from '~/client/src/shared/models/IActivitiesTreeNode'
import IActivitiesTreeNodeSource from '~/client/src/shared/models/IActivitiesTreeNodeSource'
import IActivityTreeItemData from '~/client/src/shared/models/IActivityTreeItemData'
import { IActivityTreeData } from '~/client/src/shared/stores/EventStore/EffectsProcessors/WorkerProcessor/handlers/getActivityTree'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import {
  CALCULATE_ACTIVITY_TREE,
  GET_ACTIVITY_FILTERS_SETTINGS_SUCCESS,
  GET_HIERARCHY_CONFIGURATION_SUCCESS,
  GET_SCHEDULE,
  GET_SCHEDULE_SUCCESS,
  TRIGGER_CALCULATE_ACTIVITY_TREE,
  UPDATE_USER_LAST_ACTIVITY_LIST_MOBILE_FILTERS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import {
  ICodesFilter,
  ITreeContainer,
  SUB_NODE_PREFIX,
} from '~/client/src/shared/stores/ui/BaseActivityList.store'
import CompactActivityListStore from '~/client/src/shared/stores/ui/CompactActivityList.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import MobXMap from '~/client/src/shared/types/MobXMap'
import {
  UNASSIGNED,
  UNASSIGNED_FILTER_VALUE,
} from '~/client/src/shared/utils/ZoneLevelLocationConstants'

import MobileEventStore from '../../stores/EventStore/MobileEvents.store'
import MobileActivityListStore from '../../stores/ui/ActivityList.store'

export default class ActivityListStore extends CompactActivityListStore {
  protected areActivitiesLoadedByApi: boolean = true
  private containerStatusState = new MobXMap<boolean>()
  private activitiesTreeSource: {
    tree: IActivitiesTreeNodeSource[]
  } = observable.object({ tree: [] }, null, { deep: false })

  public get currentDate() {
    return this.eventsStore.appState.filters.currentDate
  }

  public set currentDate(date: Date) {
    this.eventsStore.appState.filters.currentDate = date
  }

  public constructor(
    protected activityFiltersStore: ActivityFiltersStore,
    protected locationsStore: LocationsStore,
    protected projectDateStore: ProjectDateStore,
    protected eventStore: MobileEventStore,
    protected companiesStore: CompaniesStore,
    private activityListStore: MobileActivityListStore,
    private readonly activitiesStore: ActivitiesStore,
    protected eventsStore: MobileEventStore,
    private readonly rfisStore: RfisStore,
    private readonly flagsStore: FlagsStore,
    private readonly deliveriesStore: DeliveriesStore,
    private readonly scheduleCommentsStore: ScheduleCommentsStore,
    private readonly statusUpdatesStore: StatusUpdatesStore,
    private readonly hierarchyConfigurationStore: HierarchyConfigurationStore,
  ) {
    super(
      activityFiltersStore,
      eventsStore,
      locationsStore,
      companiesStore,
      projectDateStore,
    )
  }

  public onTreeRequest = (eventContext: EventContext) => {
    this.onResetRequest(eventContext)
    const [eventType] = eventContext.event
    if (eventType === TRIGGER_CALCULATE_ACTIVITY_TREE) {
      this.calculateActivityTree(false)
    } else if (
      [
        GET_HIERARCHY_CONFIGURATION_SUCCESS,
        GET_SCHEDULE_SUCCESS,
        GET_ACTIVITY_FILTERS_SETTINGS_SUCCESS,
        UPDATE_USER_LAST_ACTIVITY_LIST_MOBILE_FILTERS,
      ].includes(eventType)
    ) {
      this.calculateActivityTree(true)
    }

    if (eventType === UPDATE_USER_LAST_ACTIVITY_LIST_MOBILE_FILTERS) {
      this.clearRequestNodesState()
    }
  }

  @computed
  public get isTreeLoading() {
    return this.eventsStore.appState.loading.get(CALCULATE_ACTIVITY_TREE)
  }

  private get treeActivitiesDto(): { [id: string]: IActivityTreeItemData } {
    const dtos = this.treeActivities.reduce((map, activity) => {
      map[activity.code] = activity.activityTreeItemData
      map[activity.code].isActive = activity.isActiveOn(
        this.currentDate,
        this.projectDateStore,
      )
      map[activity.code].plannedPercentComplete =
        activity.getPlannedPercentCompleteOnDate(
          this.currentDate.getTime(),
          this.projectDateStore,
        )
      return map
    }, {})

    this.rfisStore.list.forEach(rfi => {
      if (rfi.isOpen && dtos[rfi.activityObjectId]) {
        dtos[rfi.activityObjectId].hasRfi = true
      }
    })

    this.flagsStore.list.forEach(flag => {
      if (flag.isOpen && dtos[flag.activityObjectId]) {
        dtos[flag.activityObjectId].hasFlag = true
      }
    })

    this.scheduleCommentsStore.list.forEach(sc => {
      if (sc.isOpen && dtos[sc.activityObjectId]) {
        dtos[sc.activityObjectId].hasScheduleComment = true
      }
    })

    this.deliveriesStore.availableDeliveries.forEach(delivery => {
      if (
        this.projectDateStore.isSameDay(delivery.startDate, this.currentDate) &&
        dtos[delivery.activityId]
      ) {
        dtos[delivery.activityId].hasDelivery = true
      }
    })

    return dtos
  }

  private get treeActivities(): Activity[] {
    let filteredActivities: Activity[]
    if (this.activityListStore.appliedPreset) {
      filteredActivities = this.activityListStore.appliedPresetActivities
    } else {
      filteredActivities = this.activitiesStore.list.filter(a =>
        this.activityListStore.isActivityInSelectedFilters(a),
      )
    }
    const { selectedCustomFilterId } = this.eventsStore.appState.filters
    if (selectedCustomFilterId) {
      filteredActivities = this.activityListStore.filterByCategories(
        filteredActivities,
        selectedCustomFilterId,
      )
    }

    if (
      this.eventsStore.appState.filters.viewMode ===
      ActivityListViewMode.ASSIGNED
    ) {
      filteredActivities = filteredActivities.filter(a =>
        this.activityListStore.userAssociatedActivitiesIds.includes(a.code),
      )
    }

    return filteredActivities
  }

  private get activityStatusMap() {
    const map = {}

    this.treeActivities.forEach(activity => {
      if (this.statusUpdatesStore.doesActivityHaveStatusUpdate(activity)) {
        map[activity.code] = activity.commonStatusUpdate
      }
    })

    return map
  }

  private get activityTreeData(): IActivityTreeData {
    const { compactActivityList, activeProject } = this.eventStore.appState

    return {
      currentDate: this.currentDate,
      activities: this.treeActivitiesDto,
      shouldShowOnlyActiveTodaySections:
        compactActivityList.shouldShowOnlyActiveTodaySections,
      orderedTreeContainers:
        this.hierarchyConfigurationStore.orderedAppliedBands,
      containersMap: this.containersByTypeMap,
      statusesMap: this.activityStatusMap,
      timezoneId: this.projectDateStore.timezoneId,
      dateTimeFormat: activeProject.dateTimeFormat,
    }
  }

  @computed
  public get containersByTypeMap(): { [key: string]: ITreeContainer[] } {
    const map: { [key: string]: ITreeContainer[] } = {}
    this.hierarchyConfigurationStore.orderedAppliedBands.forEach(type => {
      map[type] = this.getContainersByType(type)
    })
    return map
  }

  @action.bound
  public calculateActivityTree(isLoading: boolean) {
    const dataStores: Array<{ isDataReceived: boolean }> = [
      this.activitiesStore,
      this.statusUpdatesStore,
      this.deliveriesStore,
      this.rfisStore,
      this.flagsStore,
      this.scheduleCommentsStore,
    ]
    if (dataStores.some(store => !store.isDataReceived)) {
      return
    }

    this.eventsStore.dispatch(
      CALCULATE_ACTIVITY_TREE,
      isLoading,
      this.activityTreeData,
      this.setActivityTree,
    )
  }

  @action.bound
  public changeContainerExpandState(id: string, value: boolean) {
    this.containerStatusState.set(id, value)
  }

  @action.bound
  public setActivityTree(tree: IActivitiesTreeNodeSource[]) {
    this.activitiesTreeSource.tree = tree
  }

  @action.bound
  public setDate(newDate: Date) {
    this.currentDate = newDate
    this.loadSelectedDateActivities()
  }

  @action.bound
  public nextDate() {
    const { addDays, startOfDay } = this.projectDateStore
    this.currentDate = startOfDay(addDays(this.currentDate, 1))
    this.loadSelectedDateActivities()
  }

  @action.bound
  public prevDate() {
    const { addDays, startOfDay } = this.projectDateStore
    this.currentDate = startOfDay(addDays(this.currentDate, -1))
    this.loadSelectedDateActivities()
  }

  @action.bound
  public onSelectModeToggled() {
    if (
      !this.eventsStore.appState.compactActivityList
        .shouldShowOnlyActiveTodaySections
    ) {
      this.loadAllActivities()
    } else {
      this.loadSelectedDateActivities()
    }
  }

  @computed
  public get areAllRootNodesCollapsed() {
    return Object.values(this.activitiesTree).every(
      node => !node.state.expanded,
    )
  }

  @computed
  public get activitiesTree(): IActivitiesTreeNode[] {
    return this.getTreeNodesWithState(this.activitiesTreeSource.tree || [])
  }

  protected getCurrentActivityFilters(parents: ITreeContainer[]): ICodesFilter {
    const codesFilter: ICodesFilter = {}
    Object.keys(this.filtersInState).forEach(filterType => {
      let filterValues = this.filtersInState[filterType]
      const treeContainer = parents.find(p => p.type === filterType)
      if (treeContainer) {
        const { name, type, codeIds } = treeContainer
        filterValues = filterValues.filter(id => {
          if (type === FilterType.Company) {
            return name === UNASSIGNED
              ? id === UNASSIGNED_FILTER_VALUE
              : id === name
          }
          return codeIds.includes(id)
        })
      }
      codesFilter[filterType] = filterValues
    })
    return codesFilter
  }

  private get filtersInState() {
    return this.eventsStore.appState.mobileActivityFiltersByFilterType
  }

  private loadSelectedDateActivities() {
    this.eventsStore.dispatch(GET_SCHEDULE, this.currentDate)
    this.clearRequestNodesState()
  }

  private loadAllActivities() {
    this.eventsStore.dispatch(GET_SCHEDULE)
    this.clearRequestNodesState()
  }

  @action.bound
  private clearRequestNodesState() {
    this.requestNodesState.clear()
    this.nodesState.forEach((value, key) => {
      if (key.startsWith(SUB_NODE_PREFIX)) {
        this.nodesState.set(key, false)
      }
    })
    this.showMoreControlsState.clear()
    this.shouldCollapseAllNodes = null
  }

  private getTreeNodesWithState(
    source: IActivitiesTreeNodeSource[],
  ): IActivitiesTreeNode[] {
    return source
      .map(sourceNode => {
        const { id, nodeType, entityId, children: childrenSource } = sourceNode
        const state = {
          expanded: this.isNodeExpanded(id, nodeType),
          isStatusExpanded: this.containerStatusState.get(id),
        }
        const entity = entityId && this.activitiesStore.byId.get(entityId)
        const children =
          childrenSource && this.getTreeNodesWithState(childrenSource)

        if (
          (children && !children.length) ||
          (nodeType === ActivitiesTreeNodeTypes.ACTIVITY && !entity)
        ) {
          return null
        }

        return ActivitiesTreeNode.fromSource(sourceNode, {
          state,
          children,
          entity,
        })
      })
      .filter(node => node)
  }
}
