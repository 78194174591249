import * as React from 'react'

import { computed } from 'mobx'
import { inject } from 'mobx-react'

import { StatusUpdateType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import {
  DELETE_STATUS_UPDATE_FOR_DATE,
  UPDATE_ACTIVITY_COMPANIES_STATUSES,
  UPDATE_ACTIVITY_COMPANY_STATUS_WITH_NOTIFICATION,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import {
  UNASSIGNED,
  UNASSIGNED_FILTER_VALUE,
} from '~/client/src/shared/utils/ZoneLevelLocationConstants'

import ProjectDateStore, { isAfter } from '../../../stores/ui/ProjectDate.store'
import ActivitiesTreeStore from '../../ActivitiesTree/ActivitiesTree.store'
import StatusUpdateInputBase from './StatusUpdateInputBase'

interface IProps {
  commonStatusUpdate: StatusUpdate
  currentViewDate: Date
  company: string
  activity: Activity
  eventsStore?: EventsStore
  activitiesTreeStore: ActivitiesTreeStore
  projectDateStore?: ProjectDateStore
}

const STEP = 5
const CLEAR_VALUE = STEP * -1

@inject('eventsStore', 'projectDateStore')
export default class PercentCompleteStatusUpdate extends React.Component<IProps> {
  public state = {
    shouldShowFinishButton: false,
  }

  private get openStatusMap(): Map<string, boolean> {
    return this.props.activitiesTreeStore.percentCompleteInputOpenStatus
  }

  private get loadingStatusMap(): Map<string, boolean> {
    return this.props.activitiesTreeStore.percentCompleteInputLoadingStatus
  }

  private get valueStatusMap(): Map<string, number> {
    return this.props.activitiesTreeStore.percentCompleteInputValueStatus
  }

  private get activityKey(): string {
    const { activity, company } = this.props
    return `${activity.code}-${company}`
  }

  @computed
  private get currentValue() {
    const { commonStatusUpdate, company, projectDateStore } = this.props
    const companyStatus = commonStatusUpdate.getCompanyStatus(
      company,
      projectDateStore.isToday,
    )
    const value = companyStatus.percentComplete || 0
    return Math.round(value / STEP) * STEP
  }

  @computed
  private get isActivityNotStarted(): boolean {
    const { activity, currentViewDate, projectDateStore } = this.props
    const plannedStartDate = activity.dates.planned.start.date
    return (
      !activity.didStart &&
      isAfter(plannedStartDate, currentViewDate) &&
      !projectDateStore.isSameDay(
        activity.dates.planned.start.date,
        currentViewDate,
      )
    )
  }

  private get didActivityFinish(): boolean {
    const { activity, commonStatusUpdate } = this.props
    return (
      activity.didFinish &&
      commonStatusUpdate.percentComplete === StatusUpdate.MAX_PERCENT
    )
  }

  @computed
  private get didValueUpdate() {
    const { commonStatusUpdate, company, currentViewDate, projectDateStore } =
      this.props
    const { dateFor, didUpdateByCompany } = commonStatusUpdate

    if (!projectDateStore.isSameDay(new Date(+dateFor), currentViewDate)) {
      return false
    }
    const shouldShowAsUpdated = company
      ? didUpdateByCompany(company, projectDateStore.isSameDay, true, false)
      : true
    return shouldShowAsUpdated
  }

  @computed
  private get lastUpdateTime() {
    const { commonStatusUpdate, company, projectDateStore } = this.props
    const companyStatus = commonStatusUpdate.getCompanyStatus(
      company,
      projectDateStore.isToday,
    )

    return projectDateStore.getTimeToDisplay(
      companyStatus.percentCompleteUpdatedAt,
    )
  }

  private get leftCaption() {
    if (this.didActivityFinish) {
      return (
        <div className="text extra-large primary-blue">
          {Localization.translator.completed}
        </div>
      )
    }

    if (this.didValueUpdate) {
      return <div className="text light large">{this.lastUpdateTime}</div>
    }

    if (this.isActivityNotStarted) {
      return (
        <div className="text light extra-large">
          {Localization.translator.startEarly}
        </div>
      )
    }

    return (
      <div className="text red extra-large">
        {Localization.translator.PComplete}
      </div>
    )
  }

  private get icon() {
    if (this.didValueUpdate || this.didActivityFinish) {
      return <Icons.StatusBadge className="col" />
    }
    return <Icons.StatusBadgeGray className="col" />
  }

  private get submitTitle() {
    const { activity } = this.props

    if (this.state.shouldShowFinishButton) {
      return (
        <div className="actual-button">
          {Localization.translator.finish_verb}
        </div>
      )
    }

    if (!activity.didStart) {
      return (
        <div className="actual-button">
          {Localization.translator.start_verb}
        </div>
      )
    }

    return Localization.translator.ok
  }

  public render() {
    const { getTodayPercentPosition } = this.props.activity
    const normalizedPercentComplete =
      Math.round(getTodayPercentPosition(this.props.projectDateStore) / STEP) *
      STEP
    return (
      <StatusUpdateInputBase
        value={this.currentValue}
        didUpdate={this.didValueUpdate}
        leftCaption={this.leftCaption}
        icon={this.icon}
        targetValue={normalizedPercentComplete}
        step={STEP}
        onChange={this.updatePercentComplete}
        openStatusMap={this.openStatusMap}
        loadingStatusMap={this.loadingStatusMap}
        valueStatusMap={this.valueStatusMap}
        activityKey={this.activityKey}
        didStart={!this.isActivityNotStarted}
        didFinish={this.didActivityFinish}
        minValue={this.didValueUpdate ? CLEAR_VALUE : 0}
        valueRender={this.valueRender}
        submitTitle={this.submitTitle}
        onInputValueChanged={this.onInputValueChanged}
      />
    )
  }

  private onInputValueChanged = (value: number) => {
    const shouldShowFinishButton = value === StatusUpdate.MAX_PERCENT
    if (shouldShowFinishButton !== this.state.shouldShowFinishButton) {
      this.setState({ shouldShowFinishButton })
    }
  }

  private valueRender(value: number) {
    switch (value) {
      case CLEAR_VALUE:
        return Localization.translator.clear
      default:
        return value
    }
  }

  private updatePercentComplete = (percentComplete: number) => {
    const { company, activity, currentViewDate, eventsStore } = this.props

    const companyValue =
      company === UNASSIGNED ? UNASSIGNED_FILTER_VALUE : company

    if (percentComplete === CLEAR_VALUE) {
      eventsStore.dispatch(
        DELETE_STATUS_UPDATE_FOR_DATE,
        activity.code,
        companyValue,
        currentViewDate.getTime(),
      )
      return
    }

    const shouldShowMovedToCurrentSectionMessage =
      (activity.didFinish && percentComplete !== StatusUpdate.MAX_PERCENT) ||
      !activity.didStart

    const eventName = shouldShowMovedToCurrentSectionMessage
      ? UPDATE_ACTIVITY_COMPANY_STATUS_WITH_NOTIFICATION
      : UPDATE_ACTIVITY_COMPANIES_STATUSES

    const date = Date.now()
    let type = StatusUpdateType.Active
    let actualEndDate = null

    if (percentComplete === StatusUpdate.MAX_PERCENT) {
      type = StatusUpdateType.SetDate
      actualEndDate = date
    }

    eventsStore.dispatch(
      eventName,
      activity.code,
      date,
      [companyValue],
      percentComplete,
      null,
      type,
      null,
      actualEndDate,
    )
  }
}
