import { action, computed, observable } from 'mobx'

import { TagType } from '~/client/src/shared/enums/TagType'
import Tag, { ITag } from '~/client/src/shared/models/Tag'
import { IBaseTagsStore } from '~/client/src/shared/stores/BaseTags.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import Colors from '~/client/src/shared/theme.module.scss'

const tagTypesSupportingColoring: TagType[] = [
  TagType.Team,
  TagType.Role,
  TagType.Trade,
]

const tagTypeToLoadingEventMap = {
  [TagType.Team]: e.SAVE_PROJECT_TEAM,
  [TagType.Role]: e.SAVE_PROJECT_ROLE,
  [TagType.Trade]: e.SAVE_PROJECT_TRADE,
  [TagType.Company]: e.SAVE_COMPANIES,
}

export default class StruxhubTagsInputStore {
  @observable public values: string[] = []
  @observable public isActive: boolean = false
  @observable public inputValue: string = ''
  @observable public selectedColor: string = Colors.neutral0

  public constructor(
    private readonly state: InitialState,
    private readonly tagsStore: TagsStore,
    private readonly onChange: (newValues: string[]) => void,
    private readonly tagType: TagType,
    private readonly isSingleSelectionMode: boolean = false,
    private readonly restrictedValues: string[] = [],
    values: string[],
  ) {
    this.init(values)
  }

  public init(values: string[]) {
    this.values = values || []
  }

  @computed
  public get availableTags(): ITag[] {
    return this.tagsStore.tagListsByTagTypeMap[this.tagType]
      .filter(({ id }) => !this.restrictedValues.includes(id))
      .sort(({ name: name1 }, { name: name2 }) =>
        name1.toLowerCase().localeCompare(name2.toLowerCase()),
      )
  }

  public get sourceStore(): IBaseTagsStore {
    return this.tagsStore.tagStoreByTagTypeMap[this.tagType]
  }

  @computed
  public get suggestedTags(): ITag[] {
    return this.availableTags.filter(
      ({ id, name }) =>
        !this.values.includes(id) &&
        name.toLowerCase().includes(this.inputValue.toLowerCase()),
    )
  }

  public get shouldShowColorPicker(): boolean {
    return tagTypesSupportingColoring.includes(this.tagType)
  }

  public get shouldShowInput(): boolean {
    return this.isSingleSelectionMode ? !this.isThereSelectedValue : true
  }

  public get shouldShowPlusIcon(): boolean {
    return this.isSingleSelectionMode ? false : this.isThereSelectedValue
  }

  public get isLoading(): boolean {
    return this.state.loading.get(tagTypeToLoadingEventMap[this.tagType])
  }

  @action.bound
  public activate() {
    this.isActive = true
  }

  @action.bound
  public deactivate() {
    this.isActive = false
  }

  @action.bound
  public toggleActivation() {
    this.isActive = !this.isActive
  }

  public get allColors(): string[] {
    return [
      ...this.state.delivery.configurations.allColors,
      ...(this.state.projectColoringOptions.colors || []),
    ]
  }

  @action.bound
  public changeColor(newColor: string) {
    this.selectedColor = newColor
  }

  @action.bound
  public changeInputValue(newValue: string) {
    this.inputValue = newValue
  }

  @action.bound
  public addTag(id: string) {
    const newValues = this.isSingleSelectionMode ? [id] : [...this.values, id]
    this.change(newValues)

    this.clearInput()
  }

  @action.bound
  public deleteTag(tagId: string) {
    const newValues = this.values.filter(v => v !== tagId)
    this.change(newValues)
  }

  @action.bound
  public createNewTag() {
    this.sourceStore.save(
      new Tag(null, this.inputValue, this.selectedColor, null, this.tagType),
      this.createNewTagSuccess,
    )
  }

  @action.bound
  public createNewTagSuccess(id: string) {
    this.addTag(id)
    this.clearInput()
  }

  @action.bound
  private change(newValues: string[]) {
    this.onChange(newValues)

    if (this.isSingleSelectionMode) {
      this.deactivate()
    }
  }

  @action
  private clearInput() {
    this.inputValue = EMPTY_STRING
  }

  @computed
  private get isThereSelectedValue(): boolean {
    return this.values?.some(tagId => this.sourceStore.getInstanceById(tagId))
  }
}
