import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  AutoSizer,
  Column,
  Table,
  defaultTableRowRenderer,
} from 'react-virtualized'

import AnalyticsReportsType from '~/client/src/shared/enums/AnalyticReportsType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IScannerHistoryPair from '~/client/src/shared/models/IScannerHistoryPair'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import {
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
} from '../../../ListWithFixedColumns/GroupedListWithFixedColumns'
import TableHeaderCell from '../../../ListWithFixedColumns/components/TableHeaderCell'
import ReportsDownload from '../../../ReportsDownload/ReportsDownload'
import UserProfilePreview from '../../../UserProfilePreview/UserProfilePreview'
import QRCodesScannerLogListStore, {
  DataKeys,
} from './QRCodesScannerLogList.store'

interface IProps {
  store: QRCodesScannerLogListStore
  projectMembersStore?: ProjectMembersStore
}

const { CHEVRON_DOWN, CHEVRON_RIGHT, TICK_CIRCLE, DISABLE } = IconNames
const DEFAULT_HEADER_HEIGHT = 40
const DEFAULT_ROW_HEIGHT = 48
const CATEGORY_ROW_HEIGHT = 46
const COLLAPSED_ROW_HEIGHT = 52
const EXTRA_LINE_HEIGHT = 16

const OVERSCAN_ROW_COUNT = 6
const USER_COLUMN_INDEX = 0
const TOP_LEVEL_INDEX = 0

const SCAN_STATION_REPORT_TITLE = 'Scan Station Report'
const SCAN_STATION_REPORT_TEMPLATE_ID = 'scan_station_list_report'

@observer
export default class QRCodesScannerLogList extends React.Component<IProps> {
  private tableRef: Table = null

  public componentDidMount() {
    if (this.props.store.collapsedCategories.size === 0)
      this.props.store.initiateCollapsedCategories()
  }

  public componentDidUpdate() {
    this.tableRef.recomputeRowHeights()
  }

  public render() {
    const { rows, columns, minTableWidth } = this.props.store

    return (
      <div
        className="virtualized-list-smart-wrapper"
        style={{ minWidth: minTableWidth }}
      >
        <AutoSizer>
          {({ width, height }) => (
            <Table
              width={width}
              height={height}
              ref={this.setTableRef}
              headerHeight={DEFAULT_HEADER_HEIGHT}
              rowHeight={this.getRowHeight}
              rowCount={rows.length}
              rowClassName="row y-stretch no-outline no-select"
              rowGetter={this.rowGetter}
              rowRenderer={this.rowRenderer}
              overscanRowCount={OVERSCAN_ROW_COUNT}
              gridClassName="no-outline"
            >
              {columns.map(column => (
                <Column
                  key={column.dataKey}
                  className="ma0 overflow-hidden lpMinus01"
                  headerClassName="ma0 overflow-hidden lpMinus01"
                  width={column.width}
                  minWidth={column.width}
                  maxWidth={column.flexGrow ? null : column.width}
                  flexGrow={column.flexGrow}
                  dataKey={column.dataKey}
                  label={column.label}
                  cellRenderer={this.renderDataCell}
                  headerRenderer={this.renderHeaderCell}
                />
              ))}
            </Table>
          )}
        </AutoSizer>
      </div>
    )
  }

  private setTableRef = (tableRef: Table) => {
    this.tableRef = tableRef
  }

  private rowGetter = ({ index }): ILWFCRow => {
    return this.props.store.rows[index]
  }

  private getRowHeight = ({ index }): number => {
    const row = this.props.store.rows[index]

    if (!row) {
      return 0
    }

    if (row.category) {
      const isCollapsed = this.props.store.collapsedCategories.get(
        row.category.categoryId,
      )

      switch (true) {
        case row.level === TOP_LEVEL_INDEX:
          return CATEGORY_ROW_HEIGHT

        case isCollapsed:
          return COLLAPSED_ROW_HEIGHT

        default:
          return DEFAULT_HEADER_HEIGHT
      }
    }

    const { getById } = this.props.projectMembersStore
    const { userId } = row.data.user.history
    const user = getById(userId)

    return user?.employeeId
      ? DEFAULT_ROW_HEIGHT + EXTRA_LINE_HEIGHT
      : DEFAULT_ROW_HEIGHT
  }

  private rowRenderer = (rowProps: {
    columns: JSX.Element[]
    rowData: ILWFCRow
  }): JSX.Element => {
    if (rowProps.rowData.category) {
      return defaultTableRowRenderer({
        ...rowProps,
        columns: [rowProps.columns[USER_COLUMN_INDEX]],
      })
    }
    return defaultTableRowRenderer(rowProps)
  }

  private renderHeaderCell = (
    { dataKey, translatorKey, label }: ILWFCColumn,
    columnIndex: number,
    style: React.CSSProperties,
  ) => {
    const { sortState } = this.props.store
    const labelText = translatorKey
      ? Localization.getText(translatorKey)
      : label || ''

    return (
      <TableHeaderCell
        key={columnIndex}
        dataKey={dataKey}
        label={labelText}
        columnIndex={columnIndex}
        toggleSelectionPopup={NOOP}
        sortedColumnKey={sortState.columnKey}
        sortingOrder={sortState.order}
        isResizable={false}
        style={style}
        className={classList({
          'br-light-cool-grey': true,
        })}
      />
    )
  }

  private renderDataCell = ({ style, dataKey, rowData }): JSX.Element => {
    if (rowData.category) {
      return this.renderCategory(
        rowData.category,
        rowData.level,
        dataKey,
        style,
      )
    }

    const value = rowData.data[dataKey]
    const renderer = this.columnKeyToCellRenderer[dataKey]

    return (
      <div
        className={classList({
          'row cell full-height full-width no-select bb-light-cool-grey br-light-cool-grey':
            true,
        })}
        style={style}
      >
        {renderer?.(value) || this.renderString(value)}
      </div>
    )
  }

  private get columnKeyToCellRenderer() {
    return {
      [DataKeys.USER]: this.renderUser,
      [DataKeys.START_TIME]: this.renderString,
      [DataKeys.FINISH_TIME]: this.renderString,
      [DataKeys.ELAPSED_TIME]: this.renderString,
      [DataKeys.QUEUE_TIME]: this.renderString,
    }
  }

  private renderUser = (scanHistory: IScannerHistoryPair): JSX.Element => {
    const { getById } = this.props.projectMembersStore
    const { userId, isAllowed, endDate } = scanHistory.history
    const { isOpenScanner } = scanHistory.scanner

    const icon = isAllowed ? TICK_CIRCLE : DISABLE

    return (
      <div className="row">
        {!isOpenScanner && (
          <Icon
            className={classList({
              'text mr8 no-grow': true,
              'success-green': isAllowed && !endDate,
              grey: isAllowed && !!endDate,
              red: !isAllowed,
            })}
            icon={icon}
            size={16}
          />
        )}
        <UserProfilePreview
          user={getById(userId)}
          withEmployeeId
          unknownUserLabel={Localization.translator.invalidUser}
        />
      </div>
    )
  }

  private renderString = (value: string): JSX.Element => {
    return (
      <div title={value} className="text ellipsis center">
        {value}
      </div>
    )
  }

  @action.bound
  private renderCategory(
    category: ILWFCCategory,
    level: number,
    dataKey: string,
    style: any,
  ): JSX.Element {
    if (dataKey !== DataKeys.USER) return null

    const { categoryId, categoryLabel, shortCategoryLabel } = category
    const {
      collapsedCategories,
      toggleCategoryCollapsing,
      getCategoryDate,
      reportData,
    } = this.props.store

    const isTopLevel = level === TOP_LEVEL_INDEX
    const isCollapsed = collapsedCategories.get(categoryId)
    const categoryIcon = isCollapsed ? CHEVRON_RIGHT : CHEVRON_DOWN
    const isActive = categoryId.startsWith('true')
    const date = !isTopLevel ? getCategoryDate(category) : undefined

    return (
      <div
        style={style}
        onClick={
          !isTopLevel ? toggleCategoryCollapsing.bind(null, categoryId) : NOOP
        }
        className={classList({
          row: true,
          'full-height full-width': !isCollapsed,
          'mx12 my5 ba-grey brada8 h40 relative collapsed': isCollapsed,
          'bg-palette-brand-lightest bb-light-cool-grey bt-light-cool-grey':
            !isTopLevel,
          'bg-white': isTopLevel,
        })}
      >
        <div
          className={classList({
            'row full-height text medium-bold pointer': true,
            ml8: !isCollapsed && !isTopLevel,
            'large line-extra-large': !isTopLevel,
            'extra-large line-24 ml12': isTopLevel,
          })}
        >
          <div className="row">
            {!isTopLevel && (
              <Icon
                className="pointer mx12 no-grow text grey-30"
                icon={categoryIcon}
              />
            )}
            {categoryLabel}
            {!isTopLevel && (
              <>
                <span className="mx8 text medium line-16 no-grow">{`(${
                  shortCategoryLabel || 0
                })`}</span>
                <div
                  className={classList({
                    'brada2 px4 py2 text large no-bold line-extra-large no-grow':
                      true,
                    'bg-orange-light': isActive,
                    'bg-palette-brand-lighter': !isActive,
                  })}
                >
                  {isActive
                    ? Localization.translator.active
                    : Localization.translator.done}
                </div>
              </>
            )}
          </div>
          {!isTopLevel && (
            <ReportsDownload
              title={SCAN_STATION_REPORT_TITLE}
              templateId={SCAN_STATION_REPORT_TEMPLATE_ID}
              activeReportViewType={AnalyticsReportsType.SCAN_STATION_REPORT}
              reportData={reportData(category.idsToSelect)}
              isProjectRangeActive={false}
              dateFrom={date}
              dateTo={date}
              isCSVOnly
            />
          )}
        </div>
      </div>
    )
  }
}
