import * as React from 'react'

import { observer } from 'mobx-react'

import CompactConfirmDialog from '~/client/src/shared/components/CompactConfirmDialog/CompactConfirmDialog'
import Localization from '~/client/src/shared/localization/LocalizationManager'

// localization: translated

interface IProps {
  isShown: boolean
  shouldBlockLateRequesting: boolean
  deadlineInterval: number

  onHide(): void
  onApply(): void
}

@observer
export default class BookingDeadlineDialog extends React.Component<IProps> {
  public render() {
    const {
      isShown,
      onHide,
      onApply,
      shouldBlockLateRequesting,
      deadlineInterval,
    } = this.props

    let title = Localization.translator.datePassedDeadline
    let applyButtonText = Localization.translator.requestAnyway
    let message = Localization.translator.thisRequestHasPassedTheDeadline

    if (shouldBlockLateRequesting) {
      title = Localization.translator.changeDate
      applyButtonText = Localization.translator.yesMoveTheDate
      message = Localization.translator.requestIsNotValid
    }

    return (
      <CompactConfirmDialog
        title={title}
        isShown={isShown}
        onHide={onHide}
        onApply={onApply}
        applyButtonText={applyButtonText}
        canOutsideClickClose={false}
      >
        <>
          <div className="text extra-large pb5">{message}</div>
          <div className="text extra-large">
            {Localization.translator.requestsMustBeInAdvance(deadlineInterval)}
          </div>
        </>
      </CompactConfirmDialog>
    )
  }
}
