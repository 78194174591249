import * as React from 'react'

import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import commonRoutes, {
  getTransitionPath,
  getURLParam,
} from '~/client/src/shared/constants/commonRoutes'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import InitialState from '~/client/src/shared/stores/InitialState'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'

import CompactDialog from '../../../CompactDialog/CompactDialog'
import DeliveryAssociationStatus from '../../../DeliveryAssociationStatus/DeliveryAssociationStatus'
import DeliveryDetailsStore from '../../DeliveryDetails.store'

import './DeliveryWorkflowMoreMenu.scss'

// translated

interface IProps {
  isShown: boolean
  onHide: () => void

  deliveryDetailsStore: DeliveryDetailsStore
  className?: string
  deliveryFollowingsStore?: DeliveryFollowingsStore
  state?: InitialState
}

const {
  duplicateCurrentDelivery,
  unsubscribeToDelivery,
  copyLinkToDelivery,
  subscribeToDelivery,
  deleteDelivery,
  copiedToClipboard,
  checkInUnscheduledDelivery,
} = Localization.translator

@inject('deliveryFollowingsStore', 'state')
@observer
export default class DeliveryWorkflowMoreMenu extends React.Component<IProps> {
  public render() {
    const {
      isShown,
      onHide,
      className,
      deliveryFollowingsStore: { isEntityFollowed },
      deliveryDetailsStore: {
        displayedDelivery,
        canDeleteDelivery,
        canDuplicateDelivery,
        toggleUnscheduledDelivery,
        shouldShowUnscheduledControl,
        isUnscheduledDelivery,
      },
    } = this.props

    return (
      <CompactDialog
        onHide={onHide}
        isShown={isShown}
        className={`compact-workflow-dialog brada10 ${className}`}
        canOutsideClickClose={true}
        shouldHideHeader={true}
      >
        <div className="col x-center">
          {canDuplicateDelivery && (
            <div className={'no-grow pa10 bb-light-cool-grey full-width'}>
              <div
                className="text extra-large center pointer row"
                onClick={this.duplicateDelivery}
              >
                <Icons.CopyFile className="no-grow mr10" />
                {duplicateCurrentDelivery}
              </div>
            </div>
          )}
          <div className="no-grow pa10 bb-light-cool-grey full-width">
            <div
              className="text extra-large center pointer row"
              onClick={this.toggleEntityFollowing}
            >
              <DeliveryAssociationStatus
                isBigStarMode={true}
                deliveryId={displayedDelivery.id}
                className="no-grow mr10"
              />
              {isEntityFollowed(displayedDelivery.id)
                ? unsubscribeToDelivery
                : subscribeToDelivery}
            </div>
          </div>
          <div className="no-grow pa10 bb-light-cool-grey full-width">
            <div
              className="text extra-large center pointer row"
              onClick={this.copyDeliveryLink}
            >
              <Icons.CopyLink className="no-grow mr10" />
              {copyLinkToDelivery}
            </div>
          </div>
          {shouldShowUnscheduledControl && (
            <div
              className={classList({
                'no-grow pa10 bb-light-cool-grey full-width unscheduled-delivery':
                  true,
                active: isUnscheduledDelivery,
              })}
            >
              <div
                className="text extra-large center pointer row unscheduled-delivery-text"
                onClick={toggleUnscheduledDelivery}
              >
                <Icons.UnscheduledDelivery className="no-grow mr10 unscheduled-delivery-icon" />
                {checkInUnscheduledDelivery}
              </div>
            </div>
          )}
          {canDeleteDelivery && (
            <div className="no-grow pa10 full-width">
              <div
                className="text extra-large center pointer row"
                onClick={this.deleteDelivery}
              >
                <Icons.DeleteDelivery className="no-grow mr10" />
                {deleteDelivery}
              </div>
            </div>
          )}
        </div>
      </CompactDialog>
    )
  }

  private toggleEntityFollowing = () => {
    const {
      onHide,
      deliveryFollowingsStore: { toggleEntityFollowing },
      deliveryDetailsStore: { displayedDelivery },
    } = this.props

    toggleEntityFollowing(displayedDelivery.id)
    onHide()
  }

  private duplicateDelivery = () => {
    const {
      onHide,
      deliveryDetailsStore: { duplicateDelivery },
    } = this.props

    duplicateDelivery()
    onHide()
  }

  private deleteDelivery = () => {
    const {
      onHide,
      deliveryDetailsStore: { canDeleteDelivery, onDeleteAction },
    } = this.props

    if (!canDeleteDelivery) {
      return
    }

    onDeleteAction()
    onHide()
  }

  private copyDeliveryLink = () => {
    const {
      onHide,
      deliveryDetailsStore: { displayedDelivery },
    } = this.props

    const path = getTransitionPath(
      commonRoutes.DELIVERY_DETAILS.replace(
        getURLParam(commonRoutes.DELIVERY_DETAILS),
        displayedDelivery.id,
      ),
      this.props.state.activeOrInitProjectCode,
    )

    navigator.clipboard.writeText(location.origin + path)
    onHide()
    showToast(copiedToClipboard, ToastTheme.SUCCESS, IconNames.TICK)
  }
}
