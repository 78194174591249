import { action, computed, observable } from 'mobx'

import { IMaturixProject } from '~/client/graph'
import {
  DeleteMaturixFromProjectDocument,
  DeleteMaturixTokenForProjectDocument,
  UpsertMaturixDataDocument,
  UpsertMaturixTokenForProjectDocument,
} from '~/client/graph/operations/generated/MaturixConfiguration.generated'
import { GetMaturixProjectsDocument } from '~/client/graph/operations/generated/MaturixProjects.generated'

import { IntegrationAppName } from '../../../enums/IntegrationAppName'
import MaturixStrengthUnit from '../../../enums/MaturixStrengthUnit'
import MaturixTemperatureUnit from '../../../enums/MaturixTemperatureUnit'
import MaturixProject from '../../../models/MaturixProject'
import Guard from '../../../utils/Guard'
import EventsStore from '../../EventStore/Events.store'
import {
  GET_MATURIX_CASTS,
  GET_MATURIX_CONFIGURATION,
} from '../../EventStore/eventConstants'
import GraphExecutorStore from '../GraphExecutor.store'
import MaturixCastsStore from './MaturixCasts.store'

const DEFAULT_TEMPERATURE_UNIT = MaturixTemperatureUnit.CELCIUS
const DEFAULT_STRENGTH_UNIT = MaturixStrengthUnit.MPA

export default class MaturixProjectsStore {
  @observable public isProjectsReceived = false
  @observable public projects: MaturixProject[] = []
  @observable public activeProjectId?: string
  @observable public maturixToken?: string
  @observable public temperatureUnit: MaturixTemperatureUnit =
    DEFAULT_TEMPERATURE_UNIT
  @observable public strengthUnit: MaturixStrengthUnit = DEFAULT_STRENGTH_UNIT

  public constructor(
    private readonly eventsStore: EventsStore,
    private graphExecutorStore: GraphExecutorStore,
    private maturixCastsStore: MaturixCastsStore,
  ) {
    Guard.requireAll({ graphExecutorStore })
  }

  public get isIntegrationEnabled(): boolean {
    return this.eventsStore.appState.isIntegrationEnabled(
      IntegrationAppName.MATURIX,
    )
  }

  @computed
  private get struxHubProjectId() {
    return this.eventsStore.appState.activeProject.id
  }

  @action.bound
  public init() {
    if (this.isIntegrationEnabled) {
      this.eventsStore.dispatch(GET_MATURIX_CONFIGURATION)
    }
  }

  @action.bound
  public loadProjects = async () => {
    this.isProjectsReceived = false
    const { data } = await this.graphExecutorStore.query(
      GetMaturixProjectsDocument,
      {
        struxHubProjectId: this.struxHubProjectId,
      },
    )

    this.projects = (data?.getMaturixProjects || []).map(
      (dto: IMaturixProject) => MaturixProject.fromDto(dto),
    )
    this.isProjectsReceived = true
  }

  private saveMaturixConfig = async (
    maturixProjectId: string,
    temperatureUnit: MaturixTemperatureUnit,
    strengthUnit: MaturixStrengthUnit,
  ) => {
    if (maturixProjectId) {
      if (this.activeProjectId) {
        await this.deleteMaturixConfig()
      }
      await this.createOrUpdateMaturixConfig(
        maturixProjectId,
        temperatureUnit,
        strengthUnit,
      )
    } else {
      await this.deleteMaturixConfig()
    }
    this.eventsStore.dispatch(GET_MATURIX_CONFIGURATION)
  }

  public saveMaturixProjectSelection = async (maturixProjectId: string) => {
    return this.saveMaturixConfig(
      maturixProjectId,
      this.temperatureUnit,
      this.strengthUnit,
    )
  }

  public saveMaturixTemperatureUnitSelection = async (
    temperatureUnit: MaturixTemperatureUnit,
  ) => {
    return this.saveMaturixConfig(
      this.activeProjectId,
      temperatureUnit,
      this.strengthUnit,
    )
  }

  public saveMaturixStrengthUnitSelection = async (
    strengthUnit: MaturixStrengthUnit,
  ) => {
    return this.saveMaturixConfig(
      this.activeProjectId,
      this.temperatureUnit,
      strengthUnit,
    )
  }

  private createOrUpdateMaturixConfig = async (
    maturixProjectId: string,
    temperatureUnit: MaturixTemperatureUnit,
    strengthUnit: MaturixStrengthUnit,
  ) => {
    return this.graphExecutorStore.mutate(UpsertMaturixDataDocument, {
      struxHubProjectId: this.struxHubProjectId,
      maturixProjectId: maturixProjectId,
      temperatureUnit: temperatureUnit,
      strengthUnit: strengthUnit,
    })
  }

  private deleteMaturixConfig = async () => {
    return this.graphExecutorStore.mutate(DeleteMaturixFromProjectDocument, {
      struxHubProjectId: this.struxHubProjectId,
    })
  }

  @action.bound
  public receiveConfig(json?: string) {
    if (json) {
      const config = JSON.parse(json)
      this.maturixToken = config?.token
      this.activeProjectId = config?.projectId
      this.temperatureUnit = config?.temperatureUnit || DEFAULT_TEMPERATURE_UNIT
      this.strengthUnit = config?.strengthUnit || DEFAULT_STRENGTH_UNIT
      if (this.maturixToken && this.activeProjectId) {
        this.eventsStore.dispatch(GET_MATURIX_CASTS)
      }
    } else {
      this.clearConfig()
      this.maturixCastsStore.clearCasts()
    }
  }

  public saveMaturixTokenForProject(maturixToken: string) {
    if (maturixToken === this.maturixToken) {
      return
    }
    if (maturixToken) {
      this.upsertMaturixTokenForProject(maturixToken)
    } else {
      this.deleteMaturixTokenForProject()
    }
  }

  @action.bound
  public upsertMaturixTokenForProject(maturixToken: string) {
    return this.graphExecutorStore
      .mutate(UpsertMaturixTokenForProjectDocument, {
        struxHubProjectId: this.struxHubProjectId,
        maturixToken: maturixToken,
      })
      .then(() => {
        this.eventsStore.dispatch(GET_MATURIX_CONFIGURATION)
        this.loadProjects()
      })
  }

  @action.bound
  public deleteMaturixTokenForProject() {
    return this.graphExecutorStore
      .mutate(DeleteMaturixTokenForProjectDocument, {
        struxHubProjectId: this.struxHubProjectId,
      })
      .then(() => {
        this.clearConfig()
        this.loadProjects()
      })
  }

  @action.bound
  public clearConfig = () => {
    this.activeProjectId = null
    this.maturixToken = null
    this.temperatureUnit = DEFAULT_TEMPERATURE_UNIT
    this.strengthUnit = DEFAULT_STRENGTH_UNIT
  }
}
