import { action, computed, observable } from 'mobx'

import { TagType } from '~/client/src/shared/enums/TagType'
import { ITag } from '~/client/src/shared/models/Tag'
import User from '~/client/src/shared/models/User'
import BaseAssignmentsStore from '~/client/src/shared/stores/BaseAssignments.store'
import BaseFollowingsStore from '~/client/src/shared/stores/BaseFollowings.store'
import InitialState from '~/client/src/shared/stores/InitialState'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'

export interface IMention {
  // external lib interface
  id: string
  display: string
}

export interface ICustomMention extends IMention {
  // extended by some custom fields only for render organization
  type: TagType
  instanceId: string
  isFirstInSection: boolean
  section: MentionModalSection
}

export type MentionModalSection = {
  sectionType: MentionModalSectionType
  sectionId?: string
}

export enum MentionModalSectionType {
  Followers,
  Teams,
  UsersByCompanies,
}

const MENTION_ID_SEPARATOR = '|'
export const MENTION_CHAR = '@'

export default class ActionBarInputWithMentionsStore {
  @observable public isSuggestionsPopupOpen: boolean = false
  @observable public shouldUserDirectoryShow: boolean = false
  @observable public selectedItemsFromDirectory: ITag[] = []

  public constructor(
    private readonly state: InitialState,
    private readonly assignmentsStore: BaseAssignmentsStore,
    private readonly followingStore: BaseFollowingsStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly entityId: string,
    private readonly tagsStore: TagsStore,
    private readonly onSuggestionsPopupShow: () => void,
    private readonly onSuggestionsPopupHide: () => void,
    private readonly onChange: (
      value: string,
      text?: string,
      mentions?: ITag[],
    ) => void,
    public currentValue: string = '',
    public currentPlainText: string = '',
    public currentMentions: ITag[] = [],
  ) {}

  public get activeUserId(): string {
    return this.state.user.id
  }

  public setValues(value: string, plainText: string, mentions: ITag[]) {
    this.currentValue = value
    this.currentPlainText = plainText
    this.currentMentions = mentions
  }

  @action.bound
  public showSuggestionsPopup() {
    this.isSuggestionsPopupOpen = true
    this.onSuggestionsPopupShow()
  }

  @action.bound
  public hideSuggestionsPopup() {
    this.isSuggestionsPopupOpen = false
    this.onSuggestionsPopupHide()
  }

  @action.bound
  public showUserDirectory() {
    this.shouldUserDirectoryShow = true
  }

  @action.bound
  public hideUserDirectory() {
    this.shouldUserDirectoryShow = false
  }

  @action.bound
  public onChangeUserDirectorySelection = (items: any[]) => {
    this.selectedItemsFromDirectory = items
  }

  public change = (
    newValue: string,
    newPlainTextValue: string,
    mentions: IMention[],
  ) => {
    const mentionTags = mentions
      .map(mention => {
        const [type, id] = mention.id.split(MENTION_ID_SEPARATOR)
        return this.tagsStore.getTag(type, id)
      })
      .filter(t => !!t)

    this.onChange(newValue, newPlainTextValue, mentionTags)
  }

  @action.bound
  public mentionFromDirectory() {
    this.hideUserDirectory()

    // translate to internal grammar of third-party widget
    const tagsAsMentionValue = this.selectedItemsFromDirectory
      .map(
        ({ name, type: type, id }) =>
          `${MENTION_CHAR}[${MENTION_CHAR}${name}](${type}${MENTION_ID_SEPARATOR}${id})`,
      )
      .join(' ')

    const tagsAsPlainText = this.selectedItemsFromDirectory
      .map(({ name }) => `${MENTION_CHAR}${name}`)
      .join(' ')

    const value = this.currentValue.slice(0, -1) + tagsAsMentionValue + ' '
    const mentions = [
      ...this.currentMentions,
      ...this.selectedItemsFromDirectory,
    ]

    this.onChange(value, this.currentPlainText + tagsAsPlainText, mentions)
  }

  public get hasUseDirectorySelectedItems(): boolean {
    return !!this.selectedItemsFromDirectory.length
  }

  public getSuggestions = (
    query: string,
    callback: (data: ICustomMention[]) => void,
  ) => {
    const searchQuery = query.toLowerCase()

    return callback([
      ...this.getAssociatedUsersAsMentions(searchQuery),
      ...this.getTeamsAsMentions(searchQuery),
      ...this.getUsersByCompaniesAsMentions(searchQuery),
    ])
  }

  private getAssociatedUsersAsMentions(searchQuery: string): ICustomMention[] {
    const associatedUsers = []

    this.associatedUsers.forEach(u => {
      const fullName = User.getFullNameToDisplay(u, this.userProjectsStore)

      if (fullName.toLowerCase().includes(searchQuery)) {
        associatedUsers.push(
          this.getMention(
            TagType.User,
            u.id,
            fullName,
            [MentionModalSectionType.Followers],
            associatedUsers.length === 0,
          ),
        )
      }
    })

    return associatedUsers
  }

  private getTeamsAsMentions(searchQuery: string): ICustomMention[] {
    const teams: ICustomMention[] = []

    const { projectTeams, defaultProjectTeams } = this.state

    projectTeams.forEach(team =>
      this.addTeamToCustomMentions(teams, team, searchQuery),
    )

    defaultProjectTeams.forEach(team =>
      this.addTeamToCustomMentions(teams, team, searchQuery),
    )

    return teams
  }

  private addTeamToCustomMentions(
    customMentions: ICustomMention[],
    team: ITag,
    searchQuery: string,
  ) {
    const { id: teamId, name: teamName, type: teamType } = team

    if (!teamName.toLowerCase().includes(searchQuery)) {
      return
    }

    customMentions.push(
      this.getMention(
        teamType,
        teamId,
        teamName,
        [MentionModalSectionType.Teams],
        customMentions.length === 0,
      ),
    )
  }

  private getUsersByCompaniesAsMentions(searchQuery: string): ICustomMention[] {
    const { usersByRelatedTagIdMap, tagListsByTagTypeMap } = this.tagsStore

    const usersByCompanies = []

    const companies = tagListsByTagTypeMap[TagType.Company]
    companies.forEach(({ id: companyId }) => {
      const companyUsers = []

      usersByRelatedTagIdMap[companyId].forEach(u => {
        const fullName = User.getFullNameToDisplay(u, this.userProjectsStore)

        if (
          fullName.toLowerCase().includes(searchQuery) &&
          u.id !== this.activeUserId
        ) {
          companyUsers.push(
            this.getMention(
              TagType.User,
              u.id,
              fullName,
              [MentionModalSectionType.UsersByCompanies, companyId],
              companyUsers.length === 0,
            ),
          )
        }
      })

      usersByCompanies.push(...companyUsers)
    })

    return usersByCompanies
  }

  @computed
  private get associatedUsers(): User[] {
    const associatedUsersIds = [
      ...this.assignedUsersIds,
      ...this.followingUsersIds,
    ]

    return this.projectMembersStore.list.filter(
      u => associatedUsersIds.includes(u.id) && u.id !== this.activeUserId,
    )
  }

  private get assignedUsersIds(): string[] {
    const assignment = this.assignmentsStore.getAssignmentByEntityId(
      this.entityId,
    )

    return assignment ? assignment.getRecipientsByType(TagType.User) : []
  }

  private get followingUsersIds(): string[] {
    return this.followingStore.getEntityFollowersIds(this.entityId)
  }

  private getMention(
    type: TagType,
    instanceId: string,
    name: string,
    section: any[],
    isFirstInSection: boolean,
  ): ICustomMention {
    const [sectionType, sectionId = ''] = section

    return {
      id:
        type +
        MENTION_ID_SEPARATOR +
        instanceId +
        MENTION_ID_SEPARATOR +
        sectionId,
      display: MENTION_CHAR + name,

      type,
      instanceId,
      section: { sectionType, sectionId },
      isFirstInSection,
    }
  }
}
