import { action, computed, observable } from 'mobx'

import {
  FilterType,
  HierarchyModes,
  IHierarchyConfigurations,
  IViewTypes,
} from '~/client/graph'
import ViewTypes from '~/client/src/shared/enums/ViewTypes'
import HierarchyConfig from '~/client/src/shared/models/HierarchyConfig'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { UPDATE_HIERARCHY_CONFIGURATION } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import Guard from '~/client/src/shared/utils/Guard'

export default class HierarchyConfigurationStore {
  @observable private config: HierarchyConfig = HierarchyConfig.getDefault()

  public constructor(
    private eventsStore: EventsStore,
    private activityFiltersStore: ActivityFiltersStore,
  ) {
    Guard.requireAll({
      eventsStore,
      activityFiltersStore,
    })
  }

  public get allBands(): any {
    return this.activityFiltersStore.enabledFilterTypes
  }

  @computed
  public get bandTypesToCaptions() {
    const map = {}

    Object.values(this.activityFiltersStore.filterInfoList)
      .filter(filterInfo => !filterInfo.isDisabled)
      .forEach(filterInfo => (map[filterInfo.name] = filterInfo.getCaption()))

    return map
  }

  @computed
  public get orderedAppliedBands(): FilterType[] {
    const { enabledFilterTypes } = this.activityFiltersStore

    return this.config.bandsHierarchyOrder.filter(band =>
      enabledFilterTypes.includes(band),
    )
  }

  public set orderedAppliedBands(bandsHierarchyOrder: FilterType[]) {
    this.config.bandsHierarchyOrder = bandsHierarchyOrder
  }

  public get viewsToHierarchyModesMap(): IViewTypes {
    return this.config.viewsToHierarchyModes
  }

  public get canGCUpdateActivity(): boolean {
    return this.config.canGCUpdateActivity
  }

  public get isDefaultConfigurationUsed(): boolean {
    return this.config.isDefault
  }

  public receiveConfiguration(config: IHierarchyConfigurations) {
    if (config) {
      this.config = HierarchyConfig.fromDto(config)
    } else {
      this.config = HierarchyConfig.getDefault()
    }
  }

  @action.bound
  public handleBandsOrderChange(bandIndex: number, isUpward: boolean) {
    const orderedBands = Array.from(this.orderedAppliedBands)
    const newIndex = isUpward ? bandIndex - 1 : bandIndex + 1
    if (newIndex < 0 || newIndex >= orderedBands.length) {
      return
    }

    const [band] = orderedBands.splice(bandIndex, 1)
    orderedBands.splice(newIndex, 0, band)

    this.orderedAppliedBands = orderedBands
    this.updateConfiguration()
  }

  @action.bound
  public handleBandRemove(bandIndex: number) {
    const orderedBands = Array.from(this.orderedAppliedBands)
    orderedBands.splice(bandIndex, 1)

    this.orderedAppliedBands = orderedBands
    this.updateConfiguration()
  }

  @action.bound
  public handleBandsAdd(selectedBands: FilterType[]) {
    this.orderedAppliedBands = [...this.orderedAppliedBands, ...selectedBands]
    this.updateConfiguration()
  }

  @action.bound
  public handleHierarchyModesForViewChange(
    viewType: ViewTypes,
    hierarchyMode: HierarchyModes,
  ) {
    this.viewsToHierarchyModesMap[viewType] = hierarchyMode
    this.updateConfiguration()
  }

  @action.bound
  public toggleAbilityUpdateByGC() {
    this.config.canGCUpdateActivity = !this.config.canGCUpdateActivity
    this.updateConfiguration()
  }

  private updateConfiguration() {
    this.eventsStore.dispatch(UPDATE_HIERARCHY_CONFIGURATION, this.config)
  }
}
