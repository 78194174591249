import { LngLat, LngLatBounds } from 'mapbox-gl'

import {
  IAddressBoundsInput,
  ILatLngInput,
  IProjectAddressInput,
} from '~/client/graph'

export const WORLD_BOUNDS = {
  _sw: new LngLat(-175, -80),
  _ne: new LngLat(175, 80),
}
const WORLD_CENTER = new LngLat(0, 0)
export const NORTH_HEADING = 0

export function toAddressBoundsInput(b): IAddressBoundsInput {
  return {
    ne: b._ne as LngLat,
    sw: b._sw as LngLat,
  }
}

export function toLngLatBounds(b: IAddressBoundsInput): LngLatBounds {
  const bounds = b && new LngLatBounds(b.sw, b.ne)
  if (!bounds || bounds.isEmpty()) {
    return new LngLatBounds(WORLD_BOUNDS._sw, WORLD_BOUNDS._ne)
  }

  return bounds
}

export function toAddressBoundsInputFromMapBox(b: any): IAddressBoundsInput {
  return {
    ne: b._ne as LngLat,
    sw: b._sw as LngLat,
  }
}

export function toLngLat(input: ILatLngInput): LngLat {
  if (!input) {
    return WORLD_CENTER
  }
  return new LngLat(input.lng, input.lat)
}

export function createEmptyAddress(): IProjectAddressInput {
  return {
    address: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    bounds: {
      ne: WORLD_BOUNDS._ne,
      sw: WORLD_BOUNDS._sw,
    },
    center: null,
    bearing: NORTH_HEADING,
    projectId: null,
  }
}

export function getAddressCopy({
  address,
  city,
  state,
  zipcode,
  country,
  bearing,
  center,
  bounds,
  projectId,
}: IProjectAddressInput): IProjectAddressInput {
  const copy: IProjectAddressInput = {
    address,
    city,
    state,
    zipcode,
    country,
    bearing,
    projectId,
  }

  if (center) {
    copy.center = {
      lat: center.lat,
      lng: center.lng,
    }
  }

  if (bounds) {
    const { sw, ne } = bounds
    copy.bounds = {
      sw: sw && {
        lat: sw.lat,
        lng: sw.lng,
      },
      ne: ne && {
        lat: ne.lat,
        lng: ne.lng,
      },
    }
  }

  return copy
}
