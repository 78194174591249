import {
  Html5Qrcode,
  Html5QrcodeScanner,
  Html5QrcodeScannerState,
} from 'html5-qrcode'
import { action, observable } from 'mobx'

import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ScanHistory from '~/client/src/shared/models/ScanHistory'
import Scanner, {
  SCAN_CODE_SEPARATOR,
  ScanCodeTypes,
} from '~/client/src/shared/models/Scanner'
import User, { QRResponse } from '~/client/src/shared/models/User'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { SCANNER_UPDATED } from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScanHistoriesStore from '~/client/src/shared/stores/domain/ScanHistories.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'
import { beep, leadingDebounce } from '~/client/src/shared/utils/util'

import QrCodesStore from '../../QRCodes.store'
import dynamicQrBoxPercent from '../../QRCodesHelper'

const SCAN_THRESHOLD = 10000
const Signin = 'Sign in'
const DASHBOARD_HEIGHT = 43
const MOBILE_DASHBOARD_HEIGHT = 60

export const SCANNER_READER_ID = 'reader'

const qrbox = dynamicQrBoxPercent()

enum ScannerMode {
  End = 'End Ride',
  Start = 'Start Ride',
}
export enum TabIds {
  Scan = 'Scan',
  Log = 'Log',
}

export class QRCodesViewFormStore {
  // there is NO WAY user can be scanned twice WITH the same responce during one ride
  @observable public sessionUsersMap: { [userId: string]: QRResponse } = {}
  @observable private sessionUsersTime: { [userId: string]: number } = {}
  @observable public selectedTabId: TabIds = TabIds.Scan
  @observable public scanningActivationTime: number = null
  @observable public userBusEnterMap: { [userId: string]: string } = {}
  @observable public activeMode: ScannerMode = ScannerMode.Start
  @observable public isUsersListExpanded: boolean = false
  @observable public isScannerPaused: boolean = false
  @observable public html5QrcodeScanner: Html5QrcodeScanner = null
  @observable public isScannerFooterHidden: boolean = false
  @observable private scannedUserId: string = null

  public constructor(
    private readonly store: QrCodesStore,
    private readonly common: CommonStore,
    private readonly scanHistoriesStore: ScanHistoriesStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly tagsStore: TagsStore,
    private readonly scannersStore: ScannersStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly state: InitialState,
  ) {}

  public initiateScannerView(): void {
    const { scanner } = this.store
    if (scanner) {
      this.store.initiateViewForm(scanner)
    }

    if (scanner?.isTimedScanner && scanner?.isActive) {
      this.scanningActivationTime = parseInt(
        window.localStorage.getItem(scanner.activeScannerStorageKey),
        10,
      )
      this.userBusEnterMap =
        JSON.parse(
          window.localStorage.getItem(scanner.scannedUsersMapStorageKey),
        ) || {}
    }

    // Initiate qr code camera screen regardless of whether the scanner has been activated or not
    this.initiateScanner()
  }

  public initiateScanner = async () => {
    const { scanner } = this.store

    const aspectRatio = await this.getAspectRatio()

    const configBase = {
      fps: 5,
      qrbox,
    }
    const config = this.store.isFullScreenMode
      ? {
          ...configBase,
          aspectRatio: aspectRatio,
          focusMode: 'continuous',
        }
      : {
          ...configBase,
          videoConstraints: {
            facingMode: 'environment',
            aspectRatio: aspectRatio,
          },
        }
    this.html5QrcodeScanner = new Html5QrcodeScanner(
      SCANNER_READER_ID,
      config,
      false,
    )

    if (scanner?.isTimedScanner && scanner?.isActive) {
      if (!this.scanningActivationTime) {
        this.scanningActivationTime = Date.now()
        window.localStorage.setItem(
          scanner.activeScannerStorageKey,
          `${Date.now()}`,
        )
        window.localStorage.removeItem(scanner.scannedUsersMapStorageKey)
      }
    }
    this.html5QrcodeScanner.render(
      leadingDebounce(this.onScanSuccess, 1500),
      this.onScanError,
    )
  }

  private async getAspectRatio() {
    const readerElement = document.getElementById(SCANNER_READER_ID)
    const { offsetWidth: width, offsetHeight: height } = readerElement

    const cameras = await Html5Qrcode.getCameras()
    const dashboardHeight =
      cameras.length > 1 ? MOBILE_DASHBOARD_HEIGHT : DASHBOARD_HEIGHT
    const aspectRatio = width / (height - dashboardHeight)
    const reverseAspectRatio = (height - dashboardHeight) / width

    return width < 600 ? reverseAspectRatio : aspectRatio
  }

  @action.bound
  public toggleFullScreenMode(): void {
    if (this.selectedTabId !== TabIds.Scan) {
      return
    }

    const scannerState = this.html5QrcodeScanner?.getState()

    // Restart scanner if going fullscreen or if it was already running
    // If it was already stopped and is now leaving fulscreen, don't restart
    if (
      !this.store.isFullScreenMode || // value from before the toggle
      scannerState === Html5QrcodeScannerState.SCANNING ||
      scannerState === Html5QrcodeScannerState.PAUSED
    ) {
      this.restartScanner()
    }
  }

  @action.bound
  public toggleFullscreenFocused(): void {
    this.store.toggleFullscreenFocused()
    this.resizeScannerComponent()
  }

  @action.bound
  public resizeScannerComponent(): void {
    const scannerState = this.html5QrcodeScanner?.getState()

    // Restart scanner if it was already running or paused
    if (
      scannerState === Html5QrcodeScannerState.SCANNING ||
      scannerState === Html5QrcodeScannerState.PAUSED
    ) {
      this.restartScanner()
    }
  }

  private restartScanner() {
    if (this.html5QrcodeScanner) {
      this.html5QrcodeScanner.clear().then(() => this.initiateScanner())
    }
  }

  @action.bound
  public toggleSelectedTab() {
    this.selectedTabId =
      this.selectedTabId === TabIds.Log ? TabIds.Scan : TabIds.Log

    if (this.html5QrcodeScanner && this.selectedTabId === TabIds.Scan) {
      this.html5QrcodeScanner.clear().then(() => this.initiateScanner())
    }
  }

  public exitScanner = (): void => {
    const { onCreateCallback, scanner } = this.store
    onCreateCallback()
    if (!scanner.isTimedByDay && !scanner.isActive) {
      this.stopScanning()
    }
  }

  public onEndRide = (avoidToggling?: boolean): void => {
    const { toggleEndRideConfirm } = this.store

    Object.values(this.userBusEnterMap).forEach(scanId => {
      const historyRecord = this.scanHistoriesStore.byId.get(scanId)
      historyRecord.endDate = Date.now()
      this.scanHistoriesStore.save(historyRecord)
    })
    this.stopScanning()

    if (!avoidToggling) {
      toggleEndRideConfirm()
    }
  }

  public onStartFromQueue = (): void => {
    Object.values(this.userBusEnterMap).forEach(scanId => {
      const historyRecord = this.scanHistoriesStore.byId.get(scanId)
      historyRecord.date = Date.now()
      this.scanHistoriesStore.save(historyRecord)
    })

    this.store.startSessionFromQueue()
  }

  public toggleMode = (): void => {
    this.sessionUsersMap = {}
    this.scanningActivationTime = null
    this.store.response = QRResponse.notActivated

    window.localStorage.removeItem(this.store.scanner.activeScannerStorageKey)

    if (this.activeMode === ScannerMode.Start) {
      this.activeMode = ScannerMode.End
    } else {
      this.activeMode = ScannerMode.Start
    }

    this.store.toggleRideModeModalShown()
  }

  @action.bound
  public toggleUsersList(): void {
    this.isUsersListExpanded = !this.isUsersListExpanded
  }

  private onScanError(): void {
    // this function is required but not needed in current implementation
  }

  private onScanSuccess = (decodedText: string): void => {
    const { getTagById } = this.tagsStore
    const { getCompanyMembers } = this.projectMembersStore
    const { scanner } = this.store

    if (!scanner?.isActive) {
      return
    }

    let code, entityId
    if (decodedText.includes(SCAN_CODE_SEPARATOR)) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;[code, entityId] = decodedText.split(SCAN_CODE_SEPARATOR)
    } else {
      code = ScanCodeTypes.user
      entityId = decodedText
    }

    let userIds = []

    switch (code) {
      case ScanCodeTypes.user:
        userIds = [entityId]
        break
      case ScanCodeTypes.company:
        userIds = getCompanyMembers(entityId).map(user => user.id)
        break
      case ScanCodeTypes.team:
        const tag = getTagById(entityId)
        if (tag.type === TagType.Team) {
          userIds = this.getUsersByTagId(tag.id).map(user => user.id)
        }
        break
      default:
        break
    }
    userIds.forEach(userId => {
      this.scanActionForSingleUser(userId)
    })
  }

  private getUsersByTagId = (tagId: string): User[] => {
    return this.tagsStore.usersByRelatedTagIdMap[tagId]
  }

  private getUserIdFromCode = (code: string): string => {
    const user = this.projectMembersStore.getById(code)
    if (user?.id) return user.id

    const userProjectId = this.userProjectsStore.codesToUserProjectIdsMap[code]
    return this.userProjectsStore.getById(userProjectId)?.userId
  }

  private getScanResponse = (code: string): QRResponse => {
    const { scanner, isUserInScanner } = this.store

    const userId = this.getUserIdFromCode(code)
    const isLocationAvailable =
      scanner.location &&
      this.scannersStore.userLocationsMap[userId]?.includes(scanner.location.id)

    if (scanner.isOpenScanner) {
      return userId ? QRResponse.allowed : QRResponse.notAllowed
    }

    const isUserInList = isUserInScanner(scanner, code)

    return isUserInList || isLocationAvailable
      ? QRResponse.allowed
      : QRResponse.notAllowed
  }

  private scanActionForSingleUser = (userId: string): void => {
    const { scanner } = this.store

    const user = this.projectMembersStore.getById(userId)

    const response = this.getScanResponse(userId)
    this.store.response = response

    window.setTimeout(this.resetResponse, 1000)

    if (this.store.response === QRResponse.notAllowed) {
      this.beep('failure')
      showToast(Localization.translator.failed, ToastTheme.ERROR, null, 3000)
    } else {
      this.beep('success')
      if (user) {
        showToast(
          `${user.fullName} ${Signin} ${this.projectDateStore.getTimeToDisplay(
            new Date(),
          )}, ${scanner.name}`,
          ToastTheme.SUCCESS,
          null,
          3000,
        )
      }
    }

    this.setHistory(userId, scanner.id, response)
  }

  private resetResponse = (): void => {
    this.store.response = QRResponse.notActivated
  }

  private async setHistory(
    text: string,
    scannerId: string,
    response: QRResponse,
  ): Promise<void> {
    const { scanner } = this.store

    const userId = this.getUserIdFromCode(text)
    const isAllowed = response === QRResponse.allowed

    if (this.activeMode === ScannerMode.Start) {
      if (scanner.isTimedScanner) {
        this.scanningActivationTime = Date.now()
        window.localStorage.setItem(
          scanner.activeScannerStorageKey,
          `${Date.now()}`,
        )
      }

      if (
        ((!this.sessionUsersMap[userId] ||
          this.sessionUsersMap[userId] !== response) &&
          scanner.isTimedScanner) ||
        (!scanner.isTimedScanner &&
          (!this.sessionUsersTime[userId] ||
            Math.abs(this.sessionUsersTime[userId] - Date.now()) >
              SCAN_THRESHOLD))
      ) {
        const currentDate = Date.now()

        this.sessionUsersMap[userId] = response
        this.sessionUsersTime[userId] = currentDate
        const newHistoryRecord: ScanHistory = new ScanHistory(
          null,
          this.state.activeProject.id,
          scannerId,
          userId,
          isAllowed,
          scanner.isQueueScanner ? null : currentDate,
          null,
          scanner.isQueueScanner ? currentDate : null,
        )
        this.scannedUserId = userId
        await this.scanHistoriesStore.save(
          newHistoryRecord,
          this.setHistoryCallback,
        )
      }
      return
    }
    const oldHistoryRecord: ScanHistory =
      this.scanHistoriesStore.historiesByScannerAndUser[scannerId]?.[userId]

    // should update history ONLY for users that are currently on the 'bus'
    if (
      oldHistoryRecord &&
      !oldHistoryRecord.endDate &&
      (isAllowed || scanner?.isOpenScanner)
    ) {
      oldHistoryRecord.endDate = Date.now()
      this.scanHistoriesStore.save(oldHistoryRecord, this.setHistoryCallback)
    }
  }

  private setHistoryCallback = (id: string) => {
    const { scanner } = this.store

    if (!this.userBusEnterMap[this.scannedUserId]) {
      this.userBusEnterMap[this.scannedUserId] = id
    } else {
      delete this.userBusEnterMap[this.scannedUserId]
    }

    window.localStorage.setItem(
      scanner.scannedUsersMapStorageKey,
      JSON.stringify(this.userBusEnterMap),
    )
    this.scannedUserId = null
  }

  public onScannerUpdated = (eventContext: EventContext): void => {
    const { scanner, selectScanner } = this.store
    const [eventType, sc] = eventContext.event

    if (eventType === SCANNER_UPDATED && sc?.scanner?.id === scanner.id) {
      const updatedScanner = Scanner.fromDto(sc.scanner?.item)

      if (this.html5QrcodeScanner && !updatedScanner.isActive) {
        this.stopScanning()
      }

      selectScanner(updatedScanner)
    }
  }

  private stopScanning(): void {
    const { scanner } = this.store
    this.store.response = QRResponse.notActivated
    this.scanningActivationTime = null
    this.sessionUsersMap = {}
    this.userBusEnterMap = {}
    this.store.isFullScreenMode = false

    window.localStorage.removeItem(scanner?.activeScannerStorageKey)
    window.localStorage.removeItem(scanner?.scannedUsersMapStorageKey)

    if (scanner?.isActive) {
      this.store.deactivateScanner()
    }
  }

  @action.bound
  public pauseResumeScanner = (): void => {
    if (this.isScannerPaused) {
      this.html5QrcodeScanner?.resume()
    } else {
      this.html5QrcodeScanner?.pause(true)
    }
    this.isScannerPaused = !this.isScannerPaused
  }

  public onScannerFooterToggle = (): void => {
    this.isScannerFooterHidden = !this.isScannerFooterHidden
  }

  private beep(status: 'success' | 'failure'): void {
    switch (status) {
      case 'failure':
        beep(80, 200, 'sawtooth')
        break

      default: // success
        beep(1500, 50, 'square')
    }
  }
}
