import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import MobileInitialState from '../../stores/MobileInitialState'

import './ModeSelector.scss'

interface IModeSelectorProps {
  state?: MobileInitialState
  onModeToggled?: () => void
}

const { CHEVRON_DOWN, CHEVRON_UP } = IconNames

@inject('state')
@observer
export default class ModeSelector extends React.Component<IModeSelectorProps> {
  public render() {
    const { shouldShowOnlyActiveTodaySections } =
      this.props.state.compactActivityList

    return (
      <div className="row select-block mr15 uppercase no-grow">
        {shouldShowOnlyActiveTodaySections ? 'Active' : 'All'}
        <button
          className="ml10 select-button ba-grey brada2 pointer"
          onClick={this.toggleSelectModeMenu}
        >
          <Icon
            className="select-chevron"
            icon={shouldShowOnlyActiveTodaySections ? CHEVRON_DOWN : CHEVRON_UP}
          />
        </button>
      </div>
    )
  }

  @action.bound
  public toggleSelectModeMenu() {
    const settings = this.props.state.compactActivityList
    settings.shouldShowOnlyActiveTodaySections =
      !settings.shouldShowOnlyActiveTodaySections
    const { onModeToggled } = this.props
    if (onModeToggled) {
      onModeToggled()
    }
  }
}
