import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IUserProject } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import { Loader } from '~/client/src/shared/components/Loader'
import { IUserNotificationsSettings } from '~/client/src/shared/enums/ProjectSpecificUserProps'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import EventsStore from '../../stores/EventStore/Events.store'

interface IProps {
  className?: string

  eventsStore?: EventsStore
  userProjectsStore?: UserProjectsStore
}

enum notificationsType {
  sms = 'sms',
  inApp = 'inApp',
  email = 'email',
}

const relatedEvents = [
  e.GET_AUTH_USER,
  e.GET_AUTH_USER_PROJECT,
  e.SAVE_USER_PROJECTS,
]

// localization: translated

@inject('eventsStore', 'userProjectsStore')
@observer
export default class NotificationsSettingsControls extends React.Component<IProps> {
  private get isLoading(): boolean {
    const { loading } = this.props.eventsStore.appState
    return relatedEvents.some(e => loading.get(e))
  }

  public render() {
    const { className } = this.props

    return (
      <div
        className={classList({
          'notifications-settings-controls': true,
          [className]: !!className,
        })}
      >
        {this.renderBody()}
      </div>
    )
  }

  private renderBody() {
    if (this.isLoading) return <Loader />

    const {
      activityNotifications,
      deliveryNotifications,
      permitNotifications,
      announcementNotifications,
      scannerNotifications,
    } = this.userActiveProjectSettings

    return (
      <div>
        {this.renderNotificationsSettingRow(
          Localization.translator.scheduleNotifications,
          activityNotifications,
        )}
        {this.renderNotificationsSettingRow(
          Localization.translator.announcementNotifications,
          announcementNotifications,
        )}
        {this.renderNotificationsSettingRow(
          Localization.translator.deliveryNotifications,
          deliveryNotifications,
          true,
        )}
        {this.renderNotificationsSettingRow(
          Localization.translator.formNotifications,
          permitNotifications,
        )}
        {this.renderNotificationsSettingRow(
          Localization.translator.scannerNotifications,
          scannerNotifications,
        )}
      </div>
    )
  }

  private renderNotificationsSettingRow(
    sectionTitle: string,
    notificationsSettings: IUserNotificationsSettings,
    isDelivery?: boolean,
  ) {
    return (
      <>
        <div className="text mt20 extra-large bold capitalize">
          {sectionTitle}
        </div>
        <div className="row pb20 wrap">
          <div className="text large nowrap">
            {Localization.translator.iWouldLikeReceiveMy + ' ' + sectionTitle}
          </div>
          <div className="row pl20">
            <div className="row text large capitalize">
              {Localization.translator.inApp}
              <Checkbox
                className="pl10"
                isChecked={notificationsSettings && notificationsSettings.inApp}
                onClick={this.toggleNotificationSettings.bind(
                  this,
                  notificationsSettings,
                  notificationsType.inApp,
                )}
              />
            </div>
            <div className="row text large capitalize">
              {Localization.translator.bySms}
              <Checkbox
                className="pl10"
                isChecked={notificationsSettings && notificationsSettings.sms}
                onClick={this.toggleNotificationSettings.bind(
                  this,
                  notificationsSettings,
                  notificationsType.sms,
                )}
              />
            </div>
          </div>
        </div>
        {isDelivery && (
          <div className="row pb20 wrap">
            <div className="text large">
              {Localization.translator.iWouldLikeToReceiveDeliveryTickets}
            </div>
            <div className="row pl20">
              <div className="row text large capitalize">
                {Localization.translator.byEmail}
                <Checkbox
                  className="pl10"
                  isChecked={
                    notificationsSettings && notificationsSettings.email
                  }
                  onClick={this.toggleNotificationSettings.bind(
                    this,
                    notificationsSettings,
                    notificationsType.email,
                  )}
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  @action.bound
  private toggleNotificationSettings(
    section: IUserNotificationsSettings,
    option: notificationsType,
  ) {
    // toggle
    section[option] = !section[option]

    this.props.userProjectsStore.save([this.userActiveProjectSettings])
  }

  @computed
  private get userActiveProjectSettings(): IUserProject {
    return this.props.eventsStore.appState.userActiveProjectSettings.toDto()
  }
}
