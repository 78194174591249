import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ViewMode from './ViewMode'

import './ViewSelector.scss'

interface IProps {
  viewModes: ViewMode[]
  selectedViewMode: string
  selectViewMode: (mode: string) => void
}

@observer
export default class ViewSelector extends React.Component<IProps> {
  public render() {
    const { viewModes, selectedViewMode, selectViewMode } = this.props

    return (
      <div className="view-selector no-grow">
        {viewModes.map(item => (
          <div
            className={classList({
              'view-item': true,
              selected: selectedViewMode === item.mode,
              'inactive-element': item.isDisabled,
            })}
            key={item.mode}
            onClick={selectViewMode.bind(this, item.mode)}
          >
            <item.Icon />
          </div>
        ))}
      </div>
    )
  }
}
