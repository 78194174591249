import * as React from 'react'

import { observer } from 'mobx-react'

import { TagIconType } from '../../enums/TagIcon'
import { ISharedProps } from './BaseStruxhubInput'
import StruxhubTagsAsTextInput from './StruxhubTagsAsTextInput/StruxhubTagsAsTextInput'

interface IProps extends ISharedProps {
  value: string
  onChange: (newValue: string) => void
  iconName?: TagIconType
  tagColor?: string
}

@observer
export default class StruxhubTagAsTextInput extends React.Component<IProps> {
  public render() {
    const { value } = this.props

    return (
      <StruxhubTagsAsTextInput
        {...this.props}
        values={value ? [value] : []}
        isSingleSelectionMode={true}
        onChange={this.handleChange}
      />
    )
  }

  private handleChange = ([value]: string[]) => {
    this.props.onChange(value || '')
  }
}
