import Localization from '~/client/src/shared/localization/LocalizationManager'
import { enumToList } from '~/client/src/shared/utils/converters'

export enum AndOrOperator {
  OR = 'OR',
  AND = 'AND',
}

export const andOrOperators = enumToList(AndOrOperator)

export class LogicalOperation {
  public constructor(public type: AndOrOperator, public icon: any) {}
}

export enum IsNotOperator {
  IS = 'IS',
  IS_NOT = 'IS NOT',
}

export type LogicalOperator = IsNotOperator | AndOrOperator

export const getOperatorDisplayText = (operator: LogicalOperator) => {
  switch (operator) {
    case IsNotOperator.IS:
      return Localization.translator.operatorValues.is
    case IsNotOperator.IS_NOT:
      return Localization.translator.operatorValues.isNot
    case AndOrOperator.AND:
      return Localization.translator.operatorValues.and
    case AndOrOperator.OR:
      return Localization.translator.operatorValues.or
    default:
      throw new Error(operator + ' is unhandled')
  }
}

export const isNotOperatorValues = enumToList(IsNotOperator)
