import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ITag } from '~/client/src/shared/models/Tag'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { getBandTitleByTagType } from '~/client/src/shared/utils/TagHelper'
import { NOOP } from '~/client/src/shared/utils/noop'

import { EMPTY_STRING } from '../../utils/usefulStrings'
import Checkbox from '../Checkbox/Checkbox'
import CompanyProfilePreview from '../CompanyProfilePreview/CompanyProfilePreview'
import SearchBar from '../UsersDirectory/components/SearchBar/SearchBar'
import CompactCompaniesDirectoryStore from './CompactCompaniesDirectory.store'

import './CompactCompaniesDirectory.scss'

// localization: translated

interface IProps {
  className?: string
  isPhoneHidden?: boolean
  isSelectionMode?: boolean
  selectedCompanyIds?: string[]

  companyPredicate?: (company: Company) => boolean

  onHeaderCloseClick?: () => void
  onCompanyRowClick?: (company: Company) => void
  onCompanyAddClick?: (id: string) => void
  onCompanyRemoveClick?: (id: string) => void
  onResetClick?: () => void

  tagsStore?: TagsStore
  companiesStore?: CompaniesStore
}

const tagIconSize = 18

@inject('tagsStore', 'companiesStore')
@observer
export default class CompactCompaniesDirectory extends React.Component<IProps> {
  public static defaultProps = {
    onCompanyRowClick: NOOP,
    className: EMPTY_STRING,
  }

  private readonly store: CompactCompaniesDirectoryStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new CompactCompaniesDirectoryStore(
      props.tagsStore,
      props.companiesStore,
      props.selectedCompanyIds,
      props.companyPredicate,
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    const { selectedCompanyIds } = this.props
    if (selectedCompanyIds !== prevProps.selectedCompanyIds) {
      this.store.init(selectedCompanyIds)
    }
  }

  public render() {
    const { className, onHeaderCloseClick, onResetClick } = this.props

    const {
      _searchQuery,
      pivotalSearchTag,
      isDefaultMode,
      isSearchMode,
      isSingleBandMode,
      changeSearchQuery,
      resetSearch,
      resetSearchQuery,
      getBandItems,
      resetPivotalSearchTag,
    } = this.store

    return (
      <div
        className={`col full-height compact-companies-directory ${className}`}
      >
        <div>
          <div className="col">
            {!!onHeaderCloseClick && (
              <span
                className="text large blue-highlight bold no-grow lp05 pl12 py10 ml8 mb5 pointer mw65"
                onClick={onHeaderCloseClick}
              >
                {Localization.translator.close}
              </span>
            )}
            <div className="row px12 pb5">
              <SearchBar
                value={_searchQuery}
                onChange={changeSearchQuery}
                onReset={resetSearchQuery}
                pivotalTag={pivotalSearchTag}
                onTagClick={resetPivotalSearchTag}
                customPlaceholder={
                  Localization.translator.searchCompanyOrCompanyType
                }
              />
              {!isDefaultMode && (
                <span
                  className="text large blue-highlight bold no-grow lp05 ml8"
                  onClick={resetSearch}
                >
                  {Localization.translator.clear}
                </span>
              )}
            </div>

            {isDefaultMode && (
              <div className="row x-between no-flex-children px12 py5">
                <div className="pl5 text large grey-light capitalize">
                  {Localization.translator.xCompaniesShowing(
                    this.store.totalCompaniesCount,
                  )}
                </div>
                {onResetClick && (
                  <span
                    className="text large blue-highlight bold no-grow lp05 ml8 mb5 pointer mw65"
                    onClick={this.handleResetClick}
                  >
                    {Localization.translator.reset}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="overflow-auto compact-companies-directory-content">
          {isDefaultMode && this.renderCompaniesByBands()}
          {isSearchMode && this.renderSearchResByType()}
          {isSingleBandMode &&
            this.renderBandWithCompanies(
              pivotalSearchTag,
              getBandItems(pivotalSearchTag),
            )}
        </div>
      </div>
    )
  }

  private renderCompaniesByBands(): JSX.Element[] {
    const { filteredCompaniesByGroupedBands, getBand } = this.store

    return Object.keys(filteredCompaniesByGroupedBands).map(bandId => {
      const companies = filteredCompaniesByGroupedBands[bandId]

      const band = getBand(bandId)

      return this.renderBandWithCompanies(band, companies)
    })
  }

  private renderSearchResByType(): JSX.Element[] {
    const { isPhoneHidden } = this.props
    const { searchResByTypes, handleOnBandClick, getBandItemsCount } =
      this.store

    return Object.keys(searchResByTypes).map(tagType => {
      const items: any[] = searchResByTypes[tagType] || []
      const isCompanyTag = tagType === TagType.Company

      return (
        <React.Fragment key={tagType}>
          <div className="row bg-palette-brand-lightest by-light-input-border px12 py8 text large bold capitalize">
            {getBandTitleByTagType(tagType as TagType)}
          </div>
          {items.map(item => {
            const clickHandler = isCompanyTag
              ? this.handleOnCompanyRowClick
              : handleOnBandClick

            return (
              <div
                key={item.id}
                className="row px12 py12"
                onClick={clickHandler.bind(null, item)}
              >
                {this.renderCheckbox(item.id, tagType)}
                {isCompanyTag ? (
                  <CompanyProfilePreview
                    company={item}
                    isPhoneHidden={isPhoneHidden}
                  />
                ) : (
                  <SitemapAttributeTag
                    dataObject={item as LocationAttributeBase}
                    contentContainerClassName="text-ellipsis"
                    shouldShowAsTag={false}
                    iconSize={tagIconSize}
                  >
                    <span>{item.name}</span>
                  </SitemapAttributeTag>
                )}

                {!isCompanyTag && (
                  <>
                    <span className="text light no-grow nowrap">
                      (
                      {Localization.translator.xCompanies(
                        getBandItemsCount(item),
                      )}
                      )
                    </span>
                    <Icon icon={IconNames.CHEVRON_RIGHT} />
                  </>
                )}
              </div>
            )
          })}
        </React.Fragment>
      )
    })
  }

  private renderBandWithCompanies(
    band: ITag,
    companies: Company[],
  ): JSX.Element {
    const { isPhoneHidden } = this.props

    return (
      <React.Fragment key={band.id}>
        <div className="row band-row bg-grey-scale-light bb-palette-grey bt-palette-grey px12 py8">
          <SitemapAttributeTag
            contentContainerClassName="text large ellipsis white"
            dataObject={band as LocationAttributeBase}
            shouldShowAsTag={false}
            iconSize={tagIconSize}
          >
            <span>{band.name}</span>
          </SitemapAttributeTag>
          <span className="text white no-grow nowrap">
            ({Localization.translator.xCompanies(companies.length)})
          </span>
        </div>
        {companies.map((c, index) => (
          <div
            key={c.id}
            className={classList({
              'row pa12': true,
              'bt-palette-brand-lighter': !!index,
            })}
            onClick={this.handleOnCompanyRowClick.bind(null, c)}
          >
            {this.renderCheckbox(c.id, TagType.Company)}
            <CompanyProfilePreview company={c} isPhoneHidden={isPhoneHidden} />
          </div>
        ))}
      </React.Fragment>
    )
  }

  private renderCheckbox(
    itemId: string,
    itemType: TagType | string,
  ): JSX.Element {
    const { isSelectionMode, selectedCompanyIds } = this.props

    if (
      !isSelectionMode ||
      !selectedCompanyIds ||
      itemType !== TagType.Company
    ) {
      return null
    }

    const isSelected = selectedCompanyIds.includes(itemId)

    return (
      <Checkbox
        isChecked={isSelected}
        onClick={this.handleToggleItemSelection.bind(
          null,
          itemId,
          itemType,
          isSelected,
        )}
      />
    )
  }

  private handleOnCompanyRowClick = (company: Company) => {
    this.props.onCompanyRowClick(company)
  }

  private handleToggleItemSelection = (
    itemId: string,
    itemType: TagType,
    isSelected: boolean,
    e?: React.MouseEvent<HTMLElement>,
  ) => {
    e?.stopPropagation()

    this.handleCompanyAddOrRemove(itemId, itemType, isSelected)
  }

  private handleCompanyAddOrRemove = (
    itemId: string,
    itemType: TagType,
    isSelected: boolean,
  ) => {
    if (itemType !== TagType.Company) {
      return
    }

    const { onCompanyAddClick, onCompanyRemoveClick } = this.props

    return isSelected ? onCompanyRemoveClick(itemId) : onCompanyAddClick(itemId)
  }

  private handleResetClick = (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation()

    this.props.onResetClick()
  }
}
