import React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import BaseActionButton from '../../../../BaseActionButton/BaseActionButton'
import QrCodesStore from '../../../QRCodes.store'
import { QRCodesViewFormStore } from '../QRCodesViewForm.store'

interface IProps {
  store: QRCodesViewFormStore
  qrCodesStore: QrCodesStore
}

@observer
export default class RegularFooter extends React.Component<IProps> {
  public render() {
    const {
      store: { isScannerPaused, pauseResumeScanner },
      qrCodesStore: { isActive, toggleEndRideConfirm, activateScanner },
    } = this.props

    return (
      <>
        {isActive ? (
          <>
            <BaseActionButton
              className={classList({
                'primary-theme medium-bold': true,
                'primary-theme-inverted medium-bold': isScannerPaused,
              })}
              onClick={pauseResumeScanner}
              title={
                isScannerPaused
                  ? Localization.translator.resumeScanner
                  : Localization.translator.pauseScanner
              }
              isEnabled
              isGrow
            />
            <BaseActionButton
              className="ml8 primary-theme-inverted medium-bold"
              onClick={toggleEndRideConfirm}
              title={Localization.translator.endSessionForAll}
              isEnabled
              isGrow
            />
          </>
        ) : (
          <BaseActionButton
            className="primary-theme-inverted medium-bold"
            onClick={activateScanner}
            title={Localization.translator.startScanner}
            isEnabled
            isGrow
          />
        )}
      </>
    )
  }
}
