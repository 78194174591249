import * as React from 'react'

import { Spinner } from '@blueprintjs/core'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { DeliveryPageMode } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import DeliveryIconLabel from '~/client/src/shared/components/DeliveryIconLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Delivery from '~/client/src/shared/models/Delivery'
import BaseNotification from '~/client/src/shared/models/Notification'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { VIEW_NOTIFICATION } from '~/client/src/shared/stores/EventStore/eventConstants'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import getDeliveryCopyWithNewStatus from '~/client/src/shared/utils/getDeliveryCopyWithNewStatus'

import './MobileDeliveryNotificationActions.scss'

interface IProps {
  notification: BaseNotification
  eventsStore?: EventsStore
  isViewButtonHidden?: boolean
  deliveriesStore?: DeliveriesStore
  common?: CommonStore
}

@inject('eventsStore', 'deliveriesStore', 'common')
@observer
export default class MobileDeliveryNotificationActions extends React.Component<IProps> {
  private get appState() {
    return this.props.eventsStore.appState
  }

  private get delivery(): Delivery {
    const { entityId } = this.props.notification
    return this.props.deliveriesStore.byId.get(entityId)
  }

  private get isLoading(): boolean {
    return this.appState.loading.get(this.delivery.id)
  }

  public render() {
    return (
      <div className="notification-delivery-actions row">
        {this.renderChangeButton()}
        {this.renderViewButton()}
        {this.renderActionButton()}
      </div>
    )
  }

  private get actionButtonCaption() {
    switch (this.delivery.nextStatus) {
      case DeliveryStatus.Scheduled:
        return 'Accept'
      case DeliveryStatus.OnSite:
        return <DeliveryIconLabel label="Onsite" />
      case DeliveryStatus.PassedInspection:
        return 'Pass'
      case DeliveryStatus.IncompleteDone:
      case DeliveryStatus.Done:
        return <DeliveryIconLabel label="Done" />
    }
  }

  private renderActionButton() {
    return (
      this.delivery &&
      this.delivery.canUserChangeStatus(
        this.delivery.nextStatus,
        this.appState.userActiveProjectSettings,
      ) && (
        <div
          onClick={this.changeDeliveryStatus}
          className={classList({
            'btn btn-primary-blue indication-click': true,
            loading: this.isLoading,
          })}
        >
          {this.actionButtonCaption}
          {this.isLoading && <Spinner size={14} />}
        </div>
      )
    )
  }

  private renderChangeButton() {
    return (
      this.delivery &&
      this.delivery.canChange && (
        <div
          onClick={this.editNotification}
          className="btn btn-red indication-click"
        >
          Change
        </div>
      )
    )
  }

  private renderViewButton() {
    return (
      !this.props.isViewButtonHidden &&
      this.delivery &&
      !this.delivery.isDone && (
        <div
          onClick={this.viewNotification}
          className="btn btn-primary-blue indication-click"
        >
          View <Icons.CalendarGrey />
        </div>
      )
    )
  }

  @action.bound
  private editNotification() {
    const { notification, eventsStore } = this.props

    if (notification) {
      eventsStore.dispatch(VIEW_NOTIFICATION, notification)
    }
  }

  @action.bound
  private viewNotification(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    event.preventDefault()

    if (!this.delivery) {
      return
    }
    const { common, eventsStore } = this.props

    const { compactView } = eventsStore.appState.delivery
    compactView.highlightedDelivery = this.delivery
    compactView.mode = DeliveryPageMode.VIEW_WITH_HIGHLIGHTED_DELIVERY
    compactView.activeDate = new Date(this.delivery.startDate)
    compactView.resetViewMode()
    common.displayDeliveriesView()
  }

  @action.bound
  private changeDeliveryStatus(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    event.preventDefault()

    const { nextStatus } = this.delivery

    if (
      !this.isLoading &&
      this.delivery &&
      this.delivery.canUserChangeStatus(
        nextStatus,
        this.appState.userActiveProjectSettings,
      )
    ) {
      this.props.deliveriesStore.updateDelivery(
        getDeliveryCopyWithNewStatus(this.delivery, nextStatus),
        null,
        true,
      )
    }
  }
}
