import {
  Locale,
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addWeeks,
  addYears,
  differenceInDays,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  differenceInMilliseconds as fns_differenceInMilliseconds,
  differenceInMinutes as fns_differenceInMinutes,
  isAfter as fns_isAfter,
  isBefore as fns_isBefore,
  format,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import fns_tz_format from 'date-fns-tz/format'
import fns_tz_toDate from 'date-fns-tz/toDate'
import { computed } from 'mobx'

import { ILocationClosureInterval, IWorkingHours } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import InitialState from '~/client/src/shared/stores/InitialState'
import IMsDateInterval from '~/client/src/shared/types/IMsDateInterval'
import Guard from '~/client/src/shared/utils/Guard'
import { capitalizeEachWord } from '~/client/src/shared/utils/capitalizeText'
import {
  EMPTY_DATE_VALUE,
  EMPTY_STRING,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'

import DateTimeFormat from '../../enums/DateTimeFormat'
import {
  DEFAULT_WORK_FINISH_HOUR,
  DEFAULT_WORK_START_HOUR,
} from '../../models/Project'
import {
  DateTimeDisplayFormat,
  InternationalDateTimeDisplayFormat,
} from '../../utils/DateTimeFormats'

// it is service patterns
// projectDateStore.get...ToDisplay should be used for display on UI
enum Format {
  SlashedDate = 'yyyy/MM/dd',
  ISODate = 'yyyy-MM-dd',
  Year = 'yyyy',
  YearAndMonth = 'yyyy-MM',
  WeekDayNum = 'e',
  HoursNum = 'H',
  DayOfMonth = 'd',
  ISOTime = 'HH:mm',
  ISODateTime = 'yyyy-MM-dd HH:mm:ss',
  ISOFullTime = 'HH:mm:ss',
  Timezone = 'xxx',
}

export const DEFAULT_ISO_DATE_STRING = '1970-01-01'
export const HOURS_IN_DAY = 24
export const MINUTES_IN_HOUR = 60
const MILLISECONDS_IN_SECOND = 1000
export const MILLISECONDS_IN_MINUTE = 60 * MILLISECONDS_IN_SECOND
const SECONDS_IN_HOUR = 3600
export const MILLISECONDS_IN_HOUR = MINUTES_IN_HOUR * MILLISECONDS_IN_MINUTE
export const MILLISECONDS_IN_DAY = HOURS_IN_DAY * MILLISECONDS_IN_HOUR
const getPronoun = (
  daysDiff: number,
  withComma?: boolean,
  withoutTomorrow?: boolean,
) => {
  let pronoun: string
  switch (daysDiff) {
    case -1:
      pronoun = Localization.translator.yesterday
      break
    case 0:
      pronoun = Localization.translator.today
      break
    case 1:
      if (withoutTomorrow) {
        return ''
      }
      pronoun = Localization.translator.tomorrow
      break
    default:
      return ''
  }

  return pronoun + (withComma ? ',' : '')
}

export const START_OF_DAY = '00:00'
const END_DAY_TIME = ' 23:59:59.999'

const intlOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
}

const MAX_SAFE_AVAILABLE_TIMESTAMP = 32472136800000

export default class ProjectDateStore {
  public static createStoreForTimezone(timezoneId?: string) {
    return new ProjectDateStore({
      activeProject: { timezoneId },
    } as InitialState)
  }

  @computed
  public get weekdayNamesList(): string[] {
    const weekDays = this.getWeekDays(new Date())
    return weekDays.map(day => this.getWeekdayToDisplay(day))
  }

  public get monthsNamesList(): string[] {
    return Array.from({ length: 12 }, (e, i) => {
      return new Date(null, i + 1, null).toLocaleDateString('en', {
        month: 'short',
      })
    })
  }

  @computed
  public get weekdaysShortNamesList(): string[] {
    const weekDays = this.getWeekDays(new Date())
    return weekDays.map(day => this.getShortWeekdayToDisplay(day))
  }

  public readonly userTimezone =
    Intl.DateTimeFormat().resolvedOptions().timeZone

  private readonly datesCache: { [key: string]: Date } = {}
  private readonly dateStringsCache: { [key: string]: string } = {}

  public readonly usFormat = new DateTimeDisplayFormat()
  public readonly internationalFormat = new InternationalDateTimeDisplayFormat()

  public constructor(public state: InitialState) {
    Guard.requireAll({ state })
  }

  public getDateFormat(dateTimeFormat: DateTimeFormat): DateTimeDisplayFormat {
    return dateTimeFormat === DateTimeFormat.International
      ? this.internationalFormat
      : this.usFormat
  }

  public get currentFormat(): DateTimeDisplayFormat {
    const { dateTimeFormat } = this.state.activeProject
    if (dateTimeFormat === DateTimeFormat.International) {
      return this.internationalFormat
    }

    return this.usFormat
  }

  public format = (
    date: Date | number,
    pattern?: string,
    locale?: Locale,
    timezoneId?: string,
  ) => {
    return this.getDateInTimezone(
      date,
      timezoneId || this.timezoneId,
      pattern,
      locale,
    )
  }

  public getTimeToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(date, this.currentFormat.time, Localization.dateLocale),
    )
  }

  public getWeekdayToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(date, this.currentFormat.weekday, Localization.dateLocale),
    )
  }

  public getShortWeekdayToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(
        date,
        this.currentFormat.weekdayShort,
        Localization.dateLocale,
      ),
    )
  }

  public getMonthToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(date, this.currentFormat.month, Localization.dateLocale),
    )
  }

  public getYearToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(date, this.currentFormat.year, Localization.dateLocale),
    )
  }

  public getDayOfMonthToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(date, this.currentFormat.dayOfMonth, Localization.dateLocale),
    )
  }

  public getMonthAndDayToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(
        date,
        this.currentFormat.monthAndDay,
        Localization.dateLocale,
      ),
    )
  }

  public getWeekdayMonthAndDayToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(
        date,
        this.currentFormat.weekdayMonthAndDay,
        Localization.dateLocale,
      ),
    )
  }

  public getMonthDayAndYearToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(
        date,
        this.currentFormat.monthDayAndYear,
        Localization.dateLocale,
      ),
    )
  }

  public getSlashedMonthDayAndYear = (date: Date | number): string => {
    return this.format(
      date,
      this.currentFormat.slashedMonthDayAndYear,
      Localization.dateLocale,
    )
  }

  public getMonthAndYearToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(
        date,
        this.currentFormat.monthAndYear,
        Localization.dateLocale,
      ),
    )
  }

  public getWeekdayMonthDayAndYearToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(
        date,
        this.currentFormat.weekdayMonthDayAndYear,
        Localization.dateLocale,
      ),
    )
  }

  public getMonthDayAndTimeToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(
        date,
        this.currentFormat.monthDayAndTime,
        Localization.dateLocale,
      ),
    )
  }

  public getMonthDayYearAndTimeToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(
        date,
        this.currentFormat.monthDayYearAndTime,
        Localization.dateLocale,
      ),
    )
  }

  public getWeekdayMonthDayAndTimeToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(
        date,
        this.currentFormat.weekdayMonthDayAndTime,
        Localization.dateLocale,
      ),
    )
  }

  public getWeekdayMonthDayYearAndTimeToDisplay = (date: Date | number) => {
    return capitalizeEachWord(
      this.format(
        date,
        this.currentFormat.weekdayMonthDayYearAndTime,
        Localization.dateLocale,
      ),
    )
  }

  // Tue, Mar 24, 2020 12:00 AM - 12:15 AM when same day
  // Tue, Mar 24, 2020 12:00 AM - 12:15 AM (+{countOfDay}d) when diff days
  // (-) - Tue, Mar 24, 2020 12:00 AM - when startMs omitted
  // Tue, Mar 24, 2020 12:00 AM - (-) - when startMs omitted
  public getTimeIntervalToDisplay = (
    startMs: number,
    endMs: number,
    baseDateFormatFn?: (date: Date | number) => string,
  ) => {
    const formatFn =
      baseDateFormatFn || this.getWeekdayMonthDayYearAndTimeToDisplay

    let endValue = endMs ? this.getTimeToDisplay(endMs) : EMPTY_DATE_VALUE
    if (endMs && !this.isSameDay(startMs, endMs)) {
      if (startMs) {
        const daysDiff = this.countDaysToDate(startMs, endMs)
        endValue = `${endValue} (+${daysDiff}${Localization.translator.d_daysShort})`
      } else {
        endValue = formatFn(endMs)
      }
    }

    return `${startMs ? formatFn(startMs) : EMPTY_DATE_VALUE} - ${endValue}`
  }

  // 12:00 AM - 13:00 AM
  public getTimeIntervalPerDayToDisplay = (
    startDate: Date | number,
    endDate: Date | number,
  ) => {
    return (
      this.getTimeToDisplay(startDate) + ' – ' + this.getTimeToDisplay(endDate)
    )
  }

  // 11am-5pm, 6-8am, 3-8pm, 4-5:30pm
  public getShortTimeIntervalPerDay = (
    startDate: Date | number,
    endDate: Date | number,
  ): string => {
    const [startTime, startAmOrPm] = this.getShortTime(startDate)
    const [endTime, endAmOrPm] = this.getShortTime(endDate)

    const formattedAmOrPm =
      startAmOrPm === endAmOrPm ? EMPTY_STRING : startAmOrPm

    return `${startTime}${formattedAmOrPm}–${endTime}${endAmOrPm}`
  }

  public getDateIntervalToDisplay = (
    startDate: Date | number,
    endDate: Date | number,
  ) => {
    if (this.isSameDay(startDate, endDate)) {
      return this.getMonthAndDayToDisplay(startDate)
    }

    if (!this.isSameYear(startDate, endDate)) {
      return (
        this.getYearToDisplay(startDate) +
        ' - ' +
        this.getMonthDayAndYearToDisplay(endDate)
      )
    }

    const {
      intervalSameMonthStartDate,
      intervalSameMonthEndDate,
      monthAndDay,
    } = this.currentFormat

    const isOneMonthInterval = this.isSameMonth(startDate, endDate)
    const startDateFormat = isOneMonthInterval
      ? intervalSameMonthStartDate
      : monthAndDay
    const endDateFormat = isOneMonthInterval
      ? intervalSameMonthEndDate
      : monthAndDay
    return capitalizeEachWord(
      this.format(startDate, startDateFormat, Localization.dateLocale) +
        ' - ' +
        this.format(endDate, endDateFormat, Localization.dateLocale),
    )
  }

  public getFullDateIntervalToDisplay = (
    startDate: Date | number,
    endDate: Date | number,
  ) => {
    const { weekdayMonthDayAndYear } = this.currentFormat

    return capitalizeEachWord(
      this.format(startDate, weekdayMonthDayAndYear, Localization.dateLocale) +
        ' – ' +
        this.format(endDate, weekdayMonthDayAndYear, Localization.dateLocale),
    )
  }

  // 2020-02-22
  public getDashedFormattedDate = (date: Date | number) => {
    return this.format(date, Format.ISODate)
  }

  // 22:33
  public getISOTime = (date: Date | number) => {
    return this.format(date, Format.ISOTime)
  }

  // Today, Sun, Feb 23, 2020 or Today, Sun 23 Feb 2020
  public getPronounDateString = (date: Date, withoutTomorrow?: boolean) => {
    const pronoun = getPronoun(
      this.countDaysToDate(new Date(), date),
      true,
      withoutTomorrow,
    )
    return pronoun + ' ' + this.getWeekdayMonthDayAndYearToDisplay(date)
  }

  public getPronounWithMonthDayAndTimeString = (
    date: Date | number,
    withoutTomorrow?: boolean,
  ) => {
    const pronoun = getPronoun(
      this.countDaysToDate(new Date(), date),
      false,
      withoutTomorrow,
    )

    return pronoun
      ? pronoun + ', ' + this.getTimeToDisplay(date)
      : this.getMonthDayAndTimeToDisplay(date)
  }

  public getPronounOrMonthAndDayString = (
    date: Date | number,
    withoutTomorrow?: boolean,
  ) => {
    const pronoun = getPronoun(
      this.countDaysToDate(new Date(), date),
      false,
      withoutTomorrow,
    )

    return pronoun || this.getMonthAndDayToDisplay(date)
  }

  // Feb 20 or 20 Feb or -
  public getMonthAndDayToDisplayWithCheck = (date: Date | number) => {
    return this.isValidDate(date) ? this.getMonthAndDayToDisplay(date) : ' - '
  }

  // yesterday or today or tomorrow
  public getPronoun = (date: Date | number = new Date()) => {
    return getPronoun(this.countDaysToDate(new Date(), date))
  }

  // 22 -> 10:00 PM
  public getFullHourLabel = (hour: number, minutes = 0) => {
    // no convertation is needed, it is just about format number as time
    const date = new Date()
    date.setHours(hour, minutes)
    return format(date, this.currentFormat.time, {
      locale: Localization.dateLocale,
    })
  }

  public isWithinDateInterval = (
    startDate: Date | number,
    endDate: Date | number,
    date: Date | number,
  ) => {
    return isBetween(
      this.startOfDay(startDate),
      this.endOfDay(endDate),
      this.startOfDay(date).getTime() + 1, // if startDate === date method should return true
    )
  }

  public isWithinTimeInterval = (
    startDate: number,
    endDate: number,
    dateToCheck: Date | number,
  ): boolean => {
    const dateToCheckTime = this.getTimeMs(dateToCheck)
    const startTime = this.getTimeMs(startDate)
    const endTime = this.getTimeMs(endDate)

    return startTime > endTime
      ? !isWithinRange(dateToCheckTime, endTime, startTime)
      : isWithinRange(dateToCheckTime, startTime, endTime)
  }

  public isToday = (date: Date | number) => {
    return this.isSameDay(date, new Date())
  }

  public isTomorrow = (date: Date | number) => {
    return this.isToday(this.addDays(date, -1))
  }

  public isBeforeToday = (date: Date | number) => {
    return isBefore(new Date(date), this.startOfDay(new Date()))
  }

  public isSameDay = (date1: Date | number, date2: Date | number) => {
    return (
      this.format(date1, Format.ISODate) === this.format(date2, Format.ISODate)
    )
  }

  public isSameWeek = (date1: Date | number, date2: Date | number) => {
    return this.isSameDay(this.startOfWeek(date1), this.startOfWeek(date2))
  }

  public isSameMonth = (date1: Date | number, date2: Date | number) => {
    return (
      this.format(date1, Format.YearAndMonth) ===
      this.format(date2, Format.YearAndMonth)
    )
  }

  public isSameYear = (date1: Date | number, date2: Date | number) => {
    return this.format(date1, Format.Year) === this.format(date2, Format.Year)
  }

  public isThisYear = (date: Date | number) => {
    return this.isSameYear(date, new Date())
  }

  public isSunday = (date: Date | number) => {
    return this.format(date, Format.WeekDayNum) === '1'
  }

  public isSaturday = (date: Date | number) => {
    return this.format(date, Format.WeekDayNum) === '7'
  }

  public isWeekend = (date: Date | number) => {
    const day = this.format(date, Format.WeekDayNum)
    return day === '1' || day === '7'
  }

  public getWeekdayNumber = (date: Date | number) => {
    return this.format(date, Format.WeekDayNum)
  }

  public isLeap = (year: number) => {
    return new Date(year, 1, 29).getDate() === 29
  }

  public isClosedBetween = (
    startDate: Date,
    endDate: Date,
    operatingIntervals: IMsDateInterval[],
    closedIntervals: Array<IMsDateInterval | ILocationClosureInterval>,
    type: 'partially' | 'fully' = 'fully',
  ) => {
    const operatingHours: IMsDateInterval[] = operatingIntervals.map(
      interval => {
        const operatingIntervalStartDate = new Date(interval.startDate)
        const operatingIntervalEndDate = new Date(interval.endDate)

        let intervalStartDate = new Date(startDate)
        let intervalEndDate = new Date(startDate)

        intervalStartDate.setHours(
          operatingIntervalStartDate.getHours(),
          operatingIntervalStartDate.getMinutes(),
        )
        intervalEndDate.setHours(
          operatingIntervalEndDate.getHours(),
          operatingIntervalEndDate.getMinutes(),
        )

        if (intervalStartDate.getTime() < intervalEndDate.getTime()) {
          return {
            startDate: intervalStartDate.getTime(),
            endDate: intervalEndDate.getTime(),
          }
        }

        if (startDate.getTime() > intervalEndDate.getTime()) {
          intervalEndDate = this.addDays(intervalEndDate, 1)
        } else {
          intervalStartDate = this.addDays(intervalEndDate, -1)
        }

        return {
          startDate: intervalStartDate.getTime(),
          endDate: intervalEndDate.getTime(),
        }
      },
    )

    const isIntervalWithinOperationHours = doIntervalsContainInterval(
      operatingHours,
      startDate,
      endDate,
    )
    const isOpenedDuringDay =
      !operatingHours.length || isIntervalWithinOperationHours.fully
    const isClosed = {
      partially: !isOpenedDuringDay,
      fully: !isOpenedDuringDay && !isIntervalWithinOperationHours.partially,
    }

    return (
      doIntervalsContainInterval(closedIntervals, startDate, endDate)[type] ||
      isClosed[type] ||
      closedIntervals.some(interval =>
        this.isDailyIntervalIntersectsWithDates(
          interval as ILocationClosureInterval,
          startDate,
          endDate,
        ),
      )
    )
  }

  public startOfDay = (date: Date | number) => {
    if (!this.isProjectTimezoneDifferent) {
      return startOfDay(date)
    }

    const dateString = this.format(date, Format.ISODate)
    return this.fromIsoString(dateString)
  }

  public endOfDay = (date: Date | number) => {
    if (!this.isProjectTimezoneDifferent) {
      return endOfDay(date)
    }

    const dateString = this.format(date, Format.ISODate) + END_DAY_TIME
    return this.fromIsoString(dateString)
  }

  public startOfWeek = (date: Date | number) => {
    if (!this.isProjectTimezoneDifferent) {
      return startOfWeek(date)
    }

    const weekDay = Number(this.format(date, Format.WeekDayNum))
    const startWeekDate = this.addDays(date, -weekDay + 1)
    const isoDateString = this.format(startWeekDate, Format.ISODate)

    return this.fromIsoString(isoDateString)
  }

  public endOfWeek = (date: Date | number) => {
    if (!this.isProjectTimezoneDifferent) {
      return endOfWeek(date)
    }

    const weekDay = Number(this.format(date, Format.WeekDayNum))
    const endWeekDate = this.addDays(date, DAYS_IN_WEEK - weekDay)
    const isoDateString =
      this.format(endWeekDate, Format.ISODate) + END_DAY_TIME

    return this.fromIsoString(isoDateString)
  }

  public getWeekRange = (date: number | Date) => {
    if (isNaN(Number(new Date(date)))) return

    const firstDayOfWeek = this.startOfWeek(date)
    const endDayOfWeek = this.endOfWeek(date)

    return [
      this.getMonthDayAndYearToDisplay(firstDayOfWeek),
      this.getMonthDayAndYearToDisplay(endDayOfWeek),
    ].join(NO_VALUE)
  }

  public getMonthRange = (date: number | Date) => {
    if (isNaN(Number(new Date(date)))) return

    const firstDayOfMonth = this.startOfMonth(date)
    const endDayOfMonth = this.endOfMonth(date)

    return [
      this.getMonthDayAndYearToDisplay(firstDayOfMonth),
      this.getMonthDayAndYearToDisplay(endDayOfMonth),
    ].join(NO_VALUE)
  }

  public getWeekDays = (date: Date | number) => {
    const sunday = this.startOfWeek(date)
    const weekDays = [sunday]
    for (let i = 1; i < 7; i++) {
      weekDays.push(this.addDays(sunday, i))
    }
    return weekDays
  }

  public startOfMonth = (date: Date | number) => {
    if (!this.isProjectTimezoneDifferent) {
      return startOfMonth(date)
    }

    const dateString = this.format(date, Format.YearAndMonth)
    return this.fromIsoString(dateString + '-01')
  }

  public getMinAvailableDate = () => {
    return this.startOfDay(-MAX_SAFE_AVAILABLE_TIMESTAMP)
  }

  public getMaxAvailableDate = () => {
    return this.startOfDay(MAX_SAFE_AVAILABLE_TIMESTAMP)
  }

  public endOfMonth = (date: Date | number) => {
    if (!this.isProjectTimezoneDifferent) {
      return endOfMonth(date)
    }

    const [year, month] = this.getYearMonthDay(date)
    const localDate = new Date().setFullYear(year, month, 0)
    return this.fromIsoString(format(localDate, Format.ISODate) + END_DAY_TIME)
  }

  public getDayOfMonth = (date: Date | number) => {
    return Number(this.format(date, Format.DayOfMonth))
  }

  public getFilteredDaysCount = (
    from: Date,
    to: Date,
    filterPredicate: (date: Date) => boolean,
    shouldExcludeStartDay?: boolean,
  ) => {
    const intervalDays = eachDayOfInterval({
      start: this.convertFromProjectDate(from),
      end: this.convertFromProjectDate(to),
    }).map(date => this.convertToProjectDate(date))
    const { length } = intervalDays.filter(filterPredicate)

    return shouldExcludeStartDay ? length - 1 : length
  }

  public differenceInCalendarMonths = (
    dateLeft: Date | number,
    dateRight: Date | number,
  ) => {
    const [yearL, monthL] = this.getYearMonthDay(dateLeft)
    const [yearR, monthR] = this.getYearMonthDay(dateRight)
    return (yearL - yearR) * 12 + (monthL - monthR)
  }

  public getDaysInMonth = (date: Date | number) => {
    const [year, month] = this.getYearMonthDay(date)
    const lastDayOfMonth = new Date()
    lastDayOfMonth.setFullYear(year, month, 0)
    lastDayOfMonth.setHours(0, 0, 0, 0)
    return lastDayOfMonth.getDate()
  }

  public getDaysInYear = (date: Date | number) => {
    const [year] = this.getYearMonthDay(date)
    return this.isLeap(year) ? 366 : 365
  }

  public getHoursMinutes = (date: Date | number) => {
    return this.getISOTime(date)
      .split(':')
      .map(el => Number(el))
  }

  // 13:30 -> [13, 30]
  public getHoursMinutesFromISOTime = (isoTime: string) => {
    return isoTime.split(':').map(Number)
  }

  public getDaySeconds = (date: Date | number) => {
    const [hours, minutes] = this.getHoursMinutes(date)
    return (
      (minutes * MILLISECONDS_IN_MINUTE + hours * MILLISECONDS_IN_HOUR) /
      MILLISECONDS_IN_SECOND
    )
  }

  public getHours = (date: Date | number) => {
    return Number(this.format(date, Format.HoursNum))
  }

  public getTimeMs = (date: Date | number) => {
    const dateBeginingMs = this.startOfDay(date).getTime()
    const dateMs = new Date(date).getTime()
    return Math.max(dateMs - dateBeginingMs, 0)
  }

  public setTimeMs = (date: Date | number, timeMs: number) => {
    const dayBeginingMs = this.startOfDay(date).getTime()
    return new Date(dayBeginingMs + timeMs)
  }

  // (base | today) - date
  public countDaysToDate = (
    date: Date | number,
    base: Date | number = null,
    shouldAccountBaseDate?: boolean,
  ): number => {
    const target = this.startOfDay(new Date(base || Date.now()))
    const otherDate = this.startOfDay(date)

    const daysCount = differenceInDays(target, otherDate)
    return shouldAccountBaseDate ? daysCount + 1 : daysCount
  }

  public addMonths = (date: Date | number, amount: number) => {
    if (!amount) {
      return new Date(date)
    }

    if (!this.isProjectTimezoneDifferent) {
      return addMonths(date, amount)
    }

    const [year, month, day] = this.getYearMonthDay(date)
    const localDate = new Date().setFullYear(year, month - 1 + amount, day)
    return this.fromIsoString(
      format(localDate, Format.ISODate) +
        ' ' +
        this.format(date, Format.ISOFullTime),
    )
  }

  public addYears = (
    date: Date | number,
    amount: number,
    shouldIgnoreTimezone?: boolean,
  ) => {
    if (!amount) {
      return new Date(date)
    }

    if (!this.isProjectTimezoneDifferent || shouldIgnoreTimezone) {
      return addYears(date, amount)
    }

    const [year, month, day] = this.getYearMonthDay(date)
    const localDate = new Date().setFullYear(year + amount, month - 1, day)
    return this.fromIsoString(
      format(localDate, Format.ISODate) +
        ' ' +
        this.format(date, Format.ISOFullTime),
    )
  }

  public addWorkingDays = (date: Date, amount: number): Date => {
    if (!this.hasWorkingDays || !amount) {
      return this.addDays(date, amount)
    }

    let workingDate = date

    for (let counter = 0; amount < 0 ? counter > amount : counter < amount; ) {
      workingDate = this.addDays(workingDate, amount < 0 ? -1 : 1)

      if (this.isWorkingDay(workingDate)) {
        amount < 0 ? counter-- : counter++
      }
    }

    return workingDate
  }

  public addDays = (date: Date | number, amount: number) => {
    if (!amount) {
      return new Date(date)
    }

    if (!this.isProjectTimezoneDifferent) {
      return addDays(date, amount)
    }

    const [year, month, day] = this.getYearMonthDay(date)
    const localDate = new Date().setFullYear(year, month - 1, day + amount)
    return this.fromIsoString(
      format(localDate, Format.ISODate) +
        ' ' +
        this.format(date, Format.ISOFullTime),
    )
  }

  public addHours = (date: Date | number, amount: number) => {
    if (!amount) {
      return new Date(date)
    }

    return addHours(date, amount)
  }

  public addMinutes = (date: Date | number, amount: number) => {
    if (!amount) {
      return new Date(date)
    }

    return addMinutes(date, amount)
  }

  public addWeeks = (date: Date | number, amount: number) => {
    if (!amount) {
      return new Date(date)
    }

    if (!this.isProjectTimezoneDifferent) {
      return addWeeks(date, amount)
    }

    const localDate = addWeeks(
      new Date(this.format(date, Format.SlashedDate)),
      amount,
    )
    const newDateStr =
      format(localDate, Format.ISODate) +
      ' ' +
      this.format(date, Format.ISOFullTime)
    return this.fromIsoString(newDateStr)
  }

  public getDaysInInterval = (
    start: Date | number,
    end: Date | number,
  ): Date[] => {
    let startDate = new Date(start)
    const endDate = new Date(end)
    if (isAfter(startDate, endDate)) {
      return []
    }

    const dates = []
    while (!this.isSameDay(startDate, endDate)) {
      dates.push(startDate)
      startDate = this.startOfDay(this.addDays(startDate, 1))
    }

    dates.push(startDate)
    return dates
  }

  public setHours = (date: Date, hours: number, minutes: number) => {
    const isoDate = this.format(date, Format.ISODate)
    const isoTime = padWithZero(hours) + ':' + padWithZero(minutes) + ':00'
    return this.combineISODateTime(isoDate, isoTime)
  }

  public convertToProjectDate = (date: Date | number) => {
    if (this.isProjectTimezoneDifferent) {
      const dateString = format(date, Format.ISODateTime)
      return this.fromIsoString(dateString)
    }

    return new Date(date)
  }

  public convertFromProjectDate = (date: Date | number) => {
    if (this.isProjectTimezoneDifferent) {
      const dateString = this.format(date, Format.ISODateTime)
      return parseISOCustom(dateString)
    }

    return new Date(date)
  }

  public combineISODateTime = (
    ISODate: string = DEFAULT_ISO_DATE_STRING,
    ISOTime: string = START_OF_DAY,
  ): Date => {
    return this.fromIsoString(ISODate + ' ' + ISOTime)
  }

  // This wrapper is needed to ensure that we are always working with a copy of the cached date returning from "_fromIsoString"
  // Otherwise the date object may have been modified somewhere and this will lead to inconsistencies in "datesCache"
  public fromIsoString = (dateISO: string) => {
    if (!dateISO) {
      return null
    }

    return new Date(this._fromIsoString(dateISO))
  }

  private _fromIsoString = (dateISO: string) => {
    if (!dateISO) {
      return null
    }

    const localDate = parseISOCustom(dateISO)

    if (this.isProjectTimezoneDifferent) {
      const cacheKey = dateISO + this.timezoneId
      const cachedValue = this.datesCache[cacheKey]
      if (cachedValue) {
        return cachedValue
      }

      const convertedDate = fns_tz_toDate(dateISO, {
        timeZone: this.timezoneId,
      })

      const convertedHours = this.getHours(convertedDate)
      let dstDiff =
        convertedHours -
        this.getHours(convertedDate.getTime() - MILLISECONDS_IN_HOUR) -
        1
      if (dstDiff < 0) {
        dstDiff += HOURS_IN_DAY
      }
      const expectedHours = localDate.getHours() + dstDiff
      if (convertedHours !== expectedHours) {
        const discrepancy =
          (expectedHours - convertedHours) * MILLISECONDS_IN_HOUR
        return (this.datesCache[cacheKey] = new Date(
          convertedDate.getTime() + discrepancy,
        ))
      }

      return (this.datesCache[cacheKey] = convertedDate)
    }

    return localDate
  }

  public getEndDate = ({
    startDate,
    daysToAdd,
  }: {
    startDate: Date
    daysToAdd: number
  }) => {
    if (!daysToAdd) {
      return this.endOfDay(startDate)
    }

    return this.endOfDay(this.addDays(startDate, daysToAdd))
  }

  public get timezoneId() {
    return this.state.activeProject.timezoneId
  }

  public getClientTimezoneId = () => {
    return this.timezoneId || this.userTimezone
  }

  public getClientTimezoneOffsetAsString = () => {
    return 'GMT ' + this.format(new Date(), Format.Timezone)
  }

  public isValidDate = (date: Date | number | string) => {
    if (!date && date !== 0) {
      return false
    }

    if (date.constructor === String) {
      return isFinite(parseISOCustom(date as string) as any)
    }

    return (
      date.constructor === Number ||
      (date.constructor === Date && isFinite(date as any))
    )
  }

  public get workingDaysMap(): { [key: string]: boolean } {
    return this.state.activeProject.projectWorkingDaysMap
  }

  public get hasWorkingDays(): boolean {
    return !!this.state.activeProject.workingDays.length
  }

  public get projectWorkingHours(): IWorkingHours {
    const { workingHours } = this.state.activeProject

    const [defaultStartHours, defaultStartMinutes] = workingHours?.startTime
      ? this.getHoursMinutes(workingHours.startTime)
      : [DEFAULT_WORK_START_HOUR, 0]

    const [defaultEndHours, defaultEndMinutes] = workingHours?.endTime
      ? this.getHoursMinutes(workingHours.endTime)
      : [DEFAULT_WORK_FINISH_HOUR, 0]

    const now = new Date()

    const startTime = this.setHours(now, defaultStartHours, defaultStartMinutes)
    const endTime = this.setHours(now, defaultEndHours, defaultEndMinutes)

    return { startTime, endTime }
  }

  public get isProjectStartsLaterThanEnds(): boolean {
    return this.projectWorkingHours.startTime > this.projectWorkingHours.endTime
  }

  public get areProjectStartEndTimesTheSame(): boolean {
    const { startTime, endTime } = this.projectWorkingHours

    const workingStartTime = this.getTimeMs(startTime)
    const workingEndTime = this.getTimeMs(endTime)

    return workingStartTime === workingEndTime
  }

  public get projectStartHourMinutes(): number[] {
    return this.getHoursMinutes(this.projectWorkingHours.startTime)
  }

  public get projectEndHourMinutes(): number[] {
    return this.getHoursMinutes(this.projectWorkingHours.endTime)
  }

  public isHolidayOrProjectClosure = (date: Date | number): boolean => {
    return this.state.activeProject.closedIntervals?.some(i =>
      isWithinRange(
        this.startOfDay(date),
        this.startOfDay(i.startDate),
        this.endOfDay(i.endDate),
      ),
    )
  }

  public isWorkingDay = (date: Date | number): boolean => {
    const dayNumber = this.getWeekdayNumber(date)
    return (
      !this.isHolidayOrProjectClosure(date) && !!this.workingDaysMap[dayNumber]
    )
  }

  public areTimesInsideWorkingHours = (
    startDate: Date | number,
    endDate: Date | number,
  ): boolean => {
    const { startTime, endTime } = this.projectWorkingHours

    const startDateTime = this.getTimeMs(startDate)
    const endDateTime = this.getTimeMs(endDate)
    let workingStartTime = this.getTimeMs(startTime)
    let workingEndTime = this.getTimeMs(endTime)

    if (this.areProjectStartEndTimesTheSame) {
      return true
    }

    if (workingStartTime > workingEndTime) {
      workingStartTime = this.getTimeMs(this.addMinutes(startTime, -1))
      workingEndTime = this.getTimeMs(this.addMinutes(endTime, 1))

      return (
        !isWithinRange(startDateTime, workingEndTime, workingStartTime) &&
        !isWithinRange(endDateTime, workingEndTime, workingStartTime)
      )
    }

    return (
      isWithinRange(startDateTime, workingStartTime, workingEndTime) &&
      isWithinRange(endDateTime, workingStartTime, workingEndTime)
    )
  }

  public isInsideWorkingDaysAndHours = (
    startDate: Date | number,
    endDate: Date | number,
  ): boolean => {
    return (
      this.isWorkingDay(startDate) &&
      this.isWorkingDay(endDate) &&
      this.areTimesInsideWorkingHours(startDate, endDate)
    )
  }

  public getYearMonthDay(date: Date | number) {
    const dateString = this.format(date, Format.ISODate)
    return dateString.split('-').map(v => Number(v))
  }

  public isDailyIntervalIntersectsWithDates = (
    interval: ILocationClosureInterval,
    checkStartDate: Date | number,
    checkEndDate: Date | number,
  ): boolean => {
    if (!interval.isDaily) {
      return false
    }

    const startDateTime = this.getTimeMs(checkStartDate)
    const endDateTime = this.getTimeMs(checkEndDate)
    const intervalStartTime = this.getTimeMs(interval.startDate)
    const intervalEndTime = this.getTimeMs(interval.endDate)

    if (intervalStartTime > intervalEndTime && startDateTime > endDateTime) {
      return true
    }

    if (startDateTime > endDateTime || intervalStartTime > intervalEndTime) {
      return this.areRevertedTimesIntersect(
        startDateTime,
        endDateTime,
        intervalStartTime,
        intervalEndTime,
      )
    }

    return areIntervalTimesIntersects(
      {
        startDate: new Date(startDateTime),
        endDate: new Date(endDateTime),
      },
      {
        startDate: new Date(intervalStartTime),
        endDate: new Date(intervalEndTime),
      },
    )
  }

  public get isProjectTimezoneDifferent() {
    return this.timezoneId && this.timezoneId !== this.userTimezone
  }

  private getDateInTimezone(
    date: Date | number,
    timeZone: string,
    formatting?: string,
    locale?: Locale,
  ) {
    if (!this.isValidDate(date)) {
      return null
    }

    const datePattern = formatting || this.currentFormat.monthDayYearAndTime
    if (timeZone && timeZone !== this.userTimezone) {
      const cacheKey =
        timeZone + datePattern + locale + new Date(date).getTime()
      if (this.dateStringsCache[cacheKey]) {
        return this.dateStringsCache[cacheKey]
      }
      // dateFnsTz.format converts time incorrectly
      // so use browser api directly with a hack
      // omg in chrome 80 00:00 is converted to 24:00
      const shiftedDate = new Date(
        new Intl.DateTimeFormat(
          'en-US',
          Object.assign({ timeZone }, intlOptions) as unknown, // Trick to avoid type error
        )
          .format(new Date(date))
          .replace(' 24:', ' 00:'),
      )
      return (this.dateStringsCache[cacheKey] = fns_tz_format(
        shiftedDate,
        datePattern,
        { timeZone, locale },
      ))
    }

    return format(new Date(date), datePattern, { locale })
  }

  private getShortTime = (date: Date | number): string[] => {
    const formattedDate = this.format(
      date,
      this.currentFormat.shortTime,
      Localization.dateLocale,
    ).toLowerCase()

    const [hours, minutesWithAmOrPm] = formattedDate.split(':')
    const [minutes, amOrPm = EMPTY_STRING] = minutesWithAmOrPm.split(' ')

    const time = `${hours}${minutes === '00' ? EMPTY_STRING : `:${minutes}`}`

    return [time, amOrPm]
  }

  private areRevertedTimesIntersect = (
    startLeftTime: number,
    endLeftTime: number,
    startRightTime: number,
    endRightTime: number,
  ): boolean => {
    const areLeftTimesReverted = startLeftTime > endLeftTime

    const startATime = areLeftTimesReverted ? startLeftTime : startRightTime
    const endATime = this.addDays(
      areLeftTimesReverted ? endLeftTime : endRightTime,
      1,
    )
    const startBTime = areLeftTimesReverted ? startRightTime : startLeftTime
    const endBTime = areLeftTimesReverted ? endRightTime : endLeftTime

    return (
      areIntervalTimesIntersects(
        {
          startDate: new Date(startATime),
          endDate: endATime,
        },
        {
          startDate: new Date(startBTime),
          endDate: new Date(endBTime),
        },
      ) ||
      areIntervalTimesIntersects(
        {
          startDate: new Date(startATime),
          endDate: endATime,
        },
        {
          startDate: this.addDays(startBTime, 1),
          endDate: this.addDays(endBTime, 1),
        },
      )
    )
  }
}

export const DAYS_IN_WEEK: number = 7
export const isAfter = fns_isAfter
export const isBefore = fns_isBefore
export const differenceInMinutes = fns_differenceInMinutes
export const differenceInMilliseconds = fns_differenceInMilliseconds

export const isWithinRange = (
  dateToCheck: Date | number,
  start: Date | number,
  end: Date | number,
) => {
  return isWithinInterval(dateToCheck, { start, end })
}

export function isBetween(
  startDate: Date | number,
  endDate: Date | number,
  dateToCheck: Date | number,
) {
  return isBefore(dateToCheck, endDate) && isAfter(dateToCheck, startDate)
}

export function padWithZero(value: number): string {
  return String(value).padStart(2, '0')
}

export function isLateDay(currentDate: Date, dueDate: Date) {
  return currentDate.getTime() - dueDate.getTime() > 0
}

export function areIntervalTimesIntersects(
  {
    startDate: startADate,
    endDate: endADate,
  }: { startDate: Date; endDate: Date },
  {
    startDate: startBDate,
    endDate: endBDate,
  }: { startDate: Date; endDate: Date },
) {
  const startATime = startADate.getTime()
  const endATime = endADate.getTime()
  const startBTime = startBDate.getTime()
  const endBTime = endBDate.getTime()

  return endATime > startBTime && startATime < endBTime
}

// 259200 s. -> 72 h.
export function secondsToFullHours(seconds: number): number {
  return Math.floor(seconds / SECONDS_IN_HOUR)
}

// 100900000 -> 28:02 (not limited by 24 hours)
export function millisecondsToTime(ms: number): string {
  const minutes = String(Math.ceil((ms / MILLISECONDS_IN_MINUTE) % 60))
  const hours = String(Math.floor(ms / MILLISECONDS_IN_HOUR))

  return [hours, minutes].map(t => padWithZero(+t)).join(':')
}

/**
 * Cross-browser parsing ISO 8601 string to local date avoiding browser specific devilry
 * @param dateISO
 */
function parseISOCustom(dateISO: string): Date {
  // @ts-ignore: use array as tuple intentionally
  // year, monthIndex (from 0) [, day [, hours [, minutes [, seconds [, ms]]]]]
  return new Date(...dateISO.split(/\D/).map((d, i) => (i === 1 ? d - 1 : d)))
}

function doIntervalsContainInterval(
  intervals: IMsDateInterval[],
  checkStartDate: Date,
  checkEndDate: Date,
) {
  const startMs = new Date(checkStartDate).getTime()
  const endMs = new Date(checkEndDate).getTime()

  const inRangeIntervals = intervals
    .map(({ startDate, endDate }) => ({
      startDate: new Date(startDate).getTime(),
      endDate: endDate ? new Date(endDate).getTime() : endMs,
    }))
    .filter(
      ({ startDate, endDate }) =>
        startDate < endDate && startDate < endMs && endDate > startMs,
    )
    .sort((a, b) => a.startDate - b.startDate)

  const response = {
    partially: !!inRangeIntervals.length,
    fully: false,
  }

  let prevTimePosition = startMs
  for (const interval of inRangeIntervals) {
    if (interval.startDate > prevTimePosition) {
      return response
    }
    prevTimePosition = Math.max(prevTimePosition, interval.endDate)
    if (prevTimePosition >= endMs) {
      response.fully = true
      return response
    }
  }

  return response
}
