import { LocationType } from '~/client/graph'

enum AdditionTagType {
  Role = 'roles',
  Team = 'teams',
  Trade = 'trades',
  Company = 'company',
  CompanyType = 'companyType',
  Status = 'status',
  User = 'user',
  AccountType = 'accountType',
  AccountPosition = 'accountPosition',

  GlobalAndProjectSpecificRole = 'globalAndProjectSpecificRole',
}

enum DefaultTag {
  DefaultTeam = 'defaultTeams',
}

export type TagType = LocationType | AdditionTagType | DefaultTag

export const TagType = {
  ...LocationType,
  ...AdditionTagType,
  ...DefaultTag,
}
