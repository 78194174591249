import { action, observable } from 'mobx'

import { ContentObjectType, NotificationType } from '~/client/graph'
import CategoryOfVariance, {
  CategoryOfVarianceTypes,
} from '~/client/src/shared/models/CategoryOfVariance'
import Delivery from '~/client/src/shared/models/Delivery'
import Flag, { flagTypes } from '~/client/src/shared/models/Flag'
import Message from '~/client/src/shared/models/Message'
import Photo from '~/client/src/shared/models/Photo'
import Rfi, { rfiTypes } from '~/client/src/shared/models/Rfi'
import SafetyHazard, {
  SafetyHazardTypes,
} from '~/client/src/shared/models/SafetyHazard'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import BaseEventStore from '~/client/src/shared/stores/EventStore/BaseEvents.store'
import {
  NOTIFY_THREAD_REPLIED,
  REPORT_ERROR,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CategoriesOfVarianceStore from '~/client/src/shared/stores/domain/CategoriesOfVariance.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryStatusChangesStore from '~/client/src/shared/stores/domain/DeliveryStatusChanges.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import SafetyHazardsStore from '~/client/src/shared/stores/domain/SafetyHazards.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ThreadsStore from '~/client/src/shared/stores/domain/ThreadsStore/Threads.store'
import BaseActionBarStore from '~/client/src/shared/stores/ui/BaseActionBar.store'
import Guard from '~/client/src/shared/utils/Guard'

import ContentObjectModel from '../../models/ContentObjectModel'
import ScheduleComment from '../../models/ScheduleComment'
import MessageDto from '../../types/MessageDto'
import BaseFollowingsStore from '../BaseFollowings.store'
import { FileUploadingStore } from '../domain/FileUploading.store'
import TagsStore from '../domain/Tags.store'
import UserProjectsStore from '../domain/UserProjects.store'

enum SlackBarModes {
  MESSAGE,
  FLAG,
  RFI,
  SCHEDULE_COMMENT,
  CATEGORY_OF_VARIANCE,
  SAFETY_HAZARD,
}

export default class SlackBarStore extends BaseActionBarStore {
  @observable public currentMode = SlackBarModes.MESSAGE
  @observable public selectedRfiType: rfiTypes = rfiTypes.NULL
  @observable public selectedFlagType: flagTypes = flagTypes.NULL
  @observable public selectedCategoryOfVarianceType: CategoryOfVarianceTypes =
    CategoryOfVarianceTypes.NULL
  @observable public selectedSafetyHazardType: SafetyHazardTypes =
    SafetyHazardTypes.NULL
  @observable public isSending: boolean = false

  public constructor(
    eventsStore: BaseEventStore,
    threadsStore: ThreadsStore,
    messagesStore: MessagesStore,
    private activitiesStore: ActivitiesStore,
    private flagsStore: FlagsStore,
    photosStore: PhotosStore,
    private rfisStore: RfisStore,
    private categoriesOfVarianceStore: CategoriesOfVarianceStore,
    private safetyHazardsStore: SafetyHazardsStore,
    private deliveriesStore: DeliveriesStore,
    private statusUpdatesStore: StatusUpdatesStore,
    private scheduleCommentsStore: ScheduleCommentsStore,
    private deliveryStatusChangesStore: DeliveryStatusChangesStore,
    public fileUploadingStore: FileUploadingStore,
    userProjectsStore: UserProjectsStore,
    tagsStore: TagsStore,
    baseFollowingStore: BaseFollowingsStore,
  ) {
    super(
      eventsStore,
      threadsStore,
      messagesStore,
      photosStore,
      fileUploadingStore,
      userProjectsStore,
      tagsStore,
      baseFollowingStore,
    )

    Guard.requireAll({
      activitiesStore,
      flagsStore,
      rfisStore,
      deliveriesStore,
      statusUpdatesStore,
      scheduleCommentsStore,
      categoriesOfVarianceStore,
      safetyHazardsStore,
      deliveryStatusChangesStore,
      tagsStore,
    })
  }

  public get entityId(): string {
    return this.activitiesStore.selectedActivity.code
  }

  @action.bound
  public async send() {
    this.isSending = true
    if (this.decodedDraftMessage) {
      this.draftMessage = this.decodedDraftMessage
    }
    this.subscribeMentionedUsers()
    switch (this.currentMode) {
      case SlackBarModes.MESSAGE:
        if (this.fileImage) {
          await this.postImage()
        } else {
          await this.sendMessage()
        }
        break
      case SlackBarModes.SCHEDULE_COMMENT:
        await this.createScheduleComment()
        break
      case SlackBarModes.FLAG:
        await this.createFlag()
        break
      case SlackBarModes.RFI:
        await this.createRfi()
        break
      case SlackBarModes.CATEGORY_OF_VARIANCE:
        await this.createCategoryOfVariance()
        break
      case SlackBarModes.SAFETY_HAZARD:
        await this.createSafetyHazard()
        break
    }

    this.fileImage = null
    this.isSending = false
  }

  public get isEmptyMessage(): boolean {
    return !this.draftMessage && !this.fileImage
  }

  public enableMessageMode(): void {
    this.changeModeTo(SlackBarModes.MESSAGE)
  }

  @action.bound
  public enableScheduleCommentMode() {
    this.changeModeTo(SlackBarModes.SCHEDULE_COMMENT)
    this.hideMenuPopup()
  }

  @action.bound
  public enableFlagMode(): void {
    this.changeModeTo(SlackBarModes.FLAG)
    this.hideMenuPopup()
  }

  @action.bound
  public enableRfiMode() {
    this.changeModeTo(SlackBarModes.RFI)
    this.hideMenuPopup()
  }

  @action.bound
  public enableCategoryOfVarianceMode() {
    this.changeModeTo(SlackBarModes.CATEGORY_OF_VARIANCE)
    this.hideMenuPopup()
  }

  @action.bound
  public enableSafetyHazardMode() {
    this.changeModeTo(SlackBarModes.SAFETY_HAZARD)
    this.hideMenuPopup()
  }

  public resetRfiType() {
    this.setRfiType(rfiTypes.NULL)
  }

  public resetFlagType() {
    this.setFlagType(flagTypes.NULL)
  }

  public resetSafetyHazardType() {
    this.setSafetyHazardType(SafetyHazardTypes.NULL)
  }

  public resetCategoryOfVarianceType() {
    this.setCategoryOfVarianceType(CategoryOfVarianceTypes.NULL)
  }

  public async sendMessage(): Promise<MessageDto> {
    try {
      const messageText = this.draftMessage.trim()
      if (!messageText.length) {
        return Promise.resolve(null)
      }
      const thread = await this.threadsStore.findOrCreateActivityThread(
        this.activitiesStore.selectedActivity,
      )
      const message = await this.messagesStore.post(messageText, thread)
      this.reset()
      return message
    } catch (e) {
      this.handleError(e)
    }
  }

  public async createFlag(): Promise<MessageDto> {
    const caption = this.draftMessage
    const flagType = this.selectedFlagType
    this.reset()

    try {
      const thread = await this.threadsStore.create()
      await this.flagsStore.create({
        activity: this.activitiesStore.selectedActivity,
        type: flagType,
        threadId: thread.id,
        contentObjectType: ContentObjectType.Flag,
      })

      return this.postMessageWithAttachments(
        caption,
        thread,
        this.getFileCopy(this.fileImage),
      )
    } catch (e) {
      this.handleError(e)
    } finally {
      this.closeImagePreview()
    }
  }

  public async createScheduleComment(): Promise<MessageDto> {
    const caption = this.draftMessage
    this.reset()

    try {
      const thread = await this.threadsStore.create()
      await this.scheduleCommentsStore.create({
        activity: this.activitiesStore.selectedActivity,
        threadId: thread.id,
        contentObjectType: ContentObjectType.ScheduleComment,
      })

      return this.postMessageWithAttachments(
        caption,
        thread,
        this.getFileCopy(this.fileImage),
      )
    } catch (e) {
      this.handleError(e)
    } finally {
      this.closeImagePreview()
    }
  }

  public async replyToThread(
    threadEntity: ContentObjectModel<any> | Message | Delivery | StatusUpdate,
  ) {
    await super.replyToThread(threadEntity)

    let type: NotificationType
    switch (threadEntity.constructor) {
      case StatusUpdate:
        type = NotificationType.StatusUpdateReplied
        break
      case Rfi:
        type = NotificationType.RfiReplied
        break
      case Flag:
        type = NotificationType.FlagReplied
        break
      case ScheduleComment:
        type = NotificationType.ScheduleCommentReplied
        break
      case SafetyHazard:
        type = NotificationType.SafetyHazardReplied
        break
      case CategoryOfVariance:
        type = NotificationType.CategoryOfVarianceReplied
        break
    }

    if (type) {
      this.eventsStore.dispatch(NOTIFY_THREAD_REPLIED, threadEntity.id, type)
    }
  }

  public async replyOnThread(threadId: string) {
    const text = this.draftMessage.trim()
    if (!this.fileImage && !text.length) {
      return
    }
    this.reset()
    await this.postMessageWithAttachments(
      text,
      threadId,
      this.getFileCopy(this.fileImage),
    )
    this.fileImage = null
  }

  public replyToPhoto(photo: Photo) {
    const photoMessage = this.messagesStore.list.find(
      message => message.photoId === photo.id,
    )

    return this.replyToThread(photoMessage)
  }

  public closeContentObject(contentObject: ContentObjectModel<any>) {
    const type = contentObject.constructor

    switch (type) {
      case Rfi:
        return this.rfisStore.close(contentObject as Rfi)

      case Flag:
        return this.flagsStore.close(contentObject as Flag)

      case ScheduleComment:
        return this.scheduleCommentsStore.close(
          contentObject as ScheduleComment,
        )

      case CategoryOfVariance:
        return this.categoriesOfVarianceStore.close(
          contentObject as CategoryOfVariance,
        )

      case SafetyHazard:
        return this.safetyHazardsStore.close(contentObject as SafetyHazard)
    }
  }

  public toggleContentObject(contentObject: ContentObjectModel<any>) {
    const type = contentObject.constructor

    switch (type) {
      case Rfi:
        return this.rfisStore.toggle(contentObject as Rfi)

      case Flag:
        return this.flagsStore.toggle(contentObject as Flag)

      case ScheduleComment:
        return this.scheduleCommentsStore.toggle(
          contentObject as ScheduleComment,
        )

      case CategoryOfVariance:
        return this.categoriesOfVarianceStore.toggle(
          contentObject as CategoryOfVariance,
        )

      case SafetyHazard:
        return this.safetyHazardsStore.toggle(contentObject as SafetyHazard)
    }
  }

  public async createRfi(): Promise<MessageDto> {
    const caption = this.draftMessage
    const rfiType = this.selectedRfiType
    this.reset()

    try {
      const thread = await this.threadsStore.create()
      await this.rfisStore.create({
        activity: this.activitiesStore.selectedActivity,
        type: rfiType,
        threadId: thread.id,
        contentObjectType: ContentObjectType.RequestForInformation,
      })

      return this.postMessageWithAttachments(
        caption,
        thread,
        this.getFileCopy(this.fileImage),
      )
    } catch (e) {
      this.handleError(e)
    } finally {
      this.closeImagePreview()
    }
  }

  public async createCategoryOfVariance(): Promise<MessageDto> {
    const caption = this.draftMessage
    const categoryOfVarianceType = this.selectedCategoryOfVarianceType
    this.reset()

    try {
      const thread = await this.threadsStore.create()
      await this.categoriesOfVarianceStore.create({
        activity: this.activitiesStore.selectedActivity,
        type: categoryOfVarianceType,
        threadId: thread.id,
        contentObjectType: ContentObjectType.CategoryOfVariance,
      })

      return this.postMessageWithAttachments(
        caption,
        thread,
        this.getFileCopy(this.fileImage),
      )
    } catch (e) {
      this.handleError(e)
    } finally {
      this.closeImagePreview()
    }
  }

  public async createSafetyHazard(): Promise<MessageDto> {
    const caption = this.draftMessage
    const safetyHazardType = this.selectedSafetyHazardType
    this.reset()

    try {
      const thread = await this.threadsStore.create()
      await this.safetyHazardsStore.create({
        activity: this.activitiesStore.selectedActivity,
        type: safetyHazardType,
        threadId: thread.id,
        contentObjectType: ContentObjectType.SafetyHazard,
      })

      return this.postMessageWithAttachments(
        caption,
        thread,
        this.getFileCopy(this.fileImage),
      )
    } catch (e) {
      this.handleError(e)
    } finally {
      this.closeImagePreview()
    }
  }

  public get isRfiMode(): boolean {
    return this.currentMode === SlackBarModes.RFI
  }

  public get isScheduleCommentMode(): boolean {
    return this.currentMode === SlackBarModes.SCHEDULE_COMMENT
  }

  public get isFlagMode(): boolean {
    return this.currentMode === SlackBarModes.FLAG
  }

  public get isSafetyHazardMode(): boolean {
    return this.currentMode === SlackBarModes.SAFETY_HAZARD
  }

  public get isCategoryOfVarianceMode(): boolean {
    return this.currentMode === SlackBarModes.CATEGORY_OF_VARIANCE
  }

  public get doesRfiRequireType(): boolean {
    return this.selectedRfiType === rfiTypes.NULL
  }

  public get doesFlagRequireType(): boolean {
    return this.selectedFlagType === flagTypes.NULL
  }

  public get doesSafetyHazardRequireType(): boolean {
    return this.selectedSafetyHazardType === SafetyHazardTypes.NULL
  }

  public get doesCategoryOfVarianceRequireType(): boolean {
    return this.selectedCategoryOfVarianceType === CategoryOfVarianceTypes.NULL
  }

  public setRfiType(type: rfiTypes) {
    this.selectedRfiType = type
  }

  public setFlagType(type: flagTypes) {
    this.selectedFlagType = type
  }

  public setCategoryOfVarianceType(type: CategoryOfVarianceTypes) {
    this.selectedCategoryOfVarianceType = type
  }

  public setSafetyHazardType(type: SafetyHazardTypes) {
    this.selectedSafetyHazardType = type
  }

  public closeImagePreview() {
    setTimeout(() => {
      this.shouldShowPreview = false
    }, 500)
  }

  public reset(): void {
    super.reset()
    this.resetMode()
  }

  protected updateThreadEntity(
    threadEntity: ContentObjectModel<any> | Message | StatusUpdate,
  ) {
    const type = threadEntity.constructor

    switch (type) {
      case Rfi:
        this.rfisStore.save(threadEntity as Rfi)
        break
      case Flag:
        this.flagsStore.save(threadEntity as Flag)
        break
      case ScheduleComment:
        this.scheduleCommentsStore.save(threadEntity as ScheduleComment)
        break
      case CategoryOfVariance:
        this.categoriesOfVarianceStore.save(threadEntity as CategoryOfVariance)
        break
      case SafetyHazard:
        this.safetyHazardsStore.save(threadEntity as SafetyHazard)
        break
      case Delivery:
        this.deliveriesStore.updateDelivery(threadEntity as any, null, false)
        break
      case StatusUpdate:
        this.statusUpdatesStore.save(threadEntity as StatusUpdate)
        break
      case Message:
        const msg = threadEntity as Message
        const threadImg = this.photosStore.byId.get(msg.photoId)
        if (threadImg) {
          this.photosStore.save(threadImg)
        }
        break
    }
  }

  private async postImage() {
    const caption = this.draftMessage
    this.reset()
    try {
      const thread = await this.threadsStore.create()
      return this.postMessageWithAttachments(
        caption,
        thread,
        this.getFileCopy(this.fileImage),
        this.activitiesStore.selectedActivity,
      )
    } catch (e) {
      this.handleError(e)
    } finally {
      this.closeImagePreview()
    }
  }

  private handleError(error: Error): any {
    console.error(error)
    this.eventsStore.dispatch(REPORT_ERROR, 'SlackBarStore', error)
  }

  private resetMode(): any {
    this.changeModeTo(SlackBarModes.MESSAGE)
  }

  private changeModeTo(nextMode: SlackBarModes) {
    if (nextMode === this.currentMode) {
      this.currentMode = SlackBarModes.MESSAGE
    } else {
      this.currentMode = nextMode
    }

    if (!this.isRfiMode) {
      this.resetRfiType()
    }
    if (!this.isFlagMode) {
      this.resetFlagType()
    }
    if (!this.isCategoryOfVarianceMode) {
      this.resetCategoryOfVarianceType()
    }
    if (!this.isSafetyHazardMode) {
      this.resetSafetyHazardType()
    }
  }
}
