import * as React from 'react'

import { FileInput, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MentionTriggerButton from '~/client/src/shared/components/ActionBarInputWIthMentions/components/MentionTriggerButton'
import CompactActionsMenuModal from '~/client/src/shared/components/CompactActionsMenuModal/CompactActionsMenuModal'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'

import ContentObjectModel from '../../models/ContentObjectModel'
import Message from '../../models/Message'
import SitePermit from '../../models/Permit'
import StatusUpdate from '../../models/StatusUpdate'
import BaseAssignmentsStore from '../../stores/BaseAssignments.store'
import BaseFollowingsStore from '../../stores/BaseFollowings.store'
import BaseActionBarStore from '../../stores/ui/BaseActionBar.store'
import ActionBarInput from '../ActionBarInput/ActionBarInput'
import ActionBarInputWithMentions from '../ActionBarInputWIthMentions/ActionBarInputWithMentions'
import CameraUploadButton from '../CameraUploadButton/CameraUploadButton'
import Dimmer from '../Dimmer'
import ActionDraggableBar from '../DraggableBar/ActionDraggableBar'
import DraggableBar, { ViewMode } from '../DraggableBar/DraggableBar'
import ImagePreview from '../ImagePreview/ImagePreview'
import { statusType } from './ActionBar'

import './MessagesActionbar.scss'

const FILE_INPUT_ID_HOLDER = 'file-input-id-holder'
const FILE_INPUT_ID = 'file-input-id'
const DRAGGABLE_LIST_BOTTOM_OFFSET = 450
const DRAGGABLE_LIST_DEFAULT_HEIGHT = 450
const addToLog = 'ADD TO LOG'
const camera = 'Camera'
const addPhotoFromLibrary = 'Add Photo From Library'
const addFile = 'Add file'
const atMentionAUser = 'At-mention a user'
const atMentionDescription =
  'To notify and add a user as an Additional Contact to the workflow'

enum acceptedFileTypes {
  pdf = 'pdf',
  excel = 'xlsx',
  powerPoint = 'pptx',
  word = 'docx',
}

type entityType =
  | ContentObjectModel<any>
  | Message
  | Delivery
  | StatusUpdate
  | SitePermit

interface IProps {
  entity: entityType
  actionBarStore: BaseActionBarStore
  isFocused: boolean
  formsRef: HTMLFormElement
  setIsFocused: (value: boolean) => void
  setFormsRef: (ref: HTMLDivElement) => void
  applyMessage?(): void
  isMobileMode?: boolean
  isSendButtonDisabled?: boolean

  followingsStore?: BaseFollowingsStore
  assignmentsStore?: BaseAssignmentsStore
  customStatusRenderer?: (status: statusType, stepId: string) => JSX.Element
  containerRef?: HTMLDivElement
  draggableRef?: ActionDraggableBar
  renderAdditionalCheckbox?: () => JSX.Element
}

@observer
export default class MessagesActionBar extends React.Component<IProps> {
  private containerRef: HTMLDivElement = null
  @observable public viewMode: ViewMode = ViewMode.Mixed
  public constructor(props: IProps) {
    super(props)

    this.store.entityId = props.entity.id
  }

  private get store(): BaseActionBarStore {
    return this.props.actionBarStore
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { entity } = this.props

    if (entity !== prevProps.entity) {
      this.store.entityId = entity.id
      this.store.fullReset()
    }
    this.props.draggableRef?.recomputeSize(this.containerRef.clientHeight)
  }

  public componentWillUnmount() {
    this.store.fullReset()
  }

  public render() {
    const {
      entity,
      assignmentsStore,
      followingsStore,
      containerRef,
      isFocused,
      setFormsRef,
      renderAdditionalCheckbox,
    } = this.props

    const {
      draftMessage,
      decodedDraftMessage,
      mentionTags,
      setDraftMessage,
      isMentionActionActive,
      activateMentionAction,
      deactivateMentionAction,
      triggerMentionActionActivation,
      previewImage,
      file,
    } = this.store

    const shouldEnableMentions = !!followingsStore && !!assignmentsStore
    const className =
      'mention-input brada24 bg-white slack-bar-input no-resize full-width'
    const shouldShowSendButton = !!file || !!draftMessage || !!previewImage

    return (
      <>
        {this.store.isEmojiOpened && this.emojiMenu}
        {this.plusMenu}
        <div
          ref={ref => setFormsRef(ref)}
          onFocus={this.onFocus}
          className={classList({
            'action-bar-form': !renderAdditionalCheckbox,
            'full-height': !renderAdditionalCheckbox && !isFocused,
            scrollable: isFocused,
            'action-bar-form-extended': !!renderAdditionalCheckbox,
            'action-bar-not-focused-extended':
              !!renderAdditionalCheckbox && !isFocused,
          })}
        >
          <div
            ref={ref => (this.containerRef = ref)}
            className={classList({
              'py10 ml15': true,
              'row y-start': !isFocused,
              col: isFocused,
              'limited-message-width':
                (this.store.file || this.store.fileImage) && !isFocused,
            })}
          >
            {!isFocused && this.renderPlusButton()}
            {shouldEnableMentions ? (
              <div
                className={classList({
                  [className]: !!className,
                  'pa10 mx15': true,
                })}
              >
                <ActionBarInputWithMentions
                  onChange={setDraftMessage}
                  entityId={entity.id}
                  value={draftMessage}
                  plainText={decodedDraftMessage}
                  mentions={mentionTags}
                  className={className}
                  followingsStore={followingsStore}
                  assignmentsStore={assignmentsStore}
                  placeholder={Localization.translator.addANote}
                  onSuggestionsPopupHide={deactivateMentionAction}
                  onSuggestionsPopupShow={activateMentionAction}
                  parentRef={containerRef}
                />
                {isFocused && this.imagePreview}
                {isFocused && this.filePreview}
                {!isFocused && this.notFocusedFilesPreview}
              </div>
            ) : (
              <div className={className}>
                {!isFocused && this.notFocusedFilesPreview}
                {this.filePreview}
                <ActionBarInput
                  value={this.store.draftMessage}
                  onChange={this.setDraftMessage}
                  placeholder={Localization.translator.addANote}
                  className="slack-bar-input no-resize full-width"
                />
              </div>
            )}
          </div>
        </div>
        {isFocused && (
          <div className="row no-grow">
            <div className="row x-start">
              {isFocused && this.renderPlusButton()}
              {shouldEnableMentions && (
                <MentionTriggerButton
                  className="no-grow brada24 mx10 bottom-bar-trigger-button-holder pointer row x-center y-center action-bar-action-btn"
                  isActive={isMentionActionActive}
                  onClick={triggerMentionActionActivation}
                />
              )}
              <div className="no-grow">
                <div className="no-grow brada24 bottom-bar-trigger-button-holder row x-center y-center action-bar-action-btn">
                  <Icons.Emoji
                    className="pointer no-grow"
                    style={{ height: '20px' }}
                    onClick={this.onEmojiButtonClick}
                  />
                </div>
              </div>
            </div>
            {shouldShowSendButton ? (
              <button
                className="slack-bar-send-btn brada24 x-end bg-blue-bright pa10 no-grow pointer"
                onClick={this.send}
              >
                <Icons.SendMessage />
              </button>
            ) : (
              <button className="slack-bar-send-btn-fine brada24 x-end pa10 no-grow pointer">
                <Icons.SendMessageSlack />
              </button>
            )}
          </div>
        )}
        {renderAdditionalCheckbox?.()}
      </>
    )
  }

  @action.bound
  private onEmojiButtonClick() {
    this.onBlur()
    this.store.isEmojiOpened = true
  }

  @action.bound
  private onFocus() {
    this.props.setIsFocused(true)
  }

  @action.bound
  private onBlur() {
    this.props.setIsFocused(false)
  }

  @action.bound
  private onEmojiClick(event: EmojiClickData) {
    this.store.draftMessage += ` ${event.emoji}`
    this.store.isEmojiOpened = false
    this.props.setIsFocused(true)
    this.props.formsRef.focus()
  }

  private renderPlusButton() {
    return (
      <div
        style={{ minWidth: 40, height: 40 }}
        className="row x-center y-center no-grow pointer ba-grey-scale-light brada24 pa10"
        onClick={this.togglePlusMenu}
      >
        <Icon icon={IconNames.PLUS} className="no-grow" />
      </div>
    )
  }

  @action.bound
  private async send(evt: React.FormEvent<HTMLButtonElement>) {
    const { entity } = this.props
    evt.preventDefault()
    evt.stopPropagation()
    await this.store.replyToThread(entity)
    this.props.formsRef.blur()
    this.onBlur()
    this.props.applyMessage?.()
  }

  private setDraftMessage = (evt: React.FormEvent<HTMLInputElement>) => {
    const text = evt.currentTarget.value
    this.store.setDraftMessage(text, text, [])
  }

  public get imagePreview(): JSX.Element {
    if (!this.store.previewImage) {
      return
    }

    return (
      <ImagePreview
        removeImage={this.store.resetPreviewImage}
        className="preview-image-wrapper image-preview-holder relative"
        removeRightPosition={-7}
        removeTopPosition={-7}
      >
        <img src={this.store.previewImage} className="center" />
      </ImagePreview>
    )
  }

  private get notFocusedFilesPreview() {
    if (!this.store.file && !this.store.fileImage) {
      return
    }

    const filesAmount =
      (this.store.file ? 1 : 0) + (this.store.fileImage ? 1 : 0)
    return (
      <div className="absolute row no-grow" style={{ bottom: 20, right: 30 }}>
        <div className="row w-fit-content relative bg-palette-brand-lightest brada10 pa5">
          <Icons.AttachButton className="pdf-icon mr5" />
          <div className="text large">{filesAmount}</div>
        </div>
      </div>
    )
  }

  private get filePreview(): JSX.Element {
    if (!this.store.file) {
      return
    }

    return (
      <div className="row no-grow mt20">
        <div className="row file-preview-holder-limited-width relative bg-palette-brand-lightest ba-light-input-border brada4 pa5">
          <div
            onClick={this.store.resetFile}
            style={{ top: -10, right: -10, zIndex: 2 }}
            className="absolute brada24 bg-dark row x-center"
          >
            <Icon className="remove-icon" icon={IconNames.SMALL_CROSS} />
          </div>
          {this.fileIcon}
          <div className="col">
            <div className="text large">{this.store.file.name}</div>
            <div className="text large light">{this.fileTypeText}</div>
          </div>
        </div>
      </div>
    )
  }

  private get fileIcon(): JSX.Element {
    const className = 'file-type-icon no-grow mr10'
    switch (this.getExtension(this.store.file?.name)) {
      case acceptedFileTypes.pdf:
        return <Icons.PDFApp className={className} />
      case acceptedFileTypes.word:
        return <Icons.WordApp className={className} />
      case acceptedFileTypes.excel:
        return <Icons.ExcelApp className={className} />
      case acceptedFileTypes.powerPoint:
        return <Icons.PPTXApp className={className} />
      default:
        return null
    }
  }

  private get fileTypeText(): string {
    switch (this.getExtension(this.store.file?.name)) {
      case acceptedFileTypes.pdf:
        return 'PDF'
      case acceptedFileTypes.word:
        return 'DOCX'
      case acceptedFileTypes.excel:
        return 'XLSX'
      case acceptedFileTypes.powerPoint:
        return 'PPTX'
      default:
        return null
    }
  }

  private get emojiMenu(): JSX.Element {
    return (
      <>
        <Dimmer shown={true} onClick={this.closeEmojiMenu} />
        <DraggableBar
          bottomOffset={DRAGGABLE_LIST_BOTTOM_OFFSET}
          defaultHeight={DRAGGABLE_LIST_DEFAULT_HEIGHT}
          viewMode={this.viewMode}
          selectViewMode={this.selectViewMode}
          className="relative"
        >
          <EmojiPicker
            height="100%"
            width="100%"
            open={true}
            onEmojiClick={this.onEmojiClick}
          />
        </DraggableBar>
      </>
    )
  }

  @action.bound
  private openMention(event) {
    event.stopPropagation()
    this.store.isPlusMenuShown = false
    this.props.formsRef.focus()
    this.store.triggerMentionActionActivation()
  }

  private get plusMenu(): JSX.Element {
    return (
      <CompactActionsMenuModal
        isShown={this.store.isPlusMenuShown}
        onHide={this.addAttachmentAndOpenForm}
        headerText={addToLog}
      >
        {this.props.isMobileMode && (
          <div className="common-row row">
            <CameraUploadButton
              showWhenSelected={true}
              store={this.store}
              className="camera-upload-button-container row mr10 pointer"
              text={camera}
              shouldUseBlueIcon={true}
              icon={<Icons.CameraButton className="no-grow mr10 row" />}
              onChange={this.addPhotoCallback}
              isCaptureMode={true}
            />
          </div>
        )}
        <div className="common-row row">
          <CameraUploadButton
            showWhenSelected={true}
            store={this.store}
            className="camera-upload-button-container row mr10 pointer"
            text={addPhotoFromLibrary}
            shouldUseBlueIcon={true}
            icon={<Icons.GalleryButton className="no-grow mr10 row" />}
            onChange={this.addPhotoCallback}
          />
        </div>
        <div className="common-row row">
          <FileInput
            id={FILE_INPUT_ID}
            disabled={false}
            className="attachment-upload-button full-height"
            inputProps={{
              onChange: this.uploadAttachment,
              accept: '.pdf, .docx, .pptx, .xlsx',
              className: 'bare-file-input',
            }}
            text={
              <div id={FILE_INPUT_ID_HOLDER} className="row full-height">
                <Icons.AttachButton className="pdf-icon mr10 no-grow row x-center mw20" />
                <span className="text extra-large">{addFile}</span>
              </div>
            }
          />
        </div>
        <div className="common-row row y-start py10" onClick={this.openMention}>
          <MentionTriggerButton
            className="no-grow mr10 row x-center y-start"
            isActive={this.store.isMentionActionActive}
            onClick={this.openMention}
          />
          <div className="col">
            <div className="text extra-large">{atMentionAUser}</div>
            <div className="text grey-light">{atMentionDescription}</div>
          </div>
        </div>
      </CompactActionsMenuModal>
    )
  }

  @action.bound
  private addAttachmentAndOpenForm(event?) {
    if (
      event?.target?.parentElement?.id === FILE_INPUT_ID ||
      event?.target?.parentElement?.id === FILE_INPUT_ID_HOLDER
    ) {
      event.stopPropagation()
      return
    }
    this.togglePlusMenu()
  }

  @action.bound
  private async uploadAttachment(event) {
    await this.store.uploadAttachment(event)
    this.togglePlusMenu()
    this.props.setIsFocused(true)
    this.props.formsRef.focus()
  }

  @action.bound
  private togglePlusMenu() {
    if (!this.store.isPlusMenuShown) {
      this.onBlur()
    }
    this.store.isPlusMenuShown = !this.store.isPlusMenuShown
  }

  @action.bound
  private addPhotoCallback() {
    this.togglePlusMenu()
    this.props.setIsFocused(true)
    this.props.formsRef.focus()
  }

  public selectViewMode = (mode: ViewMode) => {
    if (this.viewMode !== mode) {
      this.viewMode = mode
    }
    if (this.viewMode === ViewMode.Closed) {
      this.closeEmojiMenu()
    }
  }

  @action.bound
  private closeEmojiMenu() {
    this.store.isEmojiOpened = false
  }

  private getExtension = (filename: string): string => {
    return filename?.split('.').pop()
  }
}
