import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import StatusUpdateInputBase from '~/client/src/shared/components/ActivityItemContentWithStatusUpdate/componets/StatusUpdateInputBase'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'

import ProjectDateStore from '../../../stores/ui/ProjectDate.store'
import BulkStatusUpdateStore, { ALL_COMPANIES } from '../BulkStatusUpdate.store'

// translated

interface IProps {
  date: Date
  activity: Activity
  company: string
  commonStatusUpdate: StatusUpdate
  store: BulkStatusUpdateStore

  projectDateStore?: ProjectDateStore
}

const PERCENT_COMPLETE_STEP = 5

@inject('projectDateStore')
@observer
export default class BulkRemainingDurationUpdate extends React.Component<IProps> {
  private get openStatusMap(): Map<string, boolean> {
    return this.props.store.remainingDurationInputOpenStatus
  }

  private get loadingStatusMap(): Map<string, boolean> {
    return this.props.store.remainingDurationInputLoadingStatus
  }

  private get valueStatusMap(): Map<string, number> {
    return this.props.store.remainingDurationInputValueStatus
  }

  private get activityKey(): string {
    const { activity, company, date } = this.props
    return `${activity.code}-${company}-${date.getTime()}`
  }

  @computed
  private get didValueUpdate(): boolean {
    const { commonStatusUpdate, company, date, store, projectDateStore } =
      this.props
    const { dateFor, didUpdateByCompany } = commonStatusUpdate

    if (store.isConfirmModalShown) {
      return false
    }

    if (!projectDateStore.isSameDay(new Date(+dateFor), date)) {
      return false
    }

    if (company === ALL_COMPANIES) {
      return store.allCompanies.some(companyName =>
        didUpdateByCompany(
          companyName,
          projectDateStore.isSameDay,
          true,
          false,
        ),
      )
    }

    return didUpdateByCompany(company, projectDateStore.isSameDay, true, false)
  }

  private get leftCaption(): JSX.Element {
    return (
      <div className="text red extra-large">
        {Localization.translator.remainingDuration}
      </div>
    )
  }

  private get icon(): JSX.Element {
    if (this.didValueUpdate) {
      return <Icons.StatusBadge className="col" />
    }
    return <Icons.StatusBadgeGray className="col" />
  }

  public render() {
    const { activity, company, store } = this.props
    const { areDaysWithoutPercentCompleteUpdate, clearActualButtonsState } =
      store

    const remainingDuration = activity.getRemainingDuration(company)
    const remainingDurationTargetValue = this.getNormalizedRemainingDuration()

    if (this.didValueUpdate && areDaysWithoutPercentCompleteUpdate) {
      return null
    }

    return (
      <StatusUpdateInputBase
        value={remainingDuration}
        didUpdate={this.didValueUpdate}
        leftCaption={this.leftCaption}
        icon={this.icon}
        maxValue={activity.plannedDuration}
        targetValue={remainingDurationTargetValue}
        onChange={this.updateRemainingDurationForSelection}
        openStatusMap={this.openStatusMap}
        loadingStatusMap={this.loadingStatusMap}
        valueStatusMap={this.valueStatusMap}
        activityKey={this.activityKey}
        onInputClosed={clearActualButtonsState}
        onInputValueChanged={this.onInputValueChanged}
      />
    )
  }

  private onInputValueChanged = (value: number) => {
    const { isActualFinishSettingSelected, clearActualButtonsState } =
      this.props.store

    if (
      isActualFinishSettingSelected &&
      value !== this.props.activity.plannedDuration
    ) {
      clearActualButtonsState()
    }
  }

  private updateRemainingDurationForSelection = (remainingDuration: number) => {
    const { updatePercentCompleteForSelection } = this.props.store

    const percentComplete = Math.round(
      100 - (remainingDuration * 100) / this.props.activity.plannedDuration,
    )
    updatePercentCompleteForSelection(percentComplete)
  }

  public getNormalizedRemainingDuration(): number {
    const { activity } = this.props

    const normalizedPercentComplete =
      Math.round(
        activity.getTodayPercentPosition(this.props.projectDateStore) /
          PERCENT_COMPLETE_STEP,
      ) * PERCENT_COMPLETE_STEP

    const earnedDuration = Math.round(
      activity.plannedDuration * (normalizedPercentComplete / 100),
    )

    const remainingDuration = Math.round(
      activity.plannedDuration - earnedDuration,
    )

    return remainingDuration
  }
}
