import * as React from 'react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

interface IProps {
  stepPosition: number
  stepName: string

  className?: string
}

export default function WorkflowStepNameLabel({
  stepPosition,
  stepName,
  className,
}: IProps): JSX.Element {
  return (
    <span className={className || 'text large bold line-extra-large'}>
      {`${Localization.translator.step} ${stepPosition}: ${stepName}`}
    </span>
  )
}
