import { LocationType } from '~/client/graph'

import { ActivityFieldType } from './components/ActivityForm.store'

export enum ActivityDetailsTab {
  Form = 'form',
  Log = 'log',
}

export interface IActivityField {
  fieldId: string
  required?: boolean
  type: ActivityFieldType
  getCaption?: () => string
  isValid?: () => boolean
  validationMessage?: string
  isActivity?: boolean
  locationType?: LocationType
  isChanged?: boolean
  min?: number
  max?: number
}
