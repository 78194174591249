import * as React from 'react'

import { observer } from 'mobx-react'

import { IMeasure, IPermitTypeField } from '~/client/graph'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubTextValueSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import StruxhubInputLabel from '~/client/src/shared/components/StruxhubInputs/components/StruxhubInputLabel'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import { VALID_NUMBER_PATTERN } from '~/client/src/shared/utils/regExpPatterns'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'
import { copyObject } from '~/client/src/shared/utils/util'

import SitePermitCreationFormStore, {
  FormDropdownType,
} from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean
  store: SitePermitCreationFormStore

  fieldsStore: IPermitFieldsStore

  tableId?: string
  tableRowIndex?: number
}

@observer
export default class PermitFormMeasureField extends React.Component<IProps> {
  public render() {
    const { isViewMode, fieldsStore, typeField, tableId, tableRowIndex } =
      this.props
    const { caption, isMandatory } = typeField

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.viewModeElements}
      >
        {this.fieldValues.map((measure, index) => {
          const inputId = `${typeField.id}_${index}_${tableRowIndex || 0}`
          const isValid = fieldsStore.isFieldValid(
            typeField,
            index,
            tableId,
            tableRowIndex,
          )

          return (
            <div key={index} className="col">
              <StruxhubInputLabel
                label={caption}
                isRequired={isMandatory}
                isValid={isValid}
                value={measure?.value || measure?.units ? '1' : EMPTY_STRING}
              />
              <div className="row y-center">
                <StruxhubInput
                  id={inputId}
                  className="measure-field overflow-hidden"
                  isLabelHidden={true}
                  type="number"
                  autoComplete="off"
                  isAlternativeNumberMode
                  ignoreValueForValidation
                  negativeOrDecimal={true}
                  isRequired={isMandatory}
                  isChanged={this.isCountChanged(index)}
                  isValid={this.isCountValid(measure)}
                  validationMessage={this.getCountValidationMsg(measure)}
                  value={measure?.value || EMPTY_STRING}
                  onChange={this.changeMeasureCount.bind(this, index)}
                  onValueReset={this.resetMeasureCount.bind(this, index)}
                />
                <div className="mx8 no-grow" />
                <StruxhubTextValueSelector
                  className="measure-field overflow-hidden"
                  isLabelHidden={true}
                  isRequired={isMandatory}
                  ignoreValueForValidation
                  isChanged={this.isUnitChanged(index)}
                  isValid={this.areUnitsValid(measure)}
                  value={measure?.units || EMPTY_STRING}
                  onClick={this.onMeasureClick.bind(null, index)}
                  validationMessage={this.getUnitsValidationMsg(measure)}
                />
              </div>
            </div>
          )
        })}
      </PermitBaseFormField>
    )
  }

  private get viewModeElements(): JSX.Element[] {
    return this.fieldValues
      .filter(p => p)
      .map((measure, idx) => (
        <div key={idx} className="text large word-break-all">
          {measure?.value} {measure?.units}
        </div>
      ))
  }

  private get fieldValues(): IMeasure[] {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const values = fieldsStore.getFieldValues<IMeasure>(
      typeField.id,
      tableId,
      tableRowIndex,
    )

    return values?.length ? values : [{}]
  }

  private get existingValues(): IMeasure[] {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const values = fieldsStore.getFieldValues<IMeasure>(
      typeField.id,
      tableId,
      tableRowIndex,
      true,
    )

    return values?.length ? values : [{}]
  }

  private isCountValid = (measure: IMeasure): boolean => {
    return (
      !measure ||
      (!!measure.value && VALID_NUMBER_PATTERN.test(measure.value)) ||
      (!measure.value && !measure.units)
    )
  }

  private areUnitsValid = (measure: IMeasure): boolean => {
    return !measure || !!measure.units || (!measure.value && !measure.units)
  }

  private getCountValidationMsg = (measure: IMeasure): string => {
    if (!measure) {
      return null
    }
    if (!!measure.units && !measure.value) {
      return Localization.translator.pleaseFillAllFields
    }
    if (!!measure.value && !VALID_NUMBER_PATTERN.test(measure.value)) {
      return Localization.translator.invalidNumberFormat
    }
  }

  private getUnitsValidationMsg = (measure: IMeasure): string => {
    if (!!measure && !!measure.value && !measure.units) {
      return Localization.translator.pleaseFillAllFields
    }
  }

  private isCountChanged(index: number): boolean {
    const { fieldsStore, typeField, tableId, tableRowIndex } = this.props
    const { editableEntity, isFieldChanged } = fieldsStore

    if (!editableEntity?.id) {
      return false
    }

    return (
      isFieldChanged(typeField.id, index, tableId, tableRowIndex) &&
      this.fieldValues[index]?.value !== this.existingValues[index]?.value
    )
  }

  private isUnitChanged(index: number): boolean {
    const { fieldsStore, typeField, tableId, tableRowIndex } = this.props
    const { editableEntity, isFieldChanged } = fieldsStore

    if (!editableEntity?.id) {
      return false
    }

    return (
      isFieldChanged(typeField.id, index, tableId, tableRowIndex) &&
      this.fieldValues[index]?.units !== this.existingValues[index]?.units
    )
  }

  private changeMeasureCount(
    fieldIndex: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const measure = copyObject<IMeasure>(this.fieldValues[fieldIndex])

    measure.value = event.target.value

    this.setMeasureValue(measure, fieldIndex)
  }

  private resetMeasureCount(fieldIndex: number) {
    const measure = copyObject<IMeasure>(this.fieldValues[fieldIndex])

    measure.value = null

    this.setMeasureValue(measure, fieldIndex)
  }

  private onMeasureClick = (fieldIndex: number) => {
    const { store, typeField, tableId, tableRowIndex } = this.props
    const {
      setSelectedField,
      setSelectedFieldDropdownType,
      setSelectedOnChangeHandler,
    } = store

    setSelectedField(typeField, 0, tableId, tableRowIndex)
    setSelectedFieldDropdownType(FormDropdownType.DEFAULT)
    setSelectedOnChangeHandler(this.changeMeasureUnit.bind(this, fieldIndex))
  }

  private changeMeasureUnit(fieldIndex: number, newValue: string) {
    const measure = copyObject<IMeasure>(this.fieldValues[fieldIndex])

    measure.units = newValue

    this.setMeasureValue(measure, fieldIndex)
  }

  private setMeasureValue = (measure: IMeasure, fieldIndex: number) => {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const { changeFieldValue, changeTableFieldValue, changeValidationState } =
      fieldsStore

    const measureToSave = !measure.units && !measure.value ? null : measure

    changeValidationState(
      typeField.id,
      fieldIndex,
      this.isMeasureValid(measureToSave),
      tableRowIndex,
    )

    if (this.isTableField) {
      changeTableFieldValue(
        tableId,
        tableRowIndex,
        typeField,
        fieldIndex,
        measureToSave,
      )
      return
    }

    changeFieldValue(typeField, fieldIndex, measureToSave)
  }

  private isMeasureValid = (measure: IMeasure): boolean => {
    const { typeField } = this.props
    return (
      !measure ||
      !typeField.isMandatory ||
      (!!measure.units &&
        !!measure.value &&
        VALID_NUMBER_PATTERN.test(measure.value))
    )
  }

  private get isTableField(): boolean {
    return !!this.props.tableId
  }
}
