import * as React from 'react'

import { observer } from 'mobx-react'

import { ISiteLocation, LocationType, PermitFieldType } from '~/client/graph'
import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import { companyRestrictionFieldTypes } from '~/client/src/shared/constants/PermitFieldTypeConstants'
import locationTypesList from '~/client/src/shared/constants/locationTypesList'
import routingLocationStructureList from '~/client/src/shared/constants/routingLocationStructureList'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import { NOOP } from '~/client/src/shared/utils/noop'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import SitePermitCreationFormStore from '../../../../../SitePermitCreationForm.store'

// localization: no text to translate

interface IProps {
  store: SitePermitCreationFormStore
}

@observer
export default class FormLocationPicker extends React.Component<IProps> {
  public render() {
    const {
      resetSelectedField,
      selectedField,
      selectedSubField,
      isAdminOrFormsMaster,
    } = this.props.store

    const restrictedLocationsIds =
      (selectedSubField || selectedField).restrictedOptions?.map(
        ({ id }) => id,
      ) || []

    return (
      <LocationPicker
        className="full-height overflow-hidden relative"
        isTitleHidden
        isSingleSelectionMode
        shouldScrollToSelected
        isCompanyRestrictionEnabled={this.isCompanyRestrictionEnabled}
        companyIds={this.selectedCompanyIds}
        canBypassCompanyRestriction={isAdminOrFormsMaster}
        selectedIds={this.selectedIds}
        restrictedObjectIds={restrictedLocationsIds}
        restrictedObjectTypes={this.restrictedTypes}
        allowedObjectIds={this.allowedIds}
        onSingleSelect={this.onItemSelect}
        onChange={NOOP}
        onApplyChanges={resetSelectedField}
      />
    )
  }

  private get selectedIds(): string[] {
    const {
      selectedField,
      selectedFieldIndex,
      selectedLocationType,
      selectedFieldValues,
    } = this.props.store

    if (selectedLocationType) {
      return selectedFieldValues
        .filter(l => l.type === selectedLocationType)
        .map(l => l.id)
    }

    const selectedLocationId =
      selectedField.type === PermitFieldType.Material
        ? selectedFieldValues[selectedFieldIndex]?.locationId
        : selectedFieldValues[selectedFieldIndex]?.id

    return selectedLocationId ? [selectedLocationId] : []
  }

  private get restrictedTypes(): LocationType[] {
    const { selectedField, selectedLocationType, selectedSubField } =
      this.props.store

    if (selectedLocationType) {
      return locationTypesList.filter(lt => lt !== selectedLocationType)
    }
    if ((selectedSubField || selectedField).isRouting) {
      return locationTypesList.filter(
        lt => !routingLocationStructureList.includes(lt),
      )
    }
    return [LocationType.OffloadingEquipment]
  }

  private get allowedIds(): string[] {
    const {
      selectedField,
      selectedLocationType,
      selectedSubField,
      getLocationsByRestrictions,
    } = this.props.store

    if (
      !selectedLocationType ||
      (!(selectedSubField || selectedField).isRouting &&
        selectedLocationType !== LocationType.OffloadingEquipment)
    ) {
      return []
    }

    const locations = getLocationsByRestrictions(selectedLocationType)
    return !locations.length ? [EMPTY_STRING] : locations.map(l => l.id)
  }

  private get isCompanyRestrictionEnabled(): boolean {
    return companyRestrictionFieldTypes.includes(
      this.props.store.selectedField.type,
    )
  }

  private get selectedCompanyIds(): string[] {
    return this.isCompanyRestrictionEnabled
      ? this.props.store.editablePermit.companyIds
      : []
  }

  private onItemSelect = (locationAttr: LocationBase) => {
    if (locationAttr) {
      const newValue: ISiteLocation = {
        id: locationAttr.id,
        type: locationAttr.type,
        levels: null,
      }
      this.props.store.changeSelectedFieldValue(newValue)
    }
  }
}
