import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import CompactDialog from '../CompactDialog/CompactDialog'

import './CompactConfirmDialog.scss'

// translated

interface IProps {
  title: string

  isShown: boolean
  onHide: () => void
  onApply: () => void

  applyButtonText?: string
  cancelButtonText?: string
  canOutsideClickClose?: boolean
  className?: string
  isApplyDisabled?: boolean
}

@observer
export default class CompactConfirmDialog extends React.Component<IProps> {
  public static defaultProps = {
    className: '',
  }

  public render() {
    const {
      title,
      isShown,
      onHide,
      onApply,
      children,
      applyButtonText,
      cancelButtonText,
      canOutsideClickClose,
      className,
      isApplyDisabled,
    } = this.props

    return (
      <CompactDialog
        title={title}
        onHide={onHide}
        isShown={isShown}
        className={`compact-confirm-dialog ${className}`}
        canOutsideClickClose={canOutsideClickClose}
      >
        <div className="px20 pt10">{children}</div>
        <div className="row x-center pa20">
          <div className="no-grow mr15">
            <div
              className="pa5 text large center cancel-button pointer"
              onClick={onHide}
            >
              {cancelButtonText || Localization.translator.cancel}
            </div>
          </div>
          <div className="no-grow">
            <div
              className={classList({
                'pa5 text large center apply-button pointer': true,
                'inactive-element': isApplyDisabled,
              })}
              onClick={onApply}
            >
              {applyButtonText || Localization.translator.apply}
            </div>
          </div>
        </div>
      </CompactDialog>
    )
  }
}
