import * as React from 'react'

import { Icon, Switch } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import { Loader } from '~/client/src/shared/components/Loader'
import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubAttributeSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubAttributeSelector'
import StruxhubTextArea from '~/client/src/shared/components/StruxhubInputs/StruxhubTextArea'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import EventsStore from '../../../../stores/EventStore/Events.store'
import InitialState from '../../../../stores/InitialState'
import CommonStore from '../../../../stores/ui/Common.store'
import ConfirmDialog from '../../../ConfirmDialog/ConfirmDialog'
import AllowedUsersPicker from '../../AllowedUsersPicker'
import QrCodesStore from '../../QRCodes.store'
import { QRCodesEditFormStore } from './QRCodesEditForm.store'

interface IProps {
  store: QrCodesStore

  state?: InitialState
  common?: CommonStore
  eventsStore?: EventsStore
  projectMembersStore?: ProjectMembersStore
}

@inject('state', 'common', 'eventsStore', 'projectMembersStore')
@observer
export default class QRCodesEditForm extends React.Component<IProps> {
  private readonly clearPostEventCallback: () => void = null
  private store: QRCodesEditFormStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new QRCodesEditFormStore(
      props.store,
      props.projectMembersStore,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.handlePostEventCallback,
    )
  }

  public componentWillUnmount(): void {
    this.clearPostEventCallback?.()
  }

  public render(): JSX.Element {
    const { isLoading } = this.props.store

    if (isLoading) {
      return <Loader />
    }

    return (
      <>
        <div className="qr-edit-form col px10 relative full-height">
          <div className="col full-height">{this.renderBody()}</div>
        </div>
      </>
    )
  }

  private renderBody() {
    const { location, onLocationChange } = this.props.store
    const {
      isLocationPickerShown,
      isUserDirectoryShown,
      toggleLocationPickerState,
      toggleUserDirectory,
    } = this.store

    const selectedIds = location?.id ? [location.id] : []

    if (isLocationPickerShown) {
      return (
        <LocationPicker
          className="full-height"
          title={Localization.translator.selectLocation}
          applyButtonTitle={Localization.translator.select}
          isSingleSelectionMode={true}
          selectedIds={selectedIds}
          onSingleSelect={onLocationChange}
          onChange={NOOP}
          onApplyChanges={toggleLocationPickerState}
          onClose={toggleLocationPickerState}
        />
      )
    }
    if (isUserDirectoryShown) {
      return (
        <AllowedUsersPicker
          appliedUsers={this.store.selectedUsers}
          onClose={toggleUserDirectory}
          onApply={this.handleApplyingAllowedUsers}
        />
      )
    }

    return (
      <>
        {this.renderDeletionConfirmDialog()}
        {this.renderScannerForm()}
      </>
    )
  }

  private renderScannerForm(): JSX.Element {
    return (
      <>
        <div className="col px10 overflow-auto">
          {/* {this.renderScannerHeader()} */}
          {this.renderScannerFields()}
          {this.renderScannerFooter()}
        </div>
      </>
    )
  }

  private renderDeletionConfirmDialog(): JSX.Element {
    const {
      isScannerDeleteModalShown,
      isLoading,
      performDelete,
      toggleDeleteModal,
    } = this.props.store

    return (
      <ConfirmDialog
        isOpen={isScannerDeleteModalShown}
        onCancelClicked={toggleDeleteModal}
        onDoneClicked={performDelete}
        doneTitle={Localization.translator.delete}
        loading={isLoading}
      >
        <div className="text large pre-line">
          {Localization.translator.shouldDeleteScanner}
        </div>
      </ConfirmDialog>
    )
  }

  private renderScannerFooter(): JSX.Element {
    const { isSaveButtonEnabled, newScanner, onSaveClick } = this.props.store

    return (
      <>
        {newScanner && (
          <BaseActionButton
            title={Localization.translator.save}
            className="secondary-blue-theme bold pa10 brada10 my10"
            isEnabled={isSaveButtonEnabled}
            onClick={onSaveClick}
          />
        )}
      </>
    )
  }

  private renderScannerFields(): JSX.Element {
    const {
      projectMembersStore: { getById },
      store: {
        location,
        onNameChange,
        name,
        maxElapsedTime,
        onMaxElapsedTimeChange,
        badgeName,
        onBNameChange,
        newScanner,
        isOpenScanner,
        isQueueScanner,
        isTimedScanner,
        isTimedByDay,
        toggleIsTimedScanner,
        toggleIsOpenScanner,
        toggleIsTimedByDay,
        toggleIsQueueScanner,
      },
    } = this.props
    const {
      toggleLocationPickerState,
      toggleUserDirectory,
      isUsersListExpanded,
      toggleUsersList,
    } = this.store

    const icon = isUsersListExpanded
      ? IconNames.CHEVRON_DOWN
      : IconNames.CHEVRON_UP

    return (
      <>
        <StruxhubInput
          label={Localization.translator.name}
          isHelperTextHidden={true}
          isRequired
          value={name}
          onChange={onNameChange}
          className="no-grow"
        />
        <StruxhubTextArea
          className="no-grow"
          label={Localization.translator.badgeDescription}
          isRequired
          isHelperTextCondensed={true}
          value={badgeName || EMPTY_STRING}
          onChange={onBNameChange}
          rows={4}
        />
        <StruxhubAttributeSelector
          onClick={toggleLocationPickerState}
          label={Localization.translator.location}
          value={location?.id}
          className="no-grow"
          isHelperTextHidden={true}
        />
        {isTimedScanner && (
          <StruxhubInput
            label={Localization.translator.maxElapsedTime}
            isHelperTextHidden
            isRequiredTextHidden
            type="number"
            value={`${maxElapsedTime || ''}`}
            onChange={onMaxElapsedTimeChange}
            className="no-grow"
          />
        )}
        <div className="row pa10">
          <div className="text left large line-extra-large bold blue-highlight">
            {Localization.translator.limitAccess}
          </div>
          <Switch
            checked={!isOpenScanner}
            onChange={toggleIsOpenScanner}
            className="no-grow primary-blue-switch bp3-align-right no-outline-container"
          />
        </div>
        <div className="row pa10">
          <div className="text left large line-extra-large bold blue-highlight">
            {Localization.translator.makeItTimed}
          </div>
          <Switch
            checked={isTimedScanner}
            onChange={toggleIsTimedScanner}
            className="no-grow primary-blue-switch bp3-align-right no-outline-container"
          />
        </div>
        {isTimedScanner && (
          <>
            <div className="row pa10">
              <div className="text left large line-extra-large bold blue-highlight">
                {Localization.translator.isScannerTimedByDay}
              </div>
              <Switch
                checked={isTimedByDay}
                onChange={toggleIsTimedByDay}
                className="no-grow primary-blue-switch bp3-align-right no-outline-container"
              />
            </div>
            <div className="row pa10">
              <div className="text left large line-extra-large bold blue-highlight">
                {Localization.translator.queue}
              </div>
              <Switch
                checked={isQueueScanner}
                onChange={toggleIsQueueScanner}
                className="no-grow primary-blue-switch bp3-align-right no-outline-container"
              />
            </div>
          </>
        )}
        {!isOpenScanner && (
          <div className="col py10 no-grow">
            <div className="row bb-light-input-border pa10">
              <div className="text large">
                {Localization.translator.allowedUsers}
              </div>
              <Icon
                onClick={toggleUsersList}
                icon={icon}
                className="no-grow pointer"
              />
              <div
                className="no-grow text nowrap large blue bold right pointer pl15"
                onClick={toggleUserDirectory}
              >
                <Icon icon={IconNames.PLUS}></Icon>{' '}
                {Localization.translator.addNew}
              </div>
            </div>
            {isUsersListExpanded && !!newScanner?.allowedUsers?.length && (
              <div className="col overflow-auto bb-light-input-border">
                {newScanner.allowedUsers.map(userId => (
                  <UserProfilePreview
                    key={userId}
                    user={getById(userId)}
                    className="overflow-unset py2"
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  private handlePostEventCallback = () => {
    //this.store.onScannerUpdated(eventContext)
  }

  @action.bound
  private handleApplyingAllowedUsers(appliedUsersIds: string[] = []) {
    this.store.onApplyAllowedUsers(appliedUsersIds)

    // To summarize the result of the applying
    this.store.showUsersList()
  }
}
