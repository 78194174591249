import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import FilterIcon from '~/client/src/mobile/components/FilterIcon/FilterIcon'
import ActivityListViewMode from '~/client/src/mobile/enums/ActivityListViewMode'
import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileActivityListStore from '~/client/src/mobile/stores/ui/ActivityList.store'
import * as Icons from '~/client/src/shared/components/Icons'

import ActivityListComponentStore from '../../ActivityList.store'

import './ActivityListFilter.scss'

interface IActivityListFilterHeader {
  activityListStore?: MobileActivityListStore
  eventsStore?: MobileEventStore
  activityListComponentStore: ActivityListComponentStore
}

@inject('activityListStore', 'eventsStore')
@observer
export default class ActivityListFilterHeader extends React.Component<IActivityListFilterHeader> {
  private get store() {
    return this.props.activityListStore
  }

  private get filters() {
    return this.props.eventsStore.appState.filters
  }

  private get allActivitiesCountLabel(): string {
    return `${this.store.allActivities.length} of ${this.store.todayActivitiesCount}`
  }

  private get userAssociatedActivitiesCountLabel(): string {
    const filteredActivitiesCount =
      this.store.filteredUserAssociatedActivities.length
    const allActivitiesCount = this.store.allUserAssociatedActivities.length
    return `${filteredActivitiesCount} of ${allActivitiesCount}`
  }

  public render() {
    const { isFilterApplied } = this.store
    const { viewMode } = this.filters

    return (
      <div className="activities-list-filter row pa20">
        <button
          className={classList({
            'filter-btn left row x-center text-ellipsis': true,
            active: viewMode === ActivityListViewMode.ASSIGNED,
          })}
          onClick={this.clickOnAssignedActivities}
        >
          <Icons.FilledStar className="px5-right no-grow" />
          My Tasks ({this.userAssociatedActivitiesCountLabel})
        </button>
        <button
          className={classList({
            'filter-btn right text-ellipsis': true,
            active: viewMode === ActivityListViewMode.ALL,
          })}
          onClick={this.clickOnAllActivities}
        >
          All ({this.allActivitiesCountLabel})
        </button>
        <FilterIcon
          className="filter-icon pl20 no-grow indication-click"
          isActive={isFilterApplied}
          onClick={this.showCustomFiltersPage}
        />
      </div>
    )
  }

  private showCustomFiltersPage = () => {
    this.filters.isFilterPageShown = true
  }

  @action.bound
  private clickOnAllActivities() {
    this.filters.viewMode = ActivityListViewMode.ALL
    this.props.activityListComponentStore.calculateActivityTree(true)
  }

  @action.bound
  private clickOnAssignedActivities() {
    this.filters.viewMode = ActivityListViewMode.ASSIGNED
    this.props.activityListComponentStore.calculateActivityTree(true)
  }
}
