import { action, computed, observable } from 'mobx'

import InfoSectionId from '~/client/src/shared/enums/InfoSectionId'
import UserFieldId from '~/client/src/shared/enums/UserFieldId'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { ITag } from '~/client/src/shared/models/Tag'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { IAddEditDialogField } from '~/client/src/shared/stores/ui/AddEditItemDialog.store'
import BaseAddEditMemberDialogStore from '~/client/src/shared/stores/ui/BaseAddEditMemberDialog.store'
import { IServiceUserDto } from '~/client/src/shared/types/UserDto'

import { FormFieldType } from '../../enums/FormFieldType'
import { TagType } from '../../enums/TagType'
import User from '../../models/User'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'

interface IFormUserFields {
  id: string
  avatarUrl: string
  firstName: string
  lastName: string
  companyId: string
  teams: ITag[]
  roles: ITag[]
  email: string
  phoneNumber: string
  trades: ITag[]
}

const requiredFieldIds = [
  UserFieldId.FirstName,
  UserFieldId.LastName,
  UserFieldId.Company,
  UserFieldId.AccountPosition,
]

export default class CompactUserProfileStore extends BaseAddEditMemberDialogStore<IFormUserFields> {
  @observable public isEditing: boolean = false
  @observable public isEmailLinkingDialogOpen: boolean = false
  @observable private user: User = null

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly projectMembersStore: ProjectMembersStore,
    userProjectsStore: UserProjectsStore,
    user: User,
    allUsers: User[],
  ) {
    super(userProjectsStore, [user], allUsers)
    this.setUser(user)
    this.recreateFormStores()
    this.updateFormStores()
  }

  public setUser(newUser: User = null) {
    this.user = newUser
  }

  public get state() {
    return this.eventsStore.appState
  }

  @computed
  public get fields(): IAddEditDialogField[] {
    const fields: IAddEditDialogField[] = [
      {
        id: UserFieldId.AvatarUrl,
        label: Localization.translator.avatar,
        value: this.avatarUrlFieldValue,
        sectionId: InfoSectionId.Personal,
        type: FormFieldType.Avatar,
        onChange: this.handleAvatarChange,
      },
      {
        id: UserFieldId.FirstName,
        label: Localization.translator.firstName,
        value: this.getFieldValueById(UserFieldId.FirstName),
        sectionId: InfoSectionId.Personal,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, UserFieldId.FirstName),
      },
      {
        id: UserFieldId.LastName,
        label: Localization.translator.lastName,
        value: this.getFieldValueById(UserFieldId.LastName),
        sectionId: InfoSectionId.Personal,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, UserFieldId.LastName),
      },
      {
        id: UserFieldId.PhoneNumber,
        label: Localization.translator.phoneNumber,
        value: this.getFieldValueById(UserFieldId.PhoneNumber),
        sectionId: InfoSectionId.Personal,
        type: FormFieldType.Phone,
        onChange: this.handlePhoneNumberChange.bind(
          null,
          UserFieldId.PhoneNumber,
        ),
      },
      {
        id: UserFieldId.Email,
        label: Localization.translator.email_noun,
        value: this.getFieldValueById(UserFieldId.Email),
        sectionId: InfoSectionId.Personal,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onClick: this.showEmailLinkingDialog,
        onValueReset: this.handleValueReset.bind(this, UserFieldId.Email),
      },
      {
        id: UserFieldId.Company,
        label: Localization.translator.company,
        value: this.getFieldValueById(UserFieldId.Company),
        sectionId: InfoSectionId.Company,
        type: FormFieldType.Tag,
        serviceInfo: TagType.Company,
        onChange: this.handleTagChange.bind(null, UserFieldId.Company),
        isItemCreationAllowed: this.isAdmin,
      },
      {
        id: UserFieldId.Roles,
        label: Localization.translator.projectRoles,
        value: this.getFieldValueById(UserFieldId.Roles),
        sectionId: InfoSectionId.Project,
        type: FormFieldType.Tags,
        serviceInfo: TagType.Role,
        onChange: this.handleTagsChange.bind(null, UserFieldId.Roles),
        isItemCreationAllowed: true,
      },
      {
        id: UserFieldId.Teams,
        label: Localization.translator.teams,
        value: this.getFieldValueById(UserFieldId.Teams),
        sectionId: InfoSectionId.Project,
        type: FormFieldType.Tags,
        serviceInfo: TagType.Team,
        onChange: this.handleTagsChange.bind(null, UserFieldId.Teams),
        isItemCreationAllowed: true,
      },
      {
        id: UserFieldId.DefaultTeams,
        label: Localization.translator.defaultTeams,
        value: this.getFieldValueById(UserFieldId.DefaultTeams),
        sectionId: InfoSectionId.Project,
        type: FormFieldType.Tags,
        serviceInfo: TagType.DefaultTeam,
        onChange: this.handleTagsChange.bind(null, UserFieldId.DefaultTeams),
      },
      {
        id: UserFieldId.Trades,
        label: Localization.translator.projectTrades,
        value: this.getFieldValueById(UserFieldId.Trades),
        sectionId: InfoSectionId.Project,
        type: FormFieldType.Tags,
        serviceInfo: TagType.Trade,
        onChange: this.handleTagsChange.bind(null, UserFieldId.Trades),
        isItemCreationAllowed: true,
      },
    ]

    if (this.isEditing) {
      const { requiredFields } = this.activeFormStore
      fields.forEach(field => {
        field.isValid = this.isFieldValid(field)
        field.isRequired = requiredFields && requiredFields.includes(field.id)
      })
    }

    return fields
  }

  public get isCurrentUser() {
    return this.currentUser.id === this.user.id
  }

  public get isAdmin() {
    return this.state.userActiveProjectSettings?.isAdmin
  }

  public get isEditAllowed() {
    return (this.isCurrentUser || this.isAdmin) && !this.isBotUser
  }

  public get isScanMaster(): boolean {
    const { userActiveProjectSettings } = this.eventsStore.appState

    return (
      (userActiveProjectSettings?.isAdmin ||
        userActiveProjectSettings?.isScanMaster) &&
      !this.isBotUser
    )
  }

  private get currentUser() {
    return this.state.user
  }

  private get isBotUser(): boolean {
    return this.user.isHidden
  }

  @action.bound
  public hideEmailLinkingDialog() {
    this.isEmailLinkingDialogOpen = false
  }

  @action.bound
  public showEmailLinkingDialog() {
    this.isEmailLinkingDialogOpen = true
  }

  @action.bound
  public cancelEditing() {
    this.isEditing = false
    this.recreateFormStores()
    this.updateFormStores()
  }

  @action.bound
  public startEditing() {
    this.isEditing = true
  }

  protected updateFormStoreItem(item: User) {
    const userProject = this.userProjectsStore.getByUser(item)

    Object.assign(item, {
      [UserFieldId.Company]: userProject?.companyId || '',
      [UserFieldId.Roles]: userProject?.roles || [],
      [UserFieldId.Trades]: userProject?.trades || [],
      [UserFieldId.Teams]: userProject?.teams || [],
      [UserFieldId.DefaultTeams]: userProject?.defaultTeams || [],
    })
  }

  protected getRequiredFields(): string[] {
    return this.isCurrentUser
      ? [...requiredFieldIds, UserFieldId.Email]
      : requiredFieldIds
  }

  protected async updateItems(items: IServiceUserDto[]) {
    try {
      await this.projectMembersStore.saveMembers(items)
      this.isEditing = false
    } catch (e) {
      console.warn(e)
      alert(e.message)
    }
  }

  protected createFormItemFields(): IFormUserFields {
    return {
      id: null,
      [UserFieldId.AvatarUrl]: '',
      firstName: '',
      lastName: '',
      [UserFieldId.Company]: '',
      teams: [],
      [UserFieldId.Roles]: [],
      [UserFieldId.Trades]: [],
      email: '',
      phoneNumber: '',
    }
  }
}
