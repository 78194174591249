import { action, computed } from 'mobx'

import { CategoryName, FilterType, RecentlyUpdatedMode } from '~/client/graph'
import ActivityListViewMode from '~/client/src/mobile/enums/ActivityListViewMode'
import ActivityDetailsStore from '~/client/src/shared/components/ActivityDetails/ActivityDetails.store'
import Activity from '~/client/src/shared/models/Activity'
import { GET_ACTIVITY_ACTUAL_DATES } from '~/client/src/shared/stores/EventStore/EffectsProcessors/WorkerProcessor/workerConstants'
import {
  GET_SCHEDULE,
  INIT_APP,
  INIT_APP_2,
  INIT_APP_3,
  LOAD_AND_LISTEN_TO_STATUS_UPDATES,
  UPDATE_USER_LAST_ACTIVITY_LIST_MOBILE_FILTERS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'
import CategoriesOfVarianceStore from '~/client/src/shared/stores/domain/CategoriesOfVariance.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CustomActivityListFiltersStore from '~/client/src/shared/stores/domain/CustomActivityListFilters.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import SafetyHazardsStore from '~/client/src/shared/stores/domain/SafetyHazards.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import BaseActivityListStore from '~/client/src/shared/stores/ui/BaseActivityList.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import CustomActivityListFilter, {
  ActivityFilterMap,
} from '~/client/src/shared/types/CustomActivityListFilter'
import Guard from '~/client/src/shared/utils/Guard'
import { UNASSIGNED_FILTER_VALUE } from '~/client/src/shared/utils/ZoneLevelLocationConstants'

import MobileEventStore from '../EventStore/MobileEvents.store'
import MobileInitialState from '../MobileInitialState'

export const activityListFilters = [
  FilterType.Building,
  FilterType.Company,
  FilterType.Level,
  FilterType.Zone,
]

export default class MobileActivityListStore extends BaseActivityListStore {
  private get filtersInState(): ActivityFilterMap {
    return this.state.mobileActivityFiltersByFilterType
  }

  @computed
  public get enabledFilterTypes() {
    return this.activityFiltersStore.enabledFilterTypes.filter(type =>
      activityListFilters.includes(type as FilterType),
    )
  }

  public constructor(
    activitiesStore: ActivitiesStore,
    protected readonly state: MobileInitialState,
    protected readonly eventsStore: MobileEventStore,
    activityAssignmentsStore: ActivityAssignmentsStore,
    activityFollowingsStore: ActivityFollowingsStore,
    activityFiltersStore: ActivityFiltersStore,
    activityDetailsStore: ActivityDetailsStore,
    activityPresetsStore: ActivityPresetsStore,
    projectDateStore: ProjectDateStore,
    statusUpdatesStore: StatusUpdatesStore,
    rfisStore: RfisStore,
    flagsStore: FlagsStore,
    scheduleCommentsStore: ScheduleCommentsStore,
    categoriesOfVarianceStore: CategoriesOfVarianceStore,
    safetyHazardsStore: SafetyHazardsStore,
    deliveriesStore: DeliveriesStore,
    private readonly customActivityListFiltersStore: CustomActivityListFiltersStore,
    private readonly companiesStore: CompaniesStore,
  ) {
    super(
      state,
      activitiesStore,
      activityPresetsStore,
      activityFiltersStore,
      activityAssignmentsStore,
      activityFollowingsStore,
      activityDetailsStore,
      projectDateStore,
      statusUpdatesStore,
      rfisStore,
      flagsStore,
      scheduleCommentsStore,
      categoriesOfVarianceStore,
      safetyHazardsStore,
      deliveriesStore,
    )
    Guard.requireAll({
      eventsStore,
      activityAssignmentsStore,
      activityFollowingsStore,
      activityFiltersStore,
      customActivityListFiltersStore,
    })
  }

  public get areAllCompaniesSelected() {
    const selectedCompanies = this.filtersInState[FilterType.Company]
    // all companies + unassigned
    return selectedCompanies.length === this.scheduleCompanies.length + 1
  }

  public get isLoading() {
    return [
      INIT_APP,
      INIT_APP_2,
      INIT_APP_3,
      GET_SCHEDULE,
      LOAD_AND_LISTEN_TO_STATUS_UPDATES,
      GET_ACTIVITY_ACTUAL_DATES,
    ].some(key => this.state.loading.get(key))
  }

  public get activities(): Activity[] {
    switch (this.state.filters.viewMode) {
      case ActivityListViewMode.ASSIGNED:
        return this.filteredUserAssociatedActivities
      case ActivityListViewMode.ALL:
        return this.allActivities
    }
  }

  @computed
  public get activitiesCount() {
    return this.activities.length
  }

  @computed
  public get todayActivities(): Activity[] {
    if (this.state.compactActivityList.shouldShowOnlyActiveTodaySections) {
      return this.activitiesStore.list.filter(a =>
        a.isActiveOn(this.state.filters.currentDate, this.projectDateStore),
      )
    }
    return this.activitiesStore.list
  }

  public get todayActivitiesCount(): number {
    return this.todayActivities.length
  }

  @computed
  public get allActivities(): Activity[] {
    if (this.appliedPreset) {
      return this.appliedPresetActivities
    }

    if (this.state.filters.selectedCustomFilterId) {
      return this.getActivitiesByCustomFilter(this.selectedCustomFilter)
    }

    return this.todayActivities.filter(a => this.isActivityInSelectedFilters(a))
  }

  @computed
  public get filteredUserAssociatedActivities(): Activity[] {
    return this.allActivities.filter(a =>
      this.userAssociatedActivitiesIds.includes(a.code),
    )
  }

  @computed
  public get allUserAssociatedActivities(): Activity[] {
    return this.todayActivities.filter(a =>
      this.userAssociatedActivitiesIds.includes(a.code),
    )
  }

  @action.bound
  public saveFilterValuesToState(
    codeFilterValuesByType: ActivityFilterMap,
    presetId: string,
    selectedCustomFilterId: string,
  ) {
    if (!codeFilterValuesByType) {
      this.updateFiltersInState(null, false)
    } else {
      this.state.mobileActivityFiltersByFilterType = codeFilterValuesByType
    }

    this.state.appliedActivityPresetId = presetId
    this.state.filters.selectedCustomFilterId = selectedCustomFilterId
    this.eventsStore.dispatch(UPDATE_USER_LAST_ACTIVITY_LIST_MOBILE_FILTERS)
  }

  public filterByCategories(
    activities: Activity[],
    selectedCustomFilterId: string,
  ): Activity[] {
    const filter = this.customActivityListFiltersStore.byId.get(
      selectedCustomFilterId,
    )

    if (!filter.appliedCategories || !filter.appliedCategories.length) {
      return activities
    }

    return this.filterActivitiesWithCategoryFilters(
      activities,
      this.buildCategoryFilteringMatrix(filter.appliedCategories),
      filter,
    )
  }

  public getActivitiesByCustomFilter = (
    filter: CustomActivityListFilter,
  ): Activity[] => {
    const categories: CategoryName[] = filter.appliedCategories || []
    const activitiesFilteredByFilters = this.todayActivities.filter(a =>
      this.isActivityInSelectedFilters(a, filter.filtersByFilterType),
    )

    return this.filterActivitiesWithCategoryFilters(
      activitiesFilteredByFilters,
      this.buildCategoryFilteringMatrix(categories),
      filter,
    )
  }

  @computed
  public get filteredActivitiesWithoutZoneFilter(): Activity[] {
    const activityCodeFilterValuesByType = Object.assign(
      {},
      this.filtersInState,
    )
    delete activityCodeFilterValuesByType[FilterType.Zone]

    return this.todayActivities.filter(activity =>
      this.isActivityInSelectedFilters(
        activity,
        activityCodeFilterValuesByType,
      ),
    )
  }

  @action.bound
  public setInitialFilterValues() {
    this.state.filters.selectedCustomFilterId = null

    const lastFilters =
      this.state.userActiveProjectSettings?.lastMobileActivityFiltersForProject()

    this.updateFiltersInState(lastFilters, true)
  }

  public isActivityInSelectedFilters = (
    activity: Activity,
    activityFiltersByFilterType?: ActivityFilterMap,
  ) => {
    const filters = activityFiltersByFilterType || this.filtersInState
    return Object.keys(filters)
      .filter(type => activityListFilters.includes(type as FilterType))
      .every(filterType => {
        const filter = filters[filterType]
        if (filterType === FilterType.Company) {
          return this.isActivityInCompanyFilter(activity, filter)
        }

        return this.isActivityInCodeFilter(activity, filterType, filter)
      })
  }

  public isActivityInCompanyFilter(activity: Activity, companies: string[]) {
    const company = activity.company
      ? activity.company.id
      : UNASSIGNED_FILTER_VALUE

    return companies.includes(company)
  }

  public isActivityInCodeFilter(
    activity: Activity,
    filterType: string,
    filter: string[],
  ) {
    const filterTypeCodes = this.getCodesByFilterType(filterType)
    const activityCodeIdsByType = activity.codes
      .filter(code => filterTypeCodes.includes(code))
      .map(({ id }) => id)

    if (!activityCodeIdsByType.length) {
      activityCodeIdsByType.push(UNASSIGNED_FILTER_VALUE)
    }

    return filter && activityCodeIdsByType.some(id => filter.includes(id))
  }

  @computed
  public get isFilterApplied() {
    return Object.keys(this.filtersInState)
      .filter(type => activityListFilters.includes(type as FilterType))
      .some(filterType => {
        const filterValues = this.filtersInState[filterType]
        if (filterType === FilterType.Company) {
          const allCompanyFilterValues = [
            ...this.scheduleCompanies,
            UNASSIGNED_FILTER_VALUE,
          ]
          return this.areFilterValuesSelected(
            filterValues,
            allCompanyFilterValues,
          )
        }

        const allFilterValues = this.getCodesByFilterType(filterType)
          .map(({ id }) => id)
          .concat([UNASSIGNED_FILTER_VALUE])

        return this.areFilterValuesSelected(filterValues, allFilterValues)
      })
  }

  protected getRecentlyUpdatedActivities = (
    activities: Activity[],
    filter: CustomActivityListFilter,
  ) => {
    const { mode, startDate, endDate } = this.getRecentlyUpdatedFilter(
      this.state.filters,
      filter,
    )

    if (RecentlyUpdatedMode.LastP6Update === mode) {
      return activities.filter(activity =>
        this.statusUpdatesStore.doesActivityHaveStatusAfterP6(activity),
      )
    }

    return activities.filter(activity =>
      this.statusUpdatesStore.doesActivityHaveStatusInSelectedPeriod(
        activity,
        new Date(startDate),
        new Date(endDate),
      ),
    )
  }

  protected getActivitiesWithSpecificStatus(
    activities: Activity[],
    filter?: CustomActivityListFilter,
  ): Activity[] {
    const mode = filter
      ? filter.statusFilterMode
      : this.state.filters.selectedActivityStatusMode
    return activities.filter(
      activity => activity.getExpandedStatus(this.projectDateStore) === mode,
    )
  }

  private areFilterValuesSelected(filterValues: string[], allValues: string[]) {
    return (
      filterValues.length !== allValues.length ||
      !filterValues.every(v => allValues.includes(v))
    )
  }

  private updateFiltersInState(
    lastFilters: ActivityFilterMap,
    isCompanyMode: boolean,
  ) {
    this.state.mobileActivityFiltersByFilterType = {}
    const { userActiveProjectSettings } = this.state

    this.enabledFilterTypes.forEach(filterType => {
      this.filtersInState[filterType] = lastFilters?.[filterType]

      if (this.filtersInState[filterType]) {
        return
      }

      if (filterType !== FilterType.Company) {
        this.filtersInState[filterType] = this.getCodesByFilterType(filterType)
          .map(code => code.id)
          .concat([UNASSIGNED_FILTER_VALUE])
        return
      }

      const companyName = this.companiesStore.getCompanyNameById(
        userActiveProjectSettings?.companyId,
        '',
      )

      if (
        isCompanyMode &&
        companyName &&
        this.scheduleCompanies.includes(companyName)
      ) {
        this.filtersInState[filterType] = [companyName]
        this.state.filters.viewMode = ActivityListViewMode.ALL
        this.state.filters.selectedCustomFilterId =
          CustomActivityListFilter.defaultCustomCompanyFilterId
      } else {
        this.filtersInState[filterType] = [
          ...this.scheduleCompanies,
          UNASSIGNED_FILTER_VALUE,
        ]
      }
    })
  }

  private get scheduleCompanies(): string[] {
    return this.activityFiltersStore.allCompanies
  }

  private get selectedCustomFilter(): CustomActivityListFilter {
    return this.customActivityListFiltersStore.byId.get(
      this.state.filters.selectedCustomFilterId,
    )
  }
}
