import { action, computed, observable } from 'mobx'

import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import InitialState from '~/client/src/shared/stores/InitialState'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { truncateText } from '~/client/src/shared/utils/textUtils'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import SitePermit from '../../models/Permit'
import PermitTypesStore from '../domain/PermitTypes.store'

export interface ICardAttribute {
  fieldId: string
  fieldValue: string
  isBold: boolean
  color?: string
}

interface ILogisticsCardField {
  fieldId: string
  isHidden: boolean
}

enum FormFieldIds {
  FORM_TYPE = 'formType',
  TITLE = 'title',
}

export const MAX_NAME_LENGTH = 18

const TITLE_IDS: string[] = [
  FormFieldIds.FORM_TYPE,
  FormFieldIds.TITLE,
  FieldIds.COMPANY,
  FieldIds.BOOKING_TIME,
  FieldIds.STATUS,
]

export default class PermitCardFieldsConfigStore {
  @observable private permit: SitePermit

  public constructor(
    permit: SitePermit,
    private readonly state: InitialState,
    private readonly projectDateStore: ProjectDateStore,
    private readonly companiesStore: CompaniesStore,
    private readonly permitTypesStore?: PermitTypesStore,
  ) {
    this.init(permit)
  }

  @action.bound
  public init(permit: SitePermit) {
    this.permit = permit
  }

  public getCardAttributeValues = (
    shouldUseMockValues: boolean,
  ): ICardAttribute[] => {
    return this.attributesCardFields
      .map(field => {
        const fieldName = this.getFieldValueById(
          field.fieldId,
          shouldUseMockValues,
        )
        const formattedValue = truncateText(fieldName, MAX_NAME_LENGTH)

        return (
          formattedValue && {
            fieldId: field.fieldId,
            fieldValue: formattedValue,
            isBold: false,
            color: this.fieldColorsMap[field.fieldId],
          }
        )
      })
      .filter(v => v)
  }

  public getFieldValueById = (
    fieldId: string,
    shouldUseMockValues: boolean,
  ) => {
    if (!shouldUseMockValues || fieldId === FieldIds.BOOKING_TIME) {
      return this.fieldValuesMap[fieldId] || EMPTY_STRING
    }
    return EMPTY_STRING
  }

  @computed
  public get fieldValuesMap(): {
    [fieldId: string]: string | string[]
  } {
    const { id, startDate, endDate, companyIds, status, typeId } = this.permit
    const formType =
      this.permitTypesStore.getPermitTypeById(typeId)?.name || EMPTY_STRING

    const companyNames = companyIds
      .map(companyId => {
        return this.companiesStore.getCompanyNameById(companyId, EMPTY_STRING)
      })
      .join(', ')

    return {
      [FieldIds.ID]: id,
      [FormFieldIds.TITLE]: this.permit.title || EMPTY_STRING,
      [FormFieldIds.FORM_TYPE]: formType,
      [FieldIds.STATUS]: status,
      [FieldIds.COMPANY]: companyNames,
      [FieldIds.BOOKING_TIME]: this.projectDateStore.getShortTimeIntervalPerDay(
        startDate,
        endDate,
      ),
    }
  }

  @computed
  public get fieldColorsMap(): {
    [fieldId: string]: string
  } {
    return {}
  }

  public get attributesCardFields(): ILogisticsCardField[] {
    return this.cardConfig.filter(
      f => !TITLE_IDS.includes(f.fieldId) && f.fieldId !== FieldIds.ID,
    )
  }

  public get titleCardFields(): ILogisticsCardField[] {
    return this.cardConfig.filter(f => TITLE_IDS.includes(f.fieldId))
  }

  private get cardConfig(): ILogisticsCardField[] {
    return [
      {
        fieldId: FieldIds.COMPANY,
        isHidden: false,
      },
      {
        fieldId: FieldIds.BOOKING_TIME,
        isHidden: false,
      },
      {
        fieldId: FormFieldIds.FORM_TYPE,
        isHidden: false,
      },
      {
        fieldId: FormFieldIds.TITLE,
        isHidden: false,
      },
      {
        fieldId: FieldIds.STATUS,
        isHidden: false,
      },
    ]
  }
}
