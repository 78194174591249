export function urlHasSearchParam(param: string): boolean {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.has(param)
}

export function getSearchParam(param: string): string {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}

export function removeFromSearchParam(param: string): void {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.delete(param)
  window.history.replaceState(null, '', `?${urlParams.toString()}`)
}
