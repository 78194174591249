import { LocationType } from '~/client/graph'
import { enumToList } from '~/client/src/shared/utils/converters'

import Localization from '../localization/LocalizationManager'

export const getLocationTypeDisplayName = (type: LocationType) => {
  switch (type) {
    case LocationType.Building:
      return Localization.translator.building
    case LocationType.Zone:
      return Localization.translator.zone
    case LocationType.Level:
      return Localization.translator.level
    case LocationType.Area:
      return Localization.translator.area
    case LocationType.Gate:
      return Localization.translator.gate
    case LocationType.LogisticsObject:
      return Localization.translator.logisticObject
    case LocationType.OffloadingEquipment:
      return Localization.translator.equipment
    case LocationType.Route:
      return Localization.translator.route
    case LocationType.VerticalObject:
      return Localization.translator.verticalObject
    case LocationType.InteriorPath:
      return Localization.translator.interiorPath
    case LocationType.InteriorDoor:
      return Localization.translator.interiorDoor
    case LocationType.Staging:
      return Localization.translator.staging
  }
}

export const lbsBands = [
  LocationType.Area,
  LocationType.Zone,
  LocationType.Building,
  LocationType.Gate,
  LocationType.Route,
  LocationType.OffloadingEquipment,
  LocationType.Level,
]

export enum DeliveryLocationType {
  Level = LocationType.Level,
  Building = LocationType.Building,
  Gate = LocationType.Gate,
  Zone = LocationType.Zone,
  Route = LocationType.Route,
  Equipment = LocationType.OffloadingEquipment,
}

export default interface IHierarchyParent {
  parentType?: LocationType
  parentId?: string
}

const deliveryLocationTypes = enumToList(DeliveryLocationType)

export function toDeliveryLocationType(type: LocationType) {
  if (deliveryLocationTypes.includes(type as unknown as DeliveryLocationType)) {
    return type as unknown as DeliveryLocationType
  }
}
