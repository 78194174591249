import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'
import { RouteComponentProps, withRouter } from 'react-router'

import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import ActionBarBottomMenu from '~/client/src/shared/components/ActionBarBottomMenu/ActivityBarBottomMenu'
import ActivityAssociationStatus from '~/client/src/shared/components/ActivityAssociationStatus/ActivityAssociationStatus'
import ActivityDetailsStore from '~/client/src/shared/components/ActivityDetails/ActivityDetails.store'
import ActivityInfoSection from '~/client/src/shared/components/ActivityDetails/components/ActivityInfoSection/ActivityInfoSection'
import ActivityLog from '~/client/src/shared/components/ActivityDetails/components/ActivityLog/ActivityLog'
import SlackBar from '~/client/src/shared/components/ActivityDetails/components/SlackBar/SlackBar'
import ActivityCompactHeaderBar from '~/client/src/shared/components/CompactHeaderBar/components/ActivityCompactHeaderBar'
import Dimmer from '~/client/src/shared/components/Dimmer'
import ImagePreview from '~/client/src/shared/components/ImagePreview/ImagePreview'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import WorkflowsBottomMenu from '~/client/src/shared/components/WorkflowsBottomMenu/WorkflowsBottomMenu'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import ThreadsStore from '~/client/src/shared/stores/domain/ThreadsStore/Threads.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

import MobileInitialState from '../../stores/MobileInitialState'
import MobileFileInput from '../FileInput/MobileFileInput'

import '~/client/src/shared/components/ActivityDetails/ActivityDetails.scss'

interface IProps {
  activityDetailsStore?: ActivityDetailsStore
  activitiesStore?: ActivitiesStore
  common?: MobileCommonStore
  messagesStore?: MessagesStore
  threadsStore?: ThreadsStore
  slackBarStore?: SlackBarStore
  match?: any
  activityId?: string
  state?: MobileInitialState
}

@inject(
  'activityDetailsStore',
  'activitiesStore',
  'messagesStore',
  'threadsStore',
  'slackBarStore',
  'common',
  'state',
)
@observer
class MobileActivityDetails extends React.Component<
  IProps & RouteComponentProps
> {
  private get activity() {
    return this.props.activitiesStore.selectedActivity
  }

  public async componentDidMount() {
    this.props.common.hideNavBar()
    const code = this.props.activityId || this.props.match.params.code
    this.props.activitiesStore.select(code)
    const threads = this.props.threadsStore.list.filter(
      thread => thread.activityObjectId === code,
    )
    const { user } = this.props.state

    threads.forEach(thread => {
      const unreadMessages = this.props.messagesStore.list.filter(message => {
        if (message.threadId !== thread.id) {
          return false
        }
        return !message.readBy.some(
          readByUser => readByUser.userId === user.id && readByUser.isRead,
        )
      })
      unreadMessages.forEach(message => {
        message.markAsReadByUser(user)
        this.props.messagesStore.save(message)
      })
    })
  }

  public render() {
    const { listItemIdToNumberMap, selectedActivity } =
      this.props.activitiesStore
    const activity = this.activity
    const { shouldShowPreview, previewImage, isMenuPopupShown, hideMenuPopup } =
      this.props.slackBarStore

    const { userActiveProjectSettings } = this.props.state

    if (!activity) {
      return (
        <div className="activity-details-view">
          <div className="text bold large center pa20 bg-light-cool-grey">
            Loading...
          </div>
        </div>
      )
    }

    const sequenceNumber = listItemIdToNumberMap[activity.code]
    return (
      <View className="activity-details">
        <Header className="relative">
          <ActivityCompactHeaderBar
            activity={this.activity}
            sequenceNumber={sequenceNumber}
            onCloseClick={this.goBack}
          />
          <Dimmer shown={isMenuPopupShown} onClick={hideMenuPopup} />
        </Header>
        {shouldShowPreview && (
          <ImagePreview>
            <img
              alt={Localization.translator.image}
              src={previewImage}
              className="center"
            />
          </ImagePreview>
        )}
        <Content className="relative">
          <Content scrollable={true}>
            <div className="bb-light-grey">
              <div className="row py20 px20">
                <div className="text bold uppercase large">
                  Activity Details
                </div>
                <ActivityAssociationStatus
                  activityId={activity.code}
                  className={classList({
                    'x-end': true,
                    'inactive-element':
                      userActiveProjectSettings?.isPresentationUser,
                  })}
                  shouldHintShow={true}
                />
              </div>
              <ActivityInfoSection selectedActivity={selectedActivity} />
            </div>
            <ActivityLog FileInputType={MobileFileInput} />
          </Content>
          <Dimmer shown={isMenuPopupShown} onClick={hideMenuPopup} />
        </Content>
        <Footer
          className={toggleClass(
            'inactive-element',
            userActiveProjectSettings?.isPresentationUser,
          )}
        >
          <ActionBarBottomMenu isOpen={isMenuPopupShown}>
            <WorkflowsBottomMenu
              isMobile={true}
              store={this.props.activityDetailsStore}
            />
          </ActionBarBottomMenu>
          <SlackBar
            activity={activity}
            userActiveProjectSettings={userActiveProjectSettings}
          />
        </Footer>
      </View>
    )
  }

  @action.bound
  private goBack() {
    const { slackBarStore, common } = this.props
    return slackBarStore.shouldShowPreview
      ? slackBarStore.hidePreview()
      : common.activityDetailsBackClicked()
  }
}

export default withRouter(MobileActivityDetails)
