import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

export interface SelectionPopUpOption {
  label: string
  onClick: () => void
  regularFont?: boolean
}

interface IProps {
  isDisplayed: boolean
  options?: SelectionPopUpOption[]
  className?: string
}

@observer
export default class SelectionPopUp extends React.Component<IProps> {
  @observable private selectedOption: SelectionPopUpOption = null

  public render() {
    const { className, isDisplayed, options } = this.props
    return (
      isDisplayed && (
        <div
          className={classList({
            'selection-pop-up brada4 col': true,
            [className]: !!className,
          })}
        >
          {options.map(option => (
            <div
              className={classList({
                'pa5 text primary-blue large pointer': true,
                bold: !option.regularFont,
                'bg-blue-active': option.label === this.selectedOption?.label,
              })}
              key={option.label}
              onClick={this.handleClick.bind(this, option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )
    )
  }

  @action.bound
  private handleClick = (option: SelectionPopUpOption) => {
    this.selectedOption = option
    option.onClick()
  }
}
