import React from 'react'

import { computed } from 'mobx'
import { observer } from 'mobx-react'
import { Layer, Source } from 'react-map-gl'

import Basemap from '../../../models/Basemap'
import Sitemap from '../../../models/Sitemap'
import ThemeMode from '../../../utils/ThemeModeManager'
import {
  PLAN_LAYER,
  UNDER_PLAN_FILL_LAYER,
  UNDER_PLAN_LINE_LAYER,
} from '../../MapBoxEditor/MapBoxViewer'
import MapBoxViewerStore, {
  ISitemapWithBasemap,
  MAX_ITEM_ZOOM_LEVEL,
  PROJECT_MARKER_ZOOM_LEVEL,
} from '../../MapBoxEditor/MapBoxViewer.store'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  mapBoxViewerStore: MapBoxViewerStore
  sitemapWithBasemaps: ISitemapWithBasemap[]
  selectedSitemap?: Sitemap
  isEditor?: boolean
  isRubberMode?: boolean

  className?: string
}

@observer
export default class GlobeViewPlans extends React.Component<IProps> {
  public render(): JSX.Element {
    return (
      <>
        {this.props?.isEditor && this.renderUnderGlobePlanShape()}
        {this.props.sitemapWithBasemaps.map((sitemapWithBasemap, idx) =>
          this.renderGlobePlan(
            sitemapWithBasemap.sitemap,
            sitemapWithBasemap.basemap,
            idx,
          ),
        )}
      </>
    )
  }

  private renderUnderGlobePlanShape(): JSX.Element {
    return (
      <>
        {this.renderLineLayer()}
        {this.renderFillLayer()}
      </>
    )
  }

  private renderLineLayer(): JSX.Element {
    if (
      (!this.props.mapBoxViewerStore.hoveredSitemapId &&
        !this.props.selectedSitemap) ||
      this.props.isRubberMode
    ) {
      return null
    }

    return (
      <Source
        id="under_plan_line_data"
        type="geojson"
        data={this.lineShapesGeoJson}
      >
        <Layer
          id={UNDER_PLAN_LINE_LAYER}
          type="line"
          paint={{
            'line-color': ThemeMode.getHEXColor(Colors.primary60),
            'line-width': 2,
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  private renderFillLayer(): JSX.Element {
    return (
      <Source
        id="under_plan_fill_data"
        type="geojson"
        data={this.fillShapesGeoJson}
      >
        <Layer
          id={UNDER_PLAN_FILL_LAYER}
          type="fill"
          paint={{
            'fill-opacity': 0,
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  @computed
  private get fillShapesGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    return {
      type: 'FeatureCollection',
      features: this.props.sitemapWithBasemaps?.map(s => ({
        type: 'Feature',
        properties: {
          sitemapId: s.sitemap.id,
        },
        geometry: {
          type: 'Polygon',
          coordinates: s.sitemap.geoCorners && [
            [
              ...s.sitemap.geoCorners.map(c => [c.longitude, c.latitude]),
              [
                s.sitemap.geoCorners[0].longitude,
                s.sitemap.geoCorners[0].latitude,
              ],
            ],
          ],
        },
      })),
    }
  }

  @computed
  private get lineShapesGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    const { sitemapsMap, hoveredSitemapId } = this.props.mapBoxViewerStore
    const map =
      sitemapsMap[this.props.selectedSitemap?.id] ||
      sitemapsMap[hoveredSitemapId]
    if (!map) {
      return
    }

    const { sitemap } = map
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            sitemapId: sitemap.id,
          },
          geometry: {
            type: 'Polygon',
            coordinates: sitemap.geoCorners && [
              [
                ...sitemap.geoCorners.map(c => [c.longitude, c.latitude]),
                [
                  sitemap.geoCorners[0].longitude,
                  sitemap.geoCorners[0].latitude,
                ],
              ],
            ],
          },
        },
      ],
    }
  }

  private renderGlobePlan(
    sitemap: Sitemap,
    basemap: Basemap,
    idx: number,
  ): JSX.Element {
    const isVisible =
      !this.props.isRubberMode ||
      !!this.props.sitemapWithBasemaps.find(s => s.sitemap.id === sitemap.id)

    if (sitemap?.geoCorners && basemap?.source) {
      const index = idx + 1
      return (
        <Source
          id={PLAN_LAYER + index.toString()}
          type="image"
          key={PLAN_LAYER + index.toString()}
          url={basemap.source}
          coordinates={sitemap.geoCorners?.map(c => [c.longitude, c.latitude])}
        >
          <Layer
            id={PLAN_LAYER + index.toString()}
            type="raster"
            maxzoom={isVisible ? MAX_ITEM_ZOOM_LEVEL : 22}
            minzoom={isVisible ? PROJECT_MARKER_ZOOM_LEVEL : 21}
            paint={{
              'raster-fade-duration': 0,
            }}
          />
        </Source>
      )
    }
  }
}
