import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IScannerFieldsFragment = Pick<
  Types.IScanner,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'projectId'
  | 'name'
  | 'isDeleted'
  | 'allowedUsers'
  | 'associatedCodes'
  | 'badgeName'
  | 'maxElapsedTime'
  | 'isRealLocationRelated'
  | 'locationName'
  | 'isInverted'
  | 'isOpenScanner'
  | 'isQueueScanner'
  | 'isQueueActive'
  | 'isTimedScanner'
  | 'isTimedByDay'
  | 'isActive'
> & {
  location?: Types.Maybe<Pick<Types.ISiteLocation, 'id' | 'type' | 'levels'>>
}

export type IScannersByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IScannersByProjectIdQuery = {
  scanners?: Types.Maybe<{ data: Array<IScannerFieldsFragment> }>
}

export type IListenScannersByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenScannersByProjectIdSubscription = {
  scanner?: Types.Maybe<
    Pick<Types.IScannerChangeEvent, 'id'> & {
      item?: Types.Maybe<IScannerFieldsFragment>
    }
  >
}

export type ISaveManyScannersMutationVariables = Types.Exact<{
  scanners:
    | Array<Types.Maybe<Types.IScannerInput>>
    | Types.Maybe<Types.IScannerInput>
}>

export type ISaveManyScannersMutation = {
  saveManyScanners?: Types.Maybe<Array<Types.Maybe<Pick<Types.IScanner, 'id'>>>>
}

export type IDeleteManyScannersMutationVariables = Types.Exact<{
  scannerIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyScannersMutation = Pick<
  Types.IMutation,
  'deleteManyScanners'
>

export type IDeleteScannerMutationVariables = Types.Exact<{
  scannerId: Types.Scalars['ObjectId']
}>

export type IDeleteScannerMutation = Pick<Types.IMutation, 'deleteScanner'>

export const ScannerFieldsFragmentDoc = gql`
  fragment ScannerFields on Scanner {
    id
    createdAt
    updatedAt
    projectId
    name
    isDeleted
    allowedUsers
    associatedCodes
    badgeName
    maxElapsedTime
    location {
      id
      type
      levels
    }
    isRealLocationRelated
    locationName
    isInverted
    isOpenScanner
    isQueueScanner
    isQueueActive
    isTimedScanner
    isTimedByDay
    isActive
  }
`
export const ScannersByProjectIdDocument = gql`
  query ScannersByProjectId($projectId: ObjectId!) {
    scanners(limit: 1000000, projectId: $projectId) {
      data {
        ...ScannerFields
      }
    }
  }
  ${ScannerFieldsFragmentDoc}
`

/**
 * __useScannersByProjectIdQuery__
 *
 * To run a query within a React component, call `useScannersByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScannersByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScannersByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useScannersByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IScannersByProjectIdQuery,
    IScannersByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IScannersByProjectIdQuery,
    IScannersByProjectIdQueryVariables
  >(ScannersByProjectIdDocument, options)
}
export function useScannersByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IScannersByProjectIdQuery,
    IScannersByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IScannersByProjectIdQuery,
    IScannersByProjectIdQueryVariables
  >(ScannersByProjectIdDocument, options)
}
export type ScannersByProjectIdQueryHookResult = ReturnType<
  typeof useScannersByProjectIdQuery
>
export type ScannersByProjectIdLazyQueryHookResult = ReturnType<
  typeof useScannersByProjectIdLazyQuery
>
export type ScannersByProjectIdQueryResult = Apollo.QueryResult<
  IScannersByProjectIdQuery,
  IScannersByProjectIdQueryVariables
>
export const ListenScannersByProjectIdDocument = gql`
  subscription ListenScannersByProjectId($projectId: ObjectId!) {
    scanner(projectId: $projectId) {
      id
      item {
        ...ScannerFields
      }
    }
  }
  ${ScannerFieldsFragmentDoc}
`

/**
 * __useListenScannersByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenScannersByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenScannersByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenScannersByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenScannersByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenScannersByProjectIdSubscription,
    IListenScannersByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenScannersByProjectIdSubscription,
    IListenScannersByProjectIdSubscriptionVariables
  >(ListenScannersByProjectIdDocument, options)
}
export type ListenScannersByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenScannersByProjectIdSubscription
>
export type ListenScannersByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenScannersByProjectIdSubscription>
export const SaveManyScannersDocument = gql`
  mutation SaveManyScanners($scanners: [ScannerInput]!) {
    saveManyScanners(scanners: $scanners) {
      id
    }
  }
`
export type ISaveManyScannersMutationFn = Apollo.MutationFunction<
  ISaveManyScannersMutation,
  ISaveManyScannersMutationVariables
>

/**
 * __useSaveManyScannersMutation__
 *
 * To run a mutation, you first call `useSaveManyScannersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyScannersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyScannersMutation, { data, loading, error }] = useSaveManyScannersMutation({
 *   variables: {
 *      scanners: // value for 'scanners'
 *   },
 * });
 */
export function useSaveManyScannersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyScannersMutation,
    ISaveManyScannersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyScannersMutation,
    ISaveManyScannersMutationVariables
  >(SaveManyScannersDocument, options)
}
export type SaveManyScannersMutationHookResult = ReturnType<
  typeof useSaveManyScannersMutation
>
export type SaveManyScannersMutationResult =
  Apollo.MutationResult<ISaveManyScannersMutation>
export type SaveManyScannersMutationOptions = Apollo.BaseMutationOptions<
  ISaveManyScannersMutation,
  ISaveManyScannersMutationVariables
>
export const DeleteManyScannersDocument = gql`
  mutation DeleteManyScanners($scannerIds: [ObjectId]!) {
    deleteManyScanners(ids: $scannerIds)
  }
`
export type IDeleteManyScannersMutationFn = Apollo.MutationFunction<
  IDeleteManyScannersMutation,
  IDeleteManyScannersMutationVariables
>

/**
 * __useDeleteManyScannersMutation__
 *
 * To run a mutation, you first call `useDeleteManyScannersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyScannersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyScannersMutation, { data, loading, error }] = useDeleteManyScannersMutation({
 *   variables: {
 *      scannerIds: // value for 'scannerIds'
 *   },
 * });
 */
export function useDeleteManyScannersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyScannersMutation,
    IDeleteManyScannersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyScannersMutation,
    IDeleteManyScannersMutationVariables
  >(DeleteManyScannersDocument, options)
}
export type DeleteManyScannersMutationHookResult = ReturnType<
  typeof useDeleteManyScannersMutation
>
export type DeleteManyScannersMutationResult =
  Apollo.MutationResult<IDeleteManyScannersMutation>
export type DeleteManyScannersMutationOptions = Apollo.BaseMutationOptions<
  IDeleteManyScannersMutation,
  IDeleteManyScannersMutationVariables
>
export const DeleteScannerDocument = gql`
  mutation DeleteScanner($scannerId: ObjectId!) {
    deleteScanner(id: $scannerId)
  }
`
export type IDeleteScannerMutationFn = Apollo.MutationFunction<
  IDeleteScannerMutation,
  IDeleteScannerMutationVariables
>

/**
 * __useDeleteScannerMutation__
 *
 * To run a mutation, you first call `useDeleteScannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScannerMutation, { data, loading, error }] = useDeleteScannerMutation({
 *   variables: {
 *      scannerId: // value for 'scannerId'
 *   },
 * });
 */
export function useDeleteScannerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteScannerMutation,
    IDeleteScannerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteScannerMutation,
    IDeleteScannerMutationVariables
  >(DeleteScannerDocument, options)
}
export type DeleteScannerMutationHookResult = ReturnType<
  typeof useDeleteScannerMutation
>
export type DeleteScannerMutationResult =
  Apollo.MutationResult<IDeleteScannerMutation>
export type DeleteScannerMutationOptions = Apollo.BaseMutationOptions<
  IDeleteScannerMutation,
  IDeleteScannerMutationVariables
>
