import { action, computed, observable } from 'mobx'

import { TagType } from '~/client/src/shared/enums/TagType'
import { ITag } from '~/client/src/shared/models/Tag'
import User from '~/client/src/shared/models/User'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectRolesStore from '~/client/src/shared/stores/domain/ProjectRoles.store'
import ProjectTeamsStore from '~/client/src/shared/stores/domain/ProjectTeams.store'
import TagsStore, {
  userRelateTagTypes,
} from '~/client/src/shared/stores/domain/Tags.store'
import { getBandIconNameByTagType } from '~/client/src/shared/utils/TagHelper'

import { sortCompaniesByTypeAndNamePredicate } from '../../constants/companyType'
import Localization from '../../localization/LocalizationManager'
import UserProject from '../../models/UserProject'
import ProjectDefaultTeamsStore from '../../stores/domain/ProjectDefaultTeams.store'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'

interface IFilteredUsersByBand {
  users: User[]
  tagType: TagType
}

export default class UsersDirectoryStore {
  @observable public _searchQuery: string = ''
  @observable public appliedFilters: ITag[] = []
  @observable public groupingBandType: TagType = TagType.Company
  @observable public pivotalSearchTag: ITag = null

  @observable public shouldShowGroupByPopup: boolean = false
  @observable public shouldShowFilterPopup: boolean = false

  @observable public selectedItems: Map<string, any> = new Map()
  @observable public collapsedBands: Map<string, boolean> = new Map()
  @observable
  public availableGroupingTypes: TagType[] = [
    TagType.Company,
    TagType.GlobalAndProjectSpecificRole,
    TagType.Team,
    TagType.Trade,
    TagType.AccountPosition,
  ]

  public constructor(
    private readonly tagsStore: TagsStore,
    private readonly projectRolesStore: ProjectRolesStore,
    private readonly projectTeamsStore: ProjectTeamsStore,
    private readonly projectDefaultTeamsStore: ProjectDefaultTeamsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly onUserSelect: (items: ITag[]) => void,
    selectedUsers: User[],
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly searchTypes: TagType[],
    private readonly additionalBandsPredicate: (a: ITag, b: ITag) => number,
    private readonly userPredicate: (userDto: User) => boolean,
    private readonly shouldUseAllProjectMembers: boolean,
  ) {
    this.init(selectedUsers)
  }

  @action.bound
  public init(selectedUsers: User[]) {
    this.resetSelection()
    selectedUsers?.forEach(u => this.selectItem(u.id, TagType.User))
  }

  @action.bound
  public toggleBandCollapse(bandId: string) {
    const isCollapsed = this.collapsedBands.get(bandId)
    this.collapsedBands.set(bandId, !isCollapsed)
  }

  public get isGroupingByCompany(): boolean {
    return this.groupingBandType === TagType.Company
  }

  @computed
  public get filteredUsersByGroupedBands(): {
    [bandId: string]: IFilteredUsersByBand
  } {
    return [...this.sortedBands, this.unspecifiedBand].reduce(
      (acc, { id: bandId, type: tagType }) => {
        const users = this.getUsersByBandId(bandId)

        const filteredUsers = this.isFilterActive
          ? users.filter(u => this.filteredUsersIds.includes(u.id))
          : users

        if (filteredUsers.length) {
          acc[bandId] = { users: filteredUsers, tagType }
        }

        return acc
      },
      {},
    )
  }

  @computed
  private get sortedBands(): ITag[] {
    const bands =
      this.tagsStore.tagListsWithDefaultsTagsMap[this.groupingBandType].slice()

    if (this.isGroupingByCompany) {
      bands.sort(({ id: companyAId }, { id: companyBId }) =>
        sortCompaniesByTypeAndNamePredicate(
          companyAId,
          companyBId,
          this.companiesStore.getCompanyById,
          this.companiesStore.getCompanyTypeTagsByIds,
        ),
      )
    } else {
      bands.sort((a, b) => a.name.localeCompare(b.name))
    }

    return this.additionalBandsPredicate
      ? bands.sort(this.additionalBandsPredicate)
      : bands
  }

  @computed
  public get filteredUsersIds(): string[] {
    return this.users
      .filter(u =>
        this.appliedFilters.some(({ id: tagId, type: tagType }) => {
          const hasUserTag = TagsStore.getHasUserTagPredicate(tagType)
          const isUserTag = tagType === TagType.User

          if (isUserTag) return u.id === tagId

          return hasUserTag(u, tagId, this.userProjectsStore)
        }),
      )
      .map(u => u.id)
  }

  @computed
  public get searchResByTypes(): { [tagType: string]: ITag[] | User[] } {
    const { tagListsWithDefaultsTagsMap } = this.tagsStore

    const searchTagTypes = this.searchTypes || userRelateTagTypes

    return searchTagTypes.reduce((acc, tagType) => {
      if (tagType === TagType.DefaultTeam) {
        return acc
      }

      let filteredItems = []

      if (tagType === TagType.User) {
        filteredItems = this.users.filter(this.filterUserBySearchQuery)
      } else {
        const tags = tagListsWithDefaultsTagsMap[tagType] || []
        filteredItems = tags.filter(this.filterTagBySearchQuery)
      }

      if (filteredItems.length) {
        acc[tagType] = filteredItems
      }

      return acc
    }, {})
  }

  @action.bound
  public changeSearchQuery(newSearchQuery: string) {
    this._searchQuery = newSearchQuery
  }

  @action.bound
  public resetSearch() {
    this.resetSearchQuery()
    this.resetPivotalSearchTag()
  }

  @action.bound
  public resetSelection() {
    this.selectedItems.clear()
  }

  @action.bound
  public resetSearchQuery() {
    this._searchQuery = ''
  }

  @action.bound
  public resetPivotalSearchTag() {
    this.pivotalSearchTag = null
  }

  public getBandItemsCount = (band: ITag): number => {
    return this.getBandItems(band).length
  }

  public getBand = (type: TagType, id: string): ITag => {
    const { tagStoreByTagTypeMap } = this.tagsStore

    return id === this.unspecifiedBandId
      ? this.unspecifiedBand
      : tagStoreByTagTypeMap[type].getInstanceById(id)
  }

  public getBandItems = (band: ITag): User[] => {
    const users = this.getUsersByRelatedTagId(band.id)

    if (!this.pivotalSearchTag) {
      return users
    }

    return users.filter(this.filterUserBySearchQuery)
  }

  public getUsersByRelatedTagId(tagId: string): User[] {
    const { allUsersByRelatedTagIdMap, usersByRelatedTagIdMap } = this.tagsStore

    const usersByTag =
      (this.shouldUseAllProjectMembers
        ? allUsersByRelatedTagIdMap[tagId]
        : usersByRelatedTagIdMap[tagId]) || []

    return this.userPredicate
      ? usersByTag.filter(this.userPredicate)
      : usersByTag
  }

  @action.bound
  public toggleItemSelection(id: string, type: TagType) {
    if (this.selectedItems.get(id)) {
      this.deselectItem(id)
    } else {
      this.selectItem(id, type)
    }

    this.onUserSelect([...this.selectedItems.values()])
  }

  @action.bound
  public handleOnBandClick(tag: ITag) {
    this.pivotalSearchTag = { ...tag }
    this.resetSearchQuery()
  }

  @action.bound
  public showGroupByPopup() {
    this.shouldShowGroupByPopup = true
  }

  @action.bound
  public hideGroupByPopup() {
    this.shouldShowGroupByPopup = false
  }

  @action.bound
  public showFilterPopup() {
    this.shouldShowFilterPopup = true
  }

  @action.bound
  public hideFilterPopup() {
    this.shouldShowFilterPopup = false
  }

  @action.bound
  public changeGroupingBandType(newGroupingBandType: TagType) {
    this.groupingBandType = newGroupingBandType
    this.hideGroupByPopup()
  }

  @action.bound
  public setAppliedFilters(appliedFilters: ITag[]) {
    this.appliedFilters = appliedFilters
  }

  @action.bound
  public toggleSelectionForAllDisplayedUsers() {
    this.setSelectionForUsersByIds(
      this.displayedUsersIds,
      !this.areAllDisplayedUsersSelected,
    )
  }

  @action.bound
  public setSelectionForUsersByIds(
    usersIds: string[] = [],
    shouldBeSelected: boolean,
  ) {
    if (shouldBeSelected) {
      usersIds.forEach(id => this.selectItem(id, TagType.User))
    } else {
      usersIds.forEach(id => this.deselectItem(id))
    }

    this.onUserSelect([...this.selectedItems.values()])
  }

  @action.bound
  public handleFiltersApplying(appliedFilters: ITag[]) {
    this.setAppliedFilters(appliedFilters)
    this.hideFilterPopup()
  }

  public get searchQuery(): string {
    return this._searchQuery.toLowerCase().trim()
  }

  public get isSearchMode(): boolean {
    return !!this.searchQuery && !this.pivotalSearchTag
  }

  public get isDefaultMode(): boolean {
    return !this.searchQuery && !this.pivotalSearchTag
  }

  public get isSingleBandMode(): boolean {
    return !!this.pivotalSearchTag
  }

  @computed
  public get areAllDisplayedUsersSelected(): boolean {
    return this.displayedUsersIds.every(id => this.selectedItems.get(id))
  }

  public get totalDisplayedUsersCount(): number {
    return this.displayedUsersIds.length
  }

  @computed
  public get displayedUsersIds(): string[] {
    return this.isFilterActive
      ? this.filteredUsersIds
      : this.users.map(u => u.id)
  }

  private get isFilterActive(): boolean {
    return !!this.appliedFilters.length
  }

  private get users(): User[] {
    const { limitedList, list } = this.projectMembersStore
    const projectMembers = this.shouldUseAllProjectMembers ? list : limitedList

    const contactableProjectMembers = this.userPredicate
      ? projectMembers.filter(this.userPredicate)
      : projectMembers

    return contactableProjectMembers
  }

  @action
  private selectItem(id: string, type: TagType) {
    const tag = this.tagsStore.getTag(type, id)
    this.selectedItems.set(id, tag)
  }

  @action
  private deselectItem(id: string) {
    this.selectedItems.delete(id)
  }

  private filterUserBySearchQuery = (user: User) => {
    const { accountPosition, email } = user

    const teamsIds = UserProject.getTeamsIds(user, this.userProjectsStore)

    const fullName = User.getFullNameToDisplay(user, this.userProjectsStore)
    const companyName = UserProject.getCompanyName(
      user,
      this.userProjectsStore,
      this.companiesStore,
    )
    const roles = UserProject.getAllRolesAsString(
      user,
      this.userProjectsStore,
      this.projectRolesStore,
    )
    const teams = this.projectTeamsStore.getInstancesAsStringByIds(teamsIds)

    const defaultTeams =
      this.projectDefaultTeamsStore.getInstancesAsStringByIds(
        UserProject.getDefaultTeamsIds(user, this.userProjectsStore),
      )

    return [
      email,
      teams,
      defaultTeams,
      roles,
      fullName,
      companyName,
      accountPosition,
    ].some(str => str?.toLowerCase().includes(this.searchQuery))
  }

  private filterTagBySearchQuery = ({ name }: ITag) => {
    return name.toLowerCase().includes(this.searchQuery)
  }

  public get unspecifiedBand(): ITag {
    return {
      id: this.unspecifiedBandId,
      type: this.groupingBandType,
      name: `[${Localization.translator.notSpecified}]`,
      iconName: getBandIconNameByTagType(this.groupingBandType),
      color: '',
    }
  }

  private get unspecifiedBandId(): string {
    return TagsStore.getUnspecifiedTagIdByTagType(this.groupingBandType)
  }

  private getUsersByBandId = (bandId: string): User[] => {
    let users = this.getUsersByRelatedTagId(bandId)

    if (
      this.groupingBandType === TagType.Team &&
      bandId === this.unspecifiedBandId
    ) {
      const unspecifiedId = TagsStore.getUnspecifiedTagIdByTagType(
        TagType.DefaultTeam,
      )
      const defaultTeamUsrs = this.getUsersByRelatedTagId(unspecifiedId)

      users = users.filter(user => defaultTeamUsrs.some(u => u.id === user.id))
    }

    const sortedUsers = users.sort((a, b) =>
      a.lastName?.localeCompare(b.lastName),
    )

    return sortedUsers
  }
}
