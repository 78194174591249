import React from 'react'

import InlineSVG from 'svg-inline-react'

import CloudyIconSrc from '~/client/static/icons/weather-icons/cloudy.svg'
import FogIconSrc from '~/client/static/icons/weather-icons/fog.svg'
import RainIconSrc from '~/client/static/icons/weather-icons/rain.svg'
import ShowerIconSrc from '~/client/static/icons/weather-icons/shower.svg'
import SunnyIconSrc from '~/client/static/icons/weather-icons/sunny.svg'
import ThunderstormIconSrc from '~/client/static/icons/weather-icons/thunderstorm.svg'
import WindySnowIconSrc from '~/client/static/icons/weather-icons/windy-snow.svg'

interface IProps {
  color?: string
  size?: number

  [key: string]: any
}

const DEFAULT_SIZE = 20

export function Cloudy(props: IProps) {
  return renderInlineSvg(CloudyIconSrc, props)
}
export function Fog(props: IProps) {
  return renderInlineSvg(FogIconSrc, props)
}
export function Rain(props: IProps) {
  return renderInlineSvg(RainIconSrc, props)
}
export function Shower(props: IProps) {
  return renderInlineSvg(ShowerIconSrc, props)
}
export function Sunny(props: IProps) {
  return renderInlineSvg(SunnyIconSrc, props)
}
export function Thunderstorm(props: IProps) {
  return renderInlineSvg(ThunderstormIconSrc, props)
}
export function WindySnow(props: IProps) {
  return renderInlineSvg(WindySnowIconSrc, props)
}

function renderInlineSvg(src: any, props: IProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { size = DEFAULT_SIZE, stroke, ...rest } = props

  return (
    <InlineSVG
      {...rest}
      src={src}
      style={{
        width: size,
        height: size,
      }}
    />
  )
}
