import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { toggleClass } from 'react-classlist-helper'
import { RouteComponentProps, withRouter } from 'react-router'

import MobileInitialState from '~/client/src/mobile/stores/MobileInitialState'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import PhotoDetailsBase from '~/client/src/shared/components/PhotoDetails/PhotoDetails'
import ActionBar from '~/client/src/shared/components/PhotoDetails/components/ActionBar/ActionBar'
import CommentsList from '~/client/src/shared/components/PhotoDetails/components/CommentsList/CommentsList'
import ImageContainer from '~/client/src/shared/components/PhotoDetails/components/ImageContainer/ImageContainer'
import LandscapeImage from '~/client/src/shared/components/PhotoDetails/components/LandscapeImage/LandscapeImage'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'

import MobileDetailsHeader from '../DetailsHeader/MobileDetailsHeader'
import MobileFileInput from '../FileInput/MobileFileInput'

interface IProps {
  activitiesStore?: ActivitiesStore
  photosStore?: PhotosStore
  messagesStore?: MessagesStore
  common?: MobileCommonStore
  state?: MobileInitialState
  match?: any
  photoId?: string
}

@inject('activitiesStore', 'common', 'messagesStore', 'photosStore', 'state')
@observer
class MobilePhotoDetails extends PhotoDetailsBase<
  IProps & RouteComponentProps
> {
  public componentDidMount() {
    const { common } = this.props

    if (!common.shouldHideNavBar) {
      common.toggleNavBar()
    }

    if (window.innerWidth > window.innerHeight) {
      this.isPortrait = false
    }

    window.addEventListener('orientationchange', this.handleLayout)
  }

  public componentWillUnmount() {
    window.removeEventListener('orientationchange', this.handleLayout)
  }

  public render() {
    const photo = this.photo
    const activity = this.activity
    const caption = this.caption

    if (!photo || !activity || !caption) {
      return (
        <div className="activity-details-view">
          <div className="text bold large center pa20 bg-light-cool-grey">
            Loading...
          </div>
        </div>
      )
    }
    const { userActiveProjectSettings } = this.props.state

    if (this.isPortrait) {
      return (
        <View>
          <Header>
            <MobileDetailsHeader
              caption={`Photo: ${caption.text} | ${activity.code}`}
              backClicked={this.backClicked}
            />
          </Header>
          <Content scrollable={true}>
            <div className="activity-log">
              <ImageContainer src={photo.url} />
              <CommentsList
                photo={photo}
                showFooterLine={true}
                FileInputType={MobileFileInput}
              />
            </div>
          </Content>
          <Footer
            className={toggleClass(
              'inactive-element',
              userActiveProjectSettings?.isPresentationUser,
            )}
          >
            <ActionBar photo={photo} />
          </Footer>
        </View>
      )
    } else {
      return (
        <View>
          <LandscapeImage src={photo.url} />
        </View>
      )
    }
  }

  public backClicked = () => {
    this.props.common.history.goBack()
  }
}

export default withRouter(MobilePhotoDetails)
