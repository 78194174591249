import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import MapBoxViewerStore from '../../MapBoxEditor/MapBoxViewer.store'
import MapLayersMenu from './MapLayersMenu'

import './MapLayerSelector.scss'

// translated

interface IMapLayerSelectorProps {
  store: MapBoxViewerStore

  isFixed: boolean
  toggleAnnouncementsHiddenState?: () => void
  toggleDeliveriesHiddenState?: () => void
  togglePermitsHiddenState?: () => void
  toggleMonitoringsHiddenState?: () => void
  isLogisticsView?: boolean
  arePermitsHidden?: boolean
  areAnnouncementsHidden?: boolean
  areDeliveriesHidden?: boolean
  areMonitoringsHidden?: boolean

  isDeliveryView?: boolean
  areAdditionalMarkersAvailable?: boolean
}

const STREET_PATH = '/static/icons/mapbox-icons/map-styles/map-layer-street.png'
const SATELLITE_PATH =
  '/static/icons/mapbox-icons/map-styles/map-layer-satellite.png'

@observer
export default class MapLayerSelector extends React.Component<IMapLayerSelectorProps> {
  public render() {
    const {
      isFixed,
      store,
      toggleAnnouncementsHiddenState,
      toggleDeliveriesHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
      isLogisticsView,
      arePermitsHidden,
      areAnnouncementsHidden,
      isDeliveryView,
      areDeliveriesHidden,
      areMonitoringsHidden,
      areAdditionalMarkersAvailable,
    } = this.props
    const { isMoreMenuShown, isSatelliteActive } = store

    return (
      <div
        className={classList({
          'layers-container row y-end': true,
          'above-show-on': isDeliveryView,
          satellite: !isSatelliteActive,
          street: isSatelliteActive,
          fixed: isFixed,
          absolute: !isFixed,
        })}
      >
        {this.renderSmallMenu()}
        <div className={classList({ hidden: !isMoreMenuShown })}>
          <MapLayersMenu
            store={store}
            isLogisticsView={isLogisticsView}
            toggleDeliveriesHiddenState={toggleDeliveriesHiddenState}
            toggleAnnouncementsHiddenState={toggleAnnouncementsHiddenState}
            togglePermitsHiddenState={togglePermitsHiddenState}
            toggleMonitoringsHiddenState={toggleMonitoringsHiddenState}
            areDeliveriesHidden={areDeliveriesHidden}
            areAnnouncementsHidden={areAnnouncementsHidden}
            arePermitsHidden={arePermitsHidden}
            areMonitoringsHidden={areMonitoringsHidden}
            areAdditionalMarkersAvailable={areAdditionalMarkersAvailable}
          />
        </div>
      </div>
    )
  }

  private renderSmallMenu() {
    const {
      store: {
        isMoreMenuShown,
        isSatelliteActive,
        globeViewStyle: { isTrafficEnabled },
        toggleMapStyle,
        toggleTraffic,
        toggleMoreMenu,
      },
    } = this.props
    const imageSrc = isSatelliteActive ? STREET_PATH : SATELLITE_PATH

    return (
      <div className={classList({ row: true, hidden: isMoreMenuShown })}>
        <div
          className="col main-cell relative layers-container-holder"
          onClick={toggleMapStyle}
        >
          <img src={imageSrc} className="main-icon no-grow pointer" />
          <div className="main-text full-width">
            <div className="layers-text text center large absolute row full-width x-center">
              <Icons.MoreLayerStylesSmall className="no-grow more-layer-small-icon" />
              {Localization.translator.layers}
            </div>
            <div className="type-text text center large absolute full-width x-center">
              {isSatelliteActive
                ? Localization.translator.street
                : Localization.translator.satellite}
            </div>
          </div>
        </div>
        <div className="hidden-layer row ml10 brada10 bg-white pa10">
          <div
            className={classList({
              'col secondary-cell mx5 x-center pointer': true,
              active: isTrafficEnabled,
            })}
            onClick={toggleTraffic}
          >
            <Icons.MapLayerTraffic className="no-grow col secondary-cell-icon relative" />
            <div className="text large mode-text">
              {Localization.translator.traffic}
            </div>
          </div>
          <div
            className={classList({
              'col secondary-cell mx5 x-center pointer': true,
              active: isMoreMenuShown,
            })}
            onClick={toggleMoreMenu}
          >
            <Icons.MoreLayerStyles className="no-grow col" />
            <div className="text large mode-text">
              {Localization.translator.more}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
