import React from 'react'

import { classList } from 'react-classlist-helper'

import { preventLastWordWrap } from '~/client/src/shared/utils/textUtils'

import './CleanDialog.scss'

type IAction = {
  title: string
  onClick: () => void
}

type IActions =
  | [IAction | React.ReactNode]
  | [IAction | React.ReactNode, IAction | React.ReactNode]

interface IProps {
  open: boolean
  title?: React.ReactNode
  icon?: React.ReactNode
  onClose: () => void
  clickOutsideToDismiss?: boolean
  className?: string
  style?: React.CSSProperties

  // Actions can be either a title/callback pair, or a custom element each
  // One or two actions are allowed
  actions?: IActions

  children?: React.ReactNode
}

export default class CleanDialog extends React.Component<IProps> {
  public render() {
    const {
      open,
      title,
      icon,
      onClose,
      clickOutsideToDismiss = false,
      className,
      style,
      actions,
      children,
    } = this.props

    const formattedTitle =
      typeof title === 'string' ? preventLastWordWrap(title) : title

    return (
      <div
        className={classList({
          'clean-dialog full-height row x-center': true,
          open,
          [className]: !!className,
        })}
        onClick={() => clickOutsideToDismiss && onClose()}
        style={style}
      >
        <div
          className="content-container brada8 bg-white ba-grey pa24 pt12"
          onClick={event => {
            event.stopPropagation()
          }}
        >
          <div className="text large">
            {icon && <div className="dialog-icon">{icon}</div>}
            <h1 className="dialog-title">{formattedTitle}</h1>
          </div>
          <div className="dialog-content my20">{children}</div>
          {actions && (
            <div className="row x-end">
              {actions.map((action, i) => (
                <div className="dialog-action" key={i}>
                  {this.isAction(action) ? (
                    <span
                      className="as-end blue-highlight large text"
                      onClick={action.onClick}
                    >
                      {action.title}
                    </span>
                  ) : React.isValidElement(action) ? (
                    action
                  ) : null}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }

  private isAction = (action: unknown): action is IAction => {
    return (
      (action as IAction).title !== undefined &&
      (action as IAction).onClick !== undefined
    )
  }
}
