import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'

import './FilterOption.scss'

interface IFilterOption {
  id: string
  code: string
  description: string
  instancesIds: string[]
  company?: string
  selectedOptions
  changeOption
  onOnlyClicked?
  croppedAmount?
  shouldIgnoreCount?: boolean
  className?: string
  instanceIcon?: JSX.Element
  shouldShowOnlyCroppedAmount?: boolean
  optionFormatter?: (option: string) => JSX.Element
  shouldRowBeClickable?: boolean
}

@observer
class FilterOption extends React.Component<IFilterOption> {
  public render() {
    const {
      id,
      code,
      description,
      instancesIds,
      company,
      shouldIgnoreCount,
      className,
      selectedOptions,
      croppedAmount,
      instanceIcon,
      onOnlyClicked,
      shouldShowOnlyCroppedAmount,
      optionFormatter,
    } = this.props

    const isChecked = !!selectedOptions.get(id)
    const shouldShowOnlyButton = !shouldIgnoreCount && !!onOnlyClicked
    const checkBoxClassName =
      isChecked && shouldShowOnlyCroppedAmount ? 'reverted-colors' : ''
    return (
      <div
        className={classList({
          'filter-option': true,
          'no-activities':
            !shouldShowOnlyCroppedAmount &&
            !shouldIgnoreCount &&
            !croppedAmount,
          opacity3: shouldShowOnlyCroppedAmount && !croppedAmount,
          [className]: true,
        })}
        key={id}
      >
        {company && <div className="row filter-option-company">{company}</div>}
        <div
          className="row filter-option-main"
          title={(code ? code + ': ' : '') + description}
          onClick={this.onRowClick}
        >
          {shouldShowOnlyButton && (
            <div className="only-button" onClick={this.onOnlyClicked}>
              Only
            </div>
          )}
          <Checkbox
            label={code}
            onClick={this.onCheckBoxClick}
            isChecked={isChecked}
            className={checkBoxClassName}
          />
          {instanceIcon}
          <div className="no-grow">
            <div className="desk">
              {optionFormatter ? optionFormatter(id) : description}
            </div>
          </div>
          {!shouldIgnoreCount &&
            (shouldShowOnlyCroppedAmount ? (
              !!croppedAmount && (
                <div className="no-grow">
                  <span className="text large ml5 grey">({croppedAmount})</span>
                </div>
              )
            ) : (
              <div className="count">
                <span
                  className={classList({
                    'text large': true,
                    'primary-blue': !!croppedAmount,
                  })}
                >
                  {croppedAmount}
                </span>
                <span className="ml5 mr5">/</span>
                {instancesIds.length}
              </div>
            ))}
        </div>
      </div>
    )
  }

  @action.bound
  private onCheckBoxClick() {
    const { id, instancesIds, changeOption, shouldRowBeClickable } = this.props
    if (!shouldRowBeClickable) {
      changeOption(id, instancesIds)
    }
  }

  @action.bound
  private onRowClick() {
    const { id, instancesIds, changeOption, shouldRowBeClickable } = this.props
    if (shouldRowBeClickable) {
      changeOption(id, instancesIds)
    }
  }

  @action.bound
  private onOnlyClicked() {
    const { id, instancesIds, onOnlyClicked } = this.props
    onOnlyClicked(id, instancesIds)
  }
}

export default FilterOption
