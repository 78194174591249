import './ImageContainer.scss'

import * as React from 'react'

interface IProps {
  src?: string
}

export default class ImageContainer extends React.Component<IProps> {
  public render() {
    const { src } = this.props

    return (
      <div className="image-container">
        <img className="center" src={src} />
      </div>
    )
  }
}
