import * as React from 'react'

import { action, observable } from 'mobx'
import { classList } from 'react-classlist-helper'

import { UploadingType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import { isUrlToPdfOnStorage } from '../../utils/isUrlToPdfOnStorage'
import PdfDocument from '../PdfDocument/PdfDocument'
import PhotoThumbnail from '../PhotoThumbnail/PhotoThumbnail'
import IFileInputProps from './IFileInputProps'

import './FileInput.scss'

import { NO_IMAGE_PATH } from '../../utils/usefulStrings'

const PDF_PAGE_WIDTH = 150

export default class FileInputBase<
  T extends IFileInputProps,
> extends React.Component<T> {
  @observable public isImageLoaded: boolean = false
  protected fileInput: HTMLInputElement
  protected file: any

  public render() {
    const { id, name, isReadonly, textClassName, className, shouldHideName } =
      this.props

    const { value } = this.props

    const isPdf = isUrlToPdfOnStorage(value)

    return (
      <>
        <div className={className || ''}>
          {isPdf ? this.renderPdfPreview() : this.renderImagePreview()}
          {!isReadonly && this.renderChangeButton()}
        </div>
        {!shouldHideName && (
          <label className={textClassName} htmlFor={id}>
            {name}
          </label>
        )}
      </>
    )
  }

  private renderImagePreview() {
    const {
      id,
      value,
      thumbUrl,
      isFlexPreview,
      shouldUseFullSize,
      shouldHideIconAndOutline,
      onClickHandler,
    } = this.props

    const imageSrc = thumbUrl || value || NO_IMAGE_PATH

    return (
      <div
        id={id}
        className={classList({
          'file-preview': true,
          'full-width full-height': shouldUseFullSize,
          flexible: isFlexPreview,
        })}
      >
        {!this.isImageLoaded && <PhotoThumbnail />}
        <img
          src={imageSrc}
          className={classList({
            'file-preview': true,
            'ba-light-grey': !shouldHideIconAndOutline,
            'full-width full-height': shouldUseFullSize,
            flexible: isFlexPreview,
          })}
          style={!this.isImageLoaded ? { visibility: 'hidden' } : {}}
          onLoad={this.setLoaded}
          onClick={onClickHandler || this.showFullscreenPreview}
        />
        {!shouldHideIconAndOutline && (
          <Icons.CameraBadge className="file-preview-icon file-preview-icon-image ba-light-grey" />
        )}
      </div>
    )
  }

  private renderPdfPreview() {
    const { id, value, shouldUseFullSize } = this.props
    return (
      <a
        id={id}
        href={value}
        target="_blank"
        title={Localization.translator.download}
        className={classList({
          'file-preview ba-light-grey': true,
          'full-width full-height': shouldUseFullSize,
        })}
      >
        <PdfDocument file={value} pageWidth={PDF_PAGE_WIDTH} />
        <Icons.Pdf className="file-preview-icon file-preview-icon-pdf ba-light-grey" />
      </a>
    )
  }

  private renderChangeButton() {
    return (
      <div className="file-button">
        <input
          onChange={this.handleFileInputChange}
          className="file-button-hidden-input absolute-block"
          ref={this.setFileInputRef}
          type="file"
          accept="application/pdf, image/*"
        />
        <a
          className="file-button-input no-outline pl10 text primary-blue large"
          onClick={this.promptForFile}
        >
          {Localization.translator.change_verb}
        </a>
      </div>
    )
  }

  @action.bound
  protected promptForFile() {
    this.fileInput.click()
  }

  protected stopPropagation(event) {
    if (event) {
      event.stopPropagation()
    }
  }

  @action.bound
  private handleFileInputChange = async () => {
    this.file = this.fileInput.files[0]
    if (!this.file) {
      return
    }

    const fileType = this.file.type.startsWith('image/')
      ? UploadingType.Image
      : UploadingType.Pdf
    const [result] = await this.props.fileUploadingStore.uploadFile(
      this.file,
      fileType,
    )
    this.props.onChange(result.fileURL, this.file.name)
  }

  @action.bound
  private setFileInputRef(ref) {
    this.fileInput = ref
  }

  protected showFullscreenPreview(event) {
    this.stopPropagation(event)
  }

  private setLoaded = () => {
    this.isImageLoaded = true
  }
}
