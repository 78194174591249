import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { Cross } from '~/client/src/shared/components/Icons'

import './CompactDialog.scss'

interface IProps {
  isShown: boolean
  title?: string
  className?: string
  canOutsideClickClose?: boolean
  shouldHideHeader?: boolean

  onHide(): void
}

const NOOP = () => null

@observer
export default class CompactDialog extends React.Component<IProps> {
  public static defaultProps = {
    className: '',
    canOutsideClickClose: true,
    onClick: NOOP,
  }

  public render() {
    const {
      isShown,
      title,
      onHide,
      children,
      className,
      canOutsideClickClose,
      shouldHideHeader,
    } = this.props

    return (
      <>
        <div
          onClick={canOutsideClickClose ? onHide : NOOP}
          className={classList({
            dimmer: true,
            shown: isShown,
          })}
        />
        <div
          className={classList({
            'compact-dialog': true,
            [className]: true,
            shown: isShown,
          })}
        >
          {!shouldHideHeader && (
            <div className="modal-header row y-center pt10 pb5 bb-light-grey">
              <div className="text extra-large bold">{title}</div>
              <div className="no-grow pointer" onClick={onHide}>
                <Cross />
              </div>
            </div>
          )}
          {children}
        </div>
      </>
    )
  }
}
