import React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import { Content, View } from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import QRCodeAccess from '~/client/src/shared/components/QRCodes/components/QRCodeAccess'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'

import NativeIntegrator from '../../integration/NativeIntegrator/NativeIntegrator'
import MobileInitialState from '../../stores/MobileInitialState'

interface IRouteParams {
  userId: string
}

interface IProps extends RouteComponentProps<IRouteParams> {
  userId: string
  eventsStore?: MobileEventStore
  common?: MobileCommonStore
  scannersStore?: ScannersStore
  projectMembersStore?: ProjectMembersStore
  state?: MobileInitialState
}

/**
 * This component is used to display the QR code badge scanner for a user in a standalone view.
 * It is used in the native mobile app (as embedded in the webview).
 * It needs to track the document height to report it to the webview for proper sizing on the fly.
 */
@inject(
  'common',
  'scannersStore',
  'eventsStore',
  'projectMembersStore',
  'userProjectsStore',
  'state',
)
@observer
class QRCodeAccessStandalone extends React.Component<IProps> {
  private contentRef = React.createRef<HTMLDivElement>()
  @observable private currentHeight: number
  private resizeObserver

  public componentDidMount() {
    // @ts-ignore - ResizeObserver types can be installed from @types/resize-observer-browser if needed elsewhere
    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { height } = entry.contentRect
        this.reportDocumentHeight(height)
      }
    })

    this.resizeObserver.observe(this.contentRef.current)
  }

  public componentWillUnmount() {
    if (this.contentRef.current) {
      this.resizeObserver.unobserve(this.contentRef.current)
    }
  }

  @action
  private reportDocumentHeight(height: number) {
    const heightInt = Math.floor(height) || 0
    if (heightInt && this.currentHeight !== heightInt) {
      this.currentHeight = heightInt
      NativeIntegrator.instance?.reportDocumentHeight(heightInt)
    }
  }

  public render() {
    const { state } = this.props

    return (
      <div ref={this.contentRef}>
        <View>
          <Content scrollable>
            <UserDebugInfo user={this.user} height={this.currentHeight} />
            {state.isLoading ? (
              <Centered>
                <Loader size={25} />
                <span>{Localization.translator.loading}</span>
              </Centered>
            ) : this.user ? (
              <QRCodeAccess user={this.user} />
            ) : (
              <Centered>
                <span>{Localization.translator.userNotFound}</span>
              </Centered>
            )}
          </Content>
        </View>
      </div>
    )
  }

  @computed
  private get user() {
    const { projectMembersStore, match } = this.props
    return projectMembersStore.getById(match.params.userId)
  }
}

const Centered = ({ children }) => (
  <div className="absolute-block" style={{ top: '50%' }}>
    <div className="col x-center y-center">{children}</div>
  </div>
)

// Convenience display when debugging on the desktop
// If not in a webview, show the username as a title
const UserDebugInfo = ({ user, height }) => {
  if (!user || window.ReactNativeWebView) return null

  return (
    <div className="col x-center y-center bg-cool-grey bb-light-cool-grey no-grow">
      <pre>
        <b>{user.fullName}</b>
        <br />
        id:&#9;{user.id}
        <br />
        email:&#9;{user.email}
        <br />
        height:&#9;{height}
      </pre>
    </div>
  )
}

export default withRouter(QRCodeAccessStandalone)
