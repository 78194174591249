import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import Draggable from 'react-draggable'

import SortOrder from '~/client/src/shared/enums/SortOrder'
import { NOOP } from '~/client/src/shared/utils/noop'

import SelectButton from '../../SelectButton/SelectButton'
import { BasicDataKeys, LWFCRowData } from '../GroupedListWithFixedColumns'

interface IProps {
  dataKey: string
  label: string
  columnIndex: number
  toggleSelectionPopup: () => void
  onColumnSort?: (columnKey: string) => void
  sortedColumnKey?: string
  getCellClassNames?: (
    columnKey: string,
    data?: LWFCRowData,
  ) => { [className: string]: boolean }
  sortingOrder?: SortOrder
  isResizable: boolean
  onResizeColumn?: ({ columnIndex, deltaX }) => void
  style?: React.CSSProperties
  className?: string
}

const { ARROW_UP, ARROW_DOWN } = IconNames
const SORT_INDICATION_ICON_SIZE = 8

@observer
export default class TableHeaderCell extends React.Component<IProps> {
  public static defaultProps = {
    getCellClassNames: NOOP,
    onColumnSort: NOOP,
    onResizeColumn: NOOP,
    className: '',
  }

  public render() {
    const {
      onColumnSort,
      sortedColumnKey,
      getCellClassNames,
      dataKey,
      label,
      columnIndex,
      isResizable,
      onResizeColumn,
      style,
      className,
    } = this.props
    const isCheckboxColumn = dataKey === BasicDataKeys.CHECKBOX
    const isColumnSorted = sortedColumnKey === dataKey
    const isGroupedList = style !== undefined
    const handler = isCheckboxColumn ? NOOP : onColumnSort.bind(null, dataKey)

    return (
      <div
        className={classList({
          'row text uppercase no-outline-container': true,
          'cell cell-header pointer lp05 no-select': true,
          'full-height full-width relative bb-light-cool-grey': !isGroupedList,
          'bold bb-palette-grey': isGroupedList,
          'sorted bold white': isColumnSorted,
          'x-center': isCheckboxColumn,
          [className]: true,
          ...getCellClassNames(dataKey),
        })}
        style={style}
      >
        {isCheckboxColumn ? (
          <SelectButton onClick={this.props.toggleSelectionPopup} />
        ) : (
          <div onClick={handler}>
            {this.renderHeaderLabel(label, isColumnSorted)}
          </div>
        )}
        {isResizable && (
          <Draggable
            axis="x"
            defaultClassName="DragHandle"
            defaultClassNameDragging="DragHandleActive"
            onStop={(event, data) =>
              onResizeColumn({
                columnIndex,
                deltaX: data.x,
              })
            }
            position={{
              x: 0,
              y: 0,
            }}
          >
            <div className="resize-cursor resize-cursor-holder" />
          </Draggable>
        )}
      </div>
    )
  }

  private renderHeaderLabel = (
    label: string,
    isColumnSorted: boolean,
  ): JSX.Element => {
    const { sortingOrder } = this.props

    return (
      <>
        {label}
        {isColumnSorted && (
          <Icon
            className="pl2"
            size={SORT_INDICATION_ICON_SIZE}
            icon={sortingOrder === SortOrder.ASC ? ARROW_DOWN : ARROW_UP}
          />
        )}
      </>
    )
  }
}
