import { undergroundLevelBaseName } from './usefulStrings'

export const getFloorFromLevel = (levelName: string): number => {
  if (!levelName) {
    return 0
  }

  const levelSplit: string[] = levelName.split(' ')
  const multiplier = levelSplit[0] === undergroundLevelBaseName ? -1 : 1

  return multiplier * Number(levelSplit[levelSplit.length - 1])
}
