import { observable } from 'mobx'

import { FilterType, IFilterInfo } from '~/client/graph'

import FilterSourceTypeCategory from '../../enums/FilterSourceTypeCategory'
import Localization from '../../localization/LocalizationManager'
import FilterInfo from './FilterInfo'

export enum FilterSourceType {
  ActivityCode = 'ActivityCode',
  ProjectMember = 'ProjectMember',
  Default = 'ActivityCode',
}

export const FilterSourceTypeCategories = {
  [FilterSourceTypeCategory.ScheduleData]: [FilterSourceType.ActivityCode],
  [FilterSourceTypeCategory.StruxHubData]: [FilterSourceType.ProjectMember],
}

const canDisabledFilters = [
  FilterType.Building,
  FilterType.Level,
  FilterType.Zone,
  FilterType.Company,
]

export default class ActivityFilterInfo extends FilterInfo {
  @observable public codeTypeIds: string[] = []
  @observable public hiddenCodeIds: string[] = []

  @observable public sourceType: string = FilterSourceType.Default
  @observable public isDisabled: boolean = false

  public readonly canBeDisabled: boolean
  public index: number = 0

  public constructor(
    name: string,
    isRemovable?: boolean,
    caption?: string,
    index?: number,
  ) {
    super(name, isRemovable, caption)
    this.index = index || 0
    this.canBeDisabled = canDisabledFilters.includes(name as FilterType)
  }

  public setFromSource({
    caption,
    codeTypeIds,
    hiddenCodeIds,
    index,
    sourceType,
    isDisabled,
  }: IFilterInfo) {
    if (caption && this.getCaption() !== caption) {
      this.caption = caption
    }

    if (sourceType) {
      this.sourceType = sourceType
    }

    if (codeTypeIds) {
      this.codeTypeIds = codeTypeIds
    }

    if (hiddenCodeIds) {
      this.hiddenCodeIds = hiddenCodeIds
    }

    if (index) {
      this.index = index
    }

    if (this.canBeDisabled && isDisabled) {
      this.isDisabled = true
    }
  }

  public getKnownCaption(): string {
    switch (this.name) {
      case FilterType.Building:
        return Localization.translator.building
      case FilterType.Level:
        return Localization.translator.level
      case FilterType.Zone:
        return Localization.translator.zone
      case FilterType.Company:
        return Localization.translator.company
      default:
        return super.getKnownCaption()
    }
  }
}
