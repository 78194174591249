import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { observer } from 'mobx-react'

import { customDebounce } from '~/client/src/shared/utils/util'

// localization: no display text to translate

const DEBOUNCE_TIME = 300
const ANIMATE_TIME = 200

interface IProps {
  items: string[]
  defaultItemIndex: number
  onChange: (value: string) => void
  shouldRenderInput?: boolean
}

@observer
export default class CircularSelectControl extends React.Component<IProps> {
  @observable private activeElementIndex: number = 0
  @observable private pointerStart: number = 0
  @observable private pointerOffset: number = 0

  private spin: React.MutableRefObject<number> = React.createRef()

  private listRef = React.createRef<HTMLUListElement>()
  private spinnerRef = React.createRef<HTMLDivElement>()
  private inputRef = React.createRef<HTMLInputElement>()

  public componentDidMount() {
    this.spin.current = this.props.defaultItemIndex
    this.activeElementIndex = this.props.defaultItemIndex

    this.spinnerRef.current.addEventListener('wheel', this.onSpinnerWheel)
    this.spinnerRef.current.addEventListener('wheel', this.debouncedResolveSpin)
    this.spinnerRef.current.addEventListener(
      'touchmove',
      this.onSpinnerTouchMove,
      { passive: false },
    )
    this.spinnerRef.current.addEventListener(
      'touchstart',
      this.onSpinnerTouchStart,
      { passive: false },
    )

    this.spinTo(this.props.defaultItemIndex * this.itemHeight, true)
    this.resolveSpin(null, true)
  }

  public componentWillUnmount() {
    this.spinnerRef.current.removeEventListener('wheel', this.onSpinnerWheel)
    this.spinnerRef.current.removeEventListener(
      'wheel',
      this.debouncedResolveSpin,
    )
    this.spinnerRef.current.removeEventListener(
      'touchmove',
      this.onSpinnerTouchMove,
    )
    this.spinnerRef.current.removeEventListener(
      'touchstart',
      this.onSpinnerTouchStart,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.defaultItemIndex !== this.props.defaultItemIndex) {
      this.spinTo(this.props.defaultItemIndex * this.itemHeight, true)
      this.resolveSpin(null, true)
    }
  }

  public render() {
    const { items, shouldRenderInput } = this.props

    return (
      <div className="circular-select">
        <div className="picker">
          <div
            className="spinner"
            ref={this.spinnerRef}
            onTouchEnd={this.resolveSpin}
            onTouchCancel={this.resolveSpin}
          >
            <ul className="spinner-items" ref={this.listRef}>
              {items.map((item, index) => (
                <li
                  onClick={() => this.onSpinnerClick(index)}
                  key={index}
                  className={`item ${this.neighborClassName(
                    this.activeElementIndex,
                    index,
                  )}`}
                >
                  {item}
                </li>
              ))}
            </ul>
            {shouldRenderInput && <input type="text" ref={this.inputRef} />}
          </div>
        </div>
      </div>
    )
  }

  @computed
  private get items(): NodeListOf<HTMLElement> {
    return this.listRef.current.querySelectorAll('.item')
  }

  @computed
  private get itemHeight(): number {
    return this.items.item(0).offsetHeight
  }

  @computed
  private get listTopOffset(): number {
    return Number.parseInt(
      window
        ?.getComputedStyle(this.listRef.current)
        ?.getPropertyValue('padding-top'),
      10,
    )
  }

  private boundSpin(newSpin: number): number {
    const maxHeight = this.listRef.current.offsetHeight - this.itemHeight

    if (newSpin < 0) {
      return 0
    }

    if (newSpin > maxHeight) {
      return maxHeight
    }

    return newSpin
  }

  private neighborClassName(pivotIndex: number, currentIndex: number): string {
    if (pivotIndex === currentIndex) return 'active'
    if (Math.abs(pivotIndex - currentIndex) === 1) return 'neighbor-1'
    if (Math.abs(pivotIndex - currentIndex) === 2) return 'neighbor-2'

    return 'neighbor-3'
  }

  private debouncedResolveSpin = customDebounce(this.resolveSpin, DEBOUNCE_TIME)

  @action.bound
  private resolveSpin(value?: any, ignoreSpinTo?: boolean) {
    if (typeof value !== 'number') {
      value = this.spin.current
    }

    const spinOffset = this.listTopOffset - this.itemHeight / 2

    for (let index = 0; index < this.items.length; index++) {
      const element = this.items[index]

      if (element.offsetTop - spinOffset > value) {
        this.setActiveItemIndex(index)

        const position = element.offsetTop - this.listTopOffset
        !ignoreSpinTo && this.spinTo(position, true)
        return
      }
    }
  }

  @action.bound
  private spinTo(value: number, animate: boolean) {
    if (animate) {
      this.listRef.current.classList.add('animate')
      setTimeout(
        () => this.listRef?.current?.classList?.remove('animate'),
        ANIMATE_TIME,
      )
    }

    this.listRef.current.style.transform = `translateY(${-value}px)`
    this.spin.current = value
  }

  @action.bound
  private addSpin(delta: number) {
    this.spinTo(this.boundSpin(this.spin.current + delta), false)
  }

  @action.bound
  private onSpinnerWheel = (event: WheelEvent) => {
    event.preventDefault()

    let delta = event.deltaY
    if (delta < -this.itemHeight) {
      delta = -this.itemHeight
    }
    if (delta > this.itemHeight) {
      delta = this.itemHeight
    }

    this.addSpin(delta)
  }

  @action.bound
  private onSpinnerTouchStart(event: TouchEvent) {
    event.preventDefault()

    this.pointerStart = event.touches[0].pageY
    this.pointerOffset = this.spin.current
  }

  @action.bound
  private onSpinnerTouchMove(event: TouchEvent) {
    event.preventDefault()

    const delta = this.pointerStart - event.touches[0].pageY
    this.spinTo(this.boundSpin(delta + this.pointerOffset), false)
  }

  @action.bound
  private onSpinnerClick(index: number) {
    const position = this.items[index].offsetTop - this.listTopOffset
    this.spinTo(position, true)
    this.setActiveItemIndex(index)
  }

  @action.bound
  private setActiveItemIndex(index: number) {
    this.activeElementIndex = index
    this.props.onChange(this.props.items[index])
  }
}
