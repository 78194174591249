interface IPartialUser {
  firstName?: string
  lastName?: string
  email?: string
}

export default function getUserName({
  firstName,
  lastName,
  email,
}: IPartialUser): string {
  if (firstName && lastName) {
    return firstName + ' ' + lastName
  }
  return email
}
