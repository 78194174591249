import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '../../localization/LocalizationManager'
import LocationBase from '../../models/LocationObjects/LocationBase'
import AnnouncementAssignmentsStore from '../../stores/domain/AnnouncementAssignments.store'
import TagsStore from '../../stores/domain/Tags.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import { NOOP } from '../../utils/noop'
import Checkbox from '../Checkbox/Checkbox'
import LocationPicker from '../LocationPicker/LocationPicker'
import PermitDatePicker from '../PermitDatePicker/PermitDatePicker'
import StruxhubAttributeSelector from '../StruxhubInputs/StruxhubSelector/StruxhubAttributeSelector'
import StruxhubTagValueSelector from '../StruxhubInputs/StruxhubSelector/StruxhubTagValueSelector'
import StruxhubTextValueSelector from '../StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import StruxhubTextArea from '../StruxhubInputs/StruxhubTextArea'
import AnnouncementEditionFormStore from './AnnouncementEditionForm.store'
import AnnouncementOrderSetUp from './components/AnnouncementOrderSetUp'
import AnnouncementUserPicker from './components/AnnouncementUserPicker'

import Colors from '~/client/src/shared/theme.module.scss'

import './AnnouncementEditionFormDetails.scss'

interface IProps {
  store: AnnouncementEditionFormStore
  selectedDate: Date

  onClose(): void

  projectDateStore?: ProjectDateStore
  tagsStore?: TagsStore
  announcementAssignmentsStore?: AnnouncementAssignmentsStore
}

const announcementCreationForm = {
  newAnnouncement: 'New announcement',
  targetUser: 'Publish to',
  announcementWillDisplay: 'Announcement will display on this date range',
  endDate: 'End date',
  announcementOrder: 'Announcement order',
  change: 'Change',
  done: 'Done',
  associatedLocation: 'Associated location',
  closeLocation: 'Close location over date range',
}

const announcementTitle = 'Announcement title'
const save = 'Save'
const selectLocation = 'Select location'
const select = 'Select'

const MAX_SHOWED_USERS_TAG = 2
const MAX_DATE_RANGE_YEARS = 2

const getAnnouncementOrderLabel = (position: number, totalCount: number) => {
  return `#${position} of ${totalCount} announcements`
}

@inject('projectDateStore', 'tagsStore', 'announcementAssignmentsStore')
@observer
export default class AnnouncementEditionFormDetails extends React.Component<IProps> {
  public render() {
    const { store } = this.props

    if (store.isUserPickerDisplayed) {
      return this.renderUsersPicker()
    }

    if (store.isOrderSetUpDisplayed) {
      return (
        <AnnouncementOrderSetUp initialDate={this.initialDate} store={store} />
      )
    }

    if (store.isLocationPickerDisplayed) {
      const { location } = store.editableAnnouncement
      const selectedIds = location ? [location.id] : []

      return (
        <div className="absolute full-width full-height bg-white permit-location-picker">
          <LocationPicker
            className="full-height"
            title={selectLocation}
            applyButtonTitle={select}
            isSingleSelectionMode={true}
            selectedIds={selectedIds}
            onSingleSelect={this.onLocationItemSelect}
            onChange={NOOP}
            onApplyChanges={store.toggleLocationPickerState}
            onClose={store.toggleLocationPickerState}
          />
        </div>
      )
    }

    return (
      <>
        {this.datePicker}
        <div className="col layout-content px16">
          {this.renderTitle()}
          {this.renderUsersSelector()}
          {this.renderDateSelector()}
          {this.renderLocation()}
          {this.renderClosureTextbox()}
          {this.renderOrderSelector()}
          {true && (
            <div className="button-container row py12 px24 no-grow">
              <div
                onClick={this.addOrUpdateAnnouncement}
                className={classList({
                  'apply-button row x-center y-center text white extra-large bold':
                    true,
                  'inactive-element': !store.isAnnouncementValid,
                })}
              >
                {save}
              </div>
            </div>
          )}
        </div>
      </>
    )
  }

  private get datePicker(): JSX.Element {
    const { store } = this.props

    if (!store.isDatePickerDisplayed) {
      return null
    }

    const { applyDates, hideDatePicker, editableAnnouncement } = store

    return (
      <PermitDatePicker
        applyDates={applyDates}
        hideDatePicker={hideDatePicker}
        initialStartDate={editableAnnouncement.startDate}
        initialEndDate={editableAnnouncement.endDate}
        shouldShowTimePicker={false}
        maxRangeInYears={MAX_DATE_RANGE_YEARS}
      />
    )
  }

  private addOrUpdateAnnouncement = () => {
    const { store, onClose } = this.props

    if (store.isAnnouncementValid) {
      store.editableAnnouncement.dateToAppear = Date.now()
      store.submitForm()
      onClose()
    }
  }

  private renderUsersSelector(): JSX.Element {
    const { store } = this.props
    const { openUserPicker } = store

    return (
      <StruxhubTagValueSelector
        label={announcementCreationForm.targetUser}
        isRequired={true}
        onClick={openUserPicker}
        values={this.publishToValues}
      />
    )
  }

  @computed
  private get publishToValues() {
    const { store, announcementAssignmentsStore } = this.props

    if (store.editableAnnouncementAssignment.all) {
      return [
        {
          name: `${Localization.translator.allUsers} (${announcementAssignmentsStore.allProjectMembers.length}) `,
          color: Colors.neutral0,
        },
      ]
    }

    const { appliedUsers } = store

    const values = appliedUsers.slice(0, MAX_SHOWED_USERS_TAG).map(u => ({
      name: u.fullName || Localization.translator.unknownUser,
      color: Colors.neutral0,
    }))

    const restCount = appliedUsers.slice(MAX_SHOWED_USERS_TAG).length
    if (restCount) {
      values.push({
        name: `+ ${Localization.translator.xUsers(restCount)} `,
        color: null,
      })
    }

    return values
  }

  private renderDateSelector(): JSX.Element {
    const { openDatePicker, editableAnnouncement } = this.props.store

    return (
      <StruxhubTextValueSelector
        label={announcementCreationForm.announcementWillDisplay}
        isRequired={true}
        onClick={openDatePicker}
        value={this.props.projectDateStore.getFullDateIntervalToDisplay(
          editableAnnouncement.startDate,
          editableAnnouncement.endDate,
        )}
      />
    )
  }

  private renderLocation = (): JSX.Element => {
    const {
      editableAnnouncement: { location },
      toggleLocationPickerState: setLocationPickerState,
    } = this.props.store

    return (
      <StruxhubAttributeSelector
        label={announcementCreationForm.associatedLocation}
        isRequired={true}
        onClick={setLocationPickerState.bind(null, true)}
        value={location?.id}
      />
    )
  }

  private renderClosureTextbox(): JSX.Element {
    const { isClosure } = this.props.store.editableAnnouncement

    return (
      <div className="row no-grow y-end py15 closure-selector">
        <div className="col">
          <Checkbox
            className={classList({
              'announcement-checkbox': true,
              checked: isClosure,
            })}
            isChecked={isClosure}
            onClick={this.onIsClosureChange}
            label={announcementCreationForm.closeLocation}
            labelClassName="text extra-large"
          />
        </div>
      </div>
    )
  }

  private renderTitle(): JSX.Element {
    const { title } = this.props.store.editableAnnouncement

    return (
      <StruxhubTextArea
        label={announcementTitle}
        isRequired={true}
        onChange={this.onTitleChange}
        value={title}
      />
    )
  }

  private renderOrderSelector(): JSX.Element {
    const {
      openOrderSetUp,
      getExistingAndEditableAnnouncementsForDay,
      editableAnnouncement,
    } = this.props.store

    const startDateAnnouncements = getExistingAndEditableAnnouncementsForDay(
      this.initialDate,
    )
    const totalAnnouncementsCount = startDateAnnouncements.length
    const editableAnnouncementOrder =
      startDateAnnouncements.findIndex(
        announcement => announcement.id === editableAnnouncement.id,
      ) + 1

    return (
      <StruxhubTextValueSelector
        label={announcementCreationForm.announcementOrder}
        onClick={openOrderSetUp}
        value={getAnnouncementOrderLabel(
          editableAnnouncementOrder,
          totalAnnouncementsCount,
        )}
      />
    )
  }

  private renderUsersPicker(): JSX.Element {
    const { store } = this.props

    return (
      <AnnouncementUserPicker
        appliedUsers={store.appliedUsers}
        onApply={store.applyUsers}
        onClose={store.hideUserPicker}
        isAllMode={store.editableAnnouncementAssignment.all}
      />
    )
  }

  private onTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.store.editableAnnouncement.title = event.target.value
  }

  private onIsClosureChange = () => {
    const { editableAnnouncement } = this.props.store
    editableAnnouncement.isClosure = !editableAnnouncement.isClosure
  }

  private onLocationItemSelect = (locationAttr: LocationBase) => {
    this.props.store.applyLocation(
      locationAttr && {
        id: locationAttr.id,
        type: locationAttr.type,
      },
    )
  }

  private get initialDate(): Date {
    const { selectedDate, store, projectDateStore } = this.props
    const { startDate, endDate } = store.editableAnnouncement
    const { startOfDay, isWithinDateInterval } = projectDateStore

    const date = selectedDate || new Date()
    const isDateWithinAnnouncementDates = isWithinDateInterval(
      startDate,
      endDate,
      date,
    )

    return isDateWithinAnnouncementDates
      ? startOfDay(date)
      : new Date(startDate)
  }
}
