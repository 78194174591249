import Localization from '../localization/LocalizationManager'

enum InfoSectionId {
  Global = 'globalInfo',
  Personal = 'personalInformation',
  Company = 'companyInformation',
  Project = 'projectInformation',
  Additional = 'additionalInformation',
  WorkflowPermission = 'workflowPermissions',
  CompanyContactInfo = 'companyContactInfo',
  CompanyAddress = 'companyAddress',
  Users = 'users',
  LegalInfo = 'legalInfo',
  WhiteList = 'whiteList',
  QRCodes = 'qrCodeAccessAndBadges',
  None = 'none',
}

export function getSectionTranslate(sectionId: InfoSectionId): string {
  switch (sectionId) {
    case InfoSectionId.Global:
      return Localization.translator.modalInfoSections.globalInfo
    case InfoSectionId.Additional:
      return Localization.translator.modalInfoSections.additionalInformation
    case InfoSectionId.Company:
      return Localization.translator.modalInfoSections.companyInformation
    case InfoSectionId.CompanyAddress:
      return Localization.translator.modalInfoSections.companyAddress
    case InfoSectionId.CompanyContactInfo:
      return Localization.translator.modalInfoSections.companyContactInfo
    case InfoSectionId.LegalInfo:
      return Localization.translator.modalInfoSections.legalInfo
    case InfoSectionId.Personal:
      return Localization.translator.modalInfoSections.personalInformation
    case InfoSectionId.Project:
      return Localization.translator.modalInfoSections.projectInformation
    case InfoSectionId.Users:
      return Localization.translator.modalInfoSections.users
    case InfoSectionId.WorkflowPermission:
      return Localization.translator.modalInfoSections.workflowPermissions
    case InfoSectionId.WhiteList:
      return 'White list'
    case InfoSectionId.QRCodes:
      return Localization.translator.modalInfoSections.qrCodeAccessAndBadges
    case InfoSectionId.None:
      return 'None'

    default:
      throw new Error(sectionId + ' is not handled')
  }
}

export default InfoSectionId
