import { action, observable } from 'mobx'

export const MIN_CARD_WIDTH = 190
export const MIN_CARD_TITLE_ROW_HEIGHT = 19
export const CARD_ROW_HEIGHT = 18

export default class PermitCalendarCardStore {
  @observable public shouldAlignStatusToEnd: boolean = false

  @action.bound
  public setStatusAlignment(shouldAlignToTheEnd: boolean) {
    this.shouldAlignStatusToEnd = shouldAlignToTheEnd
  }
}
