import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { Stage } from 'konva/types/Stage'
import { observable } from 'mobx'
import { Observer, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { Group, Image, Layer, Stage as StageComponent } from 'react-konva'
import { MapMouseEvent } from 'react-map-gl'
import { AutoSizer } from 'react-virtualized'

import {
  IGlobeViewSitemapData,
  IPosition,
  IProjectAddress,
  LocationType,
} from '~/client/graph'
import { MapBoxViewer } from '~/client/src/shared/components/MapBoxEditor/MapBoxViewer'
import EDITABLE_LABEL_ID from '~/client/src/shared/constants/editableLabel'
import { NOOP } from '~/client/src/shared/utils/noop'

import Localization from '../../localization/LocalizationManager'
import GlobeView from '../../models/GlobeView'
import IGeoPosition from '../../models/IGeoPosition'
import Sitemap from '../../models/Sitemap'
import InitialState from '../../stores/InitialState'
import BasemapsStore from '../../stores/domain/Basemaps.store'
import GlobeViewsStore from '../../stores/domain/GlobeViews.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import MapBoxViewerStore from '../MapBoxEditor/MapBoxViewer.store'
import MapViewItemBase from '../SitemapHelpers/models/MapViewItemBase'

import './BaseMapView.scss'

interface ISitemapSizes {
  width: number
  height: number
  offsetX: number
  offsetY: number
  offsetXImage: number
  offsetYImage: number
}

interface IDataUrlConfig {
  x?: number
  y?: number
  width?: number
  height?: number
  pixelRatio?: number
  mimeType?: string
  quality?: number
  callback?: (str: string) => void
}

export const SCALED_IMAGE_TYPE = 'image/png'
export const SCALED_IMAGE_QUALITY = 1
export const SCALED_IMAGE_PIXEL_RATE = 2
const DEFAULT_ZOOM_VALUE = 100
const SPACE_KEY_CODE = 32
const ITEMS_LAYER_ID = 'items-layer'
const ZOOM_SCALE = 0.95
const CLOSED_ICON_SCALE = 1.4
const SELECTED_ICON_SCALE = 1.4
const MAX_SCALE = 10
const MIN_SCALE = 0.05

interface IProps {
  zoom?: number
  sitemapUrl?: string
  showOnMenu?: () => JSX.Element
  getEditableItem?: (width: number, height: number, stage) => React.ReactNode
  onWhiteboardClick?: (evt?: KonvaEventObject<MouseEvent>) => void
  onTouch?: () => void
  children?: (bounds: {
    width: number
    height: number
    offsetX?: number
    offsetY?: number
    key?: string
  }) => JSX.Element
  shouldUseFullHeight?: boolean
  isDraggable?: boolean
  isEditorMode?: boolean

  isDraggingMode?: boolean
  selectedSitemap?: Sitemap
  opacity?: number
  mapBoxViewerStore: MapBoxViewerStore
  globeViewsStore?: GlobeViewsStore
  projectDateStore?: ProjectDateStore
  appState?: InitialState

  saveSitemapItem?: (item: MapViewItemBase) => Promise<void>
  selectedMapViewItem?: MapViewItemBase
  creatableAttributeType?: LocationType
  setViewport?: (viewport: IGeoPosition, isRubberMode?: boolean) => void

  toggleAnnouncementsHiddenState?: () => void
  toggleDeliveriesHiddenState?: () => void
  togglePermitsHiddenState?: () => void
  toggleMonitoringsHiddenState?: () => void
  isLogisticsView?: boolean
  arePermitsHidden?: boolean
  areAnnouncementsHidden?: boolean
  areDeliveriesHidden?: boolean
  areMonitoringsHidden?: boolean

  isCompactMode?: boolean
  shouldHideControls?: boolean
  basemapsStore?: BasemapsStore
  createAttributeInPosition?: (position: IPosition) => void

  globeSitemaps?: IGlobeViewSitemapData[]
  globe?: GlobeView
  isDeliveryView?: boolean
  projectAddress: IProjectAddress
  isTextStickerCreationActive?: boolean
  areAdditionalMarkersAvailable?: boolean

  createMapImage?: () => Promise<string>

  onMouseDown?: (evt: MapMouseEvent) => void
  onMouseUp?: (evt: MapMouseEvent) => void
  onMouseMove?: (evt: MapMouseEvent) => void
  onKeyDown?: (evt: KeyboardEvent) => void
  onKeyUp?: (evt: KeyboardEvent) => void
  onMapClick?: (evt: MapMouseEvent) => void
  onMapDblClick?: (evt: MapMouseEvent) => void
  isRubberMode?: boolean
}

// TODO: create store and move there all the logic to make the component standalone library and make it easy to understand
@observer
export default class BaseMapView extends React.Component<IProps> {
  public static defaultProps = {
    onMount: NOOP,
  }
  @observable private sitemapImage: HTMLImageElement
  @observable private isScrollingByDraggingAllowed: boolean = false
  @observable private isScrollingByDraggingActive: boolean = false
  @observable private lastDist: number = 0
  @observable private lastCenter: IPosition = null
  @observable private isSingleTouchEvent: boolean = true

  private stage: Stage
  private imageLoadingPromise: Promise<void>

  public componentDidMount(): void {
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keyup', this.onKeyUp)
    document.addEventListener('mouseup', this.onMouseUp)
    this.sitemapImage = null
    this.loadSitemap()
    this.adjustSitemapItems()
  }

  public componentWillUnmount(): void {
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keyup', this.onKeyUp)
    document.removeEventListener('mouseup', this.onMouseUp)
  }

  public componentDidUpdate(oldProps: IProps): void {
    this.clearCanvasCache()

    if (oldProps.sitemapUrl !== this.props.sitemapUrl) {
      this.sitemapImage = null
      this.loadSitemap()
    }

    if (oldProps.isRubberMode !== this.props.isRubberMode) {
      this.props.isRubberMode ? this.loadSitemap() : (this.sitemapImage = null)
    }

    this.applyDefaultZoom(oldProps?.zoom)
    this.adjustSitemapItems()
  }

  public getDataURL = async (): Promise<string> => {
    if (!this.stage) {
      return null
    }
    if (this.imageLoadingPromise) {
      await this.imageLoadingPromise
    }

    if (!this.shouldShowWhiteboard) {
      return this.props.createMapImage?.()
    }

    const layer = this.stage.getStage().findOne(`#${ITEMS_LAYER_ID}`)
    return layer.toDataURL(Object.assign({}, this.config))
  }

  private get shouldShowWhiteboard() {
    return !this.isGlobeMode
  }

  public getItemsDataURL = async (): Promise<string> => {
    if (!this.stage) {
      return null
    }
    if (this.imageLoadingPromise) {
      await this.imageLoadingPromise
    }
    const layer = this.stage.getStage().findOne(`#${ITEMS_LAYER_ID}`).getLayer()
      .children[1]

    return layer.toDataURL(Object.assign({}, this.config))
  }

  public render(): JSX.Element {
    if (!this.sitemapImage && !this.isGlobeMode) {
      return null
    }
    const {
      getEditableItem,
      showOnMenu,
      isEditorMode,
      isRubberMode,
      isDraggingMode,
      opacity,
      mapBoxViewerStore,
      shouldUseFullHeight,
      toggleDeliveriesHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
      toggleAnnouncementsHiddenState,
      isLogisticsView,
      arePermitsHidden,
      areDeliveriesHidden,
      areMonitoringsHidden,
      areAnnouncementsHidden,
      shouldHideControls,
      isCompactMode,
      isDeliveryView,
      projectAddress,
      creatableAttributeType,
      isTextStickerCreationActive,
      areAdditionalMarkersAvailable,
      onWhiteboardClick,
      createAttributeInPosition,
      zoom,
      isDraggable,
      children,
      globe,
      onMapClick,
      onMapDblClick,
      onMouseDown,
      onMouseMove,
      onKeyDown,
      onKeyUp,
      onMouseUp,
    } = this.props

    return (
      <div className="full-width full-height relative base-sitemap">
        {showOnMenu && (
          <div className="absolute show-on-holder brada10 row relative">
            {showOnMenu()}
          </div>
        )}
        <div
          className={classList({
            'delivery-sitemap full-width full-height': true,
            'overflow-hidden': !isEditorMode,
          })}
        >
          <AutoSizer>
            {({ width: containerWidth, height: containerHeight, key }) => (
              <Observer>
                {() => {
                  const { offsetX, offsetY, width, height } =
                    this.getSitemapSizes(containerHeight, containerWidth)

                  const shouldShowMapBox = this.isGlobeMode

                  const hideControls =
                    !isDraggingMode ||
                    shouldUseFullHeight ||
                    shouldHideControls ||
                    !this.isGlobeMode
                  return (
                    <>
                      {/* referencing map */}
                      {this.shouldShowWhiteboard && (
                        <StageComponent
                          width={containerWidth}
                          height={containerHeight}
                          onClick={onWhiteboardClick}
                          onWheel={!zoom && this.handleZooming}
                          onTouchMove={!zoom && this.handleMultiTouch}
                          onTouchEnd={!zoom && this.handleMultiTouchEnd}
                          draggable={isDraggable}
                          ref={this.setStage}
                          dragBoundFunc={this.dragBoundFunc}
                        >
                          <Layer
                            id={ITEMS_LAYER_ID}
                            offsetX={-offsetX}
                            offsetY={-offsetY}
                          >
                            <Image
                              width={width}
                              height={height}
                              image={this.sitemapImage}
                            />
                            <Group>
                              {children &&
                                children({
                                  width,
                                  height,
                                  offsetX: -offsetX,
                                  offsetY: -offsetY,
                                  key,
                                })}
                            </Group>
                          </Layer>
                        </StageComponent>
                      )}
                      {isRubberMode && (
                        <StageComponent
                          width={containerWidth}
                          height={containerHeight}
                        >
                          <Layer
                            id={ITEMS_LAYER_ID}
                            offsetX={-offsetX}
                            offsetY={-offsetY}
                          >
                            <Image
                              width={width}
                              height={height}
                              image={this.sitemapImage}
                            />
                          </Layer>
                        </StageComponent>
                      )}
                      {/* referenced maps */}
                      {shouldShowMapBox && (
                        <div
                          style={{
                            opacity: isRubberMode ? opacity / 100 : 100,
                            position: 'absolute',
                            zIndex: 1,
                            top: 0,
                          }}
                        >
                          <MapBoxViewer
                            globeId={globe?.id}
                            isDeliveryView={isDeliveryView}
                            isFixed={shouldUseFullHeight}
                            isCompactMode={isCompactMode}
                            store={mapBoxViewerStore}
                            creatableAttributeType={creatableAttributeType}
                            isTextStickerCreationActive={
                              isTextStickerCreationActive
                            }
                            viewport={this.viewport}
                            setViewport={this.setViewport}
                            shouldHideControls={hideControls}
                            width={width}
                            height={height}
                            offsetY={-offsetY}
                            offsetX={-offsetX}
                            containerWidth={containerWidth}
                            containerHeight={containerHeight}
                            areAdditionalMarkersAvailable={
                              areAdditionalMarkersAvailable
                            }
                            isRubberMode={isRubberMode}
                            globeLayer={this.renderLayer}
                            toggleAnnouncementsHiddenState={
                              toggleAnnouncementsHiddenState
                            }
                            toggleDeliveriesHiddenState={
                              toggleDeliveriesHiddenState
                            }
                            toggleMonitoringsHiddenState={
                              toggleMonitoringsHiddenState
                            }
                            areMonitoringsHidden={areMonitoringsHidden}
                            togglePermitsHiddenState={togglePermitsHiddenState}
                            isLogisticsView={isLogisticsView}
                            arePermitsHidden={arePermitsHidden}
                            areAnnouncementsHidden={areAnnouncementsHidden}
                            areDeliveriesHidden={areDeliveriesHidden}
                            latitude={projectAddress.center?.lat}
                            longitude={projectAddress.center?.lng}
                            isGlobeMode={this.isGlobeMode}
                            onAddressChanged={
                              mapBoxViewerStore.onAddressChanged
                            }
                            createAttributeInPosition={
                              createAttributeInPosition
                            }
                            onMouseDown={onMouseDown || NOOP}
                            onMouseUp={onMouseUp || NOOP}
                            onMouseMove={
                              onMouseMove || mapBoxViewerStore.onMouseMove
                            }
                            onKeyDown={onKeyDown || NOOP}
                            onKeyUp={onKeyUp || NOOP}
                            onMapClick={
                              onMapClick || mapBoxViewerStore.onMapClick
                            }
                            onMapDblClick={onMapDblClick || NOOP}
                            language={Localization.currentLanguage}
                            sitemapWithBasemapsOnGlobe={
                              mapBoxViewerStore.sitemapWithBasemapsOnGlobe
                            }
                          />
                        </div>
                      )}
                      {getEditableItem &&
                        getEditableItem(width, height, this.stage)}
                    </>
                  )
                }}
              </Observer>
            )}
          </AutoSizer>
        </div>
        {this.renderDraggableArea()}
      </div>
    )
  }

  private dragBoundFunc = pos => {
    const IMAGE_OFFSET_X = 10
    const IMAGE_OFFSET_Y = 10
    const stage = this.stage.getStage()
    const width = stage.attrs.width
    const height = stage.attrs.height
    const scale = this.stage.scaleX()
    const offsetX = this.stage.getStage().children[0].attrs?.offsetX || 0
    const offsetY = this.stage.getStage().children[0].attrs?.offsetY || 0

    const canvasWidth =
      this.stage.getStage().children[0].children[0].attrs?.width
    const canvasHeight =
      this.stage.getStage().children[0].children[0].attrs?.height

    let newX = pos.x
    let newY = pos.y

    if (newX < scale * (offsetX - canvasWidth) + IMAGE_OFFSET_X) {
      newX = scale * (offsetX - canvasWidth) + IMAGE_OFFSET_X
    }
    if (newX > width + offsetX * scale - IMAGE_OFFSET_X) {
      newX = width + offsetX * scale - IMAGE_OFFSET_X
    }
    if (newY < scale * (offsetY - canvasHeight) + IMAGE_OFFSET_Y) {
      newY = scale * (offsetY - canvasHeight) + IMAGE_OFFSET_Y
    }
    if (newY > height + offsetY * scale - IMAGE_OFFSET_Y) {
      newY = height + offsetY * scale - IMAGE_OFFSET_Y
    }

    return {
      x: newX,
      y: newY,
    }
  }

  public get isGlobeMode() {
    return !!this.props.globe
  }

  public get viewport() {
    return this.props.mapBoxViewerStore.viewport
  }

  private renderDraggableArea(): JSX.Element {
    return (
      <div
        className={classList({
          'unclickable-element transparent': !this.isScrollingByDraggingAllowed,
          'absolute grab-area full-width full-height':
            this.isScrollingByDraggingAllowed,
          'grabbing-cursor': this.isScrollingByDraggingActive,
        })}
        onMouseDown={this.onGrabAreaMouseDown}
        onMouseMove={this.onGrabAreaMouseMove}
      />
    )
  }

  private renderLayer = (width: number, height: number): JSX.Element => {
    const { children } = this.props

    return (
      children &&
      children({
        width,
        height,
      })
    )
  }

  private setViewport = (viewport: IGeoPosition): void => {
    this.props.setViewport(viewport)
  }

  private setStage = ref => {
    // Issue with updating stage
    this.stage = ref
  }

  private loadSitemap(): void {
    this.imageLoadingPromise = new Promise(resolve => {
      const image = document.createElement('img')
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = this.props.sitemapUrl
      image.onload = () => {
        this.sitemapImage = image
        resolve()
        this.imageLoadingPromise = null
        this.setZeroPosition()
        this.resize(1)
      }
    })
  }

  private onKeyDown = (event: KeyboardEvent): void => {
    if (this.props.globe) {
      return
    }
    // EditableLabel component triggers this as well but it needs to be allowed to put space
    const target: Element = event.target as Element
    if (target.id === EDITABLE_LABEL_ID) {
      return
    }
    switch (event.keyCode) {
      case SPACE_KEY_CODE:
        this.isScrollingByDraggingAllowed = true
        break
    }
  }

  private onKeyUp = (event: KeyboardEvent): void => {
    if (this.props.globe) {
      return
    }
    switch (event.keyCode) {
      case SPACE_KEY_CODE:
        this.isScrollingByDraggingAllowed = false
        this.isScrollingByDraggingActive = false
        break
    }
  }

  private onMouseUp = (): void => {
    if (this.props.globe) {
      return
    }
    this.isScrollingByDraggingActive = false
  }

  private onGrabAreaMouseDown = (): void => {
    if (this.props.globe) {
      return
    }
    this.isScrollingByDraggingActive = true
  }

  private onGrabAreaMouseMove = (
    event: React.MouseEvent<HTMLDivElement>,
  ): void => {
    if (
      !this.isScrollingByDraggingAllowed ||
      !this.isScrollingByDraggingActive ||
      !this.stage
    ) {
      return
    }
    const stage = this.stage.getStage()
    const scrollLeft = stage.x() + event.movementX
    const scrollTop = stage.y() + event.movementY

    stage.x(scrollLeft)
    stage.y(scrollTop)
    stage.batchDraw()
  }

  private adjustSitemapItems = (): void => {
    if (this.stage) {
      this.resize(this.stage.getStage().scaleX(), null, false, true)
    }
  }

  // canvas zooming handling
  private handleZooming = (event: KonvaEventObject<WheelEvent>): void => {
    event.evt.preventDefault()

    const stage = this.stage.getStage()
    const oldScale = stage.scaleX()
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    }

    const newScale =
      event.evt.deltaY > 0 ? oldScale * ZOOM_SCALE : oldScale / ZOOM_SCALE

    const newPosition = {
      x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    }

    this.resize(newScale, newPosition)
  }

  // reset canvas zooming
  private setZeroPosition(): void {
    if (!this.stage) {
      return
    }
    const stage = this.stage.getStage()
    stage.x(0)
    stage.y(0)
  }

  // canvas multi touch like mobile finger action
  private handleMultiTouch = (event: KonvaEventObject<TouchEvent>): void => {
    event.evt.preventDefault()
    const stage = this.stage.getStage()
    // in order for react konva to work properly with multi touch dragging should be disabled
    stage.stopDrag()

    const { touches } = event.evt

    if (touches[0] && touches[1]) {
      this.isSingleTouchEvent = false
      const point1 = {
        x: touches[0].clientX,
        y: touches[0].clientY,
      }
      const point2 = {
        x: touches[1].clientX,
        y: touches[1].clientY,
      }
      const newCenter = this.getCenter(point1, point2)

      if (!this.lastCenter) {
        this.lastCenter = newCenter
        return
      }

      const dist = this.getDistance(point1, point2)
      if (!this.lastDist) {
        this.lastDist = dist
      }
      const scale = stage.scaleX() * (dist / this.lastDist)
      const offsetX = newCenter.x - this.lastCenter.x
      const offsetY = newCenter.y - this.lastCenter.y

      const pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX(),
      }
      const newPosition = {
        x: newCenter.x - pointTo.x * scale + offsetX,
        y: newCenter.y - pointTo.y * scale + offsetY,
      }

      this.resize(scale, newPosition)

      this.lastDist = dist
      this.lastCenter = newCenter
    } else {
      stage.startDrag()
    }
  }

  private handleMultiTouchEnd = (): void => {
    const { onTouch } = this.props
    this.lastDist = 0
    this.lastCenter = null

    if (this.isSingleTouchEvent && onTouch) {
      onTouch()
    }
    this.isSingleTouchEvent = true
  }

  private getDistance(point1: IPosition, point2: IPosition): number {
    return Math.sqrt(
      Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2),
    )
  }

  private getCenter(point1: IPosition, point2: IPosition): IPosition {
    return {
      x: (point1.x + point2.x) / 2,
      y: (point1.y + point2.y) / 2,
    }
  }

  private clearCanvasCache(): void {
    const stage = this.stage?.getStage()
    if (!stage) {
      return
    }
    stage.clearCache()
    stage.bufferCanvas.pixelRatio = 1
    stage.bufferCanvas.isCache = false
    stage.bufferCanvas.width = 0
    stage.bufferCanvas.height = 0
  }

  private applyDefaultZoom = (oldZoom: number): void => {
    const { zoom } = this.props
    if (!zoom) {
      return
    }

    this.resize(zoom / DEFAULT_ZOOM_VALUE, null, true)

    if (oldZoom !== zoom && zoom === DEFAULT_ZOOM_VALUE) {
      this.setZeroPosition()
    }
  }

  private getSitemapSizes(
    containerHeight: number,
    containerWidth: number,
  ): ISitemapSizes {
    let width = containerWidth
    let height = containerHeight
    const { shouldUseFullHeight, mapBoxViewerStore } = this.props
    if (this.sitemapImage) {
      const { naturalWidth, naturalHeight } = this.sitemapImage
      const imageScale = shouldUseFullHeight
        ? Math.min(1, containerHeight / naturalHeight)
        : Math.min(
            containerHeight / naturalHeight,
            containerWidth / naturalWidth,
          )

      width = naturalWidth * imageScale
      height = naturalHeight * imageScale
    }

    const offsetX = shouldUseFullHeight
      ? (containerWidth - width) / 2
      : Math.max(0, (containerWidth - width) / 2)

    const offsetY = shouldUseFullHeight
      ? (containerHeight - height) / 2
      : Math.max(0, (containerHeight - height) / 2)

    const offsetXImage = Math.max(0, (containerWidth - width) / 2)
    const offsetYImage = Math.max(0, (containerHeight - height) / 2)
    mapBoxViewerStore.width = width
    mapBoxViewerStore.height = height
    mapBoxViewerStore.offsetX = offsetX
    mapBoxViewerStore.offsetY = offsetY

    return {
      offsetX,
      offsetY,
      width,
      height,
      offsetXImage,
      offsetYImage,
    }
  }

  private get config(): IDataUrlConfig {
    const { selectedSitemap } = this.props
    const layer = this.stage.getStage().findOne(`#${ITEMS_LAYER_ID}`)
    let width
    let height
    let y
    let x

    if (selectedSitemap?.isReferenced || !selectedSitemap) {
      const referencedLayer = layer.children[0]

      width = referencedLayer.width()
      height = referencedLayer.height()
      y = referencedLayer.attrs.y
      x = referencedLayer.attrs.x
    } else {
      const scale = this.stage.getStage().scaleX()
      const offsetX = layer.attrs.offsetX
      const offsetY = layer.attrs.offsetY

      width = (layer.width() + offsetX * 2) * scale
      height = (layer.height() + offsetY * 2) * scale
      x = this.stage.getStage().x() - offsetX * scale
      y = this.stage.getStage().y() - offsetY * scale
    }

    return {
      mimeType: SCALED_IMAGE_TYPE,
      quality: SCALED_IMAGE_QUALITY,
      pixelRatio: SCALED_IMAGE_PIXEL_RATE,
      width,
      height,
      x,
      y,
    } as IDataUrlConfig
  }

  private resize(
    newScale: number,
    newPosition?: IPosition,
    shouldOnlyFirstLayer?: boolean,
    shouldIgnoreStage?: boolean,
  ): void {
    if (newScale > MAX_SCALE || newScale < MIN_SCALE || !this.stage) {
      return
    }

    const stage = this.stage.getStage()

    let sitemapItemLayers = stage.getLayers()[0].children[1].children.toArray()

    if (shouldOnlyFirstLayer) {
      sitemapItemLayers = sitemapItemLayers.slice(0, 1)
    }

    if (!shouldIgnoreStage) {
      // resize stage
      stage.scaleX(newScale)
      stage.scaleY(newScale)
    }

    // resize stage items
    sitemapItemLayers.forEach(layer => {
      layer.children
        .toArray()
        .filter(child => !child.attrs.isStaticSize)
        .forEach(child => {
          let baseScale = 1
          if (child.attrs.isClosedIcon) {
            baseScale = CLOSED_ICON_SCALE
          } else if (child.attrs.isSelected) {
            baseScale = SELECTED_ICON_SCALE
          }

          child.scaleY(baseScale / newScale)
          child.scaleX(baseScale / newScale)
        })
    })

    if (newPosition) {
      stage.position(newPosition)
    }
    stage.batchDraw()
  }
}
