import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Dimmer from '~/client/src/shared/components/Dimmer'
import * as Icons from '~/client/src/shared/components/Icons'

import ProjectDateStore from '../../../stores/ui/ProjectDate.store'

import './MonthPicker.scss'

// localization: untranslated (month short names)

interface IProps {
  value: Date
  shown: boolean
  projectDateStore?: ProjectDateStore
  onChange: (value: Date) => void
  onHide: () => void
}

@inject('projectDateStore')
@observer
export default class MonthPicker extends React.Component<IProps> {
  @observable private selectedMonth: Date = this.props.value

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.value !== this.props.value) {
      this.selectedMonth = this.props.value
    }
  }

  public render() {
    if (!this.selectedMonth) {
      return null
    }

    const { shown, projectDateStore } = this.props
    const [year] = projectDateStore.getYearMonthDay(this.selectedMonth)

    return (
      <>
        <Dimmer shown={shown} onClick={this.onHide} />
        <div
          className={classList({
            'monthpicker-container': true,
            shown: shown,
          })}
        >
          <div className="monthpicker-header">
            <Icons.ArrowBack
              onClick={this.onPrevClick}
              className="monthpicker-header-nav-prev"
            />
            <Icons.ArrowForward
              onClick={this.onNextClick}
              className="monthpicker-header-nav-next"
            />
            <div className="monthpicker-header-title text extra-large bold-font light">
              {year}
            </div>
          </div>
          <div className="monthpicker-table">
            {projectDateStore.monthsNamesList.map((value, index) => {
              return (
                <div
                  className="monthpicker-table-cell text large light"
                  key={`monthpicker-${value}`}
                  onClick={this.onMonthsClick.bind(this, index)}
                >
                  {value}
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  }

  @action.bound
  private onPrevClick() {
    this.selectedMonth = this.props.projectDateStore.addYears(
      this.selectedMonth,
      -1,
    )
  }

  @action.bound
  private onNextClick() {
    this.selectedMonth = this.props.projectDateStore.addYears(
      this.selectedMonth,
      1,
    )
  }

  @action.bound
  private onMonthsClick(month: number) {
    this.selectedMonth.setMonth(month)
    this.props.onChange(new Date(this.selectedMonth))
    this.onHide()
  }

  @action.bound
  private onHide() {
    if (this.selectedMonth !== this.props.value) {
      this.selectedMonth = this.props.value
    }
    this.props.onHide()
  }
}
