import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import { HGap } from '~/client/src/shared/components/Layout'
import Flag from '~/client/src/shared/models/Flag'
import Rfi from '~/client/src/shared/models/Rfi'
import ScheduleComment from '~/client/src/shared/models/ScheduleComment'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import {
  isFlagType,
  isRFIType,
  isScheduleCommentType,
} from '~/client/src/shared/types/NotificationTypes'

import BaseNotification from '../../shared/models/Notification'

interface IProps {
  notification: BaseNotification
  flagsStore?: FlagsStore
  rfisStore?: RfisStore
  messagesStore?: MessagesStore
  photosStore?: PhotosStore
  scheduleCommentsStore?: ScheduleCommentsStore
}

@inject(
  'flagsStore',
  'rfisStore',
  'photosStore',
  'messagesStore',
  'scheduleCommentsStore',
)
@observer
export default class NotificationDocuments extends React.Component<IProps> {
  @computed
  private get threadId() {
    const { flagsStore, rfisStore, scheduleCommentsStore, notification } =
      this.props
    const { type, entityId } = notification
    switch (true) {
      case isFlagType(type): {
        const flag = flagsStore.byId.get(entityId) || Flag.none
        return flag && flag.threadId
      }
      case isRFIType(type): {
        const rfi = rfisStore.byId.get(entityId) || Rfi.none
        return rfi && rfi.threadId
      }
      case isScheduleCommentType(type): {
        const sc =
          scheduleCommentsStore.byId.get(entityId) || ScheduleComment.none
        return sc && sc.threadId
      }
      default:
        return null
    }
  }

  @computed
  private get messages() {
    return this.props.messagesStore.list.filter(
      m => m.threadId === this.threadId,
    )
  }

  @computed
  private get documents() {
    const messagesIds = this.messages.map(m => m.id)
    const photos = this.props.photosStore.list.filter(p =>
      messagesIds.includes(p.messageId),
    )
    return photos.slice(0, 2)
  }

  public render() {
    return this.documents.map(photo => (
      <React.Fragment key={photo.id}>
        <img
          src={photo.url}
          style={{
            flex: 0,
            height: '75px',
            minWidth: '120px',
          }}
        />
        <HGap />
      </React.Fragment>
    ))
  }
}
