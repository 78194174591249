import * as React from 'react'

import { classList } from 'react-classlist-helper'

export class HeaderBar extends React.Component<{
  children?: any | void
  className?: string
}> {
  public render() {
    return (
      <div
        className={classList({
          [this.props.className || '']: true,
          'row pa20 bb-light-grey bg-white': true,
        })}
      >
        {this.props.children}
      </div>
    )
  }
}
