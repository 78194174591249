import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Activity from '~/client/src/shared/models/Activity'

import StatusUpdatesStore from '../../../stores/domain/StatusUpdates.store'
import ProjectDateStore from '../../../stores/ui/ProjectDate.store'

interface IProps {
  activity: Activity
  projectDateStore?: ProjectDateStore
  statusUpdatesStore?: StatusUpdatesStore
}

@inject('projectDateStore', 'statusUpdatesStore')
@observer
export default class DatesInfo extends React.Component<IProps> {
  public render() {
    const { activity, projectDateStore, statusUpdatesStore } = this.props
    const { startDate, finishDate, didStart, didFinish } = activity
    const { getMonthAndDayToDisplay } = projectDateStore

    return (
      <div>
        <div
          className={classList({
            'inline-block text large pr5': true,
            'bold actual-date': didStart,
          })}
        >
          {getMonthAndDayToDisplay(startDate)}
        </div>
        –
        <div
          className={classList({
            'inline-block text large pl5': true,
            'bold actual-date': didFinish,
          })}
        >
          {getMonthAndDayToDisplay(
            finishDate(projectDateStore, statusUpdatesStore),
          )}
        </div>
      </div>
    )
  }
}
