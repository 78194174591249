import {
  IScanner,
  IScannerInput,
  ISiteLocation,
  LocationType,
} from '~/client/graph'

import { LocalStorageKey } from '../enums/LocalStorageKey'
import { copyArray, copyObjectArray } from '../utils/util'
import BaseModel from './BaseModel'

export enum ScanCodeTypes {
  company = 'company',
  user = 'user',
  team = 'team',
  project = 'project',
}

export const SCAN_CODE_SEPARATOR = '/code-type/'

export default class Scanner extends BaseModel<IScanner> implements IScanner {
  public static fromDto(dto: IScanner): Scanner {
    return new Scanner(
      dto.id,
      dto.projectId,
      dto.createdAt,
      dto.updatedAt,
      dto.name,
      dto.isDeleted,
      dto.allowedUsers || [],
      dto.associatedCodes || [],
      dto.badgeName,
      dto.maxElapsedTime,
      dto.location,
      dto.isRealLocationRelated,
      dto.locationName,
      dto.isInverted,
      dto.isOpenScanner,
      !!dto.isQueueScanner,
      typeof dto.isTimedScanner === 'undefined' ? true : dto.isTimedScanner,
      typeof dto.isTimedByDay === 'undefined' ? true : dto.isTimedByDay,
      typeof dto.isActive === 'undefined' ? false : dto.isActive,
      !!dto.isQueueActive,
    )
  }

  public id: string
  public projectId: string
  public name: string
  public isDeleted: boolean
  public allowedUsers: string[]
  public associatedCodes: string[]
  public badgeName: string
  public maxElapsedTime: number
  public location: ISiteLocation
  public locationType: LocationType
  public isRealLocationRelated: boolean
  public locationName: string
  public isInverted: boolean
  public isOpenScanner: boolean
  public isQueueScanner: boolean
  public isTimedScanner: boolean
  public isTimedByDay: boolean
  public isActive: boolean
  public isQueueActive: boolean
  public createdAt: number
  public updatedAt: number

  public constructor(
    id: string,
    projectId: string,
    createdAt?: number,
    updatedAt?: number,
    name?: string,
    isDeleted?: boolean,
    allowedUsers?: string[],
    associatedCodes?: string[],
    badgeName?: string,
    maxElapsedTime?: number,
    location?: ISiteLocation,
    isRealLocationRelated?: boolean,
    locationName?: string,
    isInverted?: boolean,
    isOpenScanner?: boolean,
    isQueueScanner?: boolean,
    isTimedScanner?: boolean,
    isTimedByDay?: boolean,
    isActive?: boolean,
    isQueueActive?: boolean,
  ) {
    super(id)

    this.projectId = projectId
    this.name = name
    this.isDeleted = isDeleted
    this.allowedUsers = allowedUsers || []
    this.associatedCodes = associatedCodes || []
    this.badgeName = badgeName
    this.maxElapsedTime = maxElapsedTime
    this.location = location
    this.isRealLocationRelated = isRealLocationRelated
    this.locationName = locationName
    this.isInverted = isInverted
    this.isOpenScanner = isOpenScanner
    this.isTimedScanner = isTimedScanner
    this.isTimedByDay = isTimedByDay
    this.isQueueScanner = isQueueScanner
    this.isActive = isActive
    this.isQueueActive = isQueueActive
    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)
  }

  public get activeScannerStorageKey() {
    return `${LocalStorageKey.ActiveScanner}-${this.id}`
  }

  public get scannedUsersMapStorageKey() {
    return `${LocalStorageKey.ScannedUsers}-${this.id}`
  }

  public copy(): Scanner {
    return new Scanner(
      this.id,
      this.projectId,
      this.createdAt,
      this.updatedAt,
      this.name,
      this.isDeleted,
      copyArray(this.allowedUsers),
      copyObjectArray(this.associatedCodes),
      this.badgeName,
      this.maxElapsedTime,
      this.location,
      this.isRealLocationRelated,
      this.locationName,
      this.isInverted,
      this.isOpenScanner,
      this.isQueueScanner,
      this.isTimedScanner,
      this.isTimedByDay,
      this.isActive,
      this.isQueueActive,
    )
  }

  public getDto(): IScannerInput {
    return {
      id: this.id,
      projectId: this.projectId,
      name: this.name,
      isDeleted: this.isDeleted || false,
      allowedUsers: this.allowedUsers || [],
      associatedCodes: this.associatedCodes || [],
      badgeName: this.badgeName,
      maxElapsedTime: this.maxElapsedTime,
      location: this.location,
      isRealLocationRelated: this.isRealLocationRelated ?? true,
      locationName: this.locationName,
      isInverted: this.isInverted || false,
      isOpenScanner: this.isOpenScanner || false,
      isTimedScanner: this.isTimedScanner || false,
      isQueueScanner: this.isQueueScanner || false,
      isTimedByDay: this.isTimedByDay ?? true,
      isActive: this.isActive || false,
      isQueueActive: this.isQueueActive || false,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }
}
