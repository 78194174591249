import { action, computed, observable } from 'mobx'

import {
  CalendricalType,
  IFormMaterial,
  IInspectionOptions,
  IPermitWithFieldsInput,
  ISiteLocation,
  ISitePermit,
  IThread,
  PermitFieldType,
  SitePermitStatus,
  WorkflowStepType,
} from '~/client/graph'
import User from '~/client/src/shared/models/User'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import Guard from '~/client/src/shared/utils/Guard'

import commonRoutes, {
  UrlParamKey,
  getTransitionPath,
} from '../constants/commonRoutes'
import ProjectDateStore, {
  areIntervalTimesIntersects,
  isAfter,
} from '../stores/ui/ProjectDate.store'
import EventStyle from '../types/EventStyle'
import getPermitFieldsDto from '../utils/getPermitFieldsDto'
import { copyObjectDeep } from '../utils/util'
import BaseModel from './BaseModel'
import IPermitFieldDto from './IPermitFieldDto'
import PermitField from './PermitField'
import PermitType from './PermitType'
import Thread from './Thread'

import Colors from '~/client/src/shared/theme.module.scss'

const userFieldTypes = [
  PermitFieldType.Assignee,
  PermitFieldType.Requester,
  PermitFieldType.Subscriber,
]
const activeStatuses = [SitePermitStatus.Accepted, SitePermitStatus.Active]

const FREQUENCY_DATES_LIMIT = 1000

export default class SitePermit
  extends BaseModel<ISitePermit>
  implements ISitePermit
{
  public static fromDto(dto: ISitePermit) {
    return new SitePermit(
      dto.id,
      dto.typeId,
      dto.isClosure,
      dto.status,
      dto.projectId,
      dto.startDate,
      dto.endDate,
      dto.title,
      dto.currentWorkflowStepId,
      dto.workflowStepLevel,
      dto.isAutoActivationEnabled,
      dto.createdAt,
      dto.updatedAt,
      dto.threadId,
      dto.code,
      dto.specificInspectionOptions,
      dto.fields,
      dto.isDeleted,
      dto.isAllDay,
      dto.isLateRequest,
    )
  }

  public static getDirectLinkToInstance(projectCode: string, id: string) {
    return `${window.location.origin}${getTransitionPath(
      commonRoutes.FORMS,
      projectCode,
    )}?${UrlParamKey.Form}=${id}`
  }

  public id: string
  @observable public typeId: string
  @observable public isClosure: boolean
  @observable public startDate: number
  @observable public endDate: number
  @observable public isAllDay: boolean
  @observable public title: string
  @observable public threadId?: string
  @observable public code: string
  @observable public specificInspectionOptions?: IInspectionOptions
  @observable public fields: PermitField[]
  @observable public isAutoActivationEnabled: boolean
  @observable public isLateRequest?: boolean

  @observable private _status: SitePermitStatus
  @observable private _currentWorkflowStepId: string
  @observable private _workflowStepLevel: number

  public get status(): SitePermitStatus {
    return this._status
  }

  private set internalStatus(value: SitePermitStatus) {
    this._status = value
  }

  public get currentWorkflowStepId(): string {
    return this._currentWorkflowStepId
  }

  private set internalCurrentWorkflowStepId(value: string) {
    this._currentWorkflowStepId = value
  }

  public get workflowStepLevel(): number {
    return this._workflowStepLevel
  }

  private set internalWorkflowStepLevel(value: number) {
    this._workflowStepLevel = value
  }

  @computed
  public get fieldsByIdMap(): { [fieldId: string]: PermitField } {
    return this.fields.reduce((map, field) => {
      map[field.fieldId] = field
      return map
    }, {} as any)
  }

  public get isLate(): boolean {
    return (
      !this.isDeleted &&
      !this.isDoneOrDenied &&
      isAfter(new Date(), this.endDate)
    )
  }

  public get isRequestedOrSubmitted(): boolean {
    return (
      this.status === SitePermitStatus.Requested ||
      this.status === SitePermitStatus.Submitted
    )
  }

  public get isChanged(): boolean {
    return this.status === SitePermitStatus.Changed
  }

  public get isApproved(): boolean {
    return this.status === SitePermitStatus.Accepted
  }

  public get isDone(): boolean {
    return this.status === SitePermitStatus.Done
  }

  public get isDenied(): boolean {
    return this.status === SitePermitStatus.Denied
  }

  public get isDoneOrDenied(): boolean {
    return this.isDone || this.isDenied
  }

  public get shouldShowClosure(): boolean {
    return (
      this.isClosure &&
      !this.isDenied &&
      !this.isRequestedOrSubmitted &&
      !this.isChanged
    )
  }

  public constructor(
    id: string,
    typeId: string,
    isClosure: boolean,
    status: SitePermitStatus,
    public projectId: string,
    startDate: number,
    endDate: number,
    title: string,
    currentWorkflowStepId: string,
    workflowStepLevel: number,
    isAutoActivationEnabled: boolean,
    createdAt: number = 0,
    updatedAt: number = 0,
    threadId?: string,
    code: string = '',
    specificInspectionOptions?: IInspectionOptions,
    fields?: IPermitFieldDto[],
    public isDeleted?: boolean,
    isAllDay?: boolean,
    isLateRequest?: boolean,
  ) {
    super(id)

    this.typeId = typeId
    this.isClosure = isClosure
    this.startDate = startDate
    this.endDate = endDate
    this.title = title
    this.threadId = threadId
    this.code = code
    this.specificInspectionOptions = specificInspectionOptions
    this.isAutoActivationEnabled = isAutoActivationEnabled
    this.isAllDay = !!isAllDay
    this.isLateRequest = isLateRequest

    this.internalStatus = status
    this.internalWorkflowStepLevel = workflowStepLevel
    this.internalCurrentWorkflowStepId = currentWorkflowStepId

    this.fields = fields?.map(field => PermitField.fromDto(field)) || []

    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)

    Guard.requireAll({ projectId, startDate, endDate })
  }

  public setThread(thread: Thread | IThread): void {
    if (!thread) {
      return
    }
    this.threadId = thread.id
  }

  public getCaption = (permitTypesStore: PermitTypesStore): string => {
    const caption = this.code + ': '

    if (this.title) {
      return caption + this.title
    }

    const permitType = permitTypesStore.getPermitTypeById(this.typeId)
    return caption + permitType?.name || ''
  }

  public isActive = (formType: PermitType): boolean => {
    if (!formType) return false

    return formType.hasStartStep
      ? this.status === SitePermitStatus.Active
      : activeStatuses.includes(this.status)
  }

  public getTypeOfPermitType = (permitTypesStore: PermitTypesStore): string => {
    return permitTypesStore.getPermitTypeById(this.typeId)?.type
  }

  public getNameOfPermitType = (permitTypesStore: PermitTypesStore): string => {
    return permitTypesStore.getPermitTypeById(this.typeId)?.name
  }

  public isInspectionPermit = (permitTypesStore: PermitTypesStore): boolean => {
    return permitTypesStore.getPermitTypeById(this.typeId)?.isInspectionType
  }

  public getAllInspectionDates = (
    permitTypesStore: PermitTypesStore,
    projectDateStore: ProjectDateStore,
    endDate?: Date | number,
  ): Date[] => {
    const permitType = permitTypesStore.getPermitTypeById(this.typeId)
    const endDateToUse = endDate || this.endDate

    if (!permitType?.isInspectionType || !this.startDate || !endDateToUse) {
      return []
    }

    const {
      hasWorkingDays,
      isWorkingDay,
      startOfDay,
      addDays,
      addWorkingDays,
    } = projectDateStore

    const frequency = this.getInspectionFrequency(permitType)
    const inspectionFrequencyType = this.getInspectionFrequencyType(permitType)
    const selectedDaysToRepeat = this.getSelectedDaysToRepeat(permitType)
    const isWeeklyInspection = inspectionFrequencyType === CalendricalType.Week

    let nextInspectionDate = startOfDay(this.startDate)
    const dates: Date[] = []

    while (!isAfter(nextInspectionDate, endDateToUse)) {
      if (dates.length > FREQUENCY_DATES_LIMIT) {
        break
      }

      if (
        !isWeeklyInspection &&
        hasWorkingDays &&
        !isWorkingDay(nextInspectionDate)
      ) {
        nextInspectionDate = addDays(nextInspectionDate, 1)
        continue
      }

      if (!isWeeklyInspection) {
        dates.push(nextInspectionDate)
      }

      switch (inspectionFrequencyType) {
        case CalendricalType.Day:
          nextInspectionDate = addWorkingDays(nextInspectionDate, frequency)
          break

        case CalendricalType.Week:
          const inspectionDays = this.getCurrentWeeklyInspectionDates(
            selectedDaysToRepeat,
            projectDateStore,
            endDateToUse,
            nextInspectionDate,
          )

          dates.push(...inspectionDays)

          nextInspectionDate = this.getNextWeeklyInspectionDate(
            frequency,
            selectedDaysToRepeat,
            projectDateStore,
            nextInspectionDate,
          )
          break

        case CalendricalType.Month:
          nextInspectionDate = this.getNextMonthlyInspectionDate(
            frequency,
            nextInspectionDate,
            projectDateStore,
          )
          break

        default:
          return
      }
    }

    return dates
  }

  public getLatestInspectionDate = (
    permitTypesStore: PermitTypesStore,
    projectDateStore: ProjectDateStore,
  ): Date => {
    const permitType = permitTypesStore.getPermitTypeById(this.typeId)

    if (!permitType?.isInspectionType) {
      return null
    }

    const now = new Date()
    const endDateToUse = isAfter(this.endDate, now) ? now : this.endDate

    return this.getAllInspectionDates(
      permitTypesStore,
      projectDateStore,
      endDateToUse,
    ).pop()
  }

  public isAssignedToUser = ({ id: userId }: User): boolean => {
    const ids = this.fields
      .filter(({ type }) => userFieldTypes.includes(type))
      .flatMap(({ values }) => values as string[])

    return ids.includes(userId)
  }

  public copy(): SitePermit {
    const copiedObj = new SitePermit(
      this.id,
      this.typeId,
      this.isClosure,
      this.status,
      this.projectId,
      this.startDate,
      this.endDate,
      this.title,
      this.currentWorkflowStepId,
      this.workflowStepLevel,
      this.isAutoActivationEnabled,
      this.createdAt,
      this.updatedAt,
      this.threadId,
      this.code,
      this.specificInspectionOptions &&
        copyObjectDeep(this.specificInspectionOptions),
      null,
      this.isDeleted,
      this.isAllDay,
      this.isLateRequest,
    )
    copiedObj.fields = this.fields?.map(f => copyObjectDeep(f)) || []

    return copiedObj
  }

  public getCopyForDuplicate(
    status: SitePermitStatus,
    formType: PermitType,
  ): SitePermit {
    const copy = new SitePermit(
      null,
      formType.id,
      this.isClosure,
      status,
      this.projectId,
      this.startDate,
      this.endDate,
      this.title,
      formType.initialStep.id,
      0,
      this.isAutoActivationEnabled,
      null,
      null,
      null,
      null,
      copyObjectDeep(this.specificInspectionOptions),
      null,
      false,
      this.isAllDay,
    )
    copy.fields = this.fields?.map(f => copyObjectDeep(f)) || []

    return copy
  }

  public getDto(): IPermitWithFieldsInput {
    return {
      permit: {
        id: this.id,
        typeId: this.typeId,
        isClosure: this.isClosure,
        status: this.status,
        projectId: this.projectId,
        startDate: this.startDate,
        endDate: this.endDate,
        title: this.title,
        threadId: this.threadId,
        code: this.code,
        specificInspectionOptions: this.specificInspectionOptions,
        currentWorkflowStepId: this.currentWorkflowStepId,
        workflowStepLevel: this.workflowStepLevel,
        isAutoActivationEnabled: this.isAutoActivationEnabled,
        isAllDay: this.isAllDay,
      },
      fields: getPermitFieldsDto(this.fields),
    }
  }

  public getReportDto(): ISitePermit {
    return {
      id: this.id,
      typeId: this.typeId,
      isClosure: this.isClosure,
      status: this.status,
      projectId: this.projectId,
      startDate: this.startDate,
      endDate: this.endDate,
      title: this.title,
      threadId: this.threadId,
      code: this.code,
      specificInspectionOptions: this.specificInspectionOptions,
      currentWorkflowStepId: this.currentWorkflowStepId,
      workflowStepLevel: this.workflowStepLevel,
      isAutoActivationEnabled: this.isAutoActivationEnabled,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      fields: this.fields || [],
      isDeleted: this.isDeleted,
    }
  }

  public get specificInspectionFrequency(): number {
    return this.specificInspectionOptions?.inspectionFrequency || 0
  }

  public get companyIds(): string[] {
    return this.getFieldValuesByType<string>(PermitFieldType.Company)
  }

  public get assigneeIds(): string[] {
    return this.getFieldValuesByType<string>(PermitFieldType.Assignee)
  }

  public get requesterIds(): string[] {
    return this.getFieldValuesByType<string>(PermitFieldType.Requester)
  }

  public get subscriberIds(): string[] {
    return this.getFieldValuesByType<string>(PermitFieldType.Subscriber)
  }

  public get locations(): ISiteLocation[] {
    return this.getFieldValuesByType<ISiteLocation>(PermitFieldType.Location)
  }

  public get equipment(): ISiteLocation[] {
    return this.getFieldValuesByType<ISiteLocation>(PermitFieldType.Equipment)
  }

  public get locationsWithEquipment(): ISiteLocation[] {
    return this.locations.concat(this.equipment)
  }

  public get materials(): IFormMaterial[] {
    return this.getFieldValuesByType<IFormMaterial>(PermitFieldType.Material)
  }

  public get allWorkflowUserIds(): string[] {
    return Array.from(
      new Set<string>(
        this.assigneeIds.concat(this.requesterIds).concat(this.subscriberIds),
      ),
    )
  }

  private getFieldValuesByType<T>(type: PermitFieldType): T[] {
    return this.fields
      .filter(f => type === f.type)
      .flatMap(({ values }) => values || [])
  }

  public getInspectionFrequency = (permitType: PermitType): number => {
    if (!permitType?.isInspectionType) {
      return 0
    }

    return this.specificInspectionFrequency || permitType?.inspectionFrequency
  }

  public getInspectionFrequencyType = (
    permitType: PermitType,
  ): CalendricalType => {
    return (
      this.specificInspectionOptions?.inspectionFrequencyType ||
      permitType?.inspectionOptions?.inspectionFrequencyType
    )
  }

  public getSelectedDaysToRepeat = (permitType: PermitType): number[] => {
    return (
      this.specificInspectionOptions?.selectedDaysToRepeat ||
      permitType?.inspectionOptions?.selectedDaysToRepeat
    )
  }

  public isApprovalStep = (formType: PermitType): boolean => {
    return formType?.workflowSteps.some(
      s =>
        s.id === this.currentWorkflowStepId &&
        s.type === WorkflowStepType.Approval,
    )
  }

  public isBicStep = (formType: PermitType): boolean => {
    return formType?.workflowSteps.some(
      s =>
        s.id === this.currentWorkflowStepId &&
        s.type === WorkflowStepType.BicInspection,
    )
  }

  public isReviewStep = (formType: PermitType): boolean => {
    return formType?.workflowSteps.some(
      s =>
        s.id === this.currentWorkflowStepId &&
        s.type === WorkflowStepType.Review,
    )
  }

  @computed
  public get materialQuantitiesMap(): { [materialId: string]: number } {
    return this.materials.reduce((acc, { materialId, quantity }) => {
      acc[materialId] = (acc[materialId] ?? 0) + Number(quantity || 0)
      return acc
    }, {})
  }

  @computed
  public get procurementQuantitiesMap(): { [procurementId: string]: number } {
    return this.materials.reduce((acc, { procurementId, quantity }) => {
      acc[procurementId] = (acc[procurementId] ?? 0) + Number(quantity || 0)
      return acc
    }, {})
  }

  public getMaterialsByIds = (
    materialId: string,
    procurementId: string,
  ): IFormMaterial[] => {
    return this.materials.filter(
      material =>
        material.materialId === materialId &&
        (!procurementId || material.procurementId === procurementId),
    )
  }

  public getMaterialLocationIds = (
    materialId: string,
    procurementId: string,
  ): string[] => {
    const set = this.getMaterialsByIds(materialId, procurementId).reduce(
      (set, material) =>
        material.locationId ? set.add(material.locationId) : set,
      new Set<string>(),
    )
    return Array.from(set.values())
  }

  public isScheduledWithinRange(
    startDate: Date | number,
    endDate: Date | number,
  ): boolean {
    return areIntervalTimesIntersects(
      { startDate: new Date(this.startDate), endDate: new Date(this.endDate) },
      { startDate: new Date(startDate), endDate: new Date(endDate) },
    )
  }

  private getCurrentWeeklyInspectionDates(
    selectedDaysToRepeat: number[],
    projectDateStore: ProjectDateStore,
    endDateToUse: number | Date,
    inspectionDate: Date,
  ): Date[] {
    const { getWeekDays, getWeekdayNumber, startOfDay } = projectDateStore

    if (!selectedDaysToRepeat?.length) {
      return [inspectionDate]
    }

    return getWeekDays(inspectionDate).filter(
      d =>
        selectedDaysToRepeat?.includes(+getWeekdayNumber(d)) &&
        !isAfter(startOfDay(this.startDate), startOfDay(d)) &&
        !isAfter(d, endDateToUse),
    )
  }

  private getNextWeeklyInspectionDate(
    frequency: number,
    selectedDaysToRepeat: number[],
    projectDateStore: ProjectDateStore,
    inspectionDate: Date,
  ): Date {
    const { startOfWeek, addWeeks } = projectDateStore

    const nextInspectionDate = !selectedDaysToRepeat?.length
      ? inspectionDate
      : startOfWeek(inspectionDate)

    return addWeeks(nextInspectionDate, frequency)
  }

  private getNextMonthlyInspectionDate(
    frequency: number,
    inspectionDate: Date,
    projectDateStore: ProjectDateStore,
  ): Date {
    const { addMonths } = projectDateStore

    return addMonths(inspectionDate, frequency)
  }

  public getEventStyleByColor(color: string = Colors.primary20): EventStyle {
    const style = new EventStyle({
      textColor: Colors.black,
      backgroundColor: Colors.neutral100,
      border: {
        thickness: 0,
        strokeStyle: 'solid',
        color: Colors.neutral100,
      },
    })

    if (this.isRequestedOrSubmitted || this.isChanged) {
      style.withTextColor(color).withBorder(1, 'solid', color)
    } else {
      style
        .withBorder(1, 'solid', color)
        .withBackgroundColor(color)
        .withTextColor(color)
    }

    return style
  }

  // It needs for compatibility of data events on
  // (see DesktopCalendar.store.ts : getEventsByColumn)
  // (see CalendarView.store.ts : getSameBlockEvents)
  public codeToDisplay = (): string => {
    return this.code
  }

  public isRequesterOrAssignee = (userId: string): boolean => {
    return (
      this.requesterIds.includes(userId) || this.assigneeIds.includes(userId)
    )
  }

  @action.bound
  public setStatus(status: SitePermitStatus) {
    this.internalStatus = status
  }

  @action.bound
  public setWorkflowStepId(workflowStepId: string) {
    this.internalCurrentWorkflowStepId = workflowStepId
  }

  @action.bound
  public setWorkflowStepLevel(workflowStepLevel: number) {
    this.internalWorkflowStepLevel = workflowStepLevel
  }
}
