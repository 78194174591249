import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { ISiteLocation } from '~/client/graph'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import Scanner from '~/client/src/shared/models/Scanner'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'

import Localization from '../../../localization/LocalizationManager'
import CommonStore from '../../../stores/ui/Common.store'
import WorkflowCardLocationLabel from '../../WorkflowCard/LocationLabel'
import QrCodesStore from '../QRCodes.store'
import QRCodesEditForm from './QRCodesEditForm/QRCodesEditForm'
import QRCodesViewForm from './QRCodesViewForm/QRCodesViewForm'

interface IProps {
  store: QrCodesStore

  isFullscreenAllowed: boolean

  common?: CommonStore
  scannersStore?: ScannersStore
}

@inject('common', 'scannersStore')
@observer
export default class QRCodesContent extends React.Component<IProps> {
  public render(): JSX.Element {
    const { store, scannersStore, isFullscreenAllowed } = this.props

    if (store.isLoading && !store.isActive) {
      return <Loader />
    }

    if (store.isSelectionModeActive) {
      return (
        <>
          <div className="col relative full-height overflow-hidden">
            {this.renderScannersList(scannersStore.scannersList)}
          </div>
          <div className="row pa15 bt-light-input-border qr-codes-scanner-active">
            <BaseActionButton
              title={Localization.translator.createNewScanner}
              className="primary-theme medium-bold line-extra-large"
              onClick={store.createNewScanner}
              isEnabled
              isGrow
            />
          </div>
        </>
      )
    }

    if (store.isEditModeActive) {
      return <QRCodesEditForm store={store} />
    }

    return (
      <QRCodesViewForm
        store={store}
        isFullscreenAllowed={isFullscreenAllowed}
      />
    )
  }

  private renderScannersList(scanners: Scanner[]): JSX.Element {
    return (
      <div className="col scrollable qr-items-list py12">
        {scanners.map(scanner => this.renderScannerListItem(scanner))}
      </div>
    )
  }

  private renderScannerListItem(scanner: Scanner): JSX.Element {
    const { selectScanner, editScanner, isAdmin } = this.props.store
    const location: ISiteLocation = scanner.isRealLocationRelated &&
      scanner.location && {
        id: scanner.location.id,
        type: scanner.location.type,
      }

    return (
      <div
        key={scanner.id}
        className="col no-grow px16 py8 text large highlighted-hover-light bb-light-input-border"
      >
        <div className="col" onClick={selectScanner.bind(null, scanner)}>
          <div className="row title-row">
            <Icons.GeneralForm
              size={24}
              className="row x-center y-center no-grow mr12"
            />
            <span className="text no-bold extra-large line-24 inline-block vertical-align-text-bottom">
              {scanner.name}
            </span>
            <div
              className={classList({
                'brada2 px4 py2 my2 text large no-bold line-extra-large no-grow':
                  true,
                'bg-orange-light': scanner.isActive,
                'bg-blue-available': !scanner.isActive,
              })}
            >
              {scanner.isActive
                ? Localization.translator.active
                : Localization.translator.available}
            </div>
          </div>
          <span className="pl36 text grey-30 no-bold large line-extra-large inline-block vertical-align-text-bottom">
            {scanner.badgeName}
          </span>
          {location && (
            <div className="pt5 nowrap pl36">
              <WorkflowCardLocationLabel
                locations={[location]}
                isOneColor={false}
                shouldShowAsTag={false}
                shouldNameBeColored={true}
              />
            </div>
          )}
        </div>
        {isAdmin && (
          <div className="row no-grow pl20 text center">
            <span
              className="no-grow py6 px12 text medium-bold large line-extra-large grey-30 pointer"
              onClick={editScanner.bind(null, scanner)}
            >
              {Localization.translator.edit_verb}
            </span>
          </div>
        )}
      </div>
    )
  }
}
