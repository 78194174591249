import { action, computed } from 'mobx'

import { IMutation, IStatusUpdate } from '~/client/graph'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { SAVE_STATUS_UPDATE_AND_SEND_STAT } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'

import BaseStoreWithById from '../baseStores/BaseWithById.store'
import ProjectDateStore, { isWithinRange } from '../ui/ProjectDate.store'

interface ICompaniesStatusUpdateByActivityMap {
  [activityId: string]: StatusUpdate[]
}

interface IStatusMap {
  [activityId: string]: {
    manpower: number
    progress: number
  }
}

export default class StatusUpdatesStore extends BaseStoreWithById<
  StatusUpdate,
  IStatusUpdate
> {
  public constructor(
    private activityFiltersStore: ActivityFiltersStore,
    private eventsStore: EventsStore,
    private activitiesStore: ActivitiesStore,
    private projectDateStore: ProjectDateStore,
  ) {
    super(StatusUpdate)
  }

  public save(item: StatusUpdate): Promise<IStatusUpdate> {
    return new Promise((resolve, reject) => {
      this.eventsStore.dispatch(
        SAVE_STATUS_UPDATE_AND_SEND_STAT,
        item,
        (data: IMutation) => resolve(data.saveStatusUpdate),
        reject,
      )
    })
  }

  public get byId() {
    return this.eventsStore.appState.statusUpdates
  }

  @computed
  public get list(): StatusUpdate[] {
    return Array.from(this.byId.values).sort(
      (a, b) =>
        b.dateFor - a.dateFor ||
        b.updatedAt - a.updatedAt ||
        b.updateVersion - a.updateVersion,
    )
  }

  @computed
  public get allStatusesUpdatesByActivityMap() {
    const map = {}
    this.list.forEach(statusUpdate => {
      const { activityP6Code } = statusUpdate
      map[activityP6Code] = map[activityP6Code] || []
      map[activityP6Code].push(statusUpdate)
    })

    return map
  }

  public doesActivityHaveStatusUpdate(a: Activity): boolean {
    return !!this.allStatusesUpdatesByActivityMap[a.code]
  }

  @computed
  private get lastCompaniesStatusUpdateByActivityMap(): ICompaniesStatusUpdateByActivityMap {
    const map: ICompaniesStatusUpdateByActivityMap = {}

    this.list.forEach(statusUpdate => {
      if (this.isUpdateDisabled(statusUpdate)) {
        return
      }
      const activityId = statusUpdate.activityP6Code
      if (!map[activityId]) {
        map[activityId] = [statusUpdate]
      } else {
        const companyStatusUpdate = map[activityId].find(
          su =>
            su.isContainsCompany(statusUpdate.companyName) || su.isMultiCompany,
        )

        if (!companyStatusUpdate) {
          map[activityId].push(statusUpdate)
        } else if (statusUpdate.isLater(companyStatusUpdate)) {
          const index = map[activityId].indexOf(companyStatusUpdate)
          map[activityId][index] = statusUpdate
        }
      }
    })

    return map
  }

  public receiveStatusUpdates(statusUpdates: IStatusUpdate[]) {
    const updateMap = {}
    statusUpdates?.forEach(dto => {
      const entry = this.updateExistingEntryOrCreate(dto)
      updateMap[entry.id] = entry
    })

    this.clearList()
    this.byId.merge(updateMap)
    this.isDataReceived = true
  }

  public updateStatusUpdates(
    statusUpdates: IStatusUpdate[],
    removedIds: string[],
  ) {
    const updateMap = {}
    statusUpdates.forEach(dto => {
      const entry = this.updateExistingEntryOrCreate(dto)
      updateMap[entry.id] = entry
    })

    this.byId.merge(updateMap)
    removedIds.forEach(id => this.byId.delete(id))
    this.isDataReceived = true
  }

  public updateExistingEntryOrCreate(dto: IStatusUpdate) {
    const existing = this.byId.get(dto.id)
    if (existing) {
      existing.updateFromJson(dto)
      return existing
    }
    return StatusUpdate.fromDto(dto)
  }

  public getLastCompaniesStatusUpdateByActivity(a: Activity): StatusUpdate[] {
    const activityCompanies = a.companies
    const activityUpdates =
      this.lastCompaniesStatusUpdateByActivityMap[a.code] || []

    const isActivityAssigned = !!activityCompanies.length
    if (!isActivityAssigned) {
      return activityUpdates.filter(su => su.isUnassigned)
    }

    if (activityUpdates.some(su => su.isAssigned)) {
      const updatesFromCurrentlyAssignedCompanies = activityUpdates.filter(su =>
        activityCompanies.includes(su.companyName),
      )

      if (updatesFromCurrentlyAssignedCompanies.length) {
        return updatesFromCurrentlyAssignedCompanies
      }

      return activityUpdates
    }

    const unassignedUpdate = activityUpdates.find(su => su.isUnassigned)
    if (!unassignedUpdate) {
      return activityUpdates
    }

    const inheritedStatusUpdates = []

    activityCompanies.forEach(companyName => {
      const statusUpdate = StatusUpdate.createWithDefaults()
      const updatingFields = { companyName, isInherited: true }
      const json = Object.assign({}, unassignedUpdate.asJson, {
        ...updatingFields,
      })
      statusUpdate.updateFromJson(json)

      inheritedStatusUpdates.push(statusUpdate)
    })

    return inheritedStatusUpdates
  }

  public getLastStatusUpdateByActivityAndCompany(
    a: Activity,
    companyName: string,
  ): StatusUpdate {
    const activityStatusUpdates = this.getLastCompaniesStatusUpdateByActivity(a)

    return (
      activityStatusUpdates.find(su => su.isContainsCompany(companyName)) ||
      StatusUpdate.createWithDefaults()
    )
  }

  public getCommonStatusUpdateByActivity(a: Activity) {
    const activityStatusUpdates = this.getLastCompaniesStatusUpdateByActivity(a)

    const multiUpdate = activityStatusUpdates.find(su => su.isMultiCompany)
    if (multiUpdate) {
      return multiUpdate
    }

    const commonStatusUpdate = StatusUpdate.createWithDefaults(
      a.percentComplete,
    )
    if (!activityStatusUpdates.length) {
      return commonStatusUpdate
    }

    const activityCompanies = a.companies
    const countOfCompanies = activityCompanies.length || 1
    const activityStatusUpdatesByCompanies = activityCompanies.map(
      company => this.getAllStatusesUpdatesByActivityAndCompany(a, company)[0],
    )

    const updateForPastDays = this.getUpdateForPastDays(
      activityStatusUpdatesByCompanies,
      countOfCompanies,
    )
    if (updateForPastDays && countOfCompanies > 1) {
      return updateForPastDays
    }

    const inheritedUpdate = activityStatusUpdates.find(su => su.isInherited)
    if (inheritedUpdate) {
      const distributedPercentComplete = Math.round(
        inheritedUpdate.percentComplete / countOfCompanies,
      )
      inheritedUpdate.percentComplete = distributedPercentComplete

      return inheritedUpdate
    }

    const totalPercentComplete = activityStatusUpdates.reduce(
      (total, su) => total + su.percentComplete,
      0,
    )

    const percentComplete = Math.round(totalPercentComplete / countOfCompanies)

    const totalManpower = activityStatusUpdates.reduce(
      (total, su) => total + su.manpower,
      0,
    )

    const lastUpdate = activityStatusUpdates[0]

    commonStatusUpdate.percentComplete = percentComplete
    commonStatusUpdate.manpower = totalManpower
    commonStatusUpdate.dateFor = lastUpdate.dateFor
    commonStatusUpdate.percentCompleteUpdatedAt =
      lastUpdate.percentCompleteUpdatedAt
    commonStatusUpdate.manpowerUpdatedAt = lastUpdate.manpowerUpdatedAt
    commonStatusUpdate.activityP6Code = lastUpdate.activityP6Code

    return commonStatusUpdate
  }

  public doesActivityHaveStatusInSelectedPeriod(
    activity: Activity,
    dateStart: Date,
    dateFinish?: Date,
  ) {
    const { startOfDay, endOfDay } = this.projectDateStore
    const filterStartDate = startOfDay(dateStart).getTime()
    const filterFinishDate = dateFinish
      ? endOfDay(dateFinish).getTime()
      : endOfDay(Date.now()).getTime()
    const activityStatusUpdates = this.getAllStatusesUpdatesByActivity(activity)

    return activityStatusUpdates.some(s =>
      isWithinRange(s.dateFor, filterStartDate, filterFinishDate),
    )
  }

  public doesActivityHaveStatusAfterP6(activity: Activity) {
    const activityStatusUpdates = this.getAllStatusesUpdatesByActivity(
      activity,
    ).sort((s1, s2) => s2.dateFor - s1.dateFor || s2.updatedAt - s1.updatedAt)

    return activityStatusUpdates.findIndex(s => s.isFromP6) >= 1
  }

  public getActivitiesStatusOnDate(date?: Date) {
    const activitiesStatusUpdates: {
      [activityP6Code: string]: StatusUpdate[]
    } = {}
    const filterDateValue = date
      ? this.projectDateStore.endOfDay(date).getTime()
      : Date.now()
    this.list.forEach(statusUpdate => {
      if (
        statusUpdate.dateFor > filterDateValue ||
        this.isUpdateDisabled(statusUpdate)
      ) {
        return
      }
      if (!activitiesStatusUpdates[statusUpdate.activityP6Code]) {
        activitiesStatusUpdates[statusUpdate.activityP6Code] = []
      }
      const activityUpdates =
        activitiesStatusUpdates[statusUpdate.activityP6Code]

      const companyStatusUpdate = activityUpdates.find(
        su =>
          su.isContainsCompany(statusUpdate.companyName) ||
          (su.isMultiCompany && statusUpdate.isMultiCompany),
      )

      if (!companyStatusUpdate) {
        activityUpdates.push(statusUpdate)
      } else if (
        companyStatusUpdate.dateFor < statusUpdate.dateFor ||
        (companyStatusUpdate.dateFor === statusUpdate.dateFor &&
          companyStatusUpdate.updatedAt < statusUpdate.updatedAt)
      ) {
        const index = activityUpdates.indexOf(companyStatusUpdate)
        activityUpdates[index] = statusUpdate
      }
    })
    const statuses: IStatusMap = {}
    Object.keys(activitiesStatusUpdates).forEach(activityId => {
      const companyName =
        this.activityFiltersStore.getActivityLinkedCompany(activityId)?.name

      const activityCompanies = companyName ? [companyName] : []
      const activityUpdates = activitiesStatusUpdates[activityId]
      const companiesLength = activityCompanies.length || 1
      const isActivityAssigned = !!activityCompanies.length

      const unassignedUpdate = activityUpdates.find(su => su.isUnassigned)
      if (!isActivityAssigned && unassignedUpdate) {
        statuses[activityId] = {
          progress: unassignedUpdate.percentComplete,
          manpower: unassignedUpdate.manpower,
        }
        return
      }

      const multiUpdate = activityUpdates.find(su => su.isMultiCompany)
      if (multiUpdate) {
        statuses[activityId] = {
          progress: multiUpdate.percentComplete,
          manpower: multiUpdate.manpower,
        }
        return
      }

      const isThereAssignedUpdate = activityUpdates.some(su => su.isAssigned)
      if (isThereAssignedUpdate) {
        const updatesFromCurrentlyAssignedCompanies = activityUpdates.filter(
          su => activityCompanies.includes(su.companyName),
        )

        const companyUpdates = updatesFromCurrentlyAssignedCompanies.length
          ? updatesFromCurrentlyAssignedCompanies
          : activityUpdates

        const manpower = companyUpdates.reduce(
          (total, su) => total + su.manpower,
          0,
        )
        const totalPercentComplete = companyUpdates.reduce(
          (total, su) => total + su.percentComplete,
          0,
        )
        const progress = Math.round(totalPercentComplete / companiesLength)
        statuses[activityId] = {
          progress,
          manpower,
        }
        return
      }

      if (unassignedUpdate) {
        statuses[activityId] = {
          progress: unassignedUpdate.percentComplete,
          manpower: unassignedUpdate.manpower,
        }
        return
      }
    })

    return statuses
  }

  public getAllStatusesUpdatesByActivity(
    a: Activity,
    isDisabledUpdatesIncluded?: boolean,
  ): StatusUpdate[] {
    if (isDisabledUpdatesIncluded) {
      return this.allStatusesUpdatesByActivityMap[a.code] || []
    }
    return (this.allStatusesUpdatesByActivityMap[a.code] || []).filter(
      su => !this.isUpdateDisabled(su),
    )
  }

  public getAllStatusesUpdatesByActivityAndCompany(
    a: Activity,
    companyName: string,
    isDisabledUpdatesIncluded?: boolean,
  ): StatusUpdate[] {
    const allActivityStatusesUpdates = this.getAllStatusesUpdatesByActivity(
      a,
      isDisabledUpdatesIncluded,
    )

    return allActivityStatusesUpdates.filter(su =>
      su.isContainsCompany(companyName),
    )
  }

  public getCountOfCompaniesUpdatedTodayByActivity(a: Activity): number {
    const activityUpdates = this.getLastCompaniesStatusUpdateByActivity(a)

    const multiUpdate = activityUpdates.find(su => su.isMultiCompany)

    if (!multiUpdate) {
      return activityUpdates
        .filter(su => !su.isInherited)
        .reduce((count, { dateFor }) => {
          return this.projectDateStore.isToday(dateFor) ? ++count : count
        }, 0)
    }

    return multiUpdate.companies.reduce((count, { updatedAt }) => {
      return this.projectDateStore.isToday(updatedAt) ? ++count : count
    }, 0)
  }

  public isAllCompaniesUpdatedTodayByActivity(a: Activity): boolean {
    const companiesCount = a.companies.length

    return this.getCountOfCompaniesUpdatedTodayByActivity(a) === companiesCount
  }

  @action.bound
  public getFinishStatusUpdateByActivityAndCompany(
    a: Activity,
    companyName: string,
    projectDateStore: ProjectDateStore,
  ): StatusUpdate {
    return this.getAllStatusesUpdatesByActivityAndCompany(a, companyName)
      .filter(su =>
        su.didUpdateByCompany(companyName, projectDateStore.isSameDay),
      )
      .find(su => su.isFinished)
  }

  @action.bound
  public getFinishStatusUpdateByActivity(a: Activity): StatusUpdate {
    return (
      this.allStatusesUpdatesByActivityMap[a.code] ||
      [].find(su => !this.isUpdateDisabled(su) && su.isFinished)
    )
  }

  private isUpdateDisabled(statusUpdate: StatusUpdate) {
    const activity = this.activitiesStore.byId.get(statusUpdate.activityP6Code)

    const { startOfDay, endOfDay } = this.projectDateStore
    const isUpdateBeforeActualStart =
      activity?.didStart &&
      statusUpdate.dateFor < startOfDay(activity.actualStartDate).getTime()
    const isUpdateAfterActualFinish =
      activity?.didFinish &&
      statusUpdate.dateFor > endOfDay(activity.actualEndDate).getTime()

    return (
      statusUpdate.isUpdateDeleted ||
      isUpdateBeforeActualStart ||
      isUpdateAfterActualFinish
    )
  }

  private getUpdateForPastDays(
    activityStatusUpdates: StatusUpdate[],
    countOfCompanies: number,
  ) {
    let percentCompleteSum = 0
    activityStatusUpdates.forEach(su => {
      percentCompleteSum += su ? su.percentComplete : 0
    })

    const statusUpdate = StatusUpdate.createWithDefaults()
    const multiPercentComplete = {
      percentComplete: Math.ceil(percentCompleteSum / countOfCompanies),
    }
    return Object.assign(statusUpdate, multiPercentComplete)
  }
}
