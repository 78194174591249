import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ProjectDateStore from '../stores/ui/ProjectDate.store'

// translated

interface IProps {
  time: number
  projectDateStore?: ProjectDateStore

  className?: string
  isTimeMode?: boolean
}

@inject('projectDateStore')
@observer
export default class Timestamp extends React.Component<IProps> {
  public render() {
    const { time, projectDateStore, className = '', isTimeMode } = this.props
    const timeLabel = projectDateStore.getTimeToDisplay(time)
    let dateLabel: string

    switch (true) {
      case isTimeMode:
        dateLabel = null
        break
      case projectDateStore.isToday(time):
        break
      case projectDateStore.isThisYear(time):
        dateLabel = projectDateStore.getMonthAndDayToDisplay(time)
        break
      default:
        dateLabel = projectDateStore.getMonthDayAndYearToDisplay(time)
    }

    const holderClassName = `text center light ${className}`
    return (
      <div className={holderClassName}>
        {dateLabel && (
          <>
            {dateLabel} <br />
          </>
        )}
        <span className="nowrap">{timeLabel}</span>
      </div>
    )
  }
}
