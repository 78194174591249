import { action, computed, observable } from 'mobx'

import { IScannerInput, ISiteLocation } from '~/client/graph'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Scanner from '~/client/src/shared/models/Scanner'
import { QRResponse } from '~/client/src/shared/models/User'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import { LocalStorageKey } from '../../enums/LocalStorageKey'
import IScannerHistoryPair from '../../models/IScannerHistoryPair'
import ScanHistoriesStore from '../../stores/domain/ScanHistories.store'

export enum QRCodesMode {
  SELECT,
  EDIT,
  VIEW,
}

const DEFAULT_MODE = QRCodesMode.VIEW

export default class QrCodesStore {
  @observable public isDeleting: boolean = false
  @observable public isRideModeModalShown: boolean = false
  @observable public isScannerDeleteModalShown: boolean = false
  @observable public isEndConfirmShown: boolean = false
  @observable public isStartSessionShown: boolean = false
  @observable public isOpenScanner: boolean = false
  @observable public isQueueScanner: boolean = false
  @observable public isQueueActive: boolean = false
  @observable public isTimedScanner: boolean = false
  @observable public isTimedByDay: boolean = true
  @observable public isActive: boolean = false
  @observable public name: string = ''
  @observable public maxElapsedTime: number = null
  @observable public locationName: string = ''
  @observable public badgeName: string = ''
  @observable public location: ISiteLocation = undefined

  @observable public response: QRResponse = QRResponse.notActivated
  @observable public isRealLocationRelated: boolean = true
  @observable public newScanner: Scanner = null
  @observable public selectedScanner: Scanner = null
  @observable public isFullScreenMode: boolean = false
  @observable public isFullScreenFocused: boolean = false

  @observable public mode: QRCodesMode = DEFAULT_MODE

  public constructor(
    private eventsStore: EventsStore,
    private scannersStore: ScannersStore,
    private scanHistoriesStore: ScanHistoriesStore,
    private projectMembersStore: ProjectMembersStore,
    private userProjectsStore: UserProjectsStore,
  ) {}

  public setLastUsedScanner(): void {
    if (this.scannersStore.isDataReceived) {
      const scannerId = window.localStorage.getItem(
        `${LocalStorageKey.LastUsedScanner}-${this.eventsStore.appState.activeProject.id}`,
      )
      const scanner = this.scannersStore.getScannerById(scannerId)

      if (scanner) {
        this.selectScanner(scanner)
        return
      }

      if (this.isScanMaster) {
        this.setScannerSelectionMode()
      }
    }
  }

  public get className(): string {
    switch (this.response) {
      case QRResponse.allowed:
        return 'bg-palette-green'
      case QRResponse.notAllowed:
        return 'bg-red'
      default:
        return ''
    }
  }

  @action.bound
  public toggleFullscreenMode(): void {
    this.isFullScreenMode = !this.isFullScreenMode
    this.isFullScreenFocused = false
  }

  @action.bound
  public toggleFullscreenFocused(): void {
    this.isFullScreenFocused = !this.isFullScreenFocused
  }

  public toggleEndRideConfirm = (): void => {
    this.isEndConfirmShown = !this.isEndConfirmShown
  }

  public toggleRideModeModalShown = (): void => {
    this.isRideModeModalShown = !this.isRideModeModalShown
  }

  @action.bound
  public toggleIsOpenScanner(): void {
    this.isOpenScanner = !this.isOpenScanner
  }

  @action.bound
  public toggleIsQueueScanner(): void {
    this.isQueueScanner = !this.isQueueScanner
  }

  @action.bound
  public toggleIsTimedScanner(): void {
    const isTimedScanner = !this.isTimedScanner

    this.isTimedScanner = isTimedScanner
    this.isTimedByDay = isTimedScanner

    if (!isTimedScanner) {
      this.isQueueScanner = false
    }
  }

  @action.bound
  public toggleIsTimedByDay() {
    this.isTimedByDay = !this.isTimedByDay
  }

  public get isSelectionModeActive() {
    return this.mode === QRCodesMode.SELECT
  }

  public get isViewModeActive() {
    return this.mode === QRCodesMode.VIEW
  }

  public get isEditModeActive() {
    return this.mode === QRCodesMode.EDIT
  }

  @action.bound
  public setScannerSelectionMode(): void {
    this.mode = QRCodesMode.SELECT
  }

  @action.bound
  public setScannerEditMode(): void {
    this.mode = QRCodesMode.EDIT
  }

  @action.bound
  public setScannerViewMode(): void {
    this.mode = QRCodesMode.VIEW
  }

  public isUserInScanner = (scanner: Scanner, qRText: string): boolean => {
    return scanner.allowedUsers.some(userId => {
      const userCodes = this.userCodesMap[userId]
      return userCodes?.includes(qRText) ?? false
    })
  }

  public performDelete = async (): Promise<void> => {
    this.isDeleting = true
    await this.scannersStore.delete(
      this.selectedScanner.id,
      this.onCreateCallback,
    )
    this.isScannerDeleteModalShown = false
    this.isDeleting = false
  }

  public toggleDeleteModal = (): void => {
    this.isScannerDeleteModalShown = !this.isScannerDeleteModalShown
  }

  public get isLoading(): boolean {
    const { loading } = this.eventsStore.appState

    return (
      [
        e.LOAD_SCANNERS,
        e.LOAD_AND_LISTEN_TO_SCANNERS,
        e.LOAD_SCAN_HISTORIES,
        e.LOAD_AND_LISTEN_TO_SCAN_HISTORIES,
      ].some(event => loading.get(event)) || !this.scannersStore.isDataReceived
    )
  }

  public get isSaveButtonEnabled(): boolean {
    return !!this.newScanner && !!this.badgeName && !!this.name
  }

  public onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.name = event.target.value
  }

  @action.bound
  public onMaxElapsedTimeChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.maxElapsedTime = event.target.valueAsNumber
  }

  @action.bound
  public initiateViewForm(scanner: Scanner): void {
    this.name = scanner.name
    this.badgeName = scanner.badgeName
    this.maxElapsedTime = scanner.maxElapsedTime
    this.locationName = scanner.locationName
    this.isOpenScanner = scanner.isOpenScanner || false
    this.isQueueScanner = scanner.isQueueScanner || false
    this.isQueueActive = scanner.isQueueActive || false
    this.isTimedScanner = scanner.isTimedScanner || false
    this.isTimedByDay = scanner.isTimedByDay || true
    this.location = scanner.location
    this.isActive = scanner.isActive || false
  }

  @action.bound
  public onBNameChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.badgeName = event.target.value
  }

  @action.bound
  public selectScanner(scanner: Scanner) {
    this.selectedScanner = scanner
    this.isRealLocationRelated = true
    this.mode = QRCodesMode.VIEW
    window.localStorage.setItem(
      `${LocalStorageKey.LastUsedScanner}-${this.eventsStore.appState.activeProject.id}`,
      scanner.id,
    )
  }

  public onScannerLocationToggle = (): void => {
    if (this.newScanner) {
      this.newScanner.isRealLocationRelated =
        !this.newScanner.isRealLocationRelated
      this.isRealLocationRelated = !this.isRealLocationRelated
    } else {
      this.selectedScanner.isRealLocationRelated =
        !this.selectedScanner.isRealLocationRelated
      this.isRealLocationRelated = !this.isRealLocationRelated
    }
  }

  public onScannerPropChange = (propName: string, event): void => {
    if (this.newScanner) {
      this.newScanner[propName] = event.target.value
    } else {
      this.selectedScanner[propName] = event.target.value
    }
  }

  public createNewScanner = (): void => {
    const { id } = this.eventsStore.appState.activeProject
    this.name = null
    this.badgeName = null
    this.maxElapsedTime = null
    this.locationName = null
    this.isOpenScanner = false
    this.isQueueScanner = false
    this.isQueueActive = false
    this.isTimedScanner = false
    this.isTimedByDay = true
    this.isActive = false
    this.location = undefined
    this.newScanner = new Scanner(null, id)
    this.setScannerEditMode()
  }

  @action.bound
  public editScanner(selectedScanner: Scanner): void {
    const { id } = this.eventsStore.appState.activeProject
    this.name = selectedScanner.name
    this.badgeName = selectedScanner.badgeName
    this.maxElapsedTime = selectedScanner.maxElapsedTime
    this.locationName = selectedScanner.locationName
    this.isOpenScanner = selectedScanner.isOpenScanner
    this.isQueueScanner = selectedScanner.isQueueScanner
    this.isQueueActive = selectedScanner.isQueueActive
    this.isTimedScanner = selectedScanner.isTimedScanner
    this.isTimedByDay = selectedScanner.isTimedByDay
    this.location = selectedScanner.location
    this.newScanner = new Scanner(selectedScanner.id, id)
    this.newScanner.allowedUsers = selectedScanner.allowedUsers
    this.newScanner.associatedCodes = selectedScanner.associatedCodes
    this.isActive = false
    this.setScannerEditMode()
  }

  public onLNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.locationName = event.target.value
  }

  public onLocationChange = (locationAttr: LocationBase<any>): void => {
    if (locationAttr) {
      this.location = {
        id: locationAttr.id,
        type: locationAttr.type,
      }
    } else {
      this.location = undefined
    }
  }

  public onCreateCallback = (): void => {
    this.newScanner = null
    this.setScannerSelectionMode()
  }

  public activateScanner = (): void => {
    this.isActive = true
    const scanner: IScannerInput = {
      ...this.scanner.getDto(),
      isActive: true,
    }
    this.scannersStore.save(scanner)
  }

  @action.bound
  public startQueue(): void {
    this.isQueueActive = true
    this.isActive = true

    const scanner: IScannerInput = {
      ...this.scanner.getDto(),
      isQueueActive: true,
      isActive: true,
    }
    this.scannersStore.save(scanner)
  }

  @action.bound
  public toggleStartSessionShown(): void {
    this.isStartSessionShown = !this.isStartSessionShown
  }

  @action.bound
  public startSessionFromQueue(): void {
    if (this.isStartSessionShown) this.toggleStartSessionShown()

    this.isQueueActive = false
    this.isActive = true

    const scanner: IScannerInput = {
      ...this.scanner.getDto(),
      isQueueActive: false,
      isActive: true,
    }
    this.scannersStore.save(scanner)
  }

  public deactivateScanner = (): void => {
    this.isActive = false
    this.isQueueActive = false
    const scanner: IScannerInput = {
      ...this.scanner.getDto(),
      isActive: false,
      isQueueActive: false,
    }
    this.scannersStore.save(scanner)
  }

  public onSaveClick = (): void => {
    const scanner: IScannerInput = {
      ...this.newScanner.getDto(),
      name: this.name,
      locationName: this.locationName,
      badgeName: this.badgeName,
      maxElapsedTime: this.maxElapsedTime,
      location: this.location,
      isOpenScanner: this.isOpenScanner,
      isQueueScanner: this.isQueueScanner,
      isQueueActive: this.isQueueActive,
      isTimedScanner: this.isTimedScanner,
      isTimedByDay: this.isTimedByDay,
      isActive: false,
    }
    this.scannersStore.save(scanner, this.onCreateCallback)
  }

  public get scanner(): Scanner {
    return this.selectedScanner
  }

  @computed
  private get userCodesMap(): { [userId: string]: string[] } {
    return this.projectMembersStore.list.reduce((map, user) => {
      const userProject = this.userProjectsStore.getByUser(user)
      const codes = userProject.associatedCodes?.map(code => code.id) || []
      map[user.id] = [user.id, ...codes]
      return map
    }, {})
  }

  @computed
  public get historiesBySelectedScannerPairs(): IScannerHistoryPair[] {
    const { historiesByScanners } = this.scanHistoriesStore

    const histories = (historiesByScanners[this.scanner?.id] || []).sort(
      (a, b) => b.startDate - a.startDate,
    )
    return histories.map(history => {
      const scanner = this.scannersStore.getScannerById(history.scannerId)
      return {
        id: scanner ? scanner.id + history.id : history.id,
        history,
        scanner,
      }
    })
  }

  public get isScanMaster(): boolean {
    const { userActiveProjectSettings } = this.eventsStore.appState

    return (
      userActiveProjectSettings?.isAdmin ||
      userActiveProjectSettings?.isScanMaster
    )
  }

  public get isAdmin(): boolean {
    const { userActiveProjectSettings } = this.eventsStore.appState

    return userActiveProjectSettings?.isAdmin
  }
}
