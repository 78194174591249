import { action, computed, observable } from 'mobx'

import {
  DefaultCompanyType,
  ICompany,
  IEntityTag,
  IMergeResult,
  IMutation,
  IStruxHubCompany,
} from '~/client/graph'
import {
  StruxHubCompaniesDocument,
  StruxHubCompanyDocument,
  UnlinkStruxHubCompanyDocument,
} from '~/client/graph/operations/generated/Companies.generated'
import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import Company from '~/client/src/shared/models/Company'
import Tag, { ITag } from '~/client/src/shared/models/Tag'
import User from '~/client/src/shared/models/User'
import {
  ACTIVATE_COMPANIES,
  DELETE_COMPANIES,
  LINK_STRUXHUB_COMPANY,
  LOAD_AND_LISTEN_TO_COMPANIES,
  LOAD_AND_LISTEN_TO_COMPANY_TYPE_TAGS,
  MERGE_COMPANIES,
  SAVE_COMPANIES,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import getCompanyTypeTranslate from '~/client/src/shared/utils/getCompanyTypeTranslate'

import Localization from '../../localization/LocalizationManager'
import ICompanyTypeTag from '../../models/ICompanyTagType'
import { mapFiltered } from '../../utils/util'
import { IBaseTagsStore } from '../BaseTags.store'
import EventsStore from '../EventStore/Events.store'
import GraphExecutorStore from './GraphExecutor.store'

import Colors from '~/client/src/shared/theme.module.scss'

interface ICompanyOption {
  name: string
  value: string
  disabled?: boolean
}

const noCompany = 'No company'

const defaultCompanyTypeTag = DefaultCompanyType.SubContractor

export default class CompaniesStore implements IBaseTagsStore {
  public companies = observable(new Map<string, Company>())
  public companyTypeTags = observable(new Map<string, ICompanyTypeTag>())

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {}

  public getCompanyById = (
    companyId: string,
    skipDeleted: boolean = false,
  ): Company => {
    const company = this.companies.get(companyId)

    if (!company) {
      return null
    }

    return skipDeleted && company.isDeleted ? null : company
  }

  public getCompanyTypeTagsByIds = (typeTags: string[]): ICompanyTypeTag[] => {
    return typeTags.map(typeTag => this.companyTypeTags.get(typeTag))
  }

  public isVendorOnlyCompany = (companyId: string) => {
    const company = this.getCompanyById(companyId)

    if (company.typeTags.length !== 1) {
      return false
    }

    const vendorTagId = this.getCompanyTypeTagByType(
      DefaultCompanyType.Vendor,
    ).id

    return company.typeTags[0] === vendorTagId
  }

  public getCompanyFullName = (
    companyId: string,
    skipDeleted: boolean = false,
  ): string => {
    const company = this.getCompanyById(companyId, skipDeleted)

    if (!company) {
      return null
    }

    const companyTagTypes = this.getCompanyTypeTagsByIds(company.typeTags)

    return company?.name
      ? `${company.name} (${companyTagTypes
          .map(tagType => getCompanyTypeTranslate(tagType.value))
          .join(',')})`
      : noCompany
  }

  public getCompanyNameById = (
    companyId: string,
    placeholder?: string,
  ): string => {
    // placeholder || User.getDefaultCompanyName() is not used
    // because a caller may want to use empty string as default name
    const defaultName =
      placeholder === undefined ? User.getDefaultCompanyName() : placeholder
    const company = this.getCompanyById(companyId)

    if (!company) return defaultName

    return company.isDeleted
      ? this.getDeactivatedCompanyName(company.name)
      : company.name
  }

  public getCompaniesAsOptions(initValue?: string): ICompanyOption[] {
    const company = this.getCompanyById(initValue)
    return company?.isDeleted
      ? [
          { value: company.id, name: company.name, disabled: true },
          ...this.companiesAsOptions,
        ]
      : this.companiesAsOptions
  }

  public getAvailableCompsWithPrevSelectedOnes = (
    ids: string[] = [],
  ): Company[] => {
    const deletedCompanies = mapFiltered(
      ids,
      id => this.getCompanyById(id),
      c => c?.isDeleted,
    )

    return [...deletedCompanies, ...this.availableSortedCompanies]
  }

  @computed
  private get companiesAsOptions(): ICompanyOption[] {
    const defaultOption = {
      value: '',
      name: User.getDefaultCompanyName(),
    }

    return [
      defaultOption,
      ...this.availableSortedCompanies.map(({ id, name }) => ({
        value: id,
        name,
      })),
    ]
  }

  @computed
  public get allCompaniesIds(): string[] {
    return this.allCompanies.map(({ id }) => id)
  }

  @computed
  private get tagByCompanyIdMap(): { [key: string]: Tag } {
    return this.availableSortedCompanies.reduce((acc, company) => {
      acc[company.id] = this.getCompanyTag(company)
      return acc
    }, {})
  }

  @computed
  public get availableCompaniesAsTags(): Tag[] {
    return Object.values(this.tagByCompanyIdMap)
  }

  // used in TagsStore
  public getInstanceById = (instanceId: string): ITag => {
    const tag = this.tagByCompanyIdMap[instanceId]

    if (tag) {
      return tag
    }

    // for deleted instance
    const company = this.getCompanyById(instanceId)
    return company ? this.getCompanyTag(company) : null
  }

  public receiveTypeTagsList(dtos: Array<IEntityTag>) {
    this.clearList()

    dtos.forEach(dto => {
      this.companyTypeTags.set(dto.id, dto)
    })
  }

  public receiveOneTypeTag(id: string, dto: IEntityTag) {
    if (dto) {
      this.companyTypeTags.set(id, dto)
    }
  }

  public receiveList(dtos: ICompany[]) {
    this.clearList()

    dtos.forEach(dto => {
      const company = Company.fromDto(dto)
      this.companies.set(dto.id, company)
    })
  }

  public receiveOne(id: string, dto: ICompany) {
    if (dto) {
      this.companies.set(id, Company.fromDto(dto))
    }
  }

  public clearList() {
    this.companies.clear()
  }

  @computed
  public get allCompanies(): Company[] {
    return [...this.companies.values()].sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    )
  }

  @computed
  public get allCompanyTypeTags(): ICompanyTypeTag[] {
    return [...this.companyTypeTags.values()].sort((a, b) =>
      a.value.toLowerCase().localeCompare(b.value.toLowerCase()),
    )
  }

  @computed
  public get availableSortedCompanies(): Company[] {
    return this.allCompanies.filter(c => !c.isDeleted)
  }

  @computed
  public get deactivatedCompanies(): Company[] {
    return this.allCompanies.filter(c => c.isDeleted)
  }

  public get isDataReceived(): boolean {
    return !(
      this.appState.loading.get(LOAD_AND_LISTEN_TO_COMPANIES) ||
      this.appState.loading.get(LOAD_AND_LISTEN_TO_COMPANY_TYPE_TAGS)
    )
  }

  public getCompanyTypeTagByType(
    type: DefaultCompanyType | string,
  ): ICompanyTypeTag {
    return this.allCompanyTypeTags.find(tag => tag.value === type)
  }

  public get defaultCompanyTypeTagId() {
    return this.getCompanyTypeTagByType(defaultCompanyTypeTag).id
  }

  @action.bound
  public save(tag: ITag, callback?: (id: string) => void) {
    const company = tag.id
      ? Object.assign(this.getCompanyById(tag.id), { name: tag.name })
      : new Company(null, tag.name, this.appState.activeProject.id, [
          this.defaultCompanyTypeTagId,
        ])

    this.saveMany([company]).then(([c]) => callback(c.id))
  }

  @action.bound
  public async addVendorTagToCompany(
    id: string,
    callback?: (id: string) => void,
  ) {
    const vendorTagId = this.getCompanyTypeTagByType(
      DefaultCompanyType.Vendor,
    ).id
    const company = this.getCompanyById(id)
    if (company.typeTags.includes(vendorTagId)) {
      return
    }
    company.typeTags = [...company.typeTags, vendorTagId]
    const [c] = await this.saveMany([company])
    callback(c.id)
  }

  @action.bound
  public async addVendorCompanyByName(
    name: string,
    callback?: (id: string) => void,
  ) {
    const vendorTagId = this.getCompanyTypeTagByType(
      DefaultCompanyType.Vendor,
    ).id

    const company = new Company(null, name, this.appState.activeProject.id)
    company.typeTags = [vendorTagId]
    const [c] = await this.saveMany([company])
    callback(c.id)
  }

  @action.bound
  public saveMany(companies: ICompany[]): Promise<ICompany[]> {
    return new Promise((resolve, reject) =>
      this.eventsStore.dispatch(SAVE_COMPANIES, companies, resolve, reject),
    )
  }

  @action.bound
  public async delete(companyId: string, callback?: () => void) {
    await this.deleteMany([companyId])
    callback?.()
  }

  @action.bound
  public async deleteMany(companyIds: string[]) {
    const { softDeleteManyCompanies: isOk } = await new Promise<IMutation>(
      (res, rej) =>
        this.eventsStore.dispatch(DELETE_COMPANIES, companyIds, res, rej),
    )

    if (!isOk) {
      throw new Error()
    }
  }

  @action.bound
  public async activateMany(companyIds: string[]) {
    const { recoverManyCompanies: isOk } = await new Promise<IMutation>(
      (res, rej) =>
        this.eventsStore.dispatch(ACTIVATE_COMPANIES, companyIds, res, rej),
    )

    if (!isOk) {
      throw new Error()
    }
  }

  @action.bound
  public async mergeMany(
    companyIds: string[],
    targetCompany: ICompany,
  ): Promise<IMergeResult> {
    const { mergeCompanies: mergeResult } = await new Promise<IMutation>(
      (res, rej) =>
        this.eventsStore.dispatch(
          MERGE_COMPANIES,
          companyIds,
          targetCompany,
          res,
          rej,
        ),
    )

    if (!mergeResult) throw new Error()

    return mergeResult
  }

  @action.bound
  public async linkStruxHubCompany(
    projectCompanyId: string,
    struxHubCompanyId: string,
  ) {
    const { linkStruxHubCompany: company } = await new Promise<IMutation>(
      (res, rej) =>
        this.eventsStore.dispatch(
          LINK_STRUXHUB_COMPANY,
          projectCompanyId,
          struxHubCompanyId,
          res,
          rej,
        ),
    )

    if (!company) {
      throw new Error()
    }
  }

  @action.bound
  public unlinkStruxHubCompany(projectCompanyId: string) {
    return this.graphExecutorStore.mutate(UnlinkStruxHubCompanyDocument, {
      companyId: projectCompanyId,
    })
  }

  public async fetchAllSTXCompanies(): Promise<IStruxHubCompany[]> {
    const { data } = await this.graphExecutorStore.query(
      StruxHubCompaniesDocument,
      {},
    )

    return data?.struxHubCompanies.data || []
  }

  public async fetchSTXCompanyById(
    struxHubCompanyId: string,
  ): Promise<IStruxHubCompany> {
    const { data } = await this.graphExecutorStore.query(
      StruxHubCompanyDocument,
      { struxHubCompanyId },
    )

    return data?.struxHubCompany
  }

  private getDeactivatedCompanyName(name: string): string {
    return `[${Localization.translator.deactivated.toUpperCase()}] ${name}`
  }

  private getCompanyTag({ id, name, isDeleted }: ICompany): Tag {
    const tagName = isDeleted ? this.getDeactivatedCompanyName(name) : name

    return new Tag(
      id,
      tagName,
      isDeleted ? Colors.neutral84 : Colors.neutral70,
      TagIconType.Company,
      TagType.Company,
    )
  }

  private get appState() {
    return this.eventsStore.appState
  }
}
