import {
  DeliveryStatus,
  LocationType,
  OperationSubjectType,
  SitePermitStatus,
} from '~/client/graph'
import { RoundBracket, SquareBracket } from '~/client/src/shared/enums/Brackets'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import {
  AndOrOperator,
  andOrOperators,
} from '~/client/src/shared/models/LogicOperation'
import Tag from '~/client/src/shared/models/Tag'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { enumToList } from '~/client/src/shared/utils/converters'

import { getDeliveryStatusAsTag } from '../../constants/deliveryStatusesTags'
import { getFormsStatusAsTag } from '../../constants/formStatusesTags'
import { TagType } from '../../enums/TagType'
import { TAG_REG_EXP } from '../regExpPatterns'
import ExpressionElement from './ExpressionElement'
import Pointer from './components/Pointer'

const SEPARATOR = ' '
const TAG_START_SYMBOL = SquareBracket.LEFT

const NOT = '!'

export const locationTypesList: TagType[] = enumToList(LocationType)
export const companyTypeList: TagType[] = [TagType.Company]

export default class ExpressionParser {
  public static isTagElement(element: ExpressionElement): boolean {
    return element instanceof Tag || element instanceof LocationBase
  }

  public static isPointerElement(element: ExpressionElement): boolean {
    return element instanceof Pointer
  }

  public static isLogicalPointerElement(element: ExpressionElement): boolean {
    return this.isPointerElement(element) && (element as Pointer).isLogical
  }

  public static isTagPointerElement(element: ExpressionElement): boolean {
    return this.isPointerElement(element) && !(element as Pointer).isLogical
  }

  public static isActivePointerElement(element: ExpressionElement): boolean {
    return this.isPointerElement(element) && (element as Pointer).isActive
  }

  public static isLogicalOperationElement(element: ExpressionElement): boolean {
    return (
      typeof element === 'string' &&
      andOrOperators.includes(element as AndOrOperator)
    )
  }

  public static isElementsChainValid(elements: ExpressionElement[]): boolean {
    const chain = elements.filter(
      el => el && !ExpressionParser.isPointerElement(el),
    )

    let isValid = true
    let prevElement: any = null

    for (let i = 0; i < chain.length; i++) {
      const element = chain[i]
      const isLastElement = i === chain.length - 1

      if (!prevElement) {
        prevElement = element
        continue
      }

      switch (true) {
        case ExpressionParser.isTagElement(element):
          isValid =
            ExpressionParser.isLogicalOperationElement(prevElement) ||
            prevElement === RoundBracket.LEFT
          break

        case ExpressionParser.isLogicalOperationElement(element):
          isValid =
            !isLastElement &&
            (ExpressionParser.isTagElement(prevElement) ||
              prevElement === RoundBracket.RIGHT)
          break

        case element === RoundBracket.RIGHT:
          isValid =
            ExpressionParser.isTagElement(prevElement) ||
            prevElement === RoundBracket.RIGHT
          break

        case element === RoundBracket.LEFT:
          isValid = ExpressionParser.isLogicalOperationElement(prevElement)
      }

      if (!isValid) {
        break
      }

      prevElement = element
    }

    return isValid
  }

  public static fromElementsToStr(elements: ExpressionElement[]): string {
    let rule = ''

    elements.forEach(element => {
      if (ExpressionParser.isPointerElement(element)) {
        return
      }

      if (typeof element === 'string') {
        rule += `${element} `
      } else {
        const tag = element as Tag
        rule += `[${tag.type}][${tag.id}] `
      }
    })

    return rule.trim()
  }

  public static convertExpressionToArray(expression: string): string[] {
    return expression.split(SEPARATOR)
  }

  public static getElementsInBrackets(elements: any[]): any[] {
    const startIndex = elements.findIndex(el => el === RoundBracket.LEFT)
    const endIndex = elements.findIndex(el => el === RoundBracket.RIGHT)

    if (startIndex === -1 || endIndex === -1) {
      return []
    }

    return elements.slice(startIndex + 1, endIndex)
  }

  public static isTagInTypeList(
    tagString: string,
    tagTypesList: string[],
  ): boolean {
    const [tagType] = tagString.match(TAG_REG_EXP)
    return !!tagType && tagTypesList.includes(tagType)
  }

  public static getTagStringsFromBrackets(
    expression: string,
    tagTypes: TagType[],
  ): string[] {
    const expressionAsArray =
      ExpressionParser.convertExpressionToArray(expression)

    const elementsWithTags: string[] =
      ExpressionParser.getElementsInBrackets(expressionAsArray)

    if (!elementsWithTags?.length) {
      return []
    }

    const onlyTagsArray = elementsWithTags.filter(
      el => !!el.startsWith(SquareBracket.LEFT),
    )

    const areTagsMatchType = onlyTagsArray.every(el =>
      ExpressionParser.isTagInTypeList(el, tagTypes),
    )

    return areTagsMatchType ? onlyTagsArray : []
  }

  public static isLocationTagValidForBicRule(
    locationTagId: string,
    locationTagType: string,
    ruleExpression: string,
  ): boolean {
    const selectedTags = ExpressionParser.getTagStringsFromBrackets(
      ruleExpression,
      locationTypesList,
    )

    const hasNotOperator =
      ExpressionParser.isExpressionHasNotOperator(ruleExpression)

    return ExpressionParser.isTagValidForBicRule(
      locationTagId,
      locationTagType,
      selectedTags,
      hasNotOperator,
    )
  }

  public static isCompanyTagValidForBicRule(
    tagId: string,
    ruleExpression: string,
  ): boolean {
    const selectedTags = ExpressionParser.getTagStringsFromBrackets(
      ruleExpression,
      companyTypeList,
    )

    let hasNotOperator = false

    if (selectedTags.length) {
      hasNotOperator =
        ExpressionParser.isExpressionHasNotOperator(ruleExpression)

      return ExpressionParser.isTagValidForBicRule(
        tagId,
        TagType.Company,
        selectedTags,
        hasNotOperator,
      )
    }

    const rightBracketIndex = ruleExpression.indexOf(RoundBracket.RIGHT)

    if (rightBracketIndex === -1) {
      return true
    }

    const slicedExpression = ruleExpression.slice(rightBracketIndex + 1)

    const companyTags = ExpressionParser.getTagStringsFromBrackets(
      slicedExpression,
      companyTypeList,
    )

    hasNotOperator =
      ExpressionParser.isExpressionHasNotOperator(slicedExpression)

    return ExpressionParser.isTagValidForBicRule(
      tagId,
      TagType.Company,
      companyTags,
      hasNotOperator,
    )
  }

  public static isTagValidForBicRule(
    tagIdToCheck: string,
    tagTypeToCheck: string,
    selectedTagsFromExpression: string[],
    hasNotOperator: boolean,
  ): boolean {
    if (!selectedTagsFromExpression?.length) {
      return true
    }

    const isTagValid = selectedTagsFromExpression.some(tagString => {
      const [tagType, tagValue] = tagString.match(TAG_REG_EXP)

      return tagType === tagTypeToCheck && tagValue === tagIdToCheck
    })

    return hasNotOperator ? !isTagValid : isTagValid
  }

  private static shouldInsertPointer(elements: any[], index: number): boolean {
    return (
      index === elements.length - 1 ||
      elements[index + 1] === RoundBracket.RIGHT
    )
  }

  private static isExpressionHasNotOperator(ruleExpression: string): boolean {
    const indexOfBracket = ruleExpression.indexOf(RoundBracket.LEFT)
    const indexOfNotOperator = indexOfBracket - 2

    if (indexOfBracket < 0 || indexOfNotOperator < 0) {
      return false
    }

    return ruleExpression[indexOfNotOperator] === NOT
  }

  public constructor(private readonly tagsStore: TagsStore) {}

  public fromStrToElements(
    expression: string,
    shouldNotUsePointer?: boolean,
    subject?: OperationSubjectType,
  ): ExpressionElement[] {
    if (!expression) {
      return shouldNotUsePointer ? [] : [new Pointer()]
    }

    const elements = ExpressionParser.convertExpressionToArray(expression)

    return elements.reduce((acc, element, i) => {
      const el = element.includes(TAG_START_SYMBOL)
        ? this.retrieveTag(element, subject)
        : element

      if (shouldNotUsePointer) {
        return [...acc, el]
      }

      return ExpressionParser.shouldInsertPointer(elements, i)
        ? [...acc, el, new Pointer(true)]
        : [...acc, el]
    }, [])
  }

  private getEntityStatusAsTag(
    tagValue: string,
    subject: OperationSubjectType,
  ): Tag {
    switch (subject) {
      case OperationSubjectType.Delivery:
        return getDeliveryStatusAsTag(tagValue as DeliveryStatus) as Tag
      case OperationSubjectType.Permit:
        return getFormsStatusAsTag(tagValue as SitePermitStatus) as Tag
      default:
        return null
    }
  }

  private retrieveTag(tagString: string, subject?: OperationSubjectType): Tag {
    const [tagType, tagValue] = tagString.match(TAG_REG_EXP)

    if (tagType === TagType.Status && subject) {
      return this.getEntityStatusAsTag(tagValue, subject)
    }

    const tagStore = this.tagsStore.tagStoreByTagTypeMap[tagType]

    if (!tagStore) {
      return Tag.getUnknown(tagValue, tagType)
    }

    return (
      tagStore.getInstanceById(tagValue) || Tag.getUnknown(tagValue, tagType)
    )
  }
}
