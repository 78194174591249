import { observable } from 'mobx'

import { DeliveryFilterType } from '~/client/graph'
import capitalizeText from '~/client/src/shared/utils/capitalizeText'

import FieldIds from '../../enums/DeliveryFieldIds'
import DeliveryGroupingOption from '../../enums/DeliveryGroupingOption'
import LogisticsGroupingOption from '../../enums/LogisticsGroupingOption'
import MaterialsGroupingOption from '../../enums/MaterialsGroupingOption'
import Localization from '../../localization/LocalizationManager'

export default class FilterInfo {
  @observable public caption: string = '' // custom name for ui display

  public readonly name: string = ''
  public readonly isRemovable: boolean

  public constructor(name: string, isRemovable?: boolean, caption?: string) {
    this.name = name
    this.caption = caption
    this.isRemovable = isRemovable || false
  }

  public getCaption = () => {
    return this.caption || this.getKnownCaption() || capitalizeText(this.name)
  }

  public getKnownCaption() {
    switch (this.name) {
      case DeliveryFilterType.Zone:
      case DeliveryGroupingOption[FieldIds.ZONE]:
      case MaterialsGroupingOption.ZONE:
        return Localization.translator.zone
      case DeliveryFilterType.Route:
      case DeliveryGroupingOption[FieldIds.ROUTE]:
      case MaterialsGroupingOption.ROUTE:
        return Localization.translator.route
      case DeliveryFilterType.Building:
      case DeliveryGroupingOption[FieldIds.BUILDING]:
      case MaterialsGroupingOption.BUILDING:
        return Localization.translator.building
      case DeliveryFilterType.Company:
      case DeliveryGroupingOption[FieldIds.COMPANY]:
        return Localization.translator.requesterCompany
      case DeliveryFilterType.Status:
      case DeliveryGroupingOption[FieldIds.STATUS]:
      case LogisticsGroupingOption[LogisticsGroupingOption.STATUS]:
      case MaterialsGroupingOption.STATUS:
        return Localization.translator.status
      case LogisticsGroupingOption[LogisticsGroupingOption.LBS]:
        return Localization.translator.lbs
      case DeliveryFilterType.Gate:
      case DeliveryGroupingOption[FieldIds.GATE]:
      case MaterialsGroupingOption.GATE:
        return Localization.translator.gate
      case DeliveryFilterType.Equipment:
      case DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]:
      case MaterialsGroupingOption.EQUIPMENT:
      case LogisticsGroupingOption[LogisticsGroupingOption.EQUIPMENT]:
        return Localization.translator.equipment
      case DeliveryFilterType.Level:
      case DeliveryGroupingOption[FieldIds.LEVEL]:
      case MaterialsGroupingOption.LEVEL:
        return Localization.translator.level
      case DeliveryFilterType.Area:
      case DeliveryGroupingOption[FieldIds.AREA]:
      case MaterialsGroupingOption.AREA:
        return Localization.translator.area

      case DeliveryGroupingOption[FieldIds.DATE]:
        return Localization.translator.bookingDate
      case DeliveryGroupingOption[FieldIds.TRUCK_SIZE]:
        return Localization.translator.deliveryVehicleType
      case DeliveryGroupingOption[FieldIds.MATERIAL]:
        return Localization.translator.material
      case DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS]:
        return Localization.translator.onsiteContacts
      case DeliveryGroupingOption[FieldIds.VENDOR]:
        return Localization.translator.vendor
      case DeliveryGroupingOption[FieldIds.DURATION]:
        return Localization.translator.duration

      case MaterialsGroupingOption.COMPANY:
        return Localization.translator.company
      case MaterialsGroupingOption.DATE:
        return Localization.translator.date
      case MaterialsGroupingOption.VENDOR:
        return Localization.translator.vendor
      case MaterialsGroupingOption.CURRENT_LOCATION:
        return Localization.translator.currentLocation
      case MaterialsGroupingOption.LOGISTICS_OBJECT:
        return Localization.translator.logisticObject
      case MaterialsGroupingOption.VERTICAL_OBJECT:
        return Localization.translator.verticalObject
      case MaterialsGroupingOption.INTERIOR_PATH:
        return Localization.translator.interiorPath
      case MaterialsGroupingOption.INTERIOR_DOOR:
        return Localization.translator.interiorDoor
      case MaterialsGroupingOption.STAGING:
        return Localization.translator.staging
      case MaterialsGroupingOption.MATERIAL_CATEGORY:
        return Localization.translator.materialsCategory
      case MaterialsGroupingOption.MATERIAL:
        return Localization.translator.material
      case MaterialsGroupingOption.MATERIAL_DESCRIPTION:
        return Localization.translator.materialDescription
      default:
        return ''
    }
  }
}
