import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import { SitePermitStatus } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import { getWorkflowStepLevel } from '~/client/src/shared/constants/formStatusesTags'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import InitialState from '~/client/src/shared/stores/InitialState'
import SitePermitAssignmentsStore from '~/client/src/shared/stores/domain/SitePermitAssignments.store'
import SitePermitFollowingsStore from '~/client/src/shared/stores/domain/SitePermitFollowings.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import ActionBar from '../../../ActionBar/ActionBar'
import PermitLogActionBarStore from '../../../PermitLogActionBar.store'
import PermitStatusSelectMenu from '../../../PermitStatusSelectMenu/PermitStatusSelectMenu'
import WorkflowCardStatus from '../../../WorkflowCard/Status'
import PermitApprovalStore from '../../PermitApproval.store'
import SitePermitCreationFormStore, {
  FormDetailsTab,
} from '../../SitePermitCreationForm.store'
import CommonPermitButtons from './components/CommonPermitButtons'
import FormAutoActivationLabel from './components/FormAutoActivationLabel'
import InspectionPermitButtons from './components/InspectionPermitButtons'

// localization: translated

const createASiteAnnouncement = 'Create a site announcement'

interface IProps {
  isStatusSelectorOpened: boolean
  isActionBarInReadOnlyMode: boolean
  canChangeStatus: boolean

  toggleStatusSelectMenu: () => void

  actionBarStore: PermitLogActionBarStore
  store: SitePermitCreationFormStore
  permitApprovalStore: PermitApprovalStore
  projectDateStore: ProjectDateStore
  state: InitialState

  shouldCreateAnnouncement: boolean
  isAnnouncementCheckboxShown: boolean
  toggleAnnouncement: () => void
  isMobileMode?: boolean
  sitePermitAssignmentsStore?: SitePermitAssignmentsStore
  sitePermitFollowingsStore?: SitePermitFollowingsStore
}

@inject('sitePermitFollowingsStore', 'sitePermitAssignmentsStore')
@observer
export default class PermitActionBar extends React.Component<IProps> {
  public render() {
    const {
      toggleStatusSelectMenu,
      isActionBarInReadOnlyMode,
      store,
      actionBarStore,
      sitePermitAssignmentsStore,
      sitePermitFollowingsStore,
      isMobileMode,
      state,
    } = this.props
    const { editablePermit } = store

    return (
      <ActionBar
        renderStatusSelectMenu={this.renderStatusSelectMenu}
        renderAdditionalCheckbox={this.renderAnnouncementCheckbox}
        applyStatusAndStep={this.setFormStatusAndStep}
        store={actionBarStore}
        entity={editablePermit}
        toggleStatusSelectMenu={toggleStatusSelectMenu}
        renderButtons={this.renderButtons}
        customRenderer={this.permitStatusRenderer}
        isReadOnly={isActionBarInReadOnlyMode}
        informationalTextElement={<FormAutoActivationLabel store={store} />}
        isMobileMode={isMobileMode}
        assignmentsStore={sitePermitAssignmentsStore}
        followingsStore={sitePermitFollowingsStore}
        shouldShowStatusSelector={
          state.userActiveProjectSettings.isAdminOrFormsMaster
        }
      />
    )
  }

  private renderAnnouncementCheckbox = () => {
    const {
      isAnnouncementCheckboxShown,
      shouldCreateAnnouncement,
      toggleAnnouncement,
    } = this.props
    return (
      isAnnouncementCheckboxShown && (
        <div className="row pa10 pa6 relative bg-white pb40">
          <span className="extra-large text">{createASiteAnnouncement}</span>
          <Checkbox
            isChecked={shouldCreateAnnouncement}
            onClick={toggleAnnouncement}
          />
        </div>
      )
    )
  }

  private renderStatusSelectMenu = (
    onClick: (status: SitePermitStatus, stepId: string) => void,
  ) => {
    const { store, isStatusSelectorOpened, toggleStatusSelectMenu } = this.props
    const {
      existingPermit,
      template,
      workflowFieldsStore: {
        areRequiredFieldsChanged,
        areOptionalFieldsChanged,
      },
    } = store

    if (areRequiredFieldsChanged || areOptionalFieldsChanged) {
      return null
    }

    return (
      <PermitStatusSelectMenu
        isShown={!!isStatusSelectorOpened}
        onHide={toggleStatusSelectMenu}
        setStatusAndStep={onClick}
        currentStatus={existingPermit.status}
        currentWorkflowStepLevel={existingPermit.workflowStepLevel}
        workflowSteps={template?.workflowSteps}
      />
    )
  }

  private renderButtons = (
    render: (
      btnText: string,
      isPermitValid: boolean,
      status: SitePermitStatus,
      isDeny?: boolean,
      customBtnHandler?: () => void,
    ) => JSX.Element,
  ) => {
    const {
      state,
      store,
      permitApprovalStore,
      projectDateStore,
      canChangeStatus,
    } = this.props
    const {
      template,
      workflowFieldsStore: {
        areRequiredFieldsChanged,
        areOptionalFieldsChanged,
      },
      isChangedPermitValid,
    } = store

    const changeBtnText = areRequiredFieldsChanged
      ? Localization.translator.change_verb
      : Localization.translator.save

    if (areRequiredFieldsChanged || areOptionalFieldsChanged) {
      return render(
        changeBtnText,
        isChangedPermitValid,
        null,
        false,
        this.updateChangedFieldsForPermit,
      )
    }

    if (!template?.type || !canChangeStatus) {
      return null
    }

    if (template?.isInspectionType) {
      return (
        <InspectionPermitButtons
          actionBarButtonsRenderer={render}
          state={state}
          store={store}
          permitApprovalStore={permitApprovalStore}
          projectDateStore={projectDateStore}
        />
      )
    }

    return (
      <CommonPermitButtons
        actionBarButtonsRenderer={render}
        store={store}
        permitApprovalStore={permitApprovalStore}
      />
    )
  }

  @action
  private setFormStatusAndStep = (status: SitePermitStatus, stepId: string) => {
    const { isPermitValid, editablePermit, updatePermit, switchActiveTab } =
      this.props.store

    if (!isPermitValid) return

    editablePermit.setStatus(status)
    if (stepId) {
      editablePermit.setWorkflowStepId(stepId)
    }
    updatePermit()
    switchActiveTab(FormDetailsTab.LOG)
  }

  @action.bound
  private updateChangedFieldsForPermit() {
    const {
      isChangedPermitValid,
      editablePermit,
      updatePermit,
      workflowFieldsStore,
    } = this.props.store

    if (!isChangedPermitValid) return

    const { areRequiredFieldsChanged, previousStepsFields } =
      workflowFieldsStore

    if (areRequiredFieldsChanged) {
      editablePermit.setStatus(SitePermitStatus.Changed)
    }

    editablePermit.fields = editablePermit.fields.filter(f =>
      previousStepsFields.some(pf => pf.id === f.fieldId),
    )
    updatePermit()
  }

  private permitStatusRenderer = (
    status: SitePermitStatus,
    stepId: string,
  ): JSX.Element => {
    const { template, existingPermit } = this.props.store
    const workflowStepLevel = getWorkflowStepLevel(
      stepId || existingPermit.currentWorkflowStepId,
      template.stepsWithoutRecurring,
    )
    return (
      <WorkflowCardStatus
        className="mr5"
        status={status}
        workflowStepLevel={workflowStepLevel}
        isLate={false}
      />
    )
  }
}
