import { IScanHistory, IScanHistoryInput } from '~/client/graph'

import BaseModel from './BaseModel'

export default class ScanHistory
  extends BaseModel<IScanHistory>
  implements IScanHistory
{
  public static fromDto(dto: IScanHistory): ScanHistory {
    return new ScanHistory(
      dto.id,
      dto.projectId,
      dto.scannerId,
      dto.userId,
      dto.isAllowed,
      dto.date,
      dto.endDate,
      dto.queueDate,
    )
  }

  public id: string
  public projectId: string
  public scannerId: string
  public userId: string
  public isAllowed: boolean
  public date: number
  public endDate?: number
  public queueDate?: number

  public constructor(
    id: string,
    projectId: string,
    scannerId: string,
    userId: string,
    isAllowed: boolean,
    date: number,
    endDate?: number,
    queueDate?: number,
  ) {
    super(id)

    this.projectId = projectId
    this.userId = userId
    this.scannerId = scannerId
    this.isAllowed = isAllowed
    this.date = date
    this.endDate = endDate || null
    this.queueDate = queueDate || null
  }

  public copy(): ScanHistory {
    return new ScanHistory(
      this.id,
      this.projectId,
      this.userId,
      this.scannerId,
      this.isAllowed,
      this.date,
      this.endDate,
      this.queueDate,
    )
  }

  public getDto(): IScanHistoryInput {
    return {
      id: this.id,
      projectId: this.projectId,
      isAllowed: this.isAllowed || false,
      scannerId: this.scannerId,
      userId: this.userId,
      date: this.date,
      endDate: this.endDate,
      queueDate: this.queueDate,
    }
  }

  public get startDate(): number {
    return this.date || this.queueDate
  }
}
