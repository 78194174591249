import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import * as Icons from '~/client/src/shared/components/Icons'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import SitemapItem from '~/client/src/shared/models/SitemapItem'

const { ISSUE_CLOSED, REFRESH, EYE_ON, PLAY } = IconNames
const blueprintProps = { className: 'row', iconSize: 12 }

interface IProps {
  dataObject?: LocationBase
  sitemapItem?: SitemapItem
  customProps?: any
}

export default class SitemapAttributeIconComponent extends React.Component<IProps> {
  public static defaultProps = {
    customProps: {},
  }

  public render() {
    const { dataObject, sitemapItem, customProps } = this.props

    if (dataObject) {
      const { color } = dataObject

      switch (dataObject.iconName as string) {
        case TagIconType.Building:
          return <TagIcon.Building color={color} {...customProps} />

        case TagIconType.Zone:
          return <TagIcon.Zone color={color} {...customProps} />

        case TagIconType.Route:
          return <TagIcon.Route color={color} {...customProps} />

        case TagIconType.Gate:
          return <TagIcon.Gate color={color} {...customProps} />

        case TagIconType.Equipment:
          return <TagIcon.Equipment color={color} {...customProps} />

        case TagIconType.Crane:
          return <TagIcon.Crane color={color} {...customProps} />

        case TagIconType.Hoist:
          return <TagIcon.Hoist color={color} {...customProps} />

        case TagIconType.AerialLift:
          return <TagIcon.AerialLift color={color} {...customProps} />

        case TagIconType.Gradall:
          return <TagIcon.Gradall color={color} {...customProps} />

        case TagIconType.ForkLift:
          return <TagIcon.ForkLift color={color} {...customProps} />

        case TagIconType.Logistics:
          return <TagIcon.LogisticsObject color={color} {...customProps} />

        case TagIconType.Monitoring:
          return <TagIcon.MaturixStation color={color} {...customProps} />

        case TagIconType.Staging:
          return <TagIcon.Staging color={color} {...customProps} />

        case TagIconType.InteriorDoor:
          return <TagIcon.InteriorDoor color={color} {...customProps} />

        case TagIconType.InteriorPath:
          return <TagIcon.InteriorPath color={color} {...customProps} />

        case TagIconType.Level:
          return <TagIcon.Level color={color} {...customProps} />

        case TagIconType.Area:
          return <TagIcon.Area color={color} {...customProps} />

        case TagIconType.Bathroom:
          return <TagIcon.Bathroom color={color} {...customProps} />

        case TagIconType.Break:
          return <TagIcon.Break color={color} {...customProps} />

        case TagIconType.Dumpster:
          return <TagIcon.Dumpster color={color} {...customProps} />

        case TagIconType.ElectricalRoom:
          return <TagIcon.ElectricalRoom color={color} {...customProps} />

        case TagIconType.Elevator:
          return <TagIcon.Elevator color={color} {...customProps} />

        case TagIconType.Entrance:
          return <TagIcon.Entrance color={color} {...customProps} />

        case TagIconType.HandWash:
          return <TagIcon.HandWash color={color} {...customProps} />

        case TagIconType.Medical:
          return <TagIcon.Medical color={color} {...customProps} />

        case TagIconType.MeetingPoint:
          return <TagIcon.MeetingPoint color={color} {...customProps} />

        case TagIconType.Parking:
          return <TagIcon.Parking color={color} {...customProps} />

        case TagIconType.Smoking:
          return <TagIcon.Smoking color={color} {...customProps} />

        case TagIconType.Stairs:
          return <TagIcon.Stairs color={color} {...customProps} />

        case TagIconType.Shaft:
          return <TagIcon.Shaft color={color} {...customProps} />

        case TagIconType.Temperature:
          return <TagIcon.Temperature color={color} {...customProps} />

        case TagIconType.Tent:
          return <TagIcon.Tent color={color} {...customProps} />

        case TagIconType.Walkway:
          return <TagIcon.Walkway color={color} {...customProps} />

        case TagIconType.Company:
          return <TagIcon.Company color={color} {...customProps} />

        case TagIconType.ToInspect:
        case TagIconType.Status:
        case TagIconType.Done:
          return <TagIcon.StatusDone color={color} {...customProps} />

        case TagIconType.Requested:
        case TagIconType.Submitted:
          return <TagIcon.StatusRequested color={color} {...customProps} />

        case TagIconType.Scheduled:
        case TagIconType.Canceled:
        case TagIconType.Paused:
        case TagIconType.OnHold:
          return <TagIcon.StatusScheduled color={color} {...customProps} />

        case TagIconType.OnSite:
        case TagIconType.Delivering:
          return <TagIcon.StatusOnSite color={color} {...customProps} />

        case TagIconType.Accepted:
        case TagIconType.PassedInspection:
        case TagIconType.Passed:
          return <TagIcon.StatusPassed color={color} {...customProps} />

        case TagIconType.FailedInspection:
        case TagIconType.Denied:
        case TagIconType.Failed:
          return <TagIcon.StatusFailed color={color} {...customProps} />

        case TagIconType.Role:
          return <TagIcon.Role color={color} {...customProps} />

        case TagIconType.Team:
          return <TagIcon.Team color={color} {...customProps} />

        case TagIconType.DefaultTeam:
          return <TagIcon.DefaultTeam color={color} {...customProps} />

        case TagIconType.Trade:
          return <TagIcon.Trade color={color} {...customProps} />

        case TagIconType.User:
          return <TagIcon.User color={color} {...customProps} />

        case TagIconType.At:
          return <Icons.At color={color} {...customProps} />
        case TagIconType.Canteen:
          return <TagIcon.Canteen color={color} {...customProps} />
        case TagIconType.Toilet:
          return <TagIcon.Toilet color={color} {...customProps} />
        case TagIconType.Trailer:
          return <TagIcon.Trailer color={color} {...customProps} />

        case TagIconType.Changed:
          return (
            <Icon
              icon={REFRESH}
              color={color}
              {...blueprintProps}
              {...customProps}
            />
          )

        case TagIconType.IncompleteDone:
        case TagIconType.BicInspected:
          return (
            <Icon
              icon={ISSUE_CLOSED}
              color={color}
              {...blueprintProps}
              {...customProps}
            />
          )

        case TagIconType.Reviewed:
          return (
            <Icon
              icon={EYE_ON}
              color={color}
              {...blueprintProps}
              {...customProps}
            />
          )

        case TagIconType.Active:
          return (
            <Icon
              icon={PLAY}
              color={color}
              {...blueprintProps}
              {...customProps}
            />
          )
      }
    }

    if (sitemapItem) {
      switch (sitemapItem.iconName) {
        case TagIconType.TextBox:
          return <TagIcon.Text color={sitemapItem.color} {...customProps} />
        case TagIconType.Line:
          return <TagIcon.Line color={sitemapItem.color} {...customProps} />
      }
    }

    return null
  }
}
