import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'

const DeliveryGroupingOption = {
  [FieldIds.COMPANY]: 'company',
  [FieldIds.STATUS]: 'status',
  [FieldIds.DATE]: 'startDate',

  [FieldIds.LOCATION]: 'location',
  [FieldIds.BUILDING]: 'building',
  [FieldIds.ZONE]: 'zone',
  [FieldIds.LEVEL]: 'level',
  [FieldIds.AREA]: 'area',

  [FieldIds.GATE]: 'gate',
  [FieldIds.ROUTE]: 'route',
  [FieldIds.OFFLOADING_EQUIPMENT]: 'offloadingEquipmentIds',
  [FieldIds.TRUCK_SIZE]: 'truckSize',
  [FieldIds.MATERIAL]: 'materialIds',

  [FieldIds.ON_SITE_CONTACTS]: 'onSiteContactPersonIds',
  [FieldIds.VENDOR]: 'vendor',

  [FieldIds.DURATION]: 'durationMs',
  [FieldIds.CANCELATION_REASON]: 'cancellationReason',
  [FieldIds.INSPECTION_SECTION]: 'inspection',
}

export default DeliveryGroupingOption
