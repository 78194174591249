import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'

import Localization from '../../shared/localization/LocalizationManager'
import { enumToList } from '../utils/converters'

export enum LocationSection {
  LOCATION = 'location',
  LOCATION_FROM = 'location-from',
  LOCATION_TO = 'location-to',
}

enum Section {
  DATE_AND_TIME = 'date-and-time',
  CONTACT = 'contact',
  OFFLOADING_EQUIPMENT = 'offloading-equipment',
  VEHICLES = 'vehicles',
  MATERIALS = 'materials',
  INSPECTION_REQUIRED = 'inspection-required',
  SUPPLIER = 'supplier',
  SPECIAL_INSTRUCTIONS = 'special-instructions',
  OTHER = 'other',
}

type DeliveryDetailsSections = LocationSection | Section
const DeliveryDetailsSections = {
  ...LocationSection,
  ...Section,
}

export const DeliveryDetailsSectionsList = [
  Section.DATE_AND_TIME,
  ...enumToList(DeliveryDetailsSections).filter(
    s => s !== Section.DATE_AND_TIME,
  ),
]

export const DeliveryDetailsSectionsInfo: {
  [key: string]: { getTitle: () => string; getCustomNameId?: () => FieldIds }
} = {
  [DeliveryDetailsSections.DATE_AND_TIME]: {
    getTitle: () => Localization.translator.dates,
  },
  [DeliveryDetailsSections.LOCATION]: {
    getTitle: () => Localization.translator.location,
  },
  [DeliveryDetailsSections.LOCATION_FROM]: {
    getTitle: () => Localization.translator.locationFrom,
  },
  [DeliveryDetailsSections.LOCATION_TO]: {
    getTitle: () => Localization.translator.locationTo,
  },
  [DeliveryDetailsSections.CONTACT]: {
    getTitle: () => Localization.translator.responsibles,
  },

  [DeliveryDetailsSections.OFFLOADING_EQUIPMENT]: {
    getTitle: () => Localization.translator.equipment,
    getCustomNameId: () => FieldIds.OFFLOADING_EQUIPMENT,
  },
  [DeliveryDetailsSections.VEHICLES]: {
    getTitle: () => Localization.translator.vehicles,
  },
  [DeliveryDetailsSections.MATERIALS]: {
    getTitle: () => Localization.translator.materials,
  },
  [DeliveryDetailsSections.INSPECTION_REQUIRED]: {
    getTitle: () => Localization.translator.inspectionRequired + '?',
    getCustomNameId: () => FieldIds.IS_INSPECTION_REQUIRED,
  },

  [DeliveryDetailsSections.SUPPLIER]: {
    getTitle: () => Localization.translator.supplierAndDriver,
  },
  [DeliveryDetailsSections.SPECIAL_INSTRUCTIONS]: {
    getTitle: () => Localization.translator.anySpecialInstructions,
  },
  [DeliveryDetailsSections.OTHER]: {
    getTitle: () => null,
  },
}

export default DeliveryDetailsSections
