import { action, computed, observable } from 'mobx'

import { IConstraint, IConstraintInput, LocationType } from '~/client/graph'
import {
  DeleteManyConstraintsDocument,
  SaveManyConstraintsDocument,
} from '~/client/graph/operations/generated/Constraint.generated'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Zone from '~/client/src/shared/models/LocationObjects/Zone'
import ConstraintsStore from '~/client/src/shared/stores/domain/Constraints.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import IRestrictionViewModel from '../../interfaces/IRestrictionViewModel'
import InitialState from '../InitialState'

const siteObjects = 'siteObjects'

export enum RestrictionAttributeKey {
  RestrictedGates = 'restrictedGates',
  RestrictedRoutes = 'restrictedRoutes',
  RestrictedEquipment = 'restrictedEquipment',
  RestrictedLevels = 'restrictedLevels',
  RestrictedAreas = 'restrictedAreas',
  RestrictedInteriorDoors = 'restrictedInteriorDoors',
  RestrictedInteriorPaths = 'restrictedInteriorPaths',
  RestrictedStagings = 'restrictedStagings',
}

// consider renaming DeliveryRestrictionsStore to CommonRestrictionsStore or RestrictionsStore
// since this store is used not only within the deliveries module.
export default class DeliveryRestrictionsStore {
  @observable private displayedBuildingId: string = null
  @observable private isLoading: boolean = false

  @observable private displayedRestrictionZoneId: string = null

  public constructor(
    private readonly state: InitialState,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly tagsStore: TagsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly constraintsStore: ConstraintsStore,
  ) {}

  public get isLoaderShown() {
    return (
      this.isLoading ||
      !this.locationAttributesStore.isDataReceived ||
      this.constraintsStore.isLoading
    )
  }

  @action.bound
  public displayFirstBuilding() {
    if (this.displayedBuilding) {
      return
    }

    if (this.displayedBuildingId !== siteObjects && this.buildings.length) {
      this.displayBuilding(this.buildings[0])
    } else {
      this.displayBuilding()
    }
  }

  @action.bound
  public displayBuilding(building?: Building) {
    this.hideRestrictionModal()
    this.displayedBuildingId = building?.id || siteObjects
  }

  @computed
  public get displayedBuilding() {
    const { byId } = this.locationAttributesStore.buildingsStore
    return byId.get(this.displayedBuildingId)
  }

  @computed
  public get displayedBuildingRestrictions(): IRestrictionViewModel[] {
    return this.zones
      .filter(zone => {
        if (this.displayedBuildingId === siteObjects) {
          // for siteObject only visible options are:
          // (manually set options) OR (options without parents AND no manually forbidden options)
          return (
            (this.tagsStore.zonesWithNoBuildingParent[zone.id] &&
              !this.hasSiteObjectsRestriction(zone)) ||
            !!this.getBuildingZoneRestriction(this.displayedBuilding, zone)
          )
        }

        return (
          !!this.getBuildingZoneRestriction(this.displayedBuilding, zone) ||
          (this.tagsStore.zonesWithNoBuildingParent[zone.id] &&
            !this.hasRestrictionsByZoneMap[zone.id])
        )
      })
      .map(zone => {
        let restriction = this.getBuildingZoneRestriction(
          this.displayedBuilding,
          zone,
        )

        // if zone does not have any restrictions it is allowed for all buildings
        if (this.shouldCreateDefaultRestriction(restriction, zone)) {
          restriction = this.createDefaultRestriction(
            this.displayedBuilding,
            zone,
          )
        }

        if (!restriction) {
          return
        }

        const {
          id,
          restrictedRoutes,
          restrictedGates,
          restrictedEquipment,
          restrictedLevels,
          restrictedAreas,
          restrictedStagings,
          restrictedInteriorDoors,
          restrictedInteriorPaths,
        } = restriction

        return {
          id,
          zone,
          building: this.displayedBuilding,
          routes: this.routes.filter(dto => !restrictedRoutes.includes(dto.id)),
          gates: this.gates.filter(dto => !restrictedGates.includes(dto.id)),
          equipment: this.equipment.filter(
            dto => !restrictedEquipment.includes(dto.id),
          ),
          levels: this.levels.filter(dto => !restrictedLevels.includes(dto.id)),
          areas: this.areas.filter(dto => !restrictedAreas.includes(dto.id)),
          stagings: this.stagings.filter(
            dto => !restrictedStagings.includes(dto.id),
          ),
          interiorDoors: this.interiorDoors.filter(
            dto => !restrictedInteriorDoors.includes(dto.id),
          ),
          interiorPaths: this.interiorPaths.filter(
            dto => !restrictedInteriorPaths.includes(dto.id),
          ),
        } as IRestrictionViewModel
      })
      .filter(m => !!m)
  }

  public shouldCreateDefaultRestriction = (
    restriction: IConstraint,
    zone: Zone,
  ): boolean => {
    if (this.displayedBuildingId === siteObjects) {
      return !restriction
    }

    const restrictionsByZoneMap = this.hasRestrictionsByZoneMap[zone?.id]
    const zonesWithNoBuildingParent =
      this.tagsStore.zonesWithNoBuildingParent[zone?.id]

    return (
      (!restriction && !restrictionsByZoneMap) ||
      (zonesWithNoBuildingParent && !restrictionsByZoneMap)
    )
  }

  @computed
  public get displayedBuildingNotPresentedZones() {
    return this.zones
      .filter(
        z => !this.displayedBuildingRestrictions.find(r => r.zone.id === z.id),
      )
      .sort(this.sortZones)
  }

  public addNewZoneRestriction = async (zone: Zone) => {
    this.isLoading = true
    const constraint = this.createDefaultRestriction(
      this.displayedBuilding,
      zone,
    )

    await this.graphExecutorStore.mutate(SaveManyConstraintsDocument, {
      constraints: [constraint],
    })
    this.isLoading = false
  }

  @action.bound
  public openModalForRestriction(restriction: IRestrictionViewModel) {
    this.displayedRestrictionZoneId = restriction.zone.id
  }

  @action.bound
  public hideRestrictionModal() {
    this.displayedRestrictionZoneId = null
  }

  public isRestrictionModalDisplayed = (r: IRestrictionViewModel) => {
    return (
      (this.displayedBuildingId === siteObjects ||
        r.building?.id === this.displayedBuildingId) &&
      this.displayedRestrictionZoneId === r.zone.id
    )
  }

  public getDefaultAllowedIds = (): string[] => {
    if (!this.displayedBuilding || !this.displayedRestrictionZoneId) {
      return []
    }

    return [
      ...this.routes,
      ...this.gates,
      ...this.equipment,
      ...this.levels,
      ...this.areas,
    ]
      .filter(dto =>
        this.isAttributeAllowed(
          dto,
          this.displayedBuildingId,
          this.displayedRestrictionZoneId,
        ),
      )
      .map(({ id }) => id)
  }

  public updateRestriction = async (
    r: IRestrictionViewModel,
    allowedIds: string[],
  ) => {
    this.isLoading = true

    const constraint: IConstraintInput = {
      id: r.id,
      buildingId: r.building?.id || null,
      zoneId: r.zone.id,
      projectId: this.state.activeProject.id,
      restrictedRoutes: this.getRestrictedIds(this.routes, allowedIds),
      restrictedGates: this.getRestrictedIds(this.gates, allowedIds),
      restrictedEquipment: this.getRestrictedIds(this.equipment, allowedIds),
      restrictedLevels: this.getRestrictedIds(this.levels, allowedIds),
      restrictedAreas: this.getRestrictedIds(this.areas, allowedIds),
      restrictedStagings: this.getRestrictedIds(this.stagings, allowedIds),
      restrictedInteriorDoors: this.getRestrictedIds(
        this.interiorDoors,
        allowedIds,
      ),
      restrictedInteriorPaths: this.getRestrictedIds(
        this.interiorPaths,
        allowedIds,
      ),
      isExplicit: true,
    }

    if (constraint.id) {
      await this.graphExecutorStore.mutate(SaveManyConstraintsDocument, {
        constraints: [constraint],
      })
    } else {
      // if constraint doesn't have id,
      // then that zone doesn't have constraints at all
      // and means that zone allowed for all buildings.

      // so creating constraint for displayed building
      // will disable that zone for others buildings.

      // to prevent this constraints for other buildings
      // created as well
      const constraints = [constraint]
      this.buildings.forEach(building => {
        if (building.id === constraint.buildingId) {
          return
        }
        constraints.push(this.createDefaultRestriction(building, r.zone))
      })
      await this.graphExecutorStore.mutate(SaveManyConstraintsDocument, {
        constraints,
      })
    }

    this.hideRestrictionModal()
    this.isLoading = false
  }

  public createDefaultRestriction(
    building: Building,
    zone: Zone,
    shouldCreateForSiteObjects?: boolean,
  ): IConstraint {
    const buildingId = shouldCreateForSiteObjects
      ? siteObjects
      : building?.id || null

    return {
      id: null,
      projectId: this.state.activeProject.id,
      buildingId,
      zoneId: zone?.id,
      restrictedGates: this.getDefaultItems(this.gates, building, zone),
      restrictedRoutes: this.getDefaultItems(this.routes, building, zone),
      restrictedEquipment: this.getDefaultItems(this.equipment, building, zone),
      restrictedLevels: this.getDefaultItems(this.levels, building, zone),
      restrictedAreas: this.getDefaultItems(this.areas, building, zone),
      restrictedInteriorDoors: this.getDefaultItems(
        this.interiorDoors,
        building,
        zone,
      ),
      restrictedInteriorPaths: this.getDefaultItems(
        this.interiorPaths,
        building,
        zone,
      ),
      restrictedStagings: this.getDefaultItems(this.stagings, building, zone),
      isExplicit: false,
      createdAt: undefined,
      updatedAt: undefined,
    }
  }

  public removeRestriction = async (r: IRestrictionViewModel) => {
    this.isLoading = true

    if (r.id) {
      await this.graphExecutorStore.mutate(DeleteManyConstraintsDocument, {
        ids: [r.id],
      })
    } else {
      // if constraint doesn't have id,
      // then that zone doesn't have constraints at all
      // and means that zone allowed for all buildings.

      // hiding zone for displayed building performed,
      // by creation constraints for all buildings except displayed
      const constraints = []
      if (!r.building) {
        // to make siteObjects restrictable restriction is created on 'site objects' to prevent noBuilding->parentless zone combination
        constraints.push(this.createDefaultRestriction(null, r.zone, true))
      } else {
        this.buildings.forEach(building => {
          if (building.id !== r.building.id) {
            constraints.push(this.createDefaultRestriction(building, r.zone))
          }
        })
      }
      await this.graphExecutorStore.mutate(SaveManyConstraintsDocument, {
        constraints,
      })
    }

    this.isLoading = false
  }

  public filterZonesByBuilding = (
    buildingId: string,
    zones: Zone[],
  ): Zone[] => {
    const { getTagById, zonesWithNoBuildingParent } = this.tagsStore
    const { constraints } = this.constraintsStore

    const building = getTagById(buildingId)

    if (!building) {
      return zones.filter(zone => {
        if (
          zonesWithNoBuildingParent[zone.id] &&
          !this.hasSiteObjectsRestriction(zone)
        ) {
          return true
        }
        const hasConstraint = constraints.find(
          c => !c.buildingId && c.zoneId === zone.id,
        )

        return hasConstraint
      })
    }

    return zones.filter(zone => {
      if (
        zonesWithNoBuildingParent[zone.id] &&
        !this.hasRestrictionsByZoneMap[zone.id]
      ) {
        return true
      }

      const hasConstraint = constraints.find(
        c => c.buildingId === building.id && c.zoneId === zone.id,
      )

      if (hasConstraint) {
        return true
      }

      const hasNoConstraints = !constraints.some(c => c.zoneId === zone.id)

      if (hasNoConstraints) {
        return false
      }

      // if there is no custom constraints, try LBS

      const zoneObj = this.locationAttributesStore.zonesStore.byId.get(zone.id)
      const hierarchyObjs = zoneObj.getHierarchyChainsObjects(
        this.tagsStore.tagStoreByTagTypeMap,
      )

      const hasBuildingParent = hierarchyObjs.some(o => o.id === buildingId)

      return hasBuildingParent
    })
  }

  public filterAttributesByZoneAndBuilding = (
    items: LocationBase[],
    rKey: RestrictionAttributeKey,
    buildingId: string,
    zoneId: string,
  ) => {
    const { buildingsStore, zonesStore } = this.locationAttributesStore
    const { constraints } = this.constraintsStore

    const building = buildingsStore.getInstanceById(buildingId)
    const zone = zonesStore.getInstanceById(zoneId)

    if (!building && !zone) {
      return items
    }

    const filteredConstraints = constraints.filter(
      c =>
        ((!building && !c.buildingId) || c.buildingId === building?.id) &&
        (!zone || c.zoneId === zone.id),
    )

    if (
      !filteredConstraints?.length &&
      this.shouldCreateDefaultRestriction(null, zone)
    ) {
      filteredConstraints.push(this.createDefaultRestriction(building, zone))
    }

    if (!filteredConstraints?.length) {
      return items
    }

    const restrictedIds = new Set(
      filteredConstraints.reduce((allConstraints, constraint) => {
        allConstraints.push(...constraint[rKey])

        return allConstraints
      }, []),
    )

    return items.filter(dto => !restrictedIds.has(dto.id))
  }

  public filterAreasBySelectedLevel = (
    levelId: string,
    areas: LocationBase[],
  ) => {
    const level = this.locationAttributesStore.levelsStore.byId.get(levelId)

    if (!level) {
      return areas
    }

    return areas.filter(area => {
      const hierarchyObjs = area.getHierarchyChainsObjects(
        this.tagsStore.tagStoreByTagTypeMap,
      )
      return (
        !hierarchyObjs?.length ||
        hierarchyObjs?.some(
          object =>
            object.id === level.id && object.type === LocationType.Level,
        )
      )
    })
  }

  public get buildings() {
    return this.locationAttributesStore.buildingsStore.list
  }

  private get equipment() {
    return this.locationAttributesStore.offloadingEquipmentsStore.list
  }

  private get routes() {
    return this.locationAttributesStore.routesStore.list
  }

  private get gates() {
    return this.locationAttributesStore.gatesStore.list
  }

  private get levels() {
    return this.locationAttributesStore.levelsStore.list
  }

  private get areas() {
    return this.locationAttributesStore.areasStore.list
  }

  private get zones() {
    return this.locationAttributesStore.zonesStore.list
  }

  private get stagings() {
    return this.locationAttributesStore.stagingsStore.list
  }

  private get interiorPaths() {
    return this.locationAttributesStore.interiorPathsStore.list
  }

  private get interiorDoors() {
    return this.locationAttributesStore.interiorDoorsStore.list
  }

  private isAttributeAllowed = (
    item: LocationBase,
    buildingId: string,
    zoneId: string,
  ) => {
    if (!item.hasParent) {
      return true
    }
    const parent = this.allSitemapAttributes.find(a => item.isParent(a))
    // sometimes it still has no parent
    if (!parent) {
      return true
    }

    const isParentAllowed =
      (parent.type !== LocationType.Building || parent.id === buildingId) &&
      (parent.type !== LocationType.Zone || parent.id === zoneId)

    if (!isParentAllowed) {
      return false
    }

    return this.isAttributeAllowed(parent, buildingId, zoneId)
  }

  @computed
  private get allSitemapAttributes() {
    return [
      ...this.buildings,
      ...this.zones,
      ...this.routes,
      ...this.gates,
      ...this.equipment,
      ...this.levels,
      ...this.areas,
    ]
  }

  @computed
  private get hasRestrictionsByZoneMap() {
    const map = {}

    this.constraintsStore.constraints.forEach(r => {
      if (r.buildingId !== siteObjects) {
        map[r.zoneId] = true
      }
    })

    return map
  }

  private getDefaultItems(
    items: LocationAttributeBase[],
    building: Building,
    zone: Zone,
  ): string[] {
    return items
      .filter(item => !this.isAttributeAllowed(item, building?.id, zone?.id))
      .map(item => item.id)
  }

  private hasSiteObjectsRestriction(zone: Zone): IConstraint {
    const key = this.getBuildingZoneKey(siteObjects, zone.id)
    return this.restrictionByBuildingZoneMap[key]
  }

  private getBuildingZoneRestriction(
    building: Building,
    zone: Zone,
  ): IConstraint {
    const key = this.getBuildingZoneKey(building?.id || null, zone.id)
    return this.restrictionByBuildingZoneMap[key]
  }

  @computed
  private get restrictionByBuildingZoneMap() {
    const map = {}

    this.constraintsStore.constraints.forEach(r => {
      const key = this.getBuildingZoneKey(r.buildingId || null, r.zoneId)
      map[key] = r
    })

    return map
  }

  private getBuildingZoneKey(buildingId: string, zoneId: string) {
    return buildingId + '_' + zoneId
  }

  private getRestrictedIds(items: LocationBase[], allowedIds: string[]) {
    return items.filter(dto => !allowedIds.includes(dto.id)).map(({ id }) => id)
  }

  private sortZones = (a: Zone, b: Zone): number => {
    const aBuilding = a
      .getHierarchyChainsObjects(this.tagsStore.tagStoreByTagTypeMap)
      ?.find(attribute => attribute.type === LocationType.Building)
    const bBuilding = b
      .getHierarchyChainsObjects(this.tagsStore.tagStoreByTagTypeMap)
      ?.find(attribute => attribute.type === LocationType.Building)

    if (!aBuilding && bBuilding) {
      return 1
    }

    if (!bBuilding && aBuilding) {
      return -1
    }

    if (aBuilding?.id !== bBuilding?.id) {
      return aBuilding.name.localeCompare(bBuilding.name)
    }

    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  }
}
