import * as React from 'react'

import { inject, observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import { GET_PROJECT_MEMBERS } from '~/client/src/shared/stores/EventStore/eventConstants'

import ChatService from '../../../services/ChatService/Chat.service'
import InitialState from '../../../stores/InitialState'
import { Loader } from '../../Loader'
import UsersDirectory from '../../UsersDirectory/UsersDirectory'
import ChatCreationMenuStore from './ChatCreationMenu.store'
import ChatDetails from './ChatDetails'
import ChatMembers from './ChatMembers'

interface IProps {
  store: ChatCreationMenuStore
  onlineContacts: Set<string>

  onMount?: () => void
  onUnmount?: () => void

  chatService?: ChatService
  state?: InitialState
}

@inject('chatService', 'state')
@observer
export default class ChatCreationMenu extends React.Component<IProps> {
  private readonly store: ChatCreationMenuStore = null

  public constructor(props: IProps) {
    super(props)
    this.store = props.store
  }

  public componentDidMount() {
    this.props.onMount?.()
  }

  public render() {
    if (this.store.isDirectChatBeingOpened || this.isLoading) {
      return <Loader size={40} hint="Preparing" />
    }

    if (this.store.isGroupMembersSelectionCompleted) {
      return (
        <ChatDetails
          chatName={this.store.groupName}
          chatAvatarUrl={this.store.avatarUrl}
          onChatAvatarUrlChange={this.store.changeAvatarUrl}
          onChatNameChange={this.store.changeGroupName}
          members={this.store.selectedMembers}
          onMemberExclude={this.store.toggleContactSelection}
          creatorId={this.store.activeUserId}
          isMemberOnline={userId => this.props.onlineContacts.has(userId)}
        />
      )
    }

    return (
      <div className="col full-height">
        {this.store.isGroupMode ? (
          <ChatMembers
            className="py15 ml10"
            selectedMembers={this.store.selectedMembers}
            onExclude={this.store.toggleContactSelection}
            isMemberOnline={userId => this.props.onlineContacts.has(userId)}
          />
        ) : (
          <div
            className="row text bold large blue pointer pa15 x-end"
            onClick={this.store.setGroupMode}
          >
            <Icons.Company
              style={{ minWidth: '20px', minHeight: '20px' }}
              className="row no-grow w20 h20 mr8"
            />
            New Group
          </div>
        )}

        <UsersDirectory
          className="overflow-y-auto"
          useExternalSearch={true}
          externalSearchQuery={this.store.searchKey}
          isSelectionMode={this.store.isGroupMode}
          onUserRowClick={this.store.handleUserRowClick}
          onUserAddClick={userId => this.store.toggleContactSelection(userId)}
          onUserRemoveClick={userId =>
            this.store.toggleContactSelection(userId)
          }
          selectedUsers={this.store.selectedMembers}
          userPredicate={user => user.id !== this.store.activeUserId}
          isUserOnline={userId => this.props.onlineContacts.has(userId)}
        />
      </div>
    )
  }

  public componentWillUnmount() {
    this.props.onUnmount?.()
    this.store.clear()
  }

  private get isLoading(): boolean {
    return this.props.state.loading.get(GET_PROJECT_MEMBERS)
  }
}
