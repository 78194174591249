import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import { IAppConfig } from '~/client/src/shared/Config'
import IFilePreviewProperties from '~/client/src/shared/interfaces/IFilePreviewProperties'
import ConstraintsStore from '~/client/src/shared/stores/domain/Constraints.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'

import Localization from '../../localization/LocalizationManager'
import { ICalendarDates } from '../../models/CalendarEvent'
import SitePermit from '../../models/Permit'
import EventsStore from '../../stores/EventStore/Events.store'
import InitialState from '../../stores/InitialState'
import AnnouncementAssignmentsStore from '../../stores/domain/AnnouncementAssignments.store'
import AnnouncementsStore from '../../stores/domain/Announcements.store'
import CompaniesStore from '../../stores/domain/Companies.store'
import { FileUploadingStore } from '../../stores/domain/FileUploading.store'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '../../stores/domain/MaterialCategories.store'
import MaterialsStore from '../../stores/domain/Materials.store'
import MessagesStore from '../../stores/domain/MessagesStore/Messages.store'
import OperationRulesStore from '../../stores/domain/OperationRules.store'
import PermitBicInspectionsStore from '../../stores/domain/PermitBicInspections.store'
import PermitInspectionChangesStore from '../../stores/domain/PermitInspectionChanges.store'
import PermitInspectionsStore from '../../stores/domain/PermitInspections.store'
import PermitStatusChangesStore from '../../stores/domain/PermitStatusChanges.store'
import PermitTypesStore from '../../stores/domain/PermitTypes.store'
import PhotosStore from '../../stores/domain/Photos.store'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import SitePermitFollowingsStore from '../../stores/domain/SitePermitFollowings.store'
import TagsStore from '../../stores/domain/Tags.store'
import ThreadsStore from '../../stores/domain/ThreadsStore/Threads.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'
import PermitBallInCourtStore from '../../stores/ui/PermitBallInCourt.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import AnnouncementEditionFormStore from '../AnnouncementEditionForm/AnnouncementEditionForm.store'
import CalendarPlaceholderStore from '../CalendarDayView/components/CalendarPlaceholder.store'
import PermitCompactHeaderBar from '../CompactHeaderBar/components/PermitCompactHeaderBar'
import EntityDateMoveConfirm from '../EntityDateMoveConfirm/EntityDateMoveConfirm'
import FileInputBase from '../FileInput/FileInput'
import PermitLogActionBarStore from '../PermitLogActionBar.store'
import FormReportGenerationStore from './FormReportGeneration.store'
import PermitApprovalStore from './PermitApproval.store'
import SitePermitCreationFormStore from './SitePermitCreationForm.store'
import SitePermitCreationFormContent from './SitePermitCreationFormContent'
import FormActionsMenu from './components/FormActionsMenu'
import FormDeleteConfirm from './components/FormDeleteConfirm'
import PermitActionBar from './components/PermitActionBar/PermitActionBar'
import PermitFormLoader from './components/PermitFormLoader'

import './SitePermitCreationForm.scss'

interface IProps {
  permitToShow: SitePermit
  isMobileMode?: boolean
  calendarEventDates?: ICalendarDates
  isDateMoveConfirmModalOpen?: boolean
  FileInputType: typeof FileInputBase

  close(): void
  onDuplicateClick(workflow: SitePermit): void
  openFilePreview?: (
    files: IFilePreviewProperties[],
    currentIndex: number,
  ) => void
  hideDateMoveConfirmModal?: () => void

  projectDateStore?: ProjectDateStore
  announcementsStore?: AnnouncementsStore
  eventsStore?: EventsStore
  fileUploadingStore?: FileUploadingStore
  companiesStore?: CompaniesStore
  permitTypesStore?: PermitTypesStore
  sitePermitsStore?: SitePermitsStore
  announcementAssignmentsStore?: AnnouncementAssignmentsStore
  operationRulesStore?: OperationRulesStore
  permitStatusChangesStore?: PermitStatusChangesStore
  permitBicInspectionsStore?: PermitBicInspectionsStore
  permitInspectionsStore?: PermitInspectionsStore
  permitInspectionChangesStore?: PermitInspectionChangesStore
  threadsStore?: ThreadsStore
  messagesStore?: MessagesStore
  photosStore?: PhotosStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  tagsStore?: TagsStore
  calendarPlaceholderStore?: CalendarPlaceholderStore
  locationAttributesStore?: LocationAttributesStore
  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoriesStore
  sitePermitFollowingsStore?: SitePermitFollowingsStore
  configuration?: IAppConfig
  sitemapItemsStore?: SitemapItemsStore
  sitemapsStore?: SitemapsStore
  constraintsStore?: ConstraintsStore
}

@inject(
  'projectDateStore',
  'announcementsStore',
  'eventsStore',
  'fileUploadingStore',
  'companiesStore',
  'permitTypesStore',
  'sitePermitsStore',
  'operationRulesStore',
  'permitStatusChangesStore',
  'threadsStore',
  'photosStore',
  'messagesStore',
  'permitBicInspectionsStore',
  'permitInspectionsStore',
  'permitInspectionChangesStore',
  'userProjectsStore',
  'projectMembersStore',
  'tagsStore',
  'announcementAssignmentsStore',
  'locationAttributesStore',
  'materialsStore',
  'materialCategoryStore',
  'calendarPlaceholderStore',
  'sitePermitFollowingsStore',
  'configuration',
  'sitemapItemsStore',
  'sitemapsStore',
  'constraintsStore',
)
@observer
export default class SitePermitViewForm extends React.Component<IProps> {
  private readonly store: SitePermitCreationFormStore
  private readonly permitApprovalStore: PermitApprovalStore
  private readonly announcementFormStore: AnnouncementEditionFormStore
  private readonly permitBicStore: PermitBallInCourtStore
  private readonly actionBarStore: PermitLogActionBarStore
  private readonly formReportGenerationStore: FormReportGenerationStore

  @observable private isStatusSelectorOpened: boolean = false
  @observable private isEditMode: boolean = false
  @observable private isActionsMenuShown = false

  @computed
  private get isFormReadOnly(): boolean {
    const { existingPermit, isFormEditAllowedForUser } = this.store
    return (
      existingPermit.isDeleted ||
      existingPermit.isDoneOrDenied ||
      !(isFormEditAllowedForUser || this.canChangeStatus)
    )
  }

  public constructor(props: IProps) {
    super(props)

    this.store = new SitePermitCreationFormStore(
      props.eventsStore,
      props.projectDateStore,
      props.fileUploadingStore,
      props.permitTypesStore,
      props.permitBicInspectionsStore,
      props.userProjectsStore,
      props.projectMembersStore,
      props.companiesStore,
      props.locationAttributesStore,
      props.materialsStore,
      props.materialCategoryStore,
      props.tagsStore,
      props.constraintsStore,
      props.sitePermitsStore,
      props.permitStatusChangesStore,
      props.permitInspectionsStore,
      props.permitInspectionChangesStore,
      props.calendarPlaceholderStore,
    )

    this.store.setDefaultSitePermit(props.permitToShow.id)

    this.announcementFormStore = new AnnouncementEditionFormStore(
      props.eventsStore,
      props.projectDateStore,
      props.announcementsStore,
      props.announcementAssignmentsStore,
    )

    this.formReportGenerationStore = new FormReportGenerationStore(
      props.projectDateStore,
      props.eventsStore,
      props.configuration,
      props.sitemapItemsStore,
      props.sitemapsStore,
      props.locationAttributesStore,
      props.projectMembersStore,
      props.permitTypesStore,
    )

    this.permitApprovalStore = new PermitApprovalStore(
      this.store,
      this.announcementFormStore,
      props.permitTypesStore,
      this.formReportGenerationStore,
      props.close,
    )

    this.permitBicStore = new PermitBallInCourtStore(
      props.eventsStore,
      props.operationRulesStore,
      props.projectMembersStore,
    )

    this.actionBarStore = new PermitLogActionBarStore(
      props.eventsStore,
      props.threadsStore,
      props.messagesStore,
      props.photosStore,
      props.fileUploadingStore,
      props.userProjectsStore,
      props.tagsStore,
      props.sitePermitFollowingsStore,
    )

    this.store.loadFormLogData()
  }

  public componentDidUpdate(prevProps: IProps) {
    const { permitToShow } = this.props

    if (permitToShow !== prevProps.permitToShow) {
      const {
        inspectionFieldsStore,
        setDefaultSitePermit,
        scrollToBottom,
        hideNonWorkTimeLabel,
        unblockSubmitButton,
        markPermitAsNonWorkTimeIfNeed,
        resetSelectedField,
        workflowFieldsStore,
      } = this.store

      inspectionFieldsStore.deselectInspection()
      unblockSubmitButton()
      hideNonWorkTimeLabel()

      resetSelectedField()
      setDefaultSitePermit(permitToShow.id)

      markPermitAsNonWorkTimeIfNeed()
      workflowFieldsStore.expandAllSections()

      this.disableEditMode()

      Promise.resolve().then(() => scrollToBottom())
    }

    if (permitToShow.id !== prevProps.permitToShow.id) {
      this.store.clearFormLogData()
      this.store.loadFormLogData()
    }
  }

  public componentWillUnmount(): void {
    this.store.clearFormLogData()
  }

  public render() {
    const {
      close,
      onDuplicateClick,
      permitTypesStore,
      FileInputType,
      projectDateStore,
      isDateMoveConfirmModalOpen,
      sitePermitsStore,
      isMobileMode,
    } = this.props

    const {
      shouldCreateAnnouncement,
      toggleAnnouncement,
      isAnnouncementCreationAvailable,
    } = this.permitApprovalStore

    const {
      editablePermit,
      existingPermit,
      shouldShowInspectionPopup,
      template,
      isDeleteConfirmModalOpen,
      toggleDeleteConfirmModal,
      deleteForm,
      isLoading,
    } = this.store

    const isAnnouncementCheckboxShown =
      !!template &&
      isAnnouncementCreationAvailable &&
      !shouldShowInspectionPopup &&
      this.canChangeStatus

    return (
      <div className="site-permit-creation-form full-height relative">
        <div className="site-permit-creation-form-content full-height col">
          <PermitCompactHeaderBar
            permit={editablePermit}
            permitTypesStore={permitTypesStore}
            onCloseClick={close}
            isMenuDisabled={isLoading || sitePermitsStore?.isDeleting}
            onMenuClick={this.toggleActionsMenu}
          />
          <div className="full-height col overflow-hidden relative">
            <FormActionsMenu
              isShown={this.isActionsMenuShown}
              onHide={this.toggleActionsMenu}
              onUpdateStatusClick={this.toggleStatusSelectMenu}
              onReportSubmitClick={
                this.formReportGenerationStore.generateAndSendReport
              }
              onDuplicateClick={onDuplicateClick}
              store={this.store}
            />
            <FormDeleteConfirm
              isShown={isDeleteConfirmModalOpen}
              onHide={toggleDeleteConfirmModal}
              onApply={deleteForm}
            />
            <EntityDateMoveConfirm
              isShown={!this.isFormReadOnly && isDateMoveConfirmModalOpen}
              title={Localization.translator.updateForm}
              onHide={this.hideMovePermitDatesModal}
              onApply={this.modePermitDates}
            />
            <PermitFormLoader
              permitCreationStore={this.store}
              permitActionBarStore={this.actionBarStore}
            />
            <SitePermitCreationFormContent
              store={this.store}
              isReadonly={this.isFormReadOnly}
              FileInputType={FileInputType}
              isViewMode={this.isViewMode}
              toggleMode={this.toggleEditMode}
              onPermitClose={close}
              permitLogActionBarStore={this.actionBarStore}
            />
            {!!template && !existingPermit.isDeleted && (
              <PermitActionBar
                shouldCreateAnnouncement={shouldCreateAnnouncement}
                toggleAnnouncement={toggleAnnouncement}
                isAnnouncementCheckboxShown={isAnnouncementCheckboxShown}
                isStatusSelectorOpened={this.isStatusSelectorOpened}
                isActionBarInReadOnlyMode={this.isActionBarReadOnly}
                canChangeStatus={this.canChangeStatus}
                toggleStatusSelectMenu={this.toggleStatusSelectMenu}
                state={this.appState}
                store={this.store}
                permitApprovalStore={this.permitApprovalStore}
                actionBarStore={this.actionBarStore}
                projectDateStore={projectDateStore}
                isMobileMode={isMobileMode}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  @action.bound
  private toggleStatusSelectMenu() {
    this.isStatusSelectorOpened = !this.isStatusSelectorOpened
  }

  @action.bound
  private toggleActionsMenu() {
    this.store.resetSelectedField()

    this.isActionsMenuShown = !this.isActionsMenuShown
  }

  private hideMovePermitDatesModal = () => {
    this.props.hideDateMoveConfirmModal()
    this.props.close()
  }

  private modePermitDates = () => {
    this.store.applyDates(
      this.props.calendarEventDates.startDate,
      this.props.calendarEventDates.endDate,
    )

    this.props.hideDateMoveConfirmModal()
  }

  @computed
  private get isActionBarReadOnly(): boolean {
    return (
      !this.appState.userActiveProjectSettings.isAdminOrFormsMaster &&
      this.isFormReadOnly
    )
  }

  private get isViewMode(): boolean {
    const { isRequestedOrSubmitted, isChanged } = this.store.editablePermit
    return !this.isEditMode && !isRequestedOrSubmitted && !isChanged
  }

  private toggleEditMode = (event: React.MouseEvent<HTMLDivElement>) => {
    this.isEditMode = !this.isEditMode
    event.stopPropagation()
  }

  @action.bound
  private disableEditMode() {
    this.isEditMode = false
  }

  private get appState(): InitialState {
    return this.props.eventsStore.appState
  }

  private get canChangeStatus(): boolean {
    const { existingPermit, template } = this.store
    const {
      isRequesterOrAssignee,
      isApprovalStep,
      isBicStep,
      isReviewStep,
      currentWorkflowStepId,
    } = existingPermit
    const { user, userActiveProjectSettings } = this.appState

    if (userActiveProjectSettings.isAdminOrFormsMaster) return true

    const stepRuleIds = template.getStepRuleIds(currentWorkflowStepId)
    const isAllowedByRules = this.permitBicStore.isUserAllowedByBicRules(
      user.id,
      existingPermit,
      stepRuleIds,
    )
    const isLimitedStep =
      isReviewStep(template) || isApprovalStep(template) || isBicStep(template)

    return isLimitedStep
      ? isAllowedByRules
      : isAllowedByRules || isRequesterOrAssignee(user.id)
  }
}
