import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import DeliveryActionBarStore from '~/client/src/shared/stores/ui/DeliveryActionBar.store'

import DeliveryDetailsStore from '../DeliveryDetails.store'

// translated

interface IProps {
  onCreationCb: (deliveryId: string) => void
  deliveryDetailsStore?: DeliveryDetailsStore
  deliveryActionBarStore: DeliveryActionBarStore
  buttonRenderer?: (
    btnText: string,
    isValid: boolean,
    status: DeliveryStatus,
    isDeny?: boolean,
    customBtnHandler?: () => void,
  ) => JSX.Element
  className?: string
}

@inject('deliveryDetailsStore')
@observer
export default class DeliveryActionButtons extends React.Component<IProps> {
  public render() {
    const {
      deliveryDetailsStore: {
        isSubmitButtonEnabled,
        isAnyDialogOpened,
        displayedDelivery,
        currentStatusActions,
        areFieldsChanged,
        isFromConcreteDirect,
      },
      deliveryActionBarStore: { isMenuPopupShown },
      buttonRenderer,
      className,
    } = this.props

    const isSubmitButtonVisible =
      (!isFromConcreteDirect || areFieldsChanged) &&
      (!displayedDelivery || displayedDelivery.canChange)
    const isActionButtonShown =
      !isFromConcreteDirect || displayedDelivery?.isPending

    return (
      !isMenuPopupShown && (
        <div className={classList({ row: true, [className]: !!className })}>
          <div className="row">
            {isSubmitButtonVisible && this.renderSubmitButton()}
            {isActionButtonShown &&
              currentStatusActions.map(
                action =>
                  !action.isHidden &&
                  buttonRenderer(
                    action.actionTitle,
                    !action.isDisabled &&
                      !isSubmitButtonEnabled &&
                      !isAnyDialogOpened,
                    action.status,
                    action.status === DeliveryStatus.Denied,
                    action.onClick,
                  ),
              )}
            {displayedDelivery && displayedDelivery.isDone && (
              <div className="text bold large center">
                {Localization.translator.done}
              </div>
            )}
            {displayedDelivery && displayedDelivery.isCanceled && (
              <div className="text bold large center">
                {Localization.translator.canceled}
              </div>
            )}
          </div>
        </div>
      )
    )
  }

  private renderSubmitButton(): JSX.Element {
    const {
      submitTitleAndStatus,
      isSubmitButtonEnabled,
      changeDelivery,
      setNewDeliveryStatus,
      areFieldsChanged,
      isDenyButtonEnabled,
      isAnyDialogOpened,
      onButtonClick,
    } = this.props.deliveryDetailsStore

    const submitButtonAction = areFieldsChanged
      ? onButtonClick.bind(
          null,
          changeDelivery.bind(null, this.props.onCreationCb),
          submitTitleAndStatus.title,
        )
      : setNewDeliveryStatus.bind(this, DeliveryStatus.Denied)
    const isSubmitButtonDisabled = areFieldsChanged
      ? !isSubmitButtonEnabled
      : !isDenyButtonEnabled

    if (this.props.buttonRenderer) {
      return this.props.buttonRenderer(
        submitTitleAndStatus.title,
        !isSubmitButtonDisabled && !isAnyDialogOpened,
        submitTitleAndStatus.status,
        submitTitleAndStatus.status === DeliveryStatus.Denied,
        submitButtonAction,
      )
    }

    return (
      <div
        onClick={submitButtonAction}
        className={classList({
          'action-button': true,
          disabled: isSubmitButtonDisabled || isAnyDialogOpened,
        })}
      >
        {submitTitleAndStatus.title}
      </div>
    )
  }
}
