import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import ThreadIndicators from '~/client/src/shared/components/ThreadIndicators/ThreadIndicators'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'

import CategoriesOfVarianceStore from '../../../stores/domain/CategoriesOfVariance.store'
import SafetyHazardsStore from '../../../stores/domain/SafetyHazards.store'
import ProjectDateStore from '../../../stores/ui/ProjectDate.store'

interface IStatusUpdateAmount {
  manpower: number
  percentComplete: number
}

interface IProps {
  activity: Activity
  currentViewDate?: Date
  commonStatusUpdate: StatusUpdate
  sectionCompanyName?: string
  rfisStore?: RfisStore
  scheduleCommentsStore?: ScheduleCommentsStore
  categoriesOfVarianceStore?: CategoriesOfVarianceStore
  safetyHazardsStore?: SafetyHazardsStore
  flagsStore?: FlagsStore
  deliveriesStore?: DeliveriesStore
  hideCriticalPath?: boolean
  projectDateStore?: ProjectDateStore
}

@inject(
  'rfisStore',
  'flagsStore',
  'deliveriesStore',
  'scheduleCommentsStore',
  'categoriesOfVarianceStore',
  'safetyHazardsStore',
  'projectDateStore',
)
@observer
export default class ActivityThreadIndicators extends React.Component<IProps> {
  @computed
  private get hasRfi(): boolean {
    const { rfisStore, activity } = this.props
    return !!rfisStore.list.filter(
      rfi => rfi.isOpen && rfi.activityObjectId === activity.code,
    ).length
  }

  @computed
  private get hasScheduleComment(): boolean {
    const { scheduleCommentsStore, activity } = this.props
    return !!scheduleCommentsStore.list.filter(
      sc => sc.isOpen && sc.activityObjectId === activity.code,
    ).length
  }

  @computed
  private get hasCategoryOfVariance(): boolean {
    const { categoriesOfVarianceStore, activity } = this.props
    return !!categoriesOfVarianceStore.list.filter(
      cov => cov.isOpen && cov.activityObjectId === activity.code,
    ).length
  }

  @computed
  private get hasSafetyHazard(): boolean {
    const { safetyHazardsStore, activity } = this.props
    return !!safetyHazardsStore.list.filter(
      sh => sh.isOpen && sh.activityObjectId === activity.code,
    ).length
  }

  @computed
  private get hasFlag(): boolean {
    const { flagsStore, activity } = this.props
    return !!flagsStore.list.filter(
      flag => flag.isOpen && flag.activityObjectId === activity.code,
    ).length
  }

  @computed
  private get hasDelivery(): boolean {
    const { deliveriesStore, activity, currentViewDate, projectDateStore } =
      this.props

    if (!currentViewDate) {
      return false
    }

    return !!deliveriesStore.list.filter(
      delivery =>
        delivery.activityId === activity.code &&
        !delivery.isDeleted &&
        projectDateStore.isSameDay(
          new Date(delivery.startDate),
          currentViewDate,
        ),
    ).length
  }

  private get actualStatusUpdateAmount(): IStatusUpdateAmount {
    const {
      commonStatusUpdate,
      sectionCompanyName,
      currentViewDate,
      projectDateStore,
    } = this.props

    const { dateFor, didUpdateByCompany } = commonStatusUpdate

    if (!projectDateStore.isSameDay(new Date(+dateFor), currentViewDate)) {
      return {
        percentComplete: 0,
        manpower: 0,
      }
    }

    const didUpdatePercentComplete = sectionCompanyName
      ? didUpdateByCompany(
          sectionCompanyName,
          projectDateStore.isSameDay,
          true,
          false,
        )
      : true

    const didUpdateManpower = didUpdateByCompany(
      sectionCompanyName,
      projectDateStore.isToday,
      false,
      true,
    )

    return {
      percentComplete: didUpdatePercentComplete ? 1 : 0,
      manpower: didUpdateManpower ? 1 : 0,
    }
  }

  public render() {
    const { activity } = this.props
    const { isOnCriticalPath } = activity

    return (
      <ThreadIndicators
        onlyIcons={true}
        hasRfi={this.hasRfi}
        hasFlag={this.hasFlag}
        hasScheduleComment={this.hasScheduleComment}
        hasCategoryOfVariance={this.hasCategoryOfVariance}
        hasSafetyHazard={this.hasSafetyHazard}
        hasDelivery={this.hasDelivery}
        hasCriticalPath={isOnCriticalPath}
        totalAmount={1}
        actualAmount={this.actualStatusUpdateAmount.percentComplete}
        updatedActivitiesAmountWithManpower={
          this.actualStatusUpdateAmount.manpower
        }
      />
    )
  }
}
