import { action, computed, observable } from 'mobx'

import { DeliveryFilterType } from '~/client/graph'
import {
  BasicDataKeys,
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { IFilters } from '~/client/src/shared/stores/InitialState'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { sortCaseInsensitive } from '~/client/src/shared/utils/collections'

// localization: translated

export type SortState = { columnKey: string; order: SortOrder }

export const CATEGORY_ROW_HEIGHT = 31
export const DEFAULT_ID_KEY = 'id'
const DEFAULT_GROUPING_KEY = 'company'
const DEFAULT_SORT_STATE: SortState = {
  columnKey: null,
  order: SortOrder.DEFAULT,
}

export interface ITreeNodeObj {
  id: string
  name: string
  object?: any

  showEmpty?: boolean
}

export default abstract class BaseListStore<T> {
  @observable public scrollToRow: number = 0
  @observable public collapsedCategories: Map<string, boolean> = new Map<
    string,
    boolean
  >()
  public abstract get columns(): ILWFCColumn[]
  public sortState = observable<SortState>({ ...DEFAULT_SORT_STATE })
  @observable protected selection: Map<string, boolean> = new Map()
  @observable protected hiddenSelection: Map<string, boolean> = new Map()

  public constructor(
    protected filter: IFilters,
    protected sourceCollection: () => T[],
    private searchKeys: string[] = [],
    protected idKey: string = DEFAULT_ID_KEY,
    protected shouldShowInstanceCount: boolean = true,
    private readonly allowEmptyCategories: boolean = false,
    public readonly showCategoriesWithEmptyTable: boolean = false,
  ) {}

  public get displayedCount(): number {
    return this.filteredCollection.length
  }

  public get selectedInstancesCount(): number {
    return this.selection.size
  }

  protected get instancesInPeriodInterval() {
    return this.sourceCollection()
  }

  @computed
  public get selectedInstances(): T[] {
    return this.sourceCollection().filter(inst =>
      this.selection.get(inst[this.idKey]),
    )
  }

  @computed
  public get hiddenSelectedInstances(): T[] {
    return this.sourceCollection().filter(inst =>
      this.hiddenSelection.get(inst[this.idKey]),
    )
  }

  @computed
  public get selectedInstancesIds(): string[] {
    // !in order of selection
    return [...this.selection.keys()]
  }

  @computed
  public get hiddenSelectedInstancesIds(): string[] {
    // !in order of selection
    return [...this.hiddenSelection.keys()]
  }

  @computed
  public get rows(): ILWFCRow[] {
    let rows = [{ data: {} }]

    const categories = Object.keys(this.categoryToInstancesMap)
    const sortedCategories = [
      ...this.sortCategories(categories).filter(c => c !== UNASSIGNED),
      UNASSIGNED,
    ]

    sortedCategories.map(category => {
      const instances = this.categoryToInstancesMap[category]

      if (!instances || (!instances.length && !this.allowEmptyCategories)) {
        return
      }

      rows.push(this.createCategoryRow(category, instances.length))

      if (this.collapsedCategories.get(category)) {
        return
      }

      const instancesRows = this.toRows(instances, category)

      if (this.shouldSort) {
        instancesRows.sort(this.compareRows)
      }

      rows = rows.concat(instancesRows)
    })

    return rows
  }

  // used in CustomRowRender
  public isRowSelected(row: ILWFCRow) {
    return row.data[BasicDataKeys.CHECKBOX]
  }

  public get isSomeRowSelected(): boolean {
    return !!this.selection.size
  }

  @computed
  public get selectAllTitle(): string {
    return `${Localization.translator.selectAll_items} (${this.displayedCount})`
  }

  @action.bound
  public selectAll() {
    this.filteredCollection.forEach(inst =>
      this.setInstanceSelection(inst[this.idKey], true),
    )
  }

  @action.bound
  public unselectAll() {
    this.filteredCollection.forEach(inst =>
      this.setInstanceSelection(inst[this.idKey], false),
    )
  }

  @computed
  public get lastSelectedInstanceId(): string {
    if (!this.selection.size) {
      return null
    }

    return Array.from(this.selection)[this.selection.size - 1][0]
  }

  @action.bound
  public resetSelection() {
    this.selection.clear()
  }

  @action.bound
  public resetHiddenSelection() {
    this.hiddenSelection.clear()
  }

  @computed
  public get groupingKey() {
    return this.filter.groupingKey || DEFAULT_GROUPING_KEY
  }

  @action.bound
  public toggleCategory(category: ILWFCCategory) {
    this.internalToggleCategory(category)
  }

  @action.bound
  public toggleCategoryCollapsing(categoryId: string) {
    const isCollapsed = this.collapsedCategories.get(categoryId)
    this.collapsedCategories.set(categoryId, !isCollapsed)
  }

  public getFilteredCollectionExcludeFilter = (
    excludedFilters: Array<DeliveryFilterType | string> = [],
  ) => {
    let filteredCollection = this.isSearchFilterActive
      ? this.instancesInPeriodInterval.filter(this.searchFiltering)
      : this.instancesInPeriodInterval

    this.activeFilterTypes.forEach(filterType => {
      const shouldUseFilter = !excludedFilters.includes(filterType)

      if (!shouldUseFilter) {
        return
      }

      const filter = this.filter.fieldsMap[filterType]
      const { appliedFilterOptions } = filter

      filteredCollection = filteredCollection.filter(inst =>
        appliedFilterOptions.includes(inst[this.idKey]),
      )
    })

    return filteredCollection
  }

  public toggleInstance = (instanceId: string) => {
    const isSelected = this.selection.get(instanceId)
    return this.setInstanceSelection(instanceId, !isSelected)
  }

  public toggleHiddenInstance = (instanceId: string) => {
    const isSelected = this.hiddenSelection.get(instanceId)
    return this.setHiddenInstanceSelection(instanceId, !isSelected)
  }

  @action.bound
  public setInstanceAsLastSelected(instanceId: string) {
    this.selection.delete(instanceId) // to ensure correct order of keys
    this.selection.set(instanceId, true)
  }

  @action.bound
  public setHiddenInstanceAsLastSelected(instanceId: string) {
    this.hiddenSelection.delete(instanceId) // to ensure correct order of keys
    this.hiddenSelection.set(instanceId, true)
  }

  @action.bound
  public collapseAllCategories() {
    this.rows.forEach(
      ({ category }) =>
        category?.categoryId &&
        this.collapsedCategories.set(category.categoryId, true),
    )
  }

  @action.bound
  public expandAllCategories() {
    this.rows.forEach(
      ({ category }) =>
        category?.categoryId &&
        this.collapsedCategories.set(category.categoryId, false),
    )
  }

  @action.bound
  public resetCollapsing() {
    this.collapsedCategories.clear()
  }

  @action.bound
  public handleColumnSort(columnKey: string) {
    const { columnKey: currentColumnKey } = this.sortState

    if (currentColumnKey !== columnKey) {
      Object.assign(this.sortState, { columnKey, order: SortOrder.ASC })
      return
    }

    const { order: currentOrder } = this.sortState
    switch (currentOrder) {
      case SortOrder.ASC:
        this.sortState.order = SortOrder.DESC
        return
      case SortOrder.DESC:
        Object.assign(this.sortState, { ...DEFAULT_SORT_STATE })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canSelectInstance(inst: T) {
    return true
  }

  @action.bound
  public scrollToInstance(instanceId: string) {
    this.scrollToRow = this.getRowIndexByInstanceId(instanceId)
  }

  @computed
  public get filteredCollection() {
    return this.getFilteredCollectionExcludeFilter()
  }

  public get isSourceCollectionEmpty(): boolean {
    return !this.sourceCollection().length
  }

  public get alternateHint(): string {
    return Localization.translator.thereAreNoInstancesToDisplay()
  }

  protected internalToggleCategory({
    categoryId,
    isChecked,
    idsToSelect,
  }: ILWFCCategory) {
    if (idsToSelect && idsToSelect.length) {
      idsToSelect.forEach(id => this.setInstanceSelection(id, !isChecked))
      return
    }

    this.filteredCollection.forEach(inst => {
      const instanceCategoryId = this.formatCategoryId(
        inst[this.groupingKey],
        inst,
      )

      if (instanceCategoryId === categoryId) {
        this.setInstanceSelection(inst[this.idKey], !isChecked)
      }
    })
  }

  protected setInstanceSelection(instanceId: string, newState: boolean) {
    if (newState) {
      this.selection.set(instanceId, true)
    } else {
      this.selection.delete(instanceId)
    }
  }

  protected setHiddenInstanceSelection(instanceId: string, newState: boolean) {
    if (newState) {
      this.hiddenSelection.set(instanceId, true)
    } else {
      this.hiddenSelection.delete(instanceId)
    }
  }

  protected abstract get categoryToInstancesMap(): { [key: string]: T[] }

  protected sortCategories(keys: string[]): string[] {
    return sortCaseInsensitive(keys)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected compareRows = (row1: ILWFCRow, row2: ILWFCRow): number => {
    return 0
  }

  protected abstract toRows(
    instances: T[],
    category?: string,
    level?: number,
  ): ILWFCRow[]

  protected get bandObjectMap(): {
    [bandType: string]: { [groupName: string]: T[] }
  } {
    throw new Error('[bandObjectMap] should be overridden in derived classes')
  }

  @computed
  protected get activeFilterTypes(): string[] {
    const { fieldsMap = {} } = this.filter
    return Object.keys(fieldsMap).filter(
      filterType => fieldsMap[filterType].isFilterActive,
    )
  }

  protected searchFiltering = (model: T) => {
    const key = this.filter.searchKey.toLowerCase()
    return this.searchKeys.some(k => model[k]?.toLowerCase().includes(key))
  }

  protected createCategoryRow(categoryId: string, count: number): ILWFCRow {
    const countLabel = this.shouldShowInstanceCount ? ` (${count})` : ''
    const category: ILWFCCategory = {
      categoryId,
      categoryLabel: this.getCategoryLabelById(categoryId) + countLabel,
      isChecked: this.categoryToInstancesMap[categoryId].every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
    }

    return { category, data: {}, height: CATEGORY_ROW_HEIGHT }
  }

  protected createTreeNodeCategoryRow(
    categoryId: string,
    categoryName: string,
    categoryObject: any,
    level: number,
    objects: T[],
  ): ILWFCRow {
    const countLabel = this.shouldShowInstanceCount
      ? ` (${objects.length})`
      : ''
    const label = categoryName + countLabel

    const category: ILWFCCategory = {
      categoryId,
      shortCategoryLabel: label,
      categoryLabel: label,
      isChecked: objects.every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
      idsToSelect: objects.map(d => d[this.idKey]),
    }

    return {
      category,
      data: categoryObject,
      height: CATEGORY_ROW_HEIGHT,
      level,
    }
  }

  protected getCategoryLabelById(categoryId: string): string {
    return categoryId
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected formatCategoryId(categoryId: any, instance: T) {
    return categoryId || UNASSIGNED
  }

  private getRowIndexByInstanceId(instanceId: string): number {
    return this.rows.findIndex(
      ({ category, data }) =>
        !category && data[BasicDataKeys.ID] === instanceId,
    )
  }

  protected get shouldSort(): boolean {
    const { columnKey, order } = this.sortState
    return !!columnKey && order !== SortOrder.DEFAULT
  }

  protected get isSearchFilterActive(): boolean {
    return !!this.filter.searchKey
  }
}
