import * as React from 'react'

import { Popover, PopoverPosition, PopperModifiers } from '@blueprintjs/core'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import EventsStore from '../../stores/EventStore/Events.store'
import { SAVE_PROJECT_COLORING_OPTIONS } from '../../stores/EventStore/eventConstants'
import InitialState from '../../stores/InitialState'
import ColorPalette from './ColorPalette'

import './ColorPicker.scss'

interface IProps {
  colors?: string[]
  value: string
  arePickerExpanded: boolean
  canAddNewColors?: boolean
  state?: InitialState
  eventsStore?: EventsStore

  onChange(color: string): void
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: true,
    boundariesElement: 'scrollParent',
  },
  hide: { enabled: false },
  computeStyle: { gpuAcceleration: false },
  arrow: { enabled: false },
}

@inject('state', 'eventsStore')
@observer
export default class ColorPicker extends React.Component<IProps> {
  @observable private areColorPaletteExpanded = false
  @observable private currentColor = null

  public componentDidUpdate(props: IProps) {
    if (this.props.value !== props.value) {
      this.currentColor = props.value
    }
  }

  @computed
  private get colors() {
    const { colors, value } = this.props
    const fullColors = [...colors]
    if (value && !colors.includes(value)) {
      fullColors.push(value)
    }
    if (this.currentColor && !colors.includes(this.currentColor)) {
      fullColors.push(this.currentColor)
    }

    return fullColors
  }

  public render() {
    const {
      value,
      arePickerExpanded,
      canAddNewColors = true,
      onChange,
    } = this.props
    return (
      <div className="colors-container">
        {this.colors.map((color, idx) => (
          <div
            key={idx}
            className={classList({
              'color-option': true,
              selected: color === value,
            })}
            style={{ backgroundColor: color }}
            onClick={onChange.bind(this, color)}
          />
        ))}
        {canAddNewColors && (
          <Popover
            className="inline-block"
            position={PopoverPosition.BOTTOM}
            minimal={true}
            usePortal={false}
            isOpen={arePickerExpanded}
            canEscapeKeyClose={false}
            modifiers={popoverPopperModifiers}
            content={this.renderColorPaletteSelector()}
          >
            <div
              className="color-option pointer"
              key="add-button"
              onClick={this.onPlusButtonClick}
            >
              <Icons.AddBordered />
            </div>
          </Popover>
        )}
      </div>
    )
  }

  private renderColorPaletteSelector = () => {
    return (
      this.areColorPaletteExpanded && (
        <ColorPalette
          value={this.currentColor || this.props.value}
          onChange={this.onChangeComplete}
          onClose={this.collapseColorPalette}
        />
      )
    )
  }

  @action.bound
  private collapseColorPalette() {
    this.setNewColor()
    this.areColorPaletteExpanded = false
  }

  @action.bound
  private onPlusButtonClick() {
    this.setNewColor()
    this.areColorPaletteExpanded = true
  }

  @action.bound
  private onChangeComplete(color: string) {
    if (!this.colors.includes(color)) {
      this.currentColor = color
    } else {
      this.currentColor = null
    }
  }

  @action.bound
  private addColorToOptions(color: string) {
    const options = this.props.state.projectColoringOptions
    const colors = options.colors || []
    if (!colors.includes(color)) {
      colors.push(color)
      options.colors = colors
      this.props.eventsStore.dispatch(SAVE_PROJECT_COLORING_OPTIONS, options)
    }
  }

  @action.bound
  private setNewColor() {
    if (this.currentColor) {
      this.addColorToOptions(this.currentColor)
      this.currentColor = null
    }
  }
}
