import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IProjectColoringOptionsFieldsFragment = Pick<
  Types.IProjectColoringOptions,
  | 'createdAt'
  | 'id'
  | 'isWWPColoringDisabled'
  | 'colors'
  | 'updatedAt'
  | 'projectId'
>

export type IGetProjectColoringOptionsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetProjectColoringOptionsQuery = {
  projectColoringOptions?: Types.Maybe<{
    data: Array<IProjectColoringOptionsFieldsFragment>
  }>
}

export type ISaveProjectColoringOptionsMutationVariables = Types.Exact<{
  projectColoringOptions: Types.IProjectColoringOptionsInput
}>

export type ISaveProjectColoringOptionsMutation = {
  saveProjectColoringOptions?: Types.Maybe<IProjectColoringOptionsFieldsFragment>
}

export type IListenToProjectColoringOptionsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToProjectColoringOptionsSubscription = {
  projectColoringOptions?: Types.Maybe<
    Pick<Types.IProjectColoringOptionsChangeEvent, 'id'> & {
      item?: Types.Maybe<IProjectColoringOptionsFieldsFragment>
    }
  >
}

export const ProjectColoringOptionsFieldsFragmentDoc = gql`
  fragment ProjectColoringOptionsFields on ProjectColoringOptions {
    createdAt
    id
    isWWPColoringDisabled
    colors
    updatedAt
    projectId
  }
`
export const GetProjectColoringOptionsDocument = gql`
  query GetProjectColoringOptions($projectId: ObjectId!) {
    projectColoringOptions(projectId: $projectId, limit: 1) {
      data {
        ...ProjectColoringOptionsFields
      }
    }
  }
  ${ProjectColoringOptionsFieldsFragmentDoc}
`

/**
 * __useGetProjectColoringOptionsQuery__
 *
 * To run a query within a React component, call `useGetProjectColoringOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectColoringOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectColoringOptionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectColoringOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetProjectColoringOptionsQuery,
    IGetProjectColoringOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetProjectColoringOptionsQuery,
    IGetProjectColoringOptionsQueryVariables
  >(GetProjectColoringOptionsDocument, options)
}
export function useGetProjectColoringOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetProjectColoringOptionsQuery,
    IGetProjectColoringOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetProjectColoringOptionsQuery,
    IGetProjectColoringOptionsQueryVariables
  >(GetProjectColoringOptionsDocument, options)
}
export type GetProjectColoringOptionsQueryHookResult = ReturnType<
  typeof useGetProjectColoringOptionsQuery
>
export type GetProjectColoringOptionsLazyQueryHookResult = ReturnType<
  typeof useGetProjectColoringOptionsLazyQuery
>
export type GetProjectColoringOptionsQueryResult = Apollo.QueryResult<
  IGetProjectColoringOptionsQuery,
  IGetProjectColoringOptionsQueryVariables
>
export const SaveProjectColoringOptionsDocument = gql`
  mutation SaveProjectColoringOptions(
    $projectColoringOptions: ProjectColoringOptionsInput!
  ) {
    saveProjectColoringOptions(
      projectColoringOptions: $projectColoringOptions
    ) {
      ...ProjectColoringOptionsFields
    }
  }
  ${ProjectColoringOptionsFieldsFragmentDoc}
`
export type ISaveProjectColoringOptionsMutationFn = Apollo.MutationFunction<
  ISaveProjectColoringOptionsMutation,
  ISaveProjectColoringOptionsMutationVariables
>

/**
 * __useSaveProjectColoringOptionsMutation__
 *
 * To run a mutation, you first call `useSaveProjectColoringOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectColoringOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectColoringOptionsMutation, { data, loading, error }] = useSaveProjectColoringOptionsMutation({
 *   variables: {
 *      projectColoringOptions: // value for 'projectColoringOptions'
 *   },
 * });
 */
export function useSaveProjectColoringOptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveProjectColoringOptionsMutation,
    ISaveProjectColoringOptionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveProjectColoringOptionsMutation,
    ISaveProjectColoringOptionsMutationVariables
  >(SaveProjectColoringOptionsDocument, options)
}
export type SaveProjectColoringOptionsMutationHookResult = ReturnType<
  typeof useSaveProjectColoringOptionsMutation
>
export type SaveProjectColoringOptionsMutationResult =
  Apollo.MutationResult<ISaveProjectColoringOptionsMutation>
export type SaveProjectColoringOptionsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveProjectColoringOptionsMutation,
    ISaveProjectColoringOptionsMutationVariables
  >
export const ListenToProjectColoringOptionsDocument = gql`
  subscription ListenToProjectColoringOptions($projectId: ObjectId!) {
    projectColoringOptions(projectId: $projectId) {
      id
      item {
        ...ProjectColoringOptionsFields
      }
    }
  }
  ${ProjectColoringOptionsFieldsFragmentDoc}
`

/**
 * __useListenToProjectColoringOptionsSubscription__
 *
 * To run a query within a React component, call `useListenToProjectColoringOptionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToProjectColoringOptionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToProjectColoringOptionsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToProjectColoringOptionsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToProjectColoringOptionsSubscription,
    IListenToProjectColoringOptionsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToProjectColoringOptionsSubscription,
    IListenToProjectColoringOptionsSubscriptionVariables
  >(ListenToProjectColoringOptionsDocument, options)
}
export type ListenToProjectColoringOptionsSubscriptionHookResult = ReturnType<
  typeof useListenToProjectColoringOptionsSubscription
>
export type ListenToProjectColoringOptionsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToProjectColoringOptionsSubscription>
