import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import TagIconByTagType from '~/client/src/shared/components/TagIconByTagType/TagIconByTagType'
import FilterCounter from '~/client/src/shared/components/UsersDirectory/components/FilterCounter/FilterCounter'
import TagFilterStore from '~/client/src/shared/components/UsersDirectory/components/TagFilter/TagFilter.store'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ITag } from '~/client/src/shared/models/Tag'
import { getBandTitleByTagType } from '~/client/src/shared/utils/TagHelper'

import './TagFilter.scss'

interface IProps {
  appliedFilters?: ITag[]
  store: TagFilterStore
  availableTagTypes: TagType[]
}

const maxTagsCount = 2
const tagIconSize = 18
const categoryTagIconSize = 20

// translated

@observer
export default class TagFilter extends React.Component<IProps> {
  public get store(): TagFilterStore {
    return this.props.store
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.appliedFilters !== prevProps.appliedFilters) {
      this.store.setFilters(this.props.appliedFilters)
    }
  }

  public render() {
    const { selectedCategoryId, clearAll, shouldShowClearAll } = this.store

    const content = selectedCategoryId
      ? this.renderSelectedCategoryContent()
      : this.renderCategories()

    return (
      <div className="col">
        {content}
        {shouldShowClearAll && (
          <span
            className="text large blue-highlight bold end pa10"
            onClick={clearAll}
          >
            {Localization.translator.clearAll_items}
          </span>
        )}
      </div>
    )
  }

  private renderCategories(): JSX.Element[] {
    const { selectCategory, selectedFiltersByTagType, toggleTag } = this.store

    return this.props.availableTagTypes.map(tagType => {
      const selectedFilters = selectedFiltersByTagType[tagType] || []
      const selectedFiltersCount = selectedFilters.length
      const delta = selectedFiltersCount - maxTagsCount

      return (
        <div
          key={tagType}
          className="row tag-filter-container bb-palette-brand-lighter pa15"
          onClick={selectCategory.bind(null, tagType)}
        >
          <TagIconByTagType
            size={categoryTagIconSize}
            tagType={tagType}
            className="no-grow mr8"
          />
          <div className="no-grow mr12 no-white-space-wrap">
            {getBandTitleByTagType(tagType)}
          </div>
          <div className="selected-tags-container row no-flex-children">
            {selectedFilters.slice(0, maxTagsCount).map(tag => (
              <div key={tag.id} className="row text-ellipsis mr8">
                <SitemapAttributeTag
                  isRemovable={true}
                  dataObject={tag as LocationAttributeBase}
                  shouldShowAsTag={true}
                  onRemove={toggleTag.bind(null, tag)}
                  contentContainerClassName="text-ellipsis"
                >
                  <span>{tag.name}</span>
                </SitemapAttributeTag>
              </div>
            ))}
          </div>
          {delta > 0 && <FilterCounter count={`+${delta}`} />}
          <Icon icon={IconNames.CHEVRON_RIGHT} />
        </div>
      )
    })
  }

  private renderSelectedCategoryContent(): JSX.Element[] {
    const { tagsBySelectedCategories, toggleTag, isFilterSelected } = this.store

    return tagsBySelectedCategories.map(tag => (
      <div
        key={tag.id}
        className="row bb-palette-brand-lighter pa15"
        onClick={toggleTag.bind(null, tag)}
      >
        <Checkbox isChecked={isFilterSelected(tag.id)} />
        <SitemapAttributeTag
          dataObject={tag as LocationAttributeBase}
          shouldShowAsTag={false}
          iconSize={tagIconSize}
        >
          <span>{tag.name}</span>
        </SitemapAttributeTag>
      </div>
    ))
  }
}
