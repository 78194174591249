import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import ExpiredInviteKeyInfo from '~/client/src/shared/components/ExpiredInviteKeyInfo/ExpiredInviteKeyInfo'
import * as Icons from '~/client/src/shared/components/Icons'
import ContactHelp from '~/client/src/shared/components/Login/ContactHelp/ContactHelp'
import InfoCode from '~/client/src/shared/enums/InfoCode'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { GO_TO_AVAILABLE_PROJECT } from '~/client/src/shared/stores/EventStore/eventConstants'

import CommonStore from '../../stores/ui/Common.store'

import './InfoView.scss'

@inject('eventsStore', 'common')
@observer
class InfoView extends React.Component<
  RouteComponentProps<{ code: string; payload: string }> & {
    eventsStore: EventsStore
    common: CommonStore
  }
> {
  private readonly code: InfoCode = null
  private readonly payload: string = ''

  public constructor(props: any) {
    super(props)

    const { code, payload } = props.match.params
    this.code = code
    this.payload = payload
  }

  public render() {
    let body = null

    switch (this.code) {
      case InfoCode.EXPIRED_INVITE_TOKEN:
        body = <ExpiredInviteKeyInfo expiredInviteKey={this.payload} />
        break

      case InfoCode.INVALID_INVITE_TOKEN:
        body = this.invalidTokenIBlock
        break

      case InfoCode.ACCOUNT_CREATED:
        body = this.accountCreatedIBlock
        break

      case InfoCode.ACCOUNT_CREATED_AND_INVITED:
        body = this.whitelistedAccountCreatedIBlock
        break

      case InfoCode.ACCESS_TO_PROJECT_REQUESTED:
        body = this.accessToProjectRequestedIBlock
        break

      default:
        body = this.defaultIBlock
    }

    const { ifYouHaveQuestionsOrNeedSupport, youCanAlwaysReachUsAt } =
      Localization.translator.loginInfoPageDescriptions

    return (
      <div className="col y-center full-height info-container pa10 ma-x-auto">
        <div className="row pb24 logo-container">
          <Icons.LogoDark className="no-grow" />
          <label className="text bold size24 ml4">StruxHub</label>
        </div>

        {body}

        <h2 className="text orange size27 mt50">
          {ifYouHaveQuestionsOrNeedSupport}
        </h2>
        <label className="text header">{youCanAlwaysReachUsAt}</label>
        <ContactHelp
          mailto="info@struxhub.com"
          hint="info@struxhub.com"
          subject="Issue with login via invite key"
          className="contact-help"
        />
      </div>
    )
  }

  private get invalidTokenIBlock() {
    return (
      <p className="text bold header">
        {
          Localization.translator.loginInfoPageDescriptions
            .inviteTokenHasInvalidFormat
        }
        ... 😞
      </p>
    )
  }

  private get whitelistedAccountCreatedIBlock(): JSX.Element {
    return (
      <p className="col text bold header no-grow">
        <span>
          {`✅ Your registration request has been successfully sent and approved`}
        </span>

        <span>
          {`An Invitation email has been sent to your mailbox. To log in follow the instructions there`}
        </span>
      </p>
    )
  }

  private get accountCreatedIBlock(): JSX.Element {
    const withEmail = this.payload === 'true'

    return (
      <p className="col text bold header no-grow">
        <span>
          {`➡️ Your request has been successfully sent and is being reviewed by the administrator`}
        </span>

        <span>
          {`You will receive an ${
            withEmail ? 'Invitation email' : 'Invitation link'
          } as soon as the administrator confirms your request. Check your ${
            withEmail ? 'Mailbox' : 'SMS folder'
          }`}
        </span>
      </p>
    )
  }

  private get accessToProjectRequestedIBlock(): JSX.Element {
    return (
      <p className="col text bold header no-grow">
        <span>
          {`➡️ Your request has been successfully sent and is being reviewed by the administrator`}
        </span>
        <span>
          {`You will receive an Invitation as soon as the administrator confirms your request`}
        </span>
        <a
          onClick={this.goToAvailableProject}
          className="text large blue-brand underline pt20"
        >
          {`Go to available project`}
        </a>
      </p>
    )
  }

  private get defaultIBlock(): JSX.Element {
    return (
      <p className="text bold header">
        {Localization.translator.somethingWentWrongDuringAPIInteraction}
        ... 😞
      </p>
    )
  }

  private goToAvailableProject = () => {
    this.props.common.history.goBack()
    this.props.eventsStore.dispatch(GO_TO_AVAILABLE_PROJECT)
  }
}

export default withRouter(InfoView)
