import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { EventData, Swipeable } from 'react-swipeable'

import CalendarTimeline from '~/client/src/shared/components/CalendarTimeline/CalendarTimeline'
import { Content, View } from '~/client/src/shared/components/Layout'
import {
  MINUTES_IN_HOUR,
  ONE_MINUTE_HEIGHT_PX,
} from '~/client/src/shared/stores/ui/CalendarView.store'

import CalendarEvent from '../../models/CalendarEvent'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import CalendarDayViewStore from './CalendarDayView.store'
import CalendarEventComponent from './components/CalendarEvent'
import CalendarHoursLabels from './components/CalendarHoursLabels'
import HourSpace from './components/HourSpace'

import './CalendarDayView.scss'

const HOUR_HEIGHT_PX = ONE_MINUTE_HEIGHT_PX * MINUTES_IN_HOUR

const scrollSettings = {
  maxDistanceFromBorders: 30,
  step: 10,
  interval: 50,
  initialScrollTop: 6 * HOUR_HEIGHT_PX,
}

const PASSIVE_OPT = { passive: false }
// if touch move is within this radius, assume no move
const TOUCH_MOVE_TOLERANCE = 7
const ANIMATION_SWIPE_DELTA = 75
const SPACE_PRESS_DELAY = 300

export enum CalendarDayMode {
  CREATE = 'create',
  VIEW = 'view',
}

enum SwipeableClassName {
  LEFT = 'left-swipe',
  RIGHT = 'right-swipe',
}

const swipeableDirectionMap = {
  Left: SwipeableClassName.LEFT,
  Right: SwipeableClassName.RIGHT,
}

interface IProps {
  events: CalendarEvent[]
  store: CalendarDayViewStore
  mode: CalendarDayMode
  activeDate: Date
  shouldHandleMouse?: boolean
  isEventCreationDisabled?: boolean

  projectDateStore?: ProjectDateStore
  onNewEventCreate?: (startDate: Date, endDate: Date) => void
  onEventClicked?: (event: CalendarEvent) => void

  onLeftSwipe?: () => void
  onRightSwipe?: () => void
}

@inject('projectDateStore')
@observer
export default class CalendarDayView extends React.Component<IProps> {
  private spacePressTimer: any
  private scrollingTimer: any
  private swipeTimer: number

  private contentRef: HTMLDivElement
  private touchStartY: number

  private currentScrollTop: number
  private currentPageY: number

  private initialContentScrollTop: number
  private initialPageY: number

  @observable private swipeableClass: SwipeableClassName = null

  private get store(): CalendarDayViewStore {
    return this.props.store
  }

  private get isWorkingDay(): boolean {
    const { activeDate, projectDateStore } = this.props

    return projectDateStore.isWorkingDay(activeDate)
  }

  public componentDidMount() {
    this.contentRef.scrollTop = scrollSettings.initialScrollTop
    document.addEventListener('touchend', this.documentTouchEnd)

    if (this.props.shouldHandleMouse) {
      document.addEventListener('mouseup', this.documentTouchEnd)
    }

    document.body.classList.add('no-select')
  }

  public componentWillUnmount() {
    document.body.classList.remove('no-select')

    document.removeEventListener('touchend', this.documentTouchEnd)

    if (this.props.shouldHandleMouse) {
      document.removeEventListener('mouseup', this.documentTouchEnd)
    }

    this.disableScrolling()
  }

  public render() {
    return (
      <View className="calendar-day-view relative overflow-hidden">
        <Content scrollable={true} setRef={this.setContentRef}>
          {this.renderDay()}
        </Content>
      </View>
    )
  }

  private renderDay(): JSX.Element {
    const {
      activeDate,
      shouldHandleMouse,
      onEventClicked,
      projectDateStore,
      events,
    } = this.props

    const activeDateEvents = this.store.getActiveDateEvents(events)
    const isToday = projectDateStore.isToday(activeDate)

    this.store.restorePositionedEvents()
    return (
      <div className="col pa10">
        <Swipeable
          onSwipedRight={this.onSwipe}
          onSwipedLeft={this.onSwipe}
          preventDefaultTouchmoveEvent={true}
          delta={ANIMATION_SWIPE_DELTA}
          className={classList({
            'swipeable-day': true,
            [this.swipeableClass]: !!this.swipeableClass,
          })}
        >
          <div className="row y-start day-container">
            {this.renderHourLabels()}
            <div className="relative col">
              {isToday && <CalendarTimeline shouldRenderDot={true} />}
              <div className="relative bt-light-grey mt10">
                {this.store.dayHours.map(
                  hour =>
                    hour < 24 && (
                      <HourSpace
                        key={hour}
                        hour={hour}
                        timeInterval={this.store.timeInterval}
                        rowsNumber={this.store.rowsNumber}
                        activeDate={activeDate}
                        onEmptyCellTouchStart={this.onHourSpaceTouchStart}
                        shouldHandleMouseClick={shouldHandleMouse}
                        isNonWorkingDay={!this.isWorkingDay}
                      />
                    ),
                )}
                {activeDateEvents.map((event, idx) => {
                  const { style, className } =
                    this.store.getEventSizeAndPosition(
                      event,
                      activeDateEvents,
                      activeDate,
                    )

                  return (
                    <CalendarEventComponent
                      key={idx}
                      event={event}
                      onEventClicked={onEventClicked}
                      style={style}
                      className={className}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </Swipeable>
      </div>
    )
  }

  private renderHourLabels = (): JSX.Element => {
    const { dayHours } = this.store

    return (
      <CalendarHoursLabels
        dayHours={dayHours}
        shouldHideTimezone={true}
        shouldUseLargeText={false}
        shouldHideProjectHours={!this.isWorkingDay}
      />
    )
  }

  private setContentRef = (ref: HTMLDivElement) => {
    const { shouldHandleMouse } = this.props

    if (this.contentRef) {
      this.contentRef.removeEventListener('touchmove', this.contentTouchMove)
      this.contentRef.removeEventListener('scroll', this.contentScroll)

      if (shouldHandleMouse) {
        this.contentRef.removeEventListener('mousemove', this.contentTouchMove)
      }
    }

    if (ref) {
      ref.addEventListener('touchmove', this.contentTouchMove, PASSIVE_OPT)
      ref.addEventListener('scroll', this.contentScroll)

      if (shouldHandleMouse) {
        ref.addEventListener('mousemove', this.contentTouchMove, PASSIVE_OPT)
      }
    }

    this.contentRef = ref
  }

  @action.bound
  private onHourSpaceTouchStart(date: Date, event: any) {
    if (this.props.mode !== CalendarDayMode.CREATE) {
      return
    }

    const isTouchEvent = !!event.touches
    const { pageY } = isTouchEvent ? event.touches[0] : event
    this.touchStartY = pageY
    if (!isTouchEvent) {
      this.activateEventCreating(date, pageY)
      return
    }

    this.spacePressTimer = setTimeout(() => {
      this.spacePressTimer = null
      this.activateEventCreating(date, pageY)
    }, SPACE_PRESS_DELAY)
  }

  @action.bound
  private activateEventCreating(initialDate: Date, initialPageY: number) {
    this.initialContentScrollTop = this.contentRef?.scrollTop || 0
    this.initialPageY = initialPageY
    if (!this.props.isEventCreationDisabled) {
      this.store.activateNewEventMode(initialDate)
    }
  }

  @action.bound
  private documentTouchEnd() {
    if (this.spacePressTimer) {
      return this.clearPressTimer()
    }

    if (this.store.isNewEventMode) {
      this.store.setDefaultMode()

      if (this.props.onNewEventCreate) {
        const startDate = this.store.editableEventStartDate
        const endDate = this.store.getEndDate(startDate)
        this.props.onNewEventCreate(startDate, endDate)
      }
    }
  }

  @action.bound
  private contentTouchMove(event: any) {
    if (this.store.isNewEventMode && event.cancelable) {
      event.preventDefault()
    }

    const isTouchEvent = !!event.touches
    const { pageY } = isTouchEvent ? event.touches[0] : event

    // ignore slight movements
    if (Math.abs(this.touchStartY - pageY) < TOUCH_MOVE_TOLERANCE) {
      return
    }

    if (this.spacePressTimer) {
      this.clearPressTimer()
      return
    }

    if (!this.store.isNewEventMode) {
      return
    }

    const { top, height } = this.contentRef.getBoundingClientRect()
    const bottom = top + height

    if (pageY < top || bottom < pageY) {
      return
    }

    this.currentPageY = pageY
    this.moveEditableEvent()

    switch (true) {
      case pageY - top < scrollSettings.maxDistanceFromBorders:
        this.activateScrollingUp()
        break
      case bottom - pageY < scrollSettings.maxDistanceFromBorders:
        this.activateScrollingDown()
        break
      default:
        this.disableScrolling()
    }
  }

  @action.bound
  private contentScroll(event: Event) {
    this.currentScrollTop = this.contentRef.scrollTop
    if (this.spacePressTimer) {
      this.clearPressTimer()
      return
    }

    if (this.store.isNewEventMode) {
      event.preventDefault()
      this.moveEditableEvent()
    }
  }

  @action.bound
  private activateScrollingUp() {
    if (this.scrollingTimer) {
      return
    }

    this.scrollingTimer = setInterval(() => {
      const { scrollTop } = this.contentRef
      if (scrollTop === 0) {
        this.disableScrolling()
        return
      }

      let newScrollTop = scrollTop - scrollSettings.step
      newScrollTop = Math.max(newScrollTop, 0)

      this.contentRef.scrollTop = newScrollTop
    }, scrollSettings.interval)
  }

  @action.bound
  private activateScrollingDown() {
    if (this.scrollingTimer) {
      return
    }

    this.scrollingTimer = setInterval(() => {
      const { scrollTop, scrollHeight, clientHeight } = this.contentRef
      if (scrollTop === scrollHeight - clientHeight) {
        this.disableScrolling()
        return
      }

      let newScrollTop = scrollTop + scrollSettings.step
      newScrollTop = Math.min(newScrollTop, scrollHeight - clientHeight)

      this.contentRef.scrollTop = newScrollTop
    }, scrollSettings.interval)
  }

  @action.bound
  private disableScrolling() {
    if (this.scrollingTimer) {
      clearInterval(this.scrollingTimer)
      this.scrollingTimer = null
    }
  }

  private clearPressTimer = () => {
    clearTimeout(this.spacePressTimer)
    this.store.setDefaultMode()
  }

  @action.bound
  private moveEditableEvent() {
    const { timeInterval } = this.store
    const deltaPageY = this.currentPageY - this.initialPageY
    const deltaScrollTop = this.currentScrollTop - this.initialContentScrollTop
    const delta = deltaPageY + deltaScrollTop
    const minutesDelta =
      Math.round(delta / ONE_MINUTE_HEIGHT_PX / timeInterval) * timeInterval

    this.store.updateEditableEventStartDate(minutesDelta)
  }

  @action.bound
  private onSwipe({ dir }: EventData) {
    const { onLeftSwipe, onRightSwipe } = this.props

    if (!onLeftSwipe || !onRightSwipe || this.store.isNewEventMode) {
      return
    }

    this.swipeableClass = null

    clearTimeout(this.swipeTimer)

    this.swipeTimer = window.setTimeout(() =>
      this.applySwipeAnimation(swipeableDirectionMap[dir]),
    )
  }

  @action.bound
  private applySwipeAnimation(className: SwipeableClassName) {
    const { onLeftSwipe, onRightSwipe } = this.props

    const onSwipeHandler =
      className === SwipeableClassName.LEFT ? onLeftSwipe : onRightSwipe

    if (!className || !onSwipeHandler) {
      return
    }

    onSwipeHandler()

    this.swipeableClass = className
  }
}
