import * as React from 'react'

import { inject, observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import ProjectDateStore, {
  isAfter,
  isBefore,
} from '../../../stores/ui/ProjectDate.store'
import CompactConfirmDialog from '../../CompactConfirmDialog/CompactConfirmDialog'

// translated

interface IProps {
  isShown: boolean
  isActualStartSettingSelected: boolean
  isActualFinishSettingSelected: boolean
  actualStartDate?: Date
  actualEndDate?: Date
  selectedDate: Date
  projectDateStore?: ProjectDateStore
  onHide: () => void
  onApply: () => void
}

@inject('projectDateStore')
@observer
export default class ActualDatesChangeConfirm extends React.Component<IProps> {
  public render() {
    const { isShown, onHide, onApply } = this.props

    return (
      <CompactConfirmDialog
        title={Localization.translator.confirmChange}
        isShown={isShown}
        onHide={onHide}
        onApply={onApply}
      >
        <>
          <div className="text extra-large">{this.message}</div>
          <div className="text extra-large pt20">
            {Localization.translator.okToProceed}
          </div>
        </>
      </CompactConfirmDialog>
    )
  }

  private get message() {
    const {
      isActualStartSettingSelected,
      isActualFinishSettingSelected,
      actualStartDate,
      actualEndDate,
      selectedDate,
    } = this.props

    const { getMonthAndDayToDisplay } = this.props.projectDateStore
    const dateWrapper = (date: string) => (
      <span className="blue-text">{date}</span>
    )
    if (
      isActualStartSettingSelected &&
      actualStartDate &&
      isAfter(selectedDate, actualStartDate)
    ) {
      const selectedDay = getMonthAndDayToDisplay(selectedDate)
      const actualDay = getMonthAndDayToDisplay(actualStartDate)
      return (
        <div>
          {...Localization.translator.actualDatesDescriptions.newStartDateAfterOld(
            selectedDay,
            actualDay,
            dateWrapper,
          )}
        </div>
      )
    }

    if (
      isActualStartSettingSelected &&
      actualStartDate &&
      isBefore(selectedDate, actualStartDate)
    ) {
      const selectedDay = getMonthAndDayToDisplay(selectedDate)
      const actualDay = getMonthAndDayToDisplay(actualStartDate)
      return (
        <div>
          {...Localization.translator.actualDatesDescriptions.newStartDateBeforeOld(
            selectedDay,
            actualDay,
            dateWrapper,
          )}
        </div>
      )
    }

    if (
      isActualFinishSettingSelected &&
      actualEndDate &&
      isAfter(selectedDate, actualEndDate)
    ) {
      const selectedDay = getMonthAndDayToDisplay(selectedDate)
      const actualDay = getMonthAndDayToDisplay(actualEndDate)
      return (
        <div>
          {...Localization.translator.actualDatesDescriptions.newEndDateAfterOld(
            selectedDay,
            actualDay,
            dateWrapper,
          )}
        </div>
      )
    }

    if (
      isActualFinishSettingSelected &&
      actualEndDate &&
      isBefore(selectedDate, actualEndDate)
    ) {
      const selectedDay = getMonthAndDayToDisplay(selectedDate)
      const actualDay = getMonthAndDayToDisplay(actualEndDate)
      return (
        <div>
          {...Localization.translator.actualDatesDescriptions.newEndDateBeforeOld(
            selectedDay,
            actualDay,
            dateWrapper,
          )}
        </div>
      )
    }
  }
}
