import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'
import {
  IPermitTypeFieldsFragmentFragment,
  PermitTypeFieldsFragmentFragmentDoc,
} from './DefaultPermitType.generated'

const defaultOptions = {}
export type IPermitTypeFragmentFragment = Pick<
  Types.IPermitType,
  | 'id'
  | 'orderIndex'
  | 'projectId'
  | 'name'
  | 'basedOn'
  | 'isEnabled'
  | 'type'
  | 'shouldBlockOnNonWorkTimes'
  | 'isAutoActivationEnabled'
  | 'isDeleted'
  | 'createdAt'
  | 'updatedAt'
> & {
  inspectionOptions?: Types.Maybe<
    Pick<
      Types.IInspectionOptions,
      | 'deadlineTime'
      | 'inspectionFrequency'
      | 'inspectionFrequencyType'
      | 'selectedDaysToRepeat'
    >
  >
  bookingDeadlineOptions?: Types.Maybe<
    Pick<Types.IBookingDeadlineOptions, 'deadlineInterval' | 'blockType'>
  >
  workflowSteps?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IWorkflowStep,
          'id' | 'type' | 'workflowRuleIds' | 'notificationFieldIds'
        > & {
          fields: Array<Types.Maybe<IPermitTypeFieldsFragmentFragment>>
          conditionalFields: Array<
            Types.Maybe<
              Pick<Types.IConditionalField, 'fieldId' | 'key'> & {
                values: Array<Types.Maybe<IPermitTypeFieldsFragmentFragment>>
              }
            >
          >
        }
      >
    >
  >
}

export type IPermitTypeListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IPermitTypeListQuery = {
  permitTypes?: Types.Maybe<{ data: Array<IPermitTypeFragmentFragment> }>
}

export type IListenPermitTypesByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenPermitTypesByProjectIdSubscription = {
  permitType?: Types.Maybe<
    Pick<Types.IPermitTypeChangeEvent, 'id'> & {
      item?: Types.Maybe<IPermitTypeFragmentFragment>
    }
  >
}

export type ISavePermitTypesMutationVariables = Types.Exact<{
  permitTypes:
    | Array<Types.Maybe<Types.IPermitTypeInput>>
    | Types.Maybe<Types.IPermitTypeInput>
  projectId: Types.Scalars['ObjectId']
}>

export type ISavePermitTypesMutation = {
  saveManyPermitTypes?: Types.Maybe<
    Array<Types.Maybe<IPermitTypeFragmentFragment>>
  >
}

export type IDeletePermitTypesMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeletePermitTypesMutation = Pick<
  Types.IMutation,
  'softDeleteManyPermitTypes'
>

export const PermitTypeFragmentFragmentDoc = gql`
  fragment PermitTypeFragment on PermitType {
    id
    orderIndex
    projectId
    name
    basedOn
    isEnabled
    inspectionOptions {
      deadlineTime
      inspectionFrequency
      inspectionFrequencyType
      selectedDaysToRepeat
    }
    bookingDeadlineOptions {
      deadlineInterval
      blockType
    }
    type
    workflowSteps {
      id
      type
      fields {
        ...PermitTypeFieldsFragment
      }
      conditionalFields {
        fieldId
        key
        values {
          ...PermitTypeFieldsFragment
        }
      }
      workflowRuleIds
      notificationFieldIds
    }
    shouldBlockOnNonWorkTimes
    isAutoActivationEnabled
    isDeleted
    createdAt
    updatedAt
  }
  ${PermitTypeFieldsFragmentFragmentDoc}
`
export const PermitTypeListDocument = gql`
  query PermitTypeList($projectId: ObjectId!) {
    permitTypes(limit: 1000000, projectId: $projectId) {
      data {
        ...PermitTypeFragment
      }
    }
  }
  ${PermitTypeFragmentFragmentDoc}
`

/**
 * __usePermitTypeListQuery__
 *
 * To run a query within a React component, call `usePermitTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermitTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermitTypeListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePermitTypeListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IPermitTypeListQuery,
    IPermitTypeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IPermitTypeListQuery, IPermitTypeListQueryVariables>(
    PermitTypeListDocument,
    options,
  )
}
export function usePermitTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IPermitTypeListQuery,
    IPermitTypeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IPermitTypeListQuery,
    IPermitTypeListQueryVariables
  >(PermitTypeListDocument, options)
}
export type PermitTypeListQueryHookResult = ReturnType<
  typeof usePermitTypeListQuery
>
export type PermitTypeListLazyQueryHookResult = ReturnType<
  typeof usePermitTypeListLazyQuery
>
export type PermitTypeListQueryResult = Apollo.QueryResult<
  IPermitTypeListQuery,
  IPermitTypeListQueryVariables
>
export const ListenPermitTypesByProjectIdDocument = gql`
  subscription ListenPermitTypesByProjectId($projectId: ObjectId!) {
    permitType(projectId: $projectId) {
      id
      item {
        ...PermitTypeFragment
      }
    }
  }
  ${PermitTypeFragmentFragmentDoc}
`

/**
 * __useListenPermitTypesByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenPermitTypesByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenPermitTypesByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenPermitTypesByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenPermitTypesByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenPermitTypesByProjectIdSubscription,
    IListenPermitTypesByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenPermitTypesByProjectIdSubscription,
    IListenPermitTypesByProjectIdSubscriptionVariables
  >(ListenPermitTypesByProjectIdDocument, options)
}
export type ListenPermitTypesByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenPermitTypesByProjectIdSubscription
>
export type ListenPermitTypesByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenPermitTypesByProjectIdSubscription>
export const SavePermitTypesDocument = gql`
  mutation SavePermitTypes(
    $permitTypes: [PermitTypeInput]!
    $projectId: ObjectId!
  ) {
    saveManyPermitTypes(permitTypes: $permitTypes, projectId: $projectId) {
      ...PermitTypeFragment
    }
  }
  ${PermitTypeFragmentFragmentDoc}
`
export type ISavePermitTypesMutationFn = Apollo.MutationFunction<
  ISavePermitTypesMutation,
  ISavePermitTypesMutationVariables
>

/**
 * __useSavePermitTypesMutation__
 *
 * To run a mutation, you first call `useSavePermitTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePermitTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePermitTypesMutation, { data, loading, error }] = useSavePermitTypesMutation({
 *   variables: {
 *      permitTypes: // value for 'permitTypes'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSavePermitTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISavePermitTypesMutation,
    ISavePermitTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISavePermitTypesMutation,
    ISavePermitTypesMutationVariables
  >(SavePermitTypesDocument, options)
}
export type SavePermitTypesMutationHookResult = ReturnType<
  typeof useSavePermitTypesMutation
>
export type SavePermitTypesMutationResult =
  Apollo.MutationResult<ISavePermitTypesMutation>
export type SavePermitTypesMutationOptions = Apollo.BaseMutationOptions<
  ISavePermitTypesMutation,
  ISavePermitTypesMutationVariables
>
export const DeletePermitTypesDocument = gql`
  mutation DeletePermitTypes($ids: [ObjectId]!) {
    softDeleteManyPermitTypes(ids: $ids)
  }
`
export type IDeletePermitTypesMutationFn = Apollo.MutationFunction<
  IDeletePermitTypesMutation,
  IDeletePermitTypesMutationVariables
>

/**
 * __useDeletePermitTypesMutation__
 *
 * To run a mutation, you first call `useDeletePermitTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermitTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermitTypesMutation, { data, loading, error }] = useDeletePermitTypesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeletePermitTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeletePermitTypesMutation,
    IDeletePermitTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeletePermitTypesMutation,
    IDeletePermitTypesMutationVariables
  >(DeletePermitTypesDocument, options)
}
export type DeletePermitTypesMutationHookResult = ReturnType<
  typeof useDeletePermitTypesMutation
>
export type DeletePermitTypesMutationResult =
  Apollo.MutationResult<IDeletePermitTypesMutation>
export type DeletePermitTypesMutationOptions = Apollo.BaseMutationOptions<
  IDeletePermitTypesMutation,
  IDeletePermitTypesMutationVariables
>
