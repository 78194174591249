import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import AvatarType from '../../../enums/AvatarType'
import User from '../../../models/User'
import UserProject from '../../../models/UserProject'
import ChatService from '../../../services/ChatService/Chat.service'
import { IChat } from '../../../services/ChatService/types'
import CompaniesStore from '../../../stores/domain/Companies.store'
import ProjectMembersStore from '../../../stores/domain/ProjectMembers.store'
import ProjectRolesStore from '../../../stores/domain/ProjectRoles.store'
import UserProjectsStore from '../../../stores/domain/UserProjects.store'
import ProjectDateStore from '../../../stores/ui/ProjectDate.store'
import { NOOP } from '../../../utils/noop'
import Avatar from '../../Avatar/Avatar'
import BaseCompactPopup from '../../BaseCompactPopup/BaseCompactPopup'
import { Loader } from '../../Loader'
import ChatActionBar from './ChatActionBar'
import ChatDetails from './ChatDetails'
import ChatEditorPopup from './ChatEditorPopup'
import ChatLogStore from './ChatLog.store'
import ContactName from './ContactName'

import Colors from '~/client/src/shared/theme.module.scss'

import './ChatLog.scss'

// left it dirty for V0 as It will be completely redesigned

const bgColorOfMessageByActiveUser = '#e8edff' // Temporary, not from the palette

interface IProps {
  chat: IChat
  onlineContacts: Set<string>
  onBackClick?: () => void
  onMount?: () => void
  onUnmount?: () => void
  onChannelOnMount?: () => void

  shouldBeSentOnEnter?: boolean

  chatService?: ChatService
  projectDateStore?: ProjectDateStore
  projectMembersStore?: ProjectMembersStore
  userProjectsStore?: UserProjectsStore
  companiesStore?: CompaniesStore
  projectRolesStore?: ProjectRolesStore
}

@inject(
  'chatService',
  'projectDateStore',
  'projectMembersStore',
  'userProjectsStore',
  'companiesStore',
  'projectRolesStore',
)
@observer
export default class ChatLog extends React.Component<IProps> {
  private readonly store: ChatLogStore = null
  private messageBoxEndRef: any = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ChatLogStore(
      props.chatService,
      props.chat,
      this.scrollMessageBoxToBottom,
      props.onlineContacts,
      props.onBackClick,
    )
  }

  public async componentDidMount() {
    this.props.onMount?.()
    await this.store.init()
    this.scrollMessageBoxToBottom()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.onlineContacts.size !== this.props.onlineContacts.size) {
      this.store.onlineContacts = this.props.onlineContacts
    }
  }

  public render() {
    return (
      <div className="col full-height channel-container">
        {this.store.chat.isGroup && this.renderChatDetailsPopup()}
        {this.renderChannelHeader()}

        {this.store.isLoading ? (
          <Loader size={40} hint="Messages loading..." />
        ) : (
          <div className="overflow-auto full-height px20">
            {this.renderMessages()}
            <div ref={ref => (this.messageBoxEndRef = ref)} />
          </div>
        )}
        <div className="col no-grow">
          {
            <ChatActionBar
              shouldBeSentOnEnter={this.props.shouldBeSentOnEnter}
              onSubmit={this.handleSendMessage}
            />
          }
        </div>
      </div>
    )
  }

  public componentWillUnmount() {
    this.store.removeListeners()
    this.props.onUnmount?.()
  }

  private renderMessages(): JSX.Element | JSX.Element[] {
    const { sortedMessages, brokenMessageIds } = this.store

    if (!sortedMessages.length) {
      return (
        <div className="col full-height y-center x-center">
          There are no messages in this channel yet...
        </div>
      )
    }

    const messageItems = sortedMessages.map(m => {
      const author = this.store.externalContactsMap.get(m.authorId)
      const isAuthoredByActiveUser = m.authorId === this.store.activeUserId
      const isBrokenMessage = brokenMessageIds.has(m.fakeId)

      return (
        <div
          key={m.id || m.fakeId}
          style={{
            wordBreak: 'break-word',
            backgroundColor: isAuthoredByActiveUser
              ? bgColorOfMessageByActiveUser
              : Colors.neutral96,
          }}
          className={classList({
            'row mb20 relative pa10 pb20 brada10': true,
            'inactive-element': !m.id || isBrokenMessage,
          })}
        >
          <span
            className="absolute text monospace light no-white-space-wrap"
            style={{
              bottom: 0,
              right: !isAuthoredByActiveUser && '10px',
            }}
          >
            {this.props.projectDateStore.getMonthDayAndTimeToDisplay(
              m.createdAt,
            )}
          </span>
          {this.props.chat.isGroup &&
            m.authorId !== this.store.activeUserId && (
              <Avatar
                className="mr20"
                avatarType={AvatarType.User}
                isOnline={this.props.onlineContacts.has(m.authorId)}
                src={author?.avatarUrl}
              />
            )}
          <div
            className={classList({
              col: true,
              'x-end': isAuthoredByActiveUser,
            })}
          >
            <div
              className={classList({
                'text large bold pb4 lp05': true,
                blue: isAuthoredByActiveUser,
              })}
            >
              {author ? <ContactName contact={author} /> : m.authorId}
            </div>
            <div className="row">
              <div
                className={classList({
                  'text extra-large': true,
                  right: isAuthoredByActiveUser,
                  left: !isAuthoredByActiveUser,
                })}
              >
                {m.text}
              </div>
              {isBrokenMessage && (
                <Icon icon={IconNames.OUTDATED} className="text red ml8" />
              )}
            </div>
          </div>
        </div>
      )
    })

    return messageItems
  }

  private renderChannelHeader() {
    const { chatService, onBackClick } = this.props

    return (
      <div className="row px15 pb20">
        <div className="row no-grow pointer" onClick={onBackClick}>
          <Icon
            icon={IconNames.CHEVRON_LEFT}
            iconSize={24}
            className={classList({
              'text blue-brand': true,
              pr20: !chatService.allUnreadMessagesCount,
            })}
          />
          {!!chatService.allUnreadMessagesCount && (
            <div className="row x-center y-center w18 h18 bg-red text white center bold br-rounded">
              {chatService.allUnreadMessagesCount}
            </div>
          )}
        </div>

        <div
          className="col pointer x-center"
          onClick={this.store.chat.isGroup ? this.store.showChatDetails : NOOP}
        >
          <div className="text large bold center">{this.props.chat.name}</div>
          <div className="text large light center">
            {this.props.chat.isGroup
              ? `${this.store.chat.memberIds.length} members, ${this.store.onlineMemberIds.length} online`
              : this.recipientCompanyAndRoles}
          </div>
        </div>

        <div
          className="pointer no-grow"
          onClick={this.store.chat.isGroup ? this.store.showChatDetails : NOOP}
        >
          <Avatar
            src={this.props.chat.avatar}
            avatarType={
              this.props.chat.isGroup ? AvatarType.Company : AvatarType.User
            }
            isOnline={this.getInboxItemOnlineStatus(this.props.chat)}
          />
        </div>
      </div>
    )
  }

  private scrollMessageBoxToBottom = () => {
    this.messageBoxEndRef?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  public handleSendMessage = (text: string) => {
    this.store.sendMessage(text)

    Promise.resolve().then(() => this.scrollMessageBoxToBottom())
  }

  private getInboxItemOnlineStatus({
    isGroup,
    memberIds: membersIds,
  }: IChat): boolean {
    if (isGroup) {
      return null
    }

    const { userId } = this.props.chatService

    const recipientId = membersIds.find(id => id !== userId)

    return this.store.isContactOnline(recipientId)
  }

  private renderChatDetailsPopupForMember(): JSX.Element {
    return (
      <BaseCompactPopup
        isShown={this.store.shouldShowChatDetails}
        title="Group details"
        onHide={this.store.hideChatDetails}
        childrenClassName="col"
      >
        {this.store.shouldShowChatDetails && (
          <ChatDetails
            isReadonlyMode={true}
            areMembersBeingLoaded={this.store.areMembersBeingLoaded}
            chatAvatarUrl={this.store.chat.avatar}
            chatName={this.store.chat.name}
            members={this.chatMembers}
            creatorId={this.store.chat.createdBy}
            isMemberOnline={userId => this.props.onlineContacts.has(userId)}
          />
        )}
      </BaseCompactPopup>
    )
  }

  private renderChatDetailsPopup(): JSX.Element {
    if (!this.store.isChatCreator) {
      return this.renderChatDetailsPopupForMember()
    }

    return (
      <ChatEditorPopup
        chat={this.store.chat}
        onHide={this.store.hideChatDetails}
        onEdit={this.store.handleEditSuccess}
        isShown={this.store.shouldShowChatDetails}
        onlineContacts={this.props.onlineContacts}
        externalContactsMap={this.store.externalContactsMap}
        areMembersBeingLoaded={this.store.areMembersBeingLoaded}
      />
    )
  }

  @computed
  private get chatMembers(): User[] {
    return this.store.chat.memberIds
      .map(
        mid =>
          this.props.projectMembersStore.getById(mid) ||
          (this.store.externalContactsMap.get(mid) as User),
      )
      .filter(m => !!m)
  }

  private get recipientCompanyAndRoles(): string {
    const {
      projectMembersStore,
      userProjectsStore,
      companiesStore,
      projectRolesStore,
    } = this.props

    const recipientId = this.store.chat.memberIds.find(
      mid => mid !== this.props.chatService.userId,
    )

    const recipient = projectMembersStore.getById(recipientId)

    if (!recipient) {
      return ''
    }

    const companyName = UserProject.getCompanyName(
      recipient,
      userProjectsStore,
      companiesStore,
    )

    const roles = UserProject.getAllRolesAsString(
      recipient,
      userProjectsStore,
      projectRolesStore,
    )

    return [companyName, roles].filter(m => !!m).join(', ')
  }
}
