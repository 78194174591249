import React from 'react'

import EntityNameFilter from '~/client/src/mobile/components/Filters/EntityNameFilter/EntityNameFilter'
import MobileInitialState from '~/client/src/mobile/stores/MobileInitialState'
import Avatar, {
  AvatarSize,
} from '~/client/src/shared/components/Avatar/Avatar'
import * as Icons from '~/client/src/shared/components/Icons'
import AvatarType from '~/client/src/shared/enums/AvatarType'
import { LogisticsFilterType } from '~/client/src/shared/enums/LogisticsFilterType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { LogisticItemApp } from '~/client/src/shared/models/ILogisticItem'
import UserProject from '~/client/src/shared/models/UserProject'
import LogisticsFilterStore from '~/client/src/shared/stores/LogisticsFilter.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import MobileLogisticsStore from '../../stores/MobileLogistics.store'
import LogisticsFilter from '../LogisticsFilter/LogisticsFilter'

import './LogisticsFilters.scss'

interface IProps {
  companiesStore?: CompaniesStore
  logisticsFilterStore?: LogisticsFilterStore
  toggleFilters: () => void
  logisticsStore: MobileLogisticsStore
  userProjectsStore: UserProjectsStore
  state: MobileInitialState
  isPermitOnly?: boolean
  selectedOptionsCount?: number
}

interface IHeaderButton {
  icon?: JSX.Element
  onClick: () => void
  isSelected: boolean
  text?: string
  isHidden?: boolean
}

export default class LogisticsFilters extends React.Component<IProps> {
  private get headerButtons(): IHeaderButton[] {
    const {
      state,
      isPermitOnly,
      companiesStore,
      logisticsStore,
      userProjectsStore,
    } = this.props
    const {
      announcementsInPeriodInterval,
      formsByCreationDate,
      deliveriesInPeriodInterval,
      activitiesInPeriodInterval,
    } = logisticsStore

    const myId = state.user?.id
    const myCompanyId = UserProject.getCompanyId(state.user, userProjectsStore)
    const myCompany = companiesStore.getCompanyById(myCompanyId)

    return [
      {
        icon: this.renderFilterIcon(),
        onClick: this.props.toggleFilters,
        isSelected: !!this.props.selectedOptionsCount,
      },
      {
        icon: (
          <Avatar
            src={state.user.avatarUrl}
            initials={state.user.initials}
            size={AvatarSize.Tiny}
          />
        ),
        onClick: this.toggleFilterOnly.bind(
          this,
          LogisticsFilterType.ResponsibleContact,
          myId,
        ),
        text: Localization.translator.me,
        isSelected: this.isOnlyFilterSelected(
          LogisticsFilterType.ResponsibleContact,
          myId,
        ),
      },
      {
        icon: (
          <Avatar
            src={myCompany?.avatarUrl}
            avatarType={AvatarType.Company}
            size={AvatarSize.Tiny}
          />
        ),
        onClick: this.toggleFilterOnly.bind(
          this,
          LogisticsFilterType.Company,
          myCompanyId,
        ),
        text: Localization.translator.myCo,
        isSelected: this.isOnlyFilterSelected(
          LogisticsFilterType.Company,
          myCompanyId,
        ),
      },
      {
        icon: <Icons.MegaphoneStencil className="row common-icon" />,
        onClick: this.toggleFilterOnly.bind(
          this,
          LogisticsFilterType.App,
          LogisticItemApp.ANNOUNCEMENT,
        ),
        text: announcementsInPeriodInterval.length.toString(),
        isSelected:
          !isPermitOnly &&
          this.isOnlyFilterSelected(
            LogisticsFilterType.App,
            LogisticItemApp.ANNOUNCEMENT,
          ),
        isHidden: isPermitOnly,
      },
      {
        icon:
          !isPermitOnly &&
          this.isOnlyFilterSelected(
            LogisticsFilterType.App,
            LogisticItemApp.FORM,
          ) ? (
            <Icons.FormFilled className="row common-icon" />
          ) : (
            <Icons.FormOutline className="row common-icon" />
          ),
        onClick: this.toggleFilterOnly.bind(
          this,
          LogisticsFilterType.App,
          LogisticItemApp.FORM,
        ),
        text: formsByCreationDate.length.toString(),
        isSelected:
          !isPermitOnly &&
          this.isOnlyFilterSelected(
            LogisticsFilterType.App,
            LogisticItemApp.FORM,
          ),
        isHidden: isPermitOnly,
      },
      {
        icon:
          !isPermitOnly &&
          this.isOnlyFilterSelected(
            LogisticsFilterType.App,
            LogisticItemApp.DELIVERY,
          ) ? (
            <Icons.DeliveryFilled className="row common-icon" />
          ) : (
            <Icons.DeliveryOutline className="row common-icon" />
          ),
        onClick: this.toggleFilterOnly.bind(
          this,
          LogisticsFilterType.App,
          LogisticItemApp.DELIVERY,
        ),
        text: deliveriesInPeriodInterval.length.toString(),
        isSelected:
          !isPermitOnly &&
          this.isOnlyFilterSelected(
            LogisticsFilterType.App,
            LogisticItemApp.DELIVERY,
          ),
        isHidden: isPermitOnly,
      },
      {
        icon: <Icons.GanttSharp className="row common-icon" />,
        onClick: this.toggleFilterOnly.bind(
          this,
          LogisticsFilterType.App,
          LogisticItemApp.SCHEDULE,
        ),
        text: activitiesInPeriodInterval.length.toString(),
        isSelected:
          !isPermitOnly &&
          this.isOnlyFilterSelected(
            LogisticsFilterType.App,
            LogisticItemApp.SCHEDULE,
          ),
        isHidden: isPermitOnly,
      },
    ]
  }

  public render() {
    return (
      <div className="row overflow-x-auto overflow-hidden-scrollbar py5 px5">
        {this.renderSearchFilter()}
        {this.headerButtons.map(this.renderHeaderButton)}
      </div>
    )
  }

  private renderFilterIcon = () => {
    return (
      <div className="row fill-available-width">
        <Icons.FilterSorted className="row x-center common-icon" />
        {!!this.props.selectedOptionsCount && (
          <div className="logistics-filter-count-label brada4 row x-center px4 mx5 text medium">
            {this.props.selectedOptionsCount}
          </div>
        )}
      </div>
    )
  }

  private renderHeaderButton(
    { isSelected, onClick, text, icon, isHidden }: IHeaderButton,
    key?: number,
  ): JSX.Element {
    return (
      <LogisticsFilter
        isSelected={isSelected}
        onClick={onClick}
        text={text}
        icon={icon}
        isHidden={isHidden}
        key={key}
      />
    )
  }

  private isOnlyFilterSelected(filterType: LogisticsFilterType, id: string) {
    const filter =
      this.props.logisticsFilterStore.filterStoresByTypeMap[filterType]
    return filter.selectedOptions.size === 1 && filter.selectedOptions.has(id)
  }

  private toggleFilterOnly = (filterType: LogisticsFilterType, id?: string) => {
    if (!id) {
      return
    }
    const userFilter =
      this.props.logisticsFilterStore.filterStoresByTypeMap[filterType]
    if (this.isOnlyFilterSelected(filterType, id)) {
      userFilter.clickOnDeselectAll()
    } else {
      userFilter.selectOptionsByIds([id])
    }
    userFilter.clickOnApply()
  }

  private renderSearchFilter() {
    return (
      <EntityNameFilter
        className="logistics-filter-button no-width row brada5 y-center px5 mx4"
        filters={this.props.logisticsStore.filters}
      />
    )
  }
}
