import { observable } from 'mobx'

import {
  FilterType,
  HierarchyModes,
  IHierarchyConfigurations,
  IHierarchyConfigurationsInput,
  IViewTypes,
} from '~/client/graph'
import ViewTypes from '~/client/src/shared/enums/ViewTypes'
import BaseModel from '~/client/src/shared/models/BaseModel'
import Guard from '~/client/src/shared/utils/Guard'

const DEFAULT_BANDS_ORDER: FilterType[] = [
  FilterType.Level,
  FilterType.Zone,
  FilterType.Company,
]

const DEFAULT_HIERARCHY_VIEW_MODES: IViewTypes = {
  [ViewTypes.webGantt]: HierarchyModes.Wbs,
  [ViewTypes.webSitemap]: HierarchyModes.Hierarchy,
  [ViewTypes.mobile]: HierarchyModes.Hierarchy,
  [ViewTypes.tablet]: HierarchyModes.Hierarchy,
}

export default class HierarchyConfig
  extends BaseModel<IHierarchyConfigurations>
  implements IHierarchyConfigurations
{
  public static fromDto(dto: IHierarchyConfigurations) {
    const {
      id,
      projectId,
      bandsHierarchyOrder = [],
      viewsToHierarchyModes,
      canGCUpdateActivity = true,
    } = dto

    return new HierarchyConfig(
      id,
      projectId,
      Object.values(bandsHierarchyOrder),
      viewsToHierarchyModes,
      canGCUpdateActivity,
    )
  }

  public static getDefault() {
    return new HierarchyConfig(
      '',
      '',
      DEFAULT_BANDS_ORDER,
      DEFAULT_HIERARCHY_VIEW_MODES,
      true,
    )
  }

  @observable public bandsHierarchyOrder: FilterType[]
  @observable public viewsToHierarchyModes: IViewTypes
  @observable public canGCUpdateActivity: boolean

  public constructor(
    public id: string,
    public projectId: string,
    bandsHierarchyOrder: FilterType[],
    viewsToHierarchyModes: IViewTypes,
    canGCUpdateActivity: boolean,
  ) {
    super(id)

    Guard.requireAll({
      id,
      projectId,
      bandsHierarchyOrder,
      viewsToHierarchyModes,
      canGCUpdateActivity,
    })

    this.bandsHierarchyOrder = bandsHierarchyOrder
    this.viewsToHierarchyModes = viewsToHierarchyModes
    this.canGCUpdateActivity = canGCUpdateActivity
  }

  public get isDefault() {
    return !this.id
  }

  public toDto(): IHierarchyConfigurationsInput {
    return {
      id: this.id,
      projectId: this.projectId,
      canGCUpdateActivity: this.canGCUpdateActivity,
      bandsHierarchyOrder: this.bandsHierarchyOrder.map(band => band),
      viewsToHierarchyModes: {
        mobile: this.viewsToHierarchyModes.mobile,
        tablet: this.viewsToHierarchyModes.tablet,
        webGantt: this.viewsToHierarchyModes.webGantt,
        webSitemap: this.viewsToHierarchyModes.webSitemap,
      },
    }
  }
}
